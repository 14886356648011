import {EntityModel} from '../core/model/entity.model';
import {Impuesto} from './v4_3/impuesto';
import {Descuento} from './v4_3/descuento';
import {Document} from './document';
import {BankAccount} from './bank-account';
import {Account} from './account';
import {AuditableModel} from '../core/model/auditable.model';

export class DocumentLine extends AuditableModel {
  constructor(public id: number = 0,
              public categorized: boolean = false,
              public classified: boolean = false,
              public key: string = '',
              public sequence: string= '',
              public currency: string= 'CRC',
              public issuer: string = '',
              public selected: boolean = false,
              public categoryId?: number,
              public commercialActivityCode?: string,
              public taxes?: string,
              public discounts?: string,
              public allowCommercialActivityCodeEdit?: boolean,
              public cabysCode?: string,
              public operationType?: OperationType,
              public account?: number,
              public taxRegistryAccount?: number,
              public counterPartAccount?: number,
              public bankAccountId?: number,
              public bankAccount?: BankAccount,
              public budgetAccountId?: number,
              public budgetAccount?: Account,
              public sellCondition?: string,
              public paymentMethod?: string,
              public processed?: boolean,
              public lineNumber?: number,
              public detail?: string,
              public subTotal?: number,
              public totalAmount?: number,
              public netTax?: number,
              public quantity?: number,
              public units?: string,
              public exonerationAmount?: number,
              public exonerationDocument?: string,
              public document?: Document,
              public taxesDetails: Impuesto[] = [],
              public discountsDetails: Descuento[] = [],
              public state = false,
              public budgetFormSelected?: boolean,
              public budgetConfigured?: boolean,
              public valueAddedTax?: number,
              public otherTax?: number,
              public bankReceipt?: string,
              public validCategory = true,
              public invalidClassification = false,
              public invalidDifference = 0
              ) {
    super(id);
  }
}

export class DocumentLineCategorized extends EntityModel {
  constructor(public id: number,
              public categoryId?: number,
              public commercialActivityCode?: string
  ) {
    super(id);
  }
}

export class DocumentLineClassified extends EntityModel {
  constructor(public id: number,
              public account?: number,
              public taxRegistryAccount?: number,
              public counterPartAccount?: number,
              public bankReceipt?: string
  ) {
    super(id);
  }
}

export class DocumentLineBudgetConfigured extends EntityModel {
  constructor(public id: number,
              public bankAccountId?: number,
              public budgetAccountId?: number
  ) {
    super(id);
  }
}

export enum OperationType {
  PURCHASE= 'PURCHASE',
  SELL= 'SELL',
  ALL = 'ALL'
}
