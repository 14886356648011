import {Injectable} from '@angular/core';

import {BaseService} from '../core/service/base.service';

import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HeaderConstants} from '../core/utils/headers.constants';
import {Account, AccountType, BudgetAccountBalance, MultipleClientAccount} from '../model/account';

@Injectable()
export class AccountService extends BaseService<Account> {

  serviceUrl(): string {
    return `/api/t/{tenant}/accounts`;
  }

  constructor(protected http: HttpClient) {
    super(http);
  }

  getClientAccounts(parents?: string[], level?: number, order?: string,
                    type: AccountType = AccountType.ACCOUNTING,
                    taxAccountSell: boolean = false, taxAccountPurchase: boolean = false): Observable<Account[]> {
    return this.getClientAccountsWithExactLevel(parents, level, undefined, order, type, taxAccountSell, taxAccountPurchase);
  }

  getClientAccountsWithExactLevel(parents?: string[], level?: number, exactLevel?: number, order?: string,
                                  type: AccountType = AccountType.ACCOUNTING,
                                  taxAccountSell: boolean = false, taxAccountPurchase: boolean = false): Observable<Account[]> {
    const headersValue = new HttpHeaders().append(HeaderConstants.CACHED, 'false');
    let paramsValue = new HttpParams();
    if (parents) {
      for (const parent of parents) {
        paramsValue = paramsValue.append('parent', parent);
      }
    }
    if (level) {
      paramsValue = paramsValue.append('level', level);
    }
    if (exactLevel) {
      paramsValue = paramsValue.append('exactLevel', exactLevel);
    }
    if (order) {
      paramsValue = paramsValue.append('order', order);
    }
    if (type) {
      paramsValue = paramsValue.append('type', type);
    }
    if (taxAccountSell) {
      paramsValue = paramsValue.append('taxAccountSell', taxAccountSell);
    }
    if (taxAccountPurchase) {
      paramsValue = paramsValue.append('taxAccountPurchase', taxAccountPurchase);
    }
    const options = {headers: headersValue, params: paramsValue};
    return this.http.get<Account[]>(`${this.serviceUrl()}/search`, options);
  }

  updateAll(model: Account[]): Observable<any> {
    return this.http
      .post(`${this.serviceUrl()}/all`, JSON.stringify(new MultipleClientAccount(model)), {responseType: 'blob'});
  }

  export(type: AccountType): Observable<any> {
    return this.http.post(`${this.serviceUrl()}/export?type=${type}`, '{}', {responseType: 'blob'});
  }

  public upload(formData: FormData, type: AccountType): Observable<any> {
    let paramsValue = new HttpParams();
    if (type) {
      paramsValue = paramsValue.append('type', type);
    }
    const headersValue = new HttpHeaders().append(HeaderConstants.IGNORE_CONTENT_TYPE, 'true');
    const options = {headers: headersValue, params: paramsValue};
    return this.http.post<any>(`${this.serviceUrl()}/import`, formData, options);
  }

  getBudgetAccountBalance(code: string): Observable<BudgetAccountBalance> {
    return this.http.get<BudgetAccountBalance>(`${this.serviceUrl()}/budget/balance/${code}`);
  }

  startNewBudgetPeriod(): Observable<any> {
    return this.http.post(`${this.serviceUrl()}/budget/start`, {});
  }

  getCurrentBudgetPeriod(): Observable<number> {
    return this.http.get<number>(`${this.serviceUrl()}/budget/year`);
  }
}
