import {Injectable} from '@angular/core';

@Injectable()
export class I18n {

  language = 'es';

  constructor() {
  }

}
