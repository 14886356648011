import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {LocalDate} from '@js-joda/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import ServiceUtils from '../../utils/service.utils';


@Component({
  selector: 'mt-date-picker',
  templateUrl: './date-picker.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatePickerComponent),
    multi: true
  }]
})
export class DatePickerComponent implements OnInit {

  // @ts-ignore
  model: NgbDateStruct;
  private internalDate: LocalDate | undefined;

  @Output() public dateChange = new EventEmitter<LocalDate>();
  @Input()
  get date(): LocalDate | undefined { return this.internalDate; }
  set date(date: LocalDate | undefined) {
    if (date !== undefined) {
      this.internalDate = ServiceUtils.dateReviver('', date) as LocalDate;
      this.model = {year: this.internalDate.year(), month: this.internalDate.monthValue(), day: this.internalDate.dayOfMonth()};
    }
  }
  @Input() public name: string | undefined;
  @Input() public label: string | undefined;
  @Input() public id: string | undefined;

  constructor() {
  }

  ngOnInit(): void {
  }

  dateChanged(newDate: NgbDateStruct): void {
    if (newDate === undefined || newDate === null) {
      this.internalDate = undefined;
    } else {
      this.internalDate = LocalDate.of(newDate.year, newDate.month, newDate.day);
    }
    this.dateChange.emit(this.internalDate as LocalDate);
  }

}
