import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AlertService} from '../../../core/service/alert.service';
import {ClientService} from '../../../service/client.service';
import {
  Client,
  CommercialActivity,
  Configuration,
  ConfigurationAndClient, ReportConfiguration
} from '../../../model/client';
import {SecurityService} from '../../../core/service/security.service';
import {Router} from '@angular/router';
import {CodeService} from '../../../service/code.service';
import {Code, CodeType, PercentageData} from '../../../model/code';
import {MessageService} from '../../../core/service/message.service';
import {HaciendaService} from '../../../service/hacienda.service';
import {TenantService} from '../../../core/service/tenant.service';
import {Tenant} from '../../../core/model/tenant';
import {TenantsReloadedMessage} from '../../../core/message/tenant.message';
import {TenantUserService} from '../../../service/tenant-user.service';
import {CoreComponent} from '../../../core/component/core.component';
import {ConfigurationService} from '../../../service/configuration.service';
import {LoggerService} from '../../../service/logger.service';
import ServiceUtils from '../../../core/utils/service.utils';

@Component({
  selector: 'app-main-client',
  templateUrl: './main-client.component.html'
})
export class MainClientComponent extends CoreComponent implements OnInit, OnDestroy {

  @ViewChild('fileUpload', {static: false})
  // @ts-ignore
  fileUpload: ElementRef;
  identificationTypes: Code[] = [];
  location1: Code[] = [];
  location2: Code[] = [];
  location3: Code[] = [];
  location4: Code[] = [];
  codeSelected = '';
  descriptionSelected = '';
  percentageSelected = 0;
  accepted = '';
  text = '';
  model = new ConfigurationAndClient();

  constructor(private service: ConfigurationService,
              protected alertService: AlertService,
              protected router: Router,
              protected securityService: SecurityService,
              protected codeService: CodeService,
              protected messageService: MessageService,
              protected haciendaService: HaciendaService,
              protected tenantService: TenantService,
              protected userService: TenantUserService,
              protected loggerService: LoggerService) {
    super(alertService, router, securityService, loggerService);
  }

  protected cleanErrors(): void {
    this.formErrors = this.clearFormErrors();
    this.alert.clear();
  }

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>().set('client.name', '').set('client.phone', '').set('client.email', '');
  }

  ngOnInit(): void {
    this.loadConfiguration();
    this.subscriptions.push(this.codeService.getCodes(CodeType.TIPO_IDENTIFICACION)
      .subscribe(response => {
        this.identificationTypes = response;
      }));
    this.subscriptions.push(this.codeService.getCodes(CodeType.UBICACION1)
      .subscribe(response => {
        this.location1 = response;
      }));
  }

  canRefresh(): boolean {
    return !!ServiceUtils.getItem('tenant');
  }

  onLocationChangeEvent(eventValue: any, location: string): void {
    if (location === 'location1' && !eventValue) {
      this.location2 = [];
      this.location3 = [];
      this.location4 = [];
    }else if (location === 'location1') {
      const parentId = this.location1.find(value => value.code === eventValue)?.id;
      this.codeService.getCodes(CodeType.UBICACION2, undefined, parentId)
        .subscribe(response => {
          this.location2 = response;
          this.location3 = [];
          this.location4 = [];
        });
    } else if (location === 'location2') {
      const parentId = this.location2.find(value => value.code === eventValue)?.id;
      this.codeService.getCodes(CodeType.UBICACION3, undefined, parentId)
        .subscribe(response => {
          this.location3 = response;
          this.location4 = [];
        });
    } else if (location === 'location3') {
      const parentId = this.location3.find(value => value.code === eventValue)?.id;
      this.codeService.getCodes(CodeType.UBICACION4, undefined, parentId)
        .subscribe(response => {
          this.location4 = response;
        });
    }
  }

  onModelUpdate(): void {
    if (this.model.client.locationLevel1) {
      const parentId1 = this.location1.find(value => value.code === this.model.client.locationLevel1)?.id;
      this.codeService.getCodes(CodeType.UBICACION2, undefined, parentId1)
        .subscribe(response1 => {
          this.location2 = response1;
          if (this.model.client.locationLevel2) {
            const parentId2 = this.location2.find(value => value.code === this.model.client.locationLevel2)?.id;
            this.codeService.getCodes(CodeType.UBICACION3, undefined, parentId2)
              .subscribe(response2 => {
                this.location3 = response2;
                if (this.model.client.locationLevel3) {
                  const parentId3 = this.location3.find(value => value.code === this.model.client.locationLevel3)?.id;
                  this.codeService.getCodes(CodeType.UBICACION4, undefined, parentId3)
                    .subscribe(response3 => {
                      this.location4 = response3;
                    });
                }
              });
          }
        });
    }
  }

  loadConfiguration(): void {
    this.loading = true;
    this.service.get().subscribe(
      value => {
        if (value) {
          if (!value.client) {
            value.client = new Client();
          }
          if (!value.configuration) {
            value.configuration = new Configuration();
            value.configuration.publicInstitution = false;
            value.configuration.generateValueAddedTax = false;
          }
          if (!value.configuration.publicInstitutionReportData) {
            value.configuration.publicInstitutionReportData = new ReportConfiguration();
          }
          this.model = value;
          this.onModelUpdate();
        }
        this.loading = false;
      },
      error => this.processError(error));
  }

  protected cleanData(): void {
    this.location2 = [];
    this.location3 = [];
    this.location4 = [];
  }

  saveEvent(): void {
    this.cleanErrors();
    // this.clientAccountService.saveAll(this.clientAccounts).subscribe( value => {}, error => this.processError(error));
    this.model.configuration.hasCertificateConfigured = true;
    this.model.configuration.hasPinConfigured = true;
    this.model.configuration.hasPasswordConfigured = true;
    if (this.model.client.id) {
      this.service.update(this.model)
        .subscribe(
          completed => {
            this.alert.success('Registro guardado exitósamente');
          },
          error => this.processError(error));
    } else {
      this.service.create(this.model)
        .subscribe(
          completed => {
            this.alert.success('Registro guardado exitósamente');
          },
          error => this.processError(error));
    }
  }

  loadTaxPayerData(identification?: string): void {
    if (identification) {
      this.loading = true;
      this.haciendaService.getTaxPayerDetails(identification).subscribe(
        value => {
          this.model.client.configuration.commercialActivities = [];
          value.actividades?.forEach(activity => {
            const commercialActivity = new CommercialActivity();
            commercialActivity.code = activity.codigo ? activity.codigo : '';
            this.model.client.configuration.commercialActivities.push(commercialActivity);
            this.codeService.getCodes( CodeType.ACTIVIDAD_ECONOMICA, undefined, undefined, activity.codigo)
              .subscribe(codeValue => commercialActivity.description = codeValue[0].description ? codeValue[0].description : '');
          });
          this.model.client.name = value.nombre;
          this.model.client.identificationType = value.tipoIdentificacion;
          this.loading = false;
        }, error => this.processError(error));
    }
  }

  deleteTenant(): void {
    const tenantFromStorage = ServiceUtils.getItem('tenant');
    const currentTenant = JSON.parse(tenantFromStorage ? tenantFromStorage : '{}') as Tenant;
    this.accepted = '';
    if (currentTenant.id) {
      this.loading = true;
      this.tenantService.deleteTenant(currentTenant.id).subscribe(value => {
        this.userService.getUserDetails()
          .subscribe(
            user => {
                  this.loading = false;
                  this.messageService.publish(new TenantsReloadedMessage());
                }
          );
      }, error => this.processError(error));
    }
  }

  savePercentage(): void {
    const data = new Code();
    data.data = `{"percentage":${this.percentageSelected}}`;
    this.codeService.updateCodeData(CodeType.ACTIVIDAD_ECONOMICA, this.codeSelected, data)
      .subscribe(value => {});
  }

  editPercentage(code?: string, description?: string): void {
    this.codeSelected = code ? code : '';
    this.descriptionSelected = description ? description : '';
    this.codeService.getCodes(CodeType.ACTIVIDAD_ECONOMICA, undefined, undefined, code, true)
      .subscribe(value => {
        if (value.length > 0) {
          const data = value[0].data;
          const percentage = JSON.parse(data ? data : '{}') as PercentageData;
          this.percentageSelected = percentage.percentage ? percentage.percentage : 0;
        }
      });
  }

  getBase64(event: any): void {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.model.configuration.invoicingCertificate = reader.result as string;
      console.log(reader.result);
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  deactivateTenant(): void {
    const tenantFromStorage = ServiceUtils.getItem('tenant');
    const currentTenant = JSON.parse(tenantFromStorage ? tenantFromStorage : '{}') as Tenant;
    this.accepted = '';
    if (currentTenant.id) {
      this.loading = true;
      this.tenantService.deactivateTenant(currentTenant.id).subscribe(value => {
        this.userService.getUserDetails()
          .subscribe(
            user => {
              this.loading = false;
              this.messageService.publish(new TenantsReloadedMessage());
            }
          );
      }, error => this.processError(error));
    }
  }
}

