import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../core/component/base.component';

import {AlertService} from '../../../core/service/alert.service';
import {MessageService} from '../../../core/service/message.service';
import {Router} from '@angular/router';
import {SecurityService} from '../../../core/service/security.service';
import {LoggerService} from '../../../service/logger.service';

import {PayrollService} from '../../service/payroll.service';
import {Payroll} from '../../service/resource/payroll';
import {EmployeeService} from '../../service/employee.service';
import {Employee} from '../../service/resource/employee';
import {LocalDate} from '@js-joda/core';
import {Periodicity} from '../../service/resource/salary';

@Component({
  selector: 'app-payroll',
  templateUrl: './payroll.component.html'
})
export class PayrollComponent extends BaseComponent<Payroll> implements OnInit, OnDestroy {

  employees: Employee[] = [];
  selectedDate: LocalDate = LocalDate.now();

  constructor(private payrollService: PayrollService,
              private alertService: AlertService,
              protected messageService: MessageService,
              protected loggerService: LoggerService,
              protected router: Router,
              protected securityService: SecurityService,
              protected employeeService: EmployeeService) {
    super(payrollService, 'Payroll', alertService, router, securityService, messageService, loggerService);
  }

  getModelInstance(): Payroll {
    return new Payroll();
  }

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.employeeService.getAll().subscribe({
        next: (v) => this.employees = v,
        error: (e) => this.processError(e)
      }
    );
  }

  generateEvent(selectedDate: LocalDate): void {
    this.payrollService.generate(selectedDate).subscribe(
      {
        complete: () => this.refreshEvent(),
        error: (e) => this.processError(e)
      }
    );
  }

  applyEvent(): void {
  }

  protected readonly Periodicity = Periodicity;
}
