<div class="container">

  <div class="loader" *ngIf="loading"></div>

  <div *ngIf="!loading" class="animate__animated animate__fadeIn">
    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">
      <div class="container-fluid">
        <span class="navbar-text">
          Reporte de Gastos
        </span>
      </div>
    </nav>

    <div>
      <mt-pagination (loadPage)="getPageEvent($event)" [page]="accountingPeriods" [showPages]="false"></mt-pagination>
      <table *ngIf="accountingPeriods.metadata.pages > 0" class="table table-hover">
        <thead>
        <tr>
          <th>
            Mes
          </th>
          <th>
            Estado
          </th>
          <th>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let accountingPeriod of accountingPeriods.content">
          <td class=" align-middle">{{accountingPeriod.startDate.toString() | date:'MMMM, y' }}</td>
          <td class=" align-middle">{{accountingPeriod.closed? 'Cerrado' : 'Abierto'}}</td>
          <td class="text-end  align-middle">
            <button class="btn btn-link pull-right" data-bs-toggle="modal" data-bs-target="#removeCategoryConfirmation"
                    (click)="exportAccountingEntriesResults(accountingPeriod.startDate)" title="Reporte Mensual">
              <span class="fa fa-download fa-lg"></span>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <div *ngIf="accountingPeriods.metadata.pages == 0" class="p-3">
        No hay registros
      </div>
    </div>
  </div>
</div>


