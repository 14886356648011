import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './core/guard/auth.guard';
import {ClientComponent} from './component/client/client.component';
import {RegistrationComponent} from './core/component/registration/registration.component';
import {ConfirmationComponent} from './core/component/confirmation/confirmation.component';
import {PrivilegeName} from './core/model/user';
import {DocumentComponent} from './component/document/view/document.component';
import {CreateDocumentComponent} from './component/document/add/create-document.component';
import {ValidateComponent} from './component/document/validate/validate.component';
import {MainClientComponent} from './component/configuration/general/main-client.component';
import {LoginComponent} from './core/component/login/login.component';
import {CompanyComponent} from './component/company/company.component';
import {DashboardComponent} from './component/dashboard/dashboard.component';
import {ClassifyComponent} from './component/document/classify/classify.component';
import {RecoverComponent} from './core/component/recover/recover.component';
import {ClientAccountComponent} from './component/configuration/accounts/client-account.component';
import {ClosePeriodComponent} from './component/close-period/close-period.component';
import {BalanceReportComponent} from './component/report/balance/balance-report.component';
import {ResultsReportComponent} from './component/report/results/results-report.component';
import {AccountsReceivableComponent} from './component/auxiliaries/accounts-receivable/accounts-receivable.component';
import {ExpensesReportComponent} from './component/report/expenses/expenses-report.component';
import {FormD101Component} from './component/forms/d101/form-d101.component';
import {CashPaymentsReportComponent} from './component/report/cash-payments/cash-payments-report.component';
import {BankAccountComponent} from './component/configuration/bank-accounts/bank-account.component';
import {AccountingFormComponent} from './component/document/accounting-form/accounting-form.component';
import {BudgetFormComponent} from './component/budget-form/budget-form.component';
import {BudgetFormReportComponent} from './component/report/budget-form/budget-form-report.component';
import {RetentionReportComponent} from './component/report/retention/retention-report.component';
import {QuarterlyReportComponent} from './component/report/quarterly/quarterly-report.component';
import {BudgetAccountBalanceComponent} from './component/budget-account-balance/budget-account-balance.component';
import {ConsolidatedReportComponent} from './component/report/consolidated/consolidated-report.component';
import {ConsolidatedLastLevelReportComponent} from './component/report/consolidated-last-level/consolidated-last-level-report.component';
import {BudgetModificationComponent} from './component/budget-modification/budget-modification.component';
import {
  RetentionProviderReportComponent
} from './component/report/retention-provider/retention-provider-report.component';
import {
  InvoiceDetailsProviderReportComponent
} from './component/report/invoice-details-provider/invoice-details-provider-report.component';
import {AddPaymentComponent} from './component/document/add-payment/add-payment.component';
import {TenantUserComponent} from './component/tenant-user/tenant-user.component';
import {UserComponent} from './core/component/user/user.component';
import {DepartmentComponent} from './rhplus/component/department/department.component';
import {JobPositionComponent} from './rhplus/component/job-position/job-position.component';
import {EmployeeComponent} from './rhplus/component/employee/employee.component';
import {IncomeTaxRangeComponent} from './rhplus/component/income-tax-range/income-tax-range.component';
import {WorkdayComponent} from './rhplus/component/workday/workday.component';
import {BonusComponent} from './rhplus/component/bonus/bonus.component';
import {SalaryComponent} from './rhplus/component/salary/salary.component';
import {AbsenceComponent} from './rhplus/component/absence/absence.component';
import {WorkdayLogComponent} from './rhplus/component/workday-log/workday-log.component';
import {HolidayComponent} from './rhplus/component/holiday/holiday.component';
import {PayrollComponent} from './rhplus/component/payroll/payroll.component';

const routes: Routes = [
  {path: '', redirectTo: '/inicio', pathMatch: 'full'},
  {path: 'login',
    component: LoginComponent
  },
  // pages
  {
    path: 'clientes',
    component: ClientComponent,
    canActivate: [AuthGuard],
    data: {roles: [PrivilegeName.ADMIN_CLIENTS]}
  },
  {
    path: 'inicio',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {roles: [PrivilegeName.ADMIN_DOCUMENTS, PrivilegeName.VIEW_DOCUMENTS]}
  },
  {
    path: 'documentos',
    children: [
      {
        path: 'agregar',
        component: CreateDocumentComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.ADMIN_DOCUMENTS]}
      },
      {
        path: 'editar-pago/:id',
        component: AddPaymentComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.ADMIN_DOCUMENTS]}
      },
      {
        path: 'agregar-pago',
        component: AddPaymentComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.ADMIN_DOCUMENTS]}
      },
      {
        path: 'ver',
        component: DocumentComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.ADMIN_DOCUMENTS, PrivilegeName.VIEW_DOCUMENTS]}
      },
      {
        path: 'categorizar',
        component: ValidateComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.ADMIN_DOCUMENTS]}
      },
      {
        path: 'clasificar',
        component: ClassifyComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.ADMIN_DOCUMENTS]}
      },
      {
        path: 'planilla',
        component: AccountingFormComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.ADMIN_DOCUMENTS]}
      }
    ]
  },
  {
    path: 'configuracion',
    children: [
      {
        path: 'general',
        component: MainClientComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.ADMIN_ACCOUNT]}
      },
      {
        path: 'cuentas-bancarias',
        component: BankAccountComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.ADMIN_ACCOUNT]}
      },
      {
        path: 'cuentas/:type',
        component: ClientAccountComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.ADMIN_ACCOUNT]}
      },
      {
        path: 'usuarios',
        component: TenantUserComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.ADMIN_USERS]}
      },
    ]
  },
  {
    path: 'auxiliares',
    children: [
      {
        path: 'cuentas-por-cobrar',
        component: AccountsReceivableComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.ADMIN_DOCUMENTS]}
      },
      {
        path: 'balance-cuentas-presupuestarias',
        component: BudgetAccountBalanceComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.ADMIN_DOCUMENTS]}
      }
    ]
  },
  {
    path: 'formularios',
    children: [
      {
        path: 'd101',
        component: FormD101Component,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.VIEW_REPORTS]}
      }
    ]
  },
  {
    path: 'reportes',
    children: [
      {
        path: 'balance',
        component: BalanceReportComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.VIEW_REPORTS]}
      },
      {
        path: 'gastos',
        component: ExpensesReportComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.VIEW_REPORTS]}
      },
      {
        path: 'resultados',
        component: ResultsReportComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.VIEW_REPORTS]}
      },
      {
        path: 'pagos-efectivo',
        component: CashPaymentsReportComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.VIEW_REPORTS]}
      },
      {
        path: 'planillas',
        component: BudgetFormReportComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.VIEW_REPORTS]}
      },
      {
        path: 'retenciones',
        component: RetentionReportComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.VIEW_REPORTS]}
      },
      {
        path: 'trimestral',
        component: QuarterlyReportComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.VIEW_REPORTS]}
      },
      {
        path: 'consolidado',
        component: ConsolidatedReportComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.VIEW_REPORTS]}
      },
      {
        path: 'segregado',
        component: ConsolidatedLastLevelReportComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.VIEW_REPORTS]}
      },
      {
        path: 'retenciones-proveedor',
        component: RetentionProviderReportComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.VIEW_REPORTS]}
      }
      ,
      {
        path: 'detalles-facturas-proveedor',
        component: InvoiceDetailsProviderReportComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.VIEW_REPORTS]}
      }
    ]
  },
  {
    path: 'procesos',
    children: [
      {
        path: 'cierre-periodo',
        component: ClosePeriodComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.ADMIN_DOCUMENTS]}
      },
      {
        path: 'planilla-presupuestaria',
        component: BudgetFormComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.ADMIN_DOCUMENTS]}
      },
      {
        path: 'modificacion-presupuestaria',
        component: BudgetModificationComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.ADMIN_DOCUMENTS]}
      }
    ]
  },
  {
    path: 'sistema',
    children: [
      {
        path: 'usuarios',
        component: UserComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.ADMIN_SYSTEM]}
      }
    ]
  },
  {
    path: 'recursos-humanos',
    children: [
      {
        path: 'departamentos',
        component: DepartmentComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.ADMIN_RRHH]}
      },
      {
        path: 'posiciones-laborales',
        component: JobPositionComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.ADMIN_RRHH]}
      },
      {
        path: 'empleados',
        component: EmployeeComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.ADMIN_RRHH]}
      },
      {
        path: 'registro-horas',
        component: WorkdayLogComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.ADMIN_RRHH]}
      },
      {
        path: 'rangos-impuesto-renta',
        component: IncomeTaxRangeComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.ADMIN_RRHH]}
      },
      {
        path: 'jornadas',
        component: WorkdayComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.ADMIN_RRHH]}
      },
      {
        path: 'bonos',
        component: BonusComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.ADMIN_RRHH]}
      },
      {
        path: 'ausencias',
        component: AbsenceComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.ADMIN_RRHH]}
      }
      ,
      {
        path: 'salarios',
        component: SalaryComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.ADMIN_RRHH]}
      },
      {
        path: 'feriados',
        component: HolidayComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.ADMIN_RRHH]}
      },
      {
        path: 'pagos',
        component: PayrollComponent,
        canActivate: [AuthGuard],
        data: {roles: [PrivilegeName.ADMIN_RRHH]}
      }
    ]
  },
  {
    path: 'company',
    component: CompanyComponent,
    canActivate: [AuthGuard],
    data: {roles: [PrivilegeName.ADMIN_ACCOUNT]}
  },
  {
    path: 'register',
    component: RegistrationComponent
  },
  {
    path: 'recover',
    component: RecoverComponent
  },
  {
    path: 'confirmation/:code',
    component: ConfirmationComponent
  },
  // otherwise redirect to home
  {path: '**', redirectTo: 'inicio'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
