import {Component, OnDestroy, OnInit} from '@angular/core';
import {CoreComponent} from '../core.component';
import {AlertService} from '../../service/alert.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SecurityService} from '../../service/security.service';
import {PublicService} from '../../service/public.service';
import {UserConfirmation} from '../../model/user-confirmation.model';
import {MessageService} from '../../service/message.service';
import {LogoutMessage} from '../../message/logout.message';
import {LoggerService} from "../../../service/logger.service";

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html'
})
export class ConfirmationComponent extends CoreComponent implements OnInit, OnDestroy {

  private sub: any;

  private code = '';

  model: UserConfirmation = new UserConfirmation();

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>().set('password', '').set('password-confirmation', '');
  }

  constructor(protected alert: AlertService,
              protected router: Router,
              private route: ActivatedRoute,
              protected messageService: MessageService,
              protected securityService: SecurityService,
              protected publicService: PublicService,
              protected loggerService: LoggerService) {
    super(alert, router, securityService, loggerService);
  }

  ngOnInit(): void {
    this.messageService.publish(new LogoutMessage(true));
    this.sub = this.route.params.subscribe(params => {
      const code = params['code' as string];
      if (code) {
        this.code = code;
      }
    });
  }


  registerUser(): void {
    this.clearFormErrors();
    this.loading = true;
    this.publicService
      .confirmUser(this.code, this.model)
      .subscribe(
        completed => {
          this.alert.success('Contraseña actualizada exitósamente');
          this.loading = false;
          this.router.navigate(['/login']);
        },
        error => this.processError(error));

  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }


}
