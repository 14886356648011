import {Exoneracion} from './exoneracion';

export class Impuesto {
    public Codigo?: string;
    public CodigoTarifa?: string;
    public Tarifa?: number;
    public FactorIVA?: number;
    public Monto?: number;
    public MontoExportacion?: number;
    public Exoneracion?: Exoneracion;
}
