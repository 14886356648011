import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../../service/alert.service';
import {SecurityService} from '../../service/security.service';
import {CoreComponent} from '../core.component';
import {LoginMessage} from '../../message/login.message';
import {MessageService} from '../../service/message.service';
import {TenantUserService} from '../../../service/tenant-user.service';
import {UserFullInfo} from '../../model/user';
import {Subscription} from 'rxjs';
import {TenantStatus} from '../../model/tenant';
import {LogoutMessage} from '../../message/logout.message';
import {LoggerService} from '../../../service/logger.service';
import ServiceUtils from '../../utils/service.utils';


@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.css']
})

export class LoginComponent extends CoreComponent implements OnInit, OnDestroy {

  model: any = {};
  loading = false;
  returnUrl = '';
  subscriptions: Subscription[] = [];

  constructor(private route: ActivatedRoute,
              protected router: Router,
              protected securityService: SecurityService,
              protected alertService: AlertService,
              private messageService: MessageService,
              private userService: TenantUserService,
              protected loggerService: LoggerService) {
    super(alertService, router, securityService, loggerService);
  }

  ngOnInit(): void {
    // reset login status
    // this.securityService.logout();

    // get return url from route parameters or default to '/'
    this.messageService.publish(new LogoutMessage(true));
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>();
  }

  login(): void {
    this.loading = true;
    this.securityService.login(this.model.username, this.model.password)
      .subscribe(
        data => {
          if (data && data['token' as string]) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            ServiceUtils.setItem('token', data['token' as string]);
            if (ServiceUtils.getItem('token')) {
              console.info('loading user...');
              this.userService.getUserDetails()
                .subscribe(
                  user => this.postLogin(user),
                  error => this.logout()
                );
            }
          }
        },
        error => {
          if (error.status === 401) {
            this.alert.error('Credenciales inválidas');
          } else {
            this.processError(error);
          }
          this.loading = false;
        });
  }

  postLogin(user: UserFullInfo): void {
    console.info('post login user loaded...');
    let url = this.returnUrl;
    ServiceUtils.setItem('user', JSON.stringify(user.user));
    ServiceUtils.setItem('configuration', JSON.stringify(user.configuration));
    ServiceUtils.setItem('tenants', JSON.stringify(user.tenants));
    ServiceUtils.setItem('permissions', JSON.stringify(user.privilegeNames));
    if (user?.tenants?.length !== undefined ) {
      const selectedTenant = user.tenants[0];
      ServiceUtils.setItem('tenant', JSON.stringify(selectedTenant));
      if (selectedTenant.status === TenantStatus.PENDING_CONFIGURATION) {
        url = '/configuration/general';
        this.alertService.error('Debe completar la configuración general antes de cargar datos o crear clientes');
      }
    }
    this.router.navigate([url]).then(value => this.messageService.publish(new LoginMessage(true)));
  }

}
