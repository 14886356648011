import {LocalDate} from '@js-joda/core';
import {AuditableModel} from '../core/model/auditable.model';

export class AccountingPeriodMonth extends AuditableModel{
  constructor(
    public id: number,
    public closed?: boolean,
    public startDate: LocalDate = LocalDate.now(),
    public endDate: LocalDate = LocalDate.now()
  ) {
    super(id);
  }
}
