<div class="container">

  <div class="loader" *ngIf="loading"></div>

  <div *ngIf="mode == 'EDIT' && !loading" class="animated fadeIn">
    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top">
      <div class="container">
        <span class="navbar-text">
          Registro Horas
        </span>
        <div class="pull-right">
          <button class="btn btn-link" (click)="cancelEvent()">
            Cerrar
          </button>
          <button class="btn btn-primary" (click)="saveEvent()">
            Guardar
          </button>
        </div>
      </div>
    </nav>

    <div class="row g-3">
      <div class="col-md-3">
        <div class="form-group">
          <label for="date">Fecha</label>
          <mt-date-picker [(date)]="model.date" name="date" id="date" label="Fecha"/>
          <div *ngIf="formErrors.get('workdaylog.date')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('workdaylog.date') }}
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="employee">Empleado</label>
          <select class="form-select" id="employee" name="employee" [(ngModel)]="model.employee">
            <option *ngFor="let employee of employees" value="{{employee.id}}">{{employee.name}} {{employee.lastName}}</option>
          </select>
          <div *ngIf="formErrors.get('workdaylog.employee')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('workdaylog.employee') }}
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="workedHours">Horas Trabajadas</label>
          <mt-time-picker [(time)]="model.workedHours" name="workedHours" label="Horas Trabajadas" id="workedHours" [meridian]="false"/>
          <div *ngIf="formErrors.get('workdaylog.workedHours')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('workdaylog.workedHours') }}
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="acceptedHours">Horas Aceptadas</label>
          <mt-time-picker [(time)]="model.acceptedHours" name="acceptedHours" label="Horas Aceptadas" id="acceptedHours" [meridian]="false"/>
          <div *ngIf="formErrors.get('workdaylog.acceptedHours')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('workdaylog.acceptedHours') }}
          </div>
        </div>
      </div>
    </div>
    <h5  class="mb-3 mt-3">Horas Ingreso/Salida</h5>
    <hr class="mb-4">
    <button class="btn btn-secondary" (click)="addEntryEvent()">
      Agregar
    </button>
    <table *ngIf="model.workdayLogEntries.length > 0" class="table table-hover">
      <tbody>
      <tr *ngFor="let entry of model.workdayLogEntries; let i = index;">
        <td class="align-middle">
          <mt-time-picker [(time)]="entry.time" name="startTime" label="Hora"/>
        </td>

        <td class="text-end  align-middle">
          <button class="btn  pull-right btn-info me-2" (click)="removeEntryEvent(i)" title="Remover Registro">
            <span class="fa fa-trash fa-lg"></span>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
    <div *ngIf="model.workdayLogEntries.length == 0" class="p-3">
      No hay registros
    </div>
  </div>


  <div *ngIf="mode == 'LIST' && !loading" class="animated fadeIn">

    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">

        <div class="container-fluid">
          <form class="d-flex">
              <mt-date-picker [(date)]="from" label="Desde" class="me-2" (dateChange)="refreshEvent()"/>

              <mt-date-picker [(date)]="to" label="Hasta" class="me-2" (dateChange)="refreshEvent()"/>

              <div>
                <select class="form-select me-2" id="type" name="type" [(ngModel)]="employeeId" (ngModelChange)="refreshEvent()" title="Empleado">
                  <option *ngFor="let employee of employees" value="{{employee.id}}">{{ employee.name }}  {{employee.lastName}}</option>
                </select>
              </div>
          </form>
          <div class="pull-right">
            <button class="btn btn-link" (click)="refreshEvent()" title="Refrescar">
              <span class="fa fa-sync fa-lg"></span>
            </button>
            <button class="btn btn-primary" (click)="createEvent()">
              Agregar
            </button>
          </div>
        </div>


    </nav>

    <mt-pagination (loadPage)="getPageEvent($event)" [page]="page"></mt-pagination>

    <table *ngIf="page.metadata.pages > 0" class="table table-hover">
      <thead>
        <tr>
          <th>
            Fecha
          </th>
          <th>
            Horas Trabajadas
          </th>
          <th>
            Horas Aprobadas
          </th>
          <th>
            Empleado
          </th>
          <th>

          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let workday of page.content">
          <td (click)="editEvent(workday)" class="mt-content-col">
            {{workday.date}}
          </td>
          <td (click)="editEvent(workday)" class="mt-content-col">
            {{workday.workedHours}}
          </td>
          <td (click)="editEvent(workday)" class="mt-content-col">
            {{workday.acceptedHours}}
          </td>
          <td (click)="editEvent(workday)" class="mt-content-col">
            {{workday.employeeName}}
          </td>
          <td class="mt-actions-col">
            <button class="btn btn-link btn-lg pull-right" data-bs-toggle="modal" data-bs-target="#workdayLogConfirmation"
                    (click)="selectEvent(workday)" title="Borrar">
              <span class="fa fa-trash-alt fa-lg"></span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="page.metadata.pages == 0">
      No hay registros
    </div>

  </div>

  <!-- Modal -->
  <div class="modal fade" id="workdayLogConfirmation" tabindex="-1" role="dialog"
       aria-labelledby="removeConfirmationLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirmar</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Desea eliminar el registro?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="removeEvent(model)">Borrar</button>
        </div>
      </div>
    </div>
  </div>

</div>


