import {Component, OnDestroy, OnInit} from '@angular/core';
import {AccountingPeriodMonth} from '../../../model/accounting-period-month';
import {Page} from '../../../model/page';
import {CoreComponent} from '../../../core/component/core.component';
import {AlertService} from '../../../core/service/alert.service';
import {Router} from '@angular/router';
import {SecurityService} from '../../../core/service/security.service';
import {AccountingPeriodMonthService} from '../../../service/accounting-period-month.service';
import {MessageService} from '../../../core/service/message.service';
import {LocalDate} from '@js-joda/core';
import ServiceUtils from '../../../core/utils/service.utils';
import {ReportService} from '../../../service/report.service';
import {Tenant} from '../../../core/model/tenant';
import {LoggerService} from '../../../service/logger.service';

@Component({
  selector: 'app-cash-payments-report',
  templateUrl: './cash-payments-report.component.html'
})
export class CashPaymentsReportComponent extends CoreComponent implements OnInit, OnDestroy {

  accountingPeriods: Page<AccountingPeriodMonth> = new Page({count: 0, page: 0, pages: 0}, []);

  constructor(private alertService: AlertService,
              protected router: Router,
              protected securityService: SecurityService,
              protected reportService: ReportService,
              protected accountingPeriodService: AccountingPeriodMonthService,
              protected messageService: MessageService,
              protected loggerService: LoggerService) {
    super(alertService, router, securityService, loggerService);

  }

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>();
  }

  ngOnInit(): void {
    this.getPageEvent(0);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  exportAccountingEntriesBalance(month: LocalDate): void {
    this.loading = true;
    const revivedMonth = ServiceUtils.dateReviver('', month) as LocalDate;
    this.reportService.exportCashPaymentsToExcel(revivedMonth.month().name().toUpperCase(), revivedMonth.year().toString())
      .subscribe(
        response => {
          this.loading = false;
          // const typeContent = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8';
          // const blob: any = new Blob([response],
          //  {type: typeContent});
          // const url = window.URL.createObjectURL(blob);
          // window.open(url);
          const tenantFromStorage = ServiceUtils.getItem('tenant');
          const a = document.createElement('a');
          const objectUrl = URL.createObjectURL(response);
          a.href = objectUrl;
          if (tenantFromStorage) {
            const tenant = JSON.parse(tenantFromStorage) as Tenant;
            a.download = `Reporte_Pagos_en_Efectivo_${revivedMonth.month().value()}_${tenant.name?.replace(/\./g, '_')}.xls`;
          }
          a.click();
          URL.revokeObjectURL(objectUrl);
        }, error => this.processError(error)
      );
  }


  getPageEvent(pageNumber: number): void {
    this.accountingPeriodService
      .getPage(pageNumber, 12, ['startDate'], 'desc', true)
      .subscribe(
        value => this.accountingPeriods = value,
        error => this.processError(error));
  }
}
