import {Component, OnDestroy, OnInit} from '@angular/core';
import {Tenant, TenantStatus} from '../../model/tenant';
import {TenantUserService} from '../../../service/tenant-user.service';
import {MessageService} from '../../service/message.service';
import {TenantChangedMessage, TenantsReloadedMessage} from '../../message/tenant.message';
import {Router} from '@angular/router';
import {AlertService} from '../../service/alert.service';
import {CoreComponent} from '../core.component';
import {SecurityService} from '../../service/security.service';
import {LoggerService} from '../../../service/logger.service';
import ServiceUtils from '../../utils/service.utils';

@Component({
  selector: 'mt-tenant-selector',
  templateUrl: './tenant-selector.component.html'
})
export class TenantSelectorComponent extends CoreComponent implements OnInit, OnDestroy {

  currentTenant: Tenant = new Tenant();
  currentTenantId = 0;

  tenants: Tenant[] = [];

  messageSubscription: any;
  loadingTenants = false;

  constructor(private userService: TenantUserService, private messageService: MessageService,
              protected router: Router, protected alertService: AlertService, protected securityService: SecurityService,
              protected loggerService: LoggerService) {
    super(alertService, router, securityService, loggerService);
  }

  setTenant(tenantId: number): void {
    // Globals.tenant = tenant;
    let tenant = new Tenant();
    const filtered = this.tenants.filter(value => value.id === tenantId);
    if (filtered.length > 0) {
      tenant = filtered[0];
    }
    console.log(tenant);
    this.currentTenant = tenant;
    this.currentTenantId = tenant.id ? tenant.id : 0;
    ServiceUtils.setItem('tenant', JSON.stringify(tenant));
    this.router.navigate(['/'], {replaceUrl: true});
    if (tenant.status === TenantStatus.PENDING_CONFIGURATION) {
      this.alertService.error('Debe completar la configuración general antes de cargar datos o crear clientes');
    }
    this.messageService.publish(new TenantChangedMessage(tenant));

  }

  setTenants(tenants: Tenant[]): void {
    this.tenants = tenants;
    ServiceUtils.setItem('tenants', JSON.stringify(tenants));
    if (tenants.length > 0) {
      const tenantFromStorage = ServiceUtils.getItem('tenant');
      const currentTenant = JSON.parse(tenantFromStorage ? tenantFromStorage : '{}') as Tenant;
      if (tenants.filter(value => value.id === currentTenant.id).length === 0) {
        const tenant = tenants[0];
        this.setTenant(tenant.id ? tenant.id : 0);
      } else {
        console.log('Tenant already set ' + currentTenant.name);
        this.currentTenant = currentTenant;
        this.currentTenantId = currentTenant.id ? currentTenant.id : 0;
      }
    }
    this.loadingTenants = false;
  }

  ngOnInit(): void {
    this.loadTenants();
    this.messageSubscription = this.messageService.of(TenantsReloadedMessage).subscribe(
      message => {
        this.loadTenants();
      }, error => this.processError(error)
    );
  }

  ngOnDestroy(): void {
    this.messageSubscription.unsubscribe();
  }

  private loadTenants(): void {
    this.loadingTenants = true;
    this.userService.getUserTenants()
        .subscribe(
          tenants => this.setTenants(tenants), error => this.processError(error));
  }

}
