import {Component, OnDestroy, OnInit} from '@angular/core';
import {BudgetForm, BudgetFormLine, BudgetFormStatus} from '../../model/budget-form';
import {BaseComponent} from '../../core/component/base.component';
import {BudgetFormService} from '../../service/budget-form.service';
import {AlertService} from '../../core/service/alert.service';
import {MessageService} from '../../core/service/message.service';
import {SecurityService} from '../../core/service/security.service';
import {Router} from '@angular/router';
import {LoggerService} from '../../service/logger.service';
import {DocumentLineService} from '../../service/document-line.service';
import {DocumentLineFilter} from '../../filter/document-line.filter';
import {DocumentLine} from '../../model/document-line';
import {Page} from '../../model/page';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {LocalDate} from '@js-joda/core';
import {BudgetFormFilter} from '../../filter/budget-form.filter';
import {HttpErrorResponse} from '@angular/common/http';
import {FileResource} from '../../model/file';
import {AccountService} from '../../service/account.service';
import ServiceUtils from '../../core/utils/service.utils';

@Component({
  selector: 'app-budget-form',
  templateUrl: './budget-form.component.html'
})
export class BudgetFormComponent extends BaseComponent<BudgetForm> implements OnInit, OnDestroy {

  date: NgbDateStruct;
  availableDocumentLines: Page<DocumentLine> = new Page({count: 0, page: 0, pages: 0}, []);
  total = 0;
  text = '';
  sequence  = '';
  statusSearch = BudgetFormStatus.ALL;
  fileUploadErrors: ErrorEntry[] = [];
  attachments: FileResource[] = [];
  selectedAttachment?: FileResource;
  allSelected = false;
  countSelections = 0;
  periodYear?: number = undefined;
  from: LocalDate = LocalDate.now().withDayOfMonth(1);
  to: LocalDate = LocalDate.now().withDayOfMonth(this.from.lengthOfMonth());

  constructor(private budgetFormService: BudgetFormService,
              private documentLineService: DocumentLineService,
              private alertService: AlertService,
              protected messageService: MessageService,
              protected router: Router,
              protected securityService: SecurityService,
              protected clientAccountService: AccountService,
              protected loggerService: LoggerService) {
    super(budgetFormService, 'budget-form', alertService, router, securityService, messageService, loggerService);
    this.model.date = LocalDate.now().minusMonths(1).withDayOfMonth(1);
    this.cleanDateAfterSaveOrUpdate = false;
    this.date = {
      year: this.model.date.year(),
      month: this.model.date.monthValue(),
      day: this.model.date.dayOfMonth()
    };
    this.sortBy = ['date', 'sequence'];
  }

  getModelInstance(): BudgetForm {
    const budgetForm = new BudgetForm(BudgetFormStatus.PENDING, LocalDate.now());
    this.date = {
      year: budgetForm.date.year(),
      month: budgetForm.date.monthValue(),
      day: budgetForm.date.dayOfMonth()
    };
    return budgetForm;
  }

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>()
      .set('budgetform.code', '')
      .set('budgetform.date', '');
  }
  ngOnInit(): void {
    this.clientAccountService.getCurrentBudgetPeriod().subscribe(value => this.periodYear = value);
    super.ngOnInit();
  }

  loadAvailableDocuments(): void {
    const filter = new DocumentLineFilter();
    filter.budgetFormSelected = false;
    filter.budgetConfigured = true;
    if (this.text) {
      filter.text = this.text;
    }
    filter.from = this.from;
    filter.to = this.to;
    this.loading = true;
    this.documentLineService.searchByFilterLoadDocument(filter, 0, 1000)
      .subscribe(value => {
        this.availableDocumentLines = value;
        this.loading = false;
      }, error => this.processError(error));
  }

  dateChanged(date: NgbDateStruct): void {
    this.model.date  = LocalDate.of(date.year, date.month, date.day);
    this.date = date;
  }

  addDocumentLine(documentLine: DocumentLine): void {
    const budgetLine = new BudgetFormLine();
    budgetLine.documentLine = documentLine;
    budgetLine.paymentDate = this.model.date;
    // this.model.lines = [... this.model.lines, budgetLine];
    if (this.availableDocumentLines.content) {
      const copy = [...this.availableDocumentLines.content];
      // copy.splice(copy.findIndex(value => value.id === documentLine.id), 1);
      // move all other lines from same invoice
      const documentLinesSiblings = [];
      for (const documentLineSibling of copy) {
        if (documentLineSibling.document?.id === documentLine.document?.id) {
          documentLinesSiblings.push(documentLineSibling);
        }
      }
      for (const documentLineSibling of documentLinesSiblings) {
        copy.splice(copy.findIndex(value => value.id === documentLineSibling.id), 1);
        const budgetLineSibling = new BudgetFormLine();
        budgetLineSibling.documentLine = documentLineSibling;
        budgetLineSibling.paymentDate = this.model.date;
        this.model.lines = [...this.model.lines, budgetLineSibling];
      }
      this.availableDocumentLines.content = [...copy];
    }
    this.refreshTotal();
  }

  removeBudgetLine(budgetLine: BudgetFormLine): void {
    if (this.model.lines) {
      const budgetLinetoRemove = [];

      // move all other lines from same invoice
      for (const budgetLineSibling of this.model.lines) {
        if (budgetLineSibling.documentLine?.document?.id === budgetLine.documentLine?.document?.id) {
          budgetLinetoRemove.push(budgetLineSibling);
        }
      }
      const copy = [... this.model.lines];
      for (const budgetLineSibling of budgetLinetoRemove) {
        // this.model.lines.splice(this.model.lines.indexOf(budgetLineSibling), 1);
        copy.splice(copy.findIndex(value => value.documentLine?.id === budgetLineSibling.documentLine?.id), 1);
        if (this.availableDocumentLines.content !== undefined && budgetLineSibling.documentLine !== undefined) {
          this.availableDocumentLines.content = [...this.availableDocumentLines.content, budgetLineSibling.documentLine];
        }
      }
      this.model.lines = [...copy];
      this.refreshTotal();
    }
  }

  createEvent(): void {
    this.attachments = [];
    super.createEvent();
    this.loadAvailableDocuments();
  }

  editEvent(model: BudgetForm): void {
    super.editEvent(model);
    this.loadAvailableDocuments();
  }

  onModelUpdate(): void {
    this.refreshTotal();
    this.loadAttachments();
    this.model.date = ServiceUtils.dateReviver('', this.model.date) as LocalDate;
    this.date  = {
      year: this.model.date.year(),
      month: this.model.date.monthValue(),
      day: this.model.date.dayOfMonth()
    };
  }

  refreshTotal(): void {
    this.total = 0;
    for (const line of this.model.lines) {
      if (line.documentLine?.totalAmount !== undefined && line.documentLine?.document?.negativeAmount !== undefined) {
        const subtotal = line.documentLine?.totalAmount * line.documentLine?.document?.negativeAmount;
        this.total += subtotal;
      }
    }
  }

  applyEvent(): void {
    const id = this.model.id;
    if (id) {
      this.loading = true;
      this.budgetFormService.apply(id).subscribe({
        error: (e) => this.processError(e),
        complete: () => {
          this.mode = 'LIST';
          this.refreshEvent();
          this.loading = false;
          this.alert.success('Plantilla aplicada exitósamente');
        }
      });
    }
  }

  revertEvent(): void {
    const id = this.model.id;
    if (id) {
      this.loading = true;
      this.budgetFormService.revert(id).subscribe({
        error: (e) => this.processError(e),
        complete: () => {
          this.mode = 'LIST';
          this.refreshEvent();
          this.loading = false;
          this.alert.success('Plantilla revertida exitósamente');
        }
      });
    }
  }

  searchTermChanged(text: string): void {
    this.loadAvailableDocuments();
  }

  getPageEvent(pageNumber: number): void {
    this.cleanErrors();
    this.loading = true;
    this.service.searchByFilter(this.getFilter(), pageNumber, this.pageSize, this.sortBy, this.sortOrder)
      .subscribe(
        page => this.setPageEvent(page),
        error => this.processError(error));
  }

  private getFilter(): BudgetFormFilter {
    const filter = new BudgetFormFilter();
    filter.sequence = this.sequence;
    filter.from = this.from;
    filter.to = this.to;
    if (this.statusSearch && this.statusSearch !== BudgetFormStatus.ALL) {
      filter.status = this.statusSearch;
    }
    return filter;
  }

  loadAttachments(): void {
    if (this.model.id) {
      this.loading = true;
      this.budgetFormService.getAttachments(this.model.id)
        .subscribe(value => {
          this.attachments = value;
          this.loading = false;
        }, error => this.processError(error));
    }
  }

  onFileSelected(event: any): void {
    for (const file of event.target.files) {
      if (file) {
        this.uploadAttachments(file);
      }
    }
    event.target.value = '';
  }

  uploadAttachments(file: File): void {
    const formData = new FormData();
    formData.append('file', file);
    this.fileUploadErrors = [];
    if (this.model.id) {
      this.loading = true;
      this.budgetFormService.uploadAttachments(formData, this.model.id)
        .subscribe(value => {
            this.loading = false;
            this.refreshEvent();
            super.cleanData();
            this.fileUploadErrors = [];
            this.loadAttachments();
            this.alert.success('Registros cargados exitósamente');
          }
          , (error: HttpErrorResponse) => {
            this.loading = false;
            const errorCode = error.error.error as string;
            if (['PARTIALLY_PROCESSED_FILE'].includes(errorCode)) {
              const errors = error.error.errors as ErrorEntry[];
              errors.forEach(value => this.fileUploadErrors.push(value));
            } else {
              this.processError(error);
            }
          });
    }
  }

  removeAttachment(attachmentId?: number): void {
    if (this.model.id && attachmentId) {
      this.loading = true;
      this.budgetFormService.deleteAttachment(this.model.id, attachmentId)
        .subscribe(value => {
          this.attachments = value;
          this.loading = false;
          this.alert.success('Combrobante eliminado exitósamente');
          this.loadAttachments();
        }, error => this.processError(error));
    }
  }
  downloadAttachment(attachmentId?: number, attachmentName?: string): void {
    this.loading = true;
    if (this.model.id && attachmentId && attachmentName) {
      this.budgetFormService.downloadAttachment(this.model.id, attachmentId)
        .subscribe(
          response => {
            this.loading = false;
            const a = document.createElement('a');
            const objectUrl = URL.createObjectURL(response);
            a.href = objectUrl;
            a.download = attachmentName;
            a.click();
            URL.revokeObjectURL(objectUrl);
          }, error => this.processError(error)
        );
    }
  }
  changeSelection(allSelected: boolean): void {
    this.countSelections = 0;
    this.model.lines?.forEach(value => {
      value.selected = allSelected;
      this.countSelections += value.selected ? 1 : 0;
    });
  }
  changeSingleSelection(selected: boolean): void {
    this.countSelections = 0;
    this.model.lines?.forEach(value => {
      this.allSelected = this.allSelected && value.selected;
      this.countSelections += value.selected ? 1 : 0;
    });
  }
  copyValues(budgetLine: BudgetFormLine): void {
    this.model.lines?.forEach(value => {
      if (value.selected) {
        value.padCode = budgetLine.padCode;
        value.paymentCode = budgetLine.paymentCode;
        value.recordNumber = budgetLine.recordNumber;
      }
    });
  }
}

class ErrorEntry {
  constructor(
    public message: string,
    public line: string
  ) {
  }
}
