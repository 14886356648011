import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../core/component/base.component';
import {AlertService} from '../../core/service/alert.service';
import {ClientService} from '../../service/client.service';
import {Client, ClientStatus, ClientType} from '../../model/client';
import {SecurityService} from '../../core/service/security.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CodeService} from '../../service/code.service';
import {Code, CodeType} from '../../model/code';
import {MessageService} from '../../core/service/message.service';
import {LoggerService} from '../../service/logger.service';
import ServiceUtils from '../../core/utils/service.utils';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html'
})
export class ClientComponent extends BaseComponent<Client> implements OnInit, OnDestroy {

  identificationTypes: Code[] = [];
  location1: Code[] = [];
  location2: Code[] = [];
  location3: Code[] = [];
  location4: Code[] = [];
  returnUrl = '';
  paymentMethods: Code[] = [];
  sellConditions: Code[] = [];

  constructor(private clientService: ClientService,
              private alertService: AlertService,
              protected router: Router,
              protected securityService: SecurityService,
              protected codeService: CodeService,
              protected messageService: MessageService,
              private route: ActivatedRoute,
              protected loggerService: LoggerService) {
    super(clientService, 'clients', alertService, router, securityService, messageService, loggerService);
    this.sortBy.push('name');
    this.sortOrder = 'asc';
  }

  getModelInstance(): Client {
    const client = new Client();
    client.status = ClientStatus.ACTIVE;
    client.type = ClientType.CLIENT;
    return client;
  }

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>().set('client.name', '').set('client.phone', '').set('client.email', '');
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.subscriptions.push(this.codeService.getCodes(CodeType.TIPO_IDENTIFICACION)
      .subscribe(response => {
        this.identificationTypes = response;
      }));
    this.subscriptions.push(this.codeService.getCodes(CodeType.UBICACION1)
      .subscribe(response => {
        this.location1 = response;
      }));
    this.codeService.getCodes(CodeType.MEDIO_PAGO).subscribe(
      codes => this.paymentMethods = codes
    );
    this.codeService.getCodes(CodeType.CONDICION_VENTA).subscribe(
      codes => this.sellConditions = codes
    );
    this.subscriptions.push(this.route.queryParams.subscribe(params => {
      const mode = params.mode;
      if (mode === 'LIST' || mode === 'EDIT') {
        this.mode = mode;
      }
      if (mode === 'EDIT') {
        this.createEvent();
        this.model.identification = params.identification;
        if (params.returnUrl) {
          this.returnUrl = params.returnUrl;
        }
      }
    }));
  }

  canRefresh(): boolean {
    return !!ServiceUtils.getItem('tenant');
  }

  onLocationChangeEvent(eventValue: any, location: string): void {
    if (location === 'location1' && !eventValue) {
      this.location2 = [];
      this.location3 = [];
      this.location4 = [];
    }else if (location === 'location1') {
      const parentId = this.location1.find(value => value.code === eventValue)?.id;
      this.codeService.getCodes(CodeType.UBICACION2, undefined, parentId)
        .subscribe(response => {
          this.location2 = response;
          this.location3 = [];
          this.location4 = [];
        });
    } else if (location === 'location2') {
      const parentId = this.location2.find(value => value.code === eventValue)?.id;
      this.codeService.getCodes(CodeType.UBICACION3, undefined, parentId)
        .subscribe(response => {
          this.location3 = response;
          this.location4 = [];
        });
    } else if (location === 'location3') {
      const parentId = this.location3.find(value => value.code === eventValue)?.id;
      this.codeService.getCodes(CodeType.UBICACION4, undefined, parentId)
        .subscribe(response => {
          this.location4 = response;
        });
    }
  }

  onModelUpdate(): void {
    if (this.model.locationLevel1) {
      const parentId1 = this.location1.find(value => value.code === this.model.locationLevel1)?.id;
      this.codeService.getCodes(CodeType.UBICACION2, undefined, parentId1)
        .subscribe(response1 => {
          this.location2 = response1;
          console.debug('location2 loaded');
          if (this.model.locationLevel2) {
            const parentId2 = this.location2.find(value => value.code === this.model.locationLevel2)?.id;
            this.codeService.getCodes(CodeType.UBICACION3, undefined, parentId2)
              .subscribe(response2 => {
                this.location3 = response2;
                console.debug('location3 loaded');
                if (this.model.locationLevel3) {
                  const parentId3 = this.location3.find(value => value.code === this.model.locationLevel3)?.id;
                  this.codeService.getCodes(CodeType.UBICACION4, undefined, parentId3)
                    .subscribe(response3 => {
                      this.location4 = response3;
                      console.debug('location4 loaded');
                    });
                }
              });
          }
        });
    }
  }

  protected cleanData(): void {
    this.location2 = [];
    this.location3 = [];
    this.location4 = [];
  }

  onSuccessfulCreate(): void {
    if (this.returnUrl) {
      this.router.navigate([this.returnUrl]);
    }
  }

  export(): void {
    this.clientService.export()
      .subscribe(
        response => {
          const typeContent = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8';
          const blob: any = new Blob([response],
            {type: typeContent });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        }
      );
  }
}
