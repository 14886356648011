import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../core/component/base.component';
import {AlertService} from '../../../core/service/alert.service';
import {SecurityService} from '../../../core/service/security.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from '../../../core/service/message.service';
import {
  DocumentLine,
  DocumentLineBudgetConfigured,
  DocumentLineClassified,
  OperationType
} from '../../../model/document-line';
import {DocumentLineService} from '../../../service/document-line.service';
import {LocalDate} from '@js-joda/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {DocumentLineFilter} from '../../../filter/document-line.filter';
import {DocumentStatus} from '../../../model/document';
import {HaciendaService} from '../../../service/hacienda.service';
import {Product} from '../../../model/product';
import {ClientService} from '../../../service/client.service';
import {Page} from '../../../model/page';
import {Account, AccountType} from '../../../model/account';
import {AccountService} from '../../../service/account.service';
import {Impuesto} from '../../../model/v4_3/impuesto';
import {Descuento} from '../../../model/v4_3/descuento';
import {LoggerService} from '../../../service/logger.service';
import {BankAccount, BudgetFormFoundingSource} from '../../../model/bank-account';
import {BankAccountService} from '../../../service/bank-account.service';

@Component({
  selector: 'app-validate',
  templateUrl: './accounting-form.component.html'
})
export class AccountingFormComponent extends BaseComponent<DocumentLine> implements OnInit, OnDestroy {

  from: NgbDateStruct;
  to: NgbDateStruct;
  fromLocalDate: LocalDate;
  toLocalDate: LocalDate;
  budgetConfigured = false;
  type = OperationType.SELL;
  percentage = '';
  allSelected = false;
  productSelected?: Product;
  countSelections = 0;
  text = '';
  accounts: Account[] = [];
  bankAccounts: BankAccount[] = [];
  budgetAccountId = 0;
  bankAccountId = 0;
  sellCondition = '';
  paymentMethod = '';

  constructor(private documentLineService: DocumentLineService,
              private alertService: AlertService,
              public router: Router,
              protected securityService: SecurityService,
              protected messageService: MessageService,
              protected haciendaService: HaciendaService,
              protected bankAccountService: BankAccountService,
              protected clientService: ClientService,
              protected clientAccountService: AccountService,
              private route: ActivatedRoute,
              protected loggerService: LoggerService) {
    super(documentLineService, 'documentLine', alertService, router, securityService, messageService, loggerService);
    this.fromLocalDate = LocalDate.now().minusMonths(1).withDayOfMonth(1);
    this.toLocalDate = this.fromLocalDate.withDayOfMonth(this.fromLocalDate.lengthOfMonth());
    this.from = {
      year: this.fromLocalDate.year(),
      month: this.fromLocalDate.monthValue(),
      day: this.fromLocalDate.dayOfMonth()
    };
    this.to = {year: this.toLocalDate.year(), month: this.toLocalDate.monthValue(), day: this.toLocalDate.dayOfMonth()};
    this.pageSize = 100000;
  }

  ngOnInit(): void {
    this.clientAccountService.getClientAccounts([], undefined, 'description', AccountType.BUDGET)
      .subscribe(value => this.accounts = value);
    this.bankAccountService.findAll().subscribe(value =>
    {
      this.bankAccounts = value;
      for (const bankAccount of this.bankAccounts) {
        bankAccount.foundingSourceDescription = this.getFoundingSourceDescription(bankAccount.foundingSource);
      }
    });
    super.ngOnInit();
    this.subscriptions.push(this.route.queryParams.subscribe(params => {
      const pageSize = params.pageSize;
      if (pageSize) {
        this.pageSize = pageSize;
      }
    }));
  }

  getFoundingSourceDescription(foundingSource?: string): string {
    if (foundingSource === '001'){
      return BudgetFormFoundingSource._001.toString();
    } else if (foundingSource === '002'){
      return BudgetFormFoundingSource._002.toString();
    } else if (foundingSource === '003'){
      return BudgetFormFoundingSource._003.toString();
    } else if (foundingSource === '004') {
      return BudgetFormFoundingSource._004.toString();
    } else {
      return 'Sin Fuente';
    }
  }

  getModelInstance(): DocumentLine {
    return new DocumentLine(0, false, false, '', '', '', '', false, 0, '', '', '');
  }

  fromDateChanged(date: NgbDateStruct): void {
    this.fromLocalDate = LocalDate.of(date.year, date.month, date.day);
    this.from = date;
    if (this.fromLocalDate.isAfter(this.toLocalDate)) {
      this.toLocalDate = LocalDate.of(date.year, date.month, this.fromLocalDate.lengthOfMonth());
      this.to = {year: date.year, month: date.month, day: this.fromLocalDate.lengthOfMonth()};
    }
    this.refreshEvent();
  }

  toDateChanged(date: NgbDateStruct): void {
    this.toLocalDate = LocalDate.of(date.year, date.month, date.day);
    this.to = date;
    if (this.toLocalDate.isBefore(this.fromLocalDate)) {
      this.fromLocalDate = LocalDate.of(date.year, date.month, 1);
      this.from = {year: date.year, month: date.month, day: 1};
    }
    this.refreshEvent();
  }

  statusChanged(status: boolean): void {
    this.refreshEvent();
  }

  typeChanged(type: string): void {
    this.refreshEvent();
  }

  percentageChanged(percentage: string): void {
    this.refreshEvent();
  }

  searchTermChanged(text: string): void {
    this.refreshEvent();
  }

  sellConditionChanged(type: OperationType): void {
    this.refreshEvent();
  }

  paymentMethodChanged(type: OperationType): void {
    this.refreshEvent();
  }

  setPageEvent(page: Page<DocumentLine>): void {
    page.content?.forEach(value => {
      value.taxesDetails = JSON.parse(value.taxes ? value.taxes : '[]') as Impuesto[];
      value.discountsDetails = JSON.parse(value.discounts ? value.discounts : '[]') as Descuento[];
    });
    super.setPageEvent(page);
  }

  getPageEvent(pageNumber: number): void {
    this.cleanErrors();
    this.loading = true;
    const documentLineFilter = this.getFilter();
    this.countSelections = 0;
    this.clearPage();
    this.service.searchByFilter(documentLineFilter, pageNumber, this.pageSize, this.sortBy, this.sortOrder)
      .subscribe(
        page => this.setPageEvent(page),
        error => this.processError(error));
  }

  private getFilter(): DocumentLineFilter {
    const documentLineFilter = new DocumentLineFilter();
    documentLineFilter.from = this.fromLocalDate;
    documentLineFilter.to = this.toLocalDate;
    documentLineFilter.budgetConfigured = this.budgetConfigured;
    documentLineFilter.documentStatus = DocumentStatus.DOCUMENT_APPROVED;
    documentLineFilter.type = this.type;
    documentLineFilter.percentage = this.percentage;
    if (this.sellCondition) {
      documentLineFilter.sellCondition = this.sellCondition;
    }
    if (this.text) {
      documentLineFilter.text = this.text;
    }
    if (this.paymentMethod) {
      documentLineFilter.paymentMethod = this.paymentMethod;
    }
    return documentLineFilter;
  }

  changeSelection(allSelected: boolean): void {
    this.countSelections = 0;
    this.page.content?.forEach(value => {
      value.selected = allSelected;
      this.countSelections += value.selected ? 1 : 0;
    });
  }

  changeSingleSelection(selected: boolean): void {
    this.countSelections = 0;
    this.page.content?.forEach(value => {
      this.allSelected = this.allSelected && value.selected;
      this.countSelections += value.selected ? 1 : 0;
    });
  }

  loadProductDetails(documentLine: DocumentLine): void {
    const code = documentLine.cabysCode;
    if (code) {
      this.haciendaService.getProductDetails(code).subscribe(value => {
        if (value.length > 0) {
          this.productSelected = value[0];
        }
      });
    }
  }

  cleanProductDetails(): void {
    this.productSelected = undefined;
  }

  configureBudgetEvent(): void {
    let lines = this.page.content;
    if (lines) {
      this.loading = true;
      lines = lines.filter(value => value.budgetAccountId && value.bankAccountId);
      if (lines.length > 0) {
        lines.forEach(value => {
          // value.cabysCode = value.details.Codigo; // TODO required?
          value.operationType = this.type;
        });
        const documentLinesBudgetConfigured =
          lines.map(value => new DocumentLineBudgetConfigured(value.id, value.bankAccountId, value.budgetAccountId));
        this.documentLineService.configureBudget(documentLinesBudgetConfigured).subscribe(value => {
          this.loading = false;
          this.allSelected = false;
          this.refreshEvent();
        }, error => this.processError(error));
      } else {
        this.loading = false;
      }
    }
  }

  applySelectedEvent(): void {
    this.page.content?.forEach(value => {
      if (value.selected) {
        if (this.budgetAccountId) {
          value.budgetAccountId = this.budgetAccountId;
        }
        if (this.bankAccountId) {
          value.bankAccountId = this.bankAccountId;
        }
        value.state = false;
      }
    });
  }

  export(format: string): void {
    this.loading = true;
    this.documentLineService.exportToExcel(this.getFilter(), format)
      .subscribe(
        response => {
          let typeContent = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8';
          if (format === 'CSV') {
            typeContent = 'text/csv; charset=utf-8';
          }
          const blob: any = new Blob([response],
            {type: typeContent });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
          this.loading = false;
          }, error => this.loading = false
      );
  }

  getAccountDescription(accounts: Account[], account?: number, selectable: boolean = true): string {
    if (account) {
      const filteredAccounts = accounts.filter(value => value.id == account);
      if (filteredAccounts.length > 0) {
        return filteredAccounts[0].code ? filteredAccounts[0].code : '';
      }
      return 'Cuenta no encontrada';
    } else if (!selectable) {
      return 'N/A';
    } else {
      return 'Seleccionar ...';
    }
  }

  getBankAccountDescription(bankAccounts: BankAccount[], bankAccount?: number, selectable: boolean = true): string {
    if (bankAccount) {
      const filteredAccounts = bankAccounts.filter(value => value.id == bankAccount);
      if (filteredAccounts.length > 0) {
        return filteredAccounts[0].bank ? filteredAccounts[0].bank : '';
      }
      return 'Cuenta no encontrada';
    } else if (!selectable) {
      return 'N/A';
    } else {
      return 'Seleccionar ...';
    }
  }
}
