import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LeaveBalance} from './resource/leave-balance';
import {BaseService} from '../../core/service/base.service';

@Injectable()
export class LeaveBalanceService extends BaseService<LeaveBalance> {

  serviceUrl(): string {
    return `/api/t/{tenant}/leave-balances`;
  }

  constructor(protected http: HttpClient) {
    super(http);
  }
}
