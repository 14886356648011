import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../core/component/base.component';

import {AlertService} from '../../../core/service/alert.service';
import {MessageService} from '../../../core/service/message.service';
import {Router} from '@angular/router';
import {SecurityService} from '../../../core/service/security.service';
import {LoggerService} from '../../../service/logger.service';

import {WorkdayService} from '../../service/workday.service';
import {Workday, WorkingDay} from '../../service/resource/workday';
import {DayOfWeek} from '@js-joda/core';

@Component({
  selector: 'app-workday',
  templateUrl: './workday.component.html'
})
export class WorkdayComponent extends BaseComponent<Workday> implements OnInit, OnDestroy {

  constructor(private workdayService: WorkdayService,
              private alertService: AlertService,
              protected messageService: MessageService,
              protected loggerService: LoggerService,
              protected router: Router,
              protected securityService: SecurityService) {
    super(workdayService, 'Workday', alertService, router, securityService, messageService, loggerService);
  }

  getModelInstance(): Workday {
    return new Workday();
  }

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  hasDaySelected(dayOfWeek: DayOfWeek): boolean {
    return this.model.workingDays.find(value => value.dayOfWeek == dayOfWeek) !== undefined;
  }

  selectedDayChange($event: Event): void {
    // @ts-ignore
    const selected = $event.currentTarget.checked;
    // @ts-ignore
    const value = $event.currentTarget.value;

    const dayOfWeek = DayOfWeek.valueOf(value);

    console.log(dayOfWeek);

    if (selected) {
      console.log('selected');
      const workingDay = new WorkingDay();
      workingDay.dayOfWeek = dayOfWeek;
      this.model.workingDays.push(workingDay);
    } else {
      const workingDay = new WorkingDay();
      workingDay.dayOfWeek = dayOfWeek;
      this.model.workingDays.splice(this.model.workingDays.findIndex(value => value.dayOfWeek == dayOfWeek), 1);
      console.log('removed');
    }
  }

  protected readonly DayOfWeek = DayOfWeek;

}
