import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {LocalTime} from '@js-joda/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';


@Component({
  selector: 'mt-time-picker',
  templateUrl: './time-picker.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TimePickerComponent),
    multi: true
  }]
})
export class TimePickerComponent implements OnInit {

  // @ts-ignore
  model = {};
  private internalTime: LocalTime | undefined;

  @Output() public timeChange = new EventEmitter<LocalTime>();
  @Input()
  get time(): LocalTime | undefined { return this.internalTime; }
  set time(time: LocalTime | undefined) {
    if (time !== undefined && (typeof time === 'string')) {
      this.internalTime = LocalTime.parse(time) as LocalTime;
      this.model = {hour: this.internalTime?.hour(), minute: this.internalTime?.minute()};
    }
  }
  @Input() public name: string | undefined;
  @Input() public label: string | undefined;
  @Input() public id: string | undefined;
  @Input() public meridian = true;

  constructor() {
  }

  ngOnInit(): void {
  }

  timeChanged(newTime: any): void {
    this.internalTime = LocalTime.of(newTime.hour, newTime.minute);
    this.timeChange.emit(this.internalTime as LocalTime);
  }

}
