import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../core/component/base.component';
import {AlertService} from '../../../core/service/alert.service';
import {SecurityService} from '../../../core/service/security.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from '../../../core/service/message.service';
import {DocumentLine, DocumentLineClassified, OperationType} from '../../../model/document-line';
import {DocumentLineService} from '../../../service/document-line.service';
import {LocalDate} from '@js-joda/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {DocumentLineFilter} from '../../../filter/document-line.filter';
import {DocumentStatus} from '../../../model/document';
import {HaciendaService} from '../../../service/hacienda.service';
import {Product} from '../../../model/product';
import {Page} from '../../../model/page';
import {Account} from '../../../model/account';
import {AccountService} from '../../../service/account.service';
import {Impuesto} from '../../../model/v4_3/impuesto';
import {Descuento} from '../../../model/v4_3/descuento';
import {LoggerService} from '../../../service/logger.service';
import ServiceUtils from '../../../core/utils/service.utils';
import {Configuration} from '../../../model/client';

@Component({
  selector: 'app-validate',
  templateUrl: './classify.component.html'
})
export class ClassifyComponent extends BaseComponent<DocumentLine> implements OnInit, OnDestroy {

  from: NgbDateStruct;
  to: NgbDateStruct;
  fromLocalDate: LocalDate;
  toLocalDate: LocalDate;
  classified = false;
  type = OperationType.SELL;
  percentage = '';
  account = '';
  allSelected = false;
  productSelected?: Product;
  countSelections = 0;
  text = '';

  accounts: Account[] = [];
  activeAccounts: Account[] = [];
  taxRegistryAccounts: Account[] = [];
  activeTaxRegistryAccounts: Account[] = [];
  counterPartAccounts: Account[] = [];
  activeCounterPartAccounts: Account[] = [];

  accountId = 0;
  taxRegistryAccountId = 0;
  counterPartAccountId = 0;
  bankReceipt = '';
  sellCondition = '';
  paymentMethod = '';
  withOutTaxes = false;
  exonerated = false;
  analyzeData = false;
  withOutTaxesAccount = false;
  publicInstitution = false;
  greaterThan = undefined;
  lessThan = undefined;

  constructor(private documentLineService: DocumentLineService,
              private alertService: AlertService,
              public router: Router,
              protected securityService: SecurityService,
              protected messageService: MessageService,
              protected haciendaService: HaciendaService,
              protected clientAccountService: AccountService,
              private route: ActivatedRoute,
              protected loggerService: LoggerService) {
    super(documentLineService, 'documentLine', alertService, router, securityService, messageService, loggerService);
    this.fromLocalDate = LocalDate.now().minusMonths(1).withDayOfMonth(1);
    this.toLocalDate = this.fromLocalDate.withDayOfMonth(this.fromLocalDate.lengthOfMonth());
    this.from = {
      year: this.fromLocalDate.year(),
      month: this.fromLocalDate.monthValue(),
      day: this.fromLocalDate.dayOfMonth()
    };
    this.to = {year: this.toLocalDate.year(), month: this.toLocalDate.monthValue(), day: this.toLocalDate.dayOfMonth()};
    this.pageSize = 100000;
  }

  ngOnInit(): void {
    this.clientAccountService.getClientAccounts([], undefined, 'description')
      .subscribe(value => {
        this.accounts = value;
        this.activeAccounts = value.filter(value1 => value1.active);
      });
    this.clientAccountService.getClientAccounts([], undefined, 'description', undefined, true)
      .subscribe(value => {
        this.taxRegistryAccounts = value;
        this.activeTaxRegistryAccounts = value.filter(value1 => value1.active);
      });
    this.clientAccountService.getClientAccounts(['1.', '2.', '4.', '5.', '3.', '6.'], undefined, 'description')
      .subscribe(value => {
        this.counterPartAccounts = value;
        this.activeCounterPartAccounts = value.filter(value1 => value1.active);
      });
    super.ngOnInit();
    this.subscriptions.push(this.route.queryParams.subscribe(params => {
      const pageSize = params.pageSize;
      if (pageSize) {
        this.pageSize = pageSize;
      }
    }));
    const configurationFromStorage = ServiceUtils.getItem('configuration');
    if (configurationFromStorage) {
      const configuration = JSON.parse(configurationFromStorage) as Configuration;
      if (configuration.publicInstitution) {
        this.publicInstitution = configuration.publicInstitution;
      }
    }
  }

  getModelInstance(): DocumentLine {
    return new DocumentLine(0, false, false, '', '', '', '', false, 0, '', '', '');
  }

  fromDateChanged(date: NgbDateStruct): void {
    this.fromLocalDate = LocalDate.of(date.year, date.month, date.day);
    this.from = date;
    if (this.fromLocalDate.isAfter(this.toLocalDate)) {
      this.toLocalDate = LocalDate.of(date.year, date.month, this.fromLocalDate.lengthOfMonth());
      this.to = {year: date.year, month: date.month, day: this.fromLocalDate.lengthOfMonth()};
    }
    this.refreshEvent();
  }

  toDateChanged(date: NgbDateStruct): void {
    this.toLocalDate = LocalDate.of(date.year, date.month, date.day);
    this.to = date;
    if (this.toLocalDate.isBefore(this.fromLocalDate)) {
      this.fromLocalDate = LocalDate.of(date.year, date.month, 1);
      this.from = {year: date.year, month: date.month, day: 1};
    }
    this.refreshEvent();
  }

  statusChanged(status: boolean): void {
    this.refreshEvent();
  }

  typeChanged(type: string): void {
    if (this.type === 'SELL') {
      this.clientAccountService.getClientAccounts([], undefined, 'description', undefined, true)
        .subscribe(value => {
          this.taxRegistryAccounts = value;
          this.activeTaxRegistryAccounts = value.filter(value1 => value1.active);
        });
    } else if (this.type === 'PURCHASE') {
      this.clientAccountService.getClientAccounts([], undefined, 'description', undefined, undefined, true)
        .subscribe(value => {
          this.taxRegistryAccounts = value;
          this.activeTaxRegistryAccounts = value.filter(value1 => value1.active);
        });
    }
    this.refreshEvent();
  }

  percentageChanged(percentage: string): void {
    this.refreshEvent();
  }

  withOutTaxesChanged(withOutTaxes: boolean): void {
    this.refreshEvent();
  }

  withOutTaxesAccountChanged(withOutTaxesAccount: boolean): void {
    this.refreshEvent();
  }

  exoneratedChanged(exonerated: boolean): void {
    this.refreshEvent();
  }

  searchTermChanged(text: string): void {
    this.refreshEvent();
  }

  sellConditionChanged(type: OperationType): void {
    this.refreshEvent();
  }

  paymentMethodChanged(type: OperationType): void {
    this.refreshEvent();
  }

  accountChanged(account: string): void {
    this.refreshEvent();
  }

  setPageEvent(page: Page<DocumentLine>): void {
    page.content?.forEach(value => {
      value.taxesDetails = JSON.parse(value.taxes ? value.taxes : '[]') as Impuesto[];
      value.discountsDetails = JSON.parse(value.discounts ? value.discounts : '[]') as Descuento[];
    });
    super.setPageEvent(page);
  }

  getPageEvent(pageNumber: number): void {
    this.cleanErrors();
    this.loading = true;
    const documentLineFilter = this.getFilter();
    this.countSelections = 0;
    this.clearPage();
    this.service.searchByFilter(documentLineFilter, pageNumber, this.pageSize, this.sortBy, this.sortOrder)
      .subscribe(
        page => this.setPageEvent(page),
        error => this.processError(error));
  }

  private getFilter(): DocumentLineFilter {
    const documentLineFilter = new DocumentLineFilter();
    documentLineFilter.from = this.fromLocalDate;
    documentLineFilter.to = this.toLocalDate;

    documentLineFilter.categorized = true;
    documentLineFilter.classified = this.classified;

    documentLineFilter.documentStatus = DocumentStatus.DOCUMENT_APPROVED;
    documentLineFilter.type = this.type;
    documentLineFilter.percentage = this.percentage;
    if (this.sellCondition) {
      documentLineFilter.sellCondition = this.sellCondition;
    }
    if (this.text) {
      documentLineFilter.text = this.text;
    }
    if (this.paymentMethod) {
      documentLineFilter.paymentMethod = this.paymentMethod;
    }
    if (this.withOutTaxes) {
      documentLineFilter.withOutTaxes = this.withOutTaxes;
    }
    if (this.withOutTaxesAccount) {
      documentLineFilter.withOutTaxesAccount = this.withOutTaxesAccount;
    }
    if (this.exonerated) {
      documentLineFilter.exonerated = this.exonerated;
    }
    if (this.account) {
      documentLineFilter.account = this.account;
    }
    if (this.analyzeData) {
      documentLineFilter.analyzeData = this.analyzeData;
      documentLineFilter.greaterThan = this.greaterThan;
      documentLineFilter.lessThan = this.lessThan;
      this.analyzeData = false; // special filter used by analyze data action, reset it after loading data
      this.greaterThan = undefined;
      this.lessThan = undefined;
    }
    return documentLineFilter;
  }

  changeSelection(allSelected: boolean): void {
    this.countSelections = 0;
    this.page.content?.forEach(value => {
      value.selected = allSelected;
      this.countSelections += value.selected ? 1 : 0;
    });
  }

  changeSingleSelection(selected: boolean): void {
    this.countSelections = 0;
    this.page.content?.forEach(value => {
      this.allSelected = this.allSelected && value.selected;
      this.countSelections += value.selected ? 1 : 0;
    });
  }

  loadProductDetails(documentLine: DocumentLine): void {
    const code = documentLine.cabysCode;
    if (code) {
      this.haciendaService.getProductDetails(code).subscribe(value => {
        if (value.length > 0) {
          this.productSelected = value[0];
        }
      });
    }
  }

  cleanProductDetails(): void {
    this.productSelected = undefined;
  }

  classifyEvent(): void {
    let lines = this.page.content;
    if (lines) {
      this.loading = true;
      lines = lines.filter(value => value.account && value.counterPartAccount);
      if (lines.length > 0) {
        lines.forEach(value => {
          // value.cabysCode = value.details.Codigo; // TODO required?
          value.operationType = this.type;
        });
        const documentLinesClassified =
          lines.map(value => new DocumentLineClassified(value.id,
            value.account,
            value.taxRegistryAccount,
            value.counterPartAccount,
            value.bankReceipt));
        this.documentLineService.classify(documentLinesClassified).subscribe(value => {
          this.loading = false;
          this.allSelected = false;
          this.alertService.success('Las líneas seleccionadas fueron clasificadas correctamente');
          this.refreshEvent();
        }, error => this.processError(error));
      } else {
        this.loading = false;
        this.alertService.error('Debe seleccionar al menos una línea y asignarle una cuenta contable y una cuenta de contrapartida');
      }
    }
  }

  applySelectedEvent(): void {
    this.page.content?.forEach(value => {
      if (value.selected && !value.processed) {
        if (this.accountId) {
          value.account = this.accountId;
        }
        if (this.taxRegistryAccountId) {
          if (this.taxRegistryAccountId == -1){
            value.taxRegistryAccount = undefined;
          } else {
            value.taxRegistryAccount = this.taxRegistryAccountId;
          }
        }
        if (this.counterPartAccountId) {
          value.counterPartAccount = this.counterPartAccountId;
        }
        if (this.bankReceipt !== '' && !this.publicInstitution) {
          value.bankReceipt = this.bankReceipt;
        }
        value.state = false;
      }
    });
  }

  export(format: string): void {
    this.loading = true;
    this.documentLineService.exportToExcel(this.getFilter(), format)
      .subscribe(
        response => {
          let typeContent = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8';
          if (format === 'CSV') {
            typeContent = 'text/csv; charset=utf-8';
          }
          const blob: any = new Blob([response],
            {type: typeContent });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
          this.loading = false;
          }, error => this.loading = false
      );
  }

  getAccountDescription(accounts: Account[], account?: number, selectable: boolean = true): string {
    if (account) {
      const filteredAccounts = accounts.filter(value => value.id == account);
      if (filteredAccounts.length > 0) {
        const accountFound = filteredAccounts[0];
        let label = accountFound.code ? accountFound.code : '';
        if (!accountFound.active) {
          label = label + ' (Inactiva)';
        }
        return label;
      }
      return 'Cuenta no encontrada';
    } else if (!selectable) {
      return 'N/A';
    } else {
      return 'Seleccionar cuenta...';
    }
  }
  getBankReceiptDescription(value?: string): string {
    if (value) {
      return value;
    } else if (!this.publicInstitution) {
      return 'Agregar...';
    } else {
      return '';
    }
  }

  analyzeAccountingEntries(): void {
    this.analyzeData = true;
    this.refreshEvent();
  }
}
