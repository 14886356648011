import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../core/component/base.component';

import {AlertService} from '../../../core/service/alert.service';
import {MessageService} from '../../../core/service/message.service';
import {Router} from '@angular/router';
import {SecurityService} from '../../../core/service/security.service';
import {LoggerService} from '../../../service/logger.service';

import {AbsenceService} from '../../service/absence.service';
import {Absence} from '../../service/resource/absence';
import {Employee} from '../../service/resource/employee';
import {EmployeeService} from '../../service/employee.service';

@Component({
  selector: 'app-absence',
  templateUrl: './absence.component.html'
})
export class AbsenceComponent extends BaseComponent<Absence> implements OnInit, OnDestroy {

  employees: Employee[] = [];

  constructor(private absenceService: AbsenceService,
              private alertService: AlertService,
              protected messageService: MessageService,
              protected loggerService: LoggerService,
              protected router: Router,
              protected securityService: SecurityService,
              protected employeeService: EmployeeService) {
    super(absenceService, 'Absence', alertService, router, securityService, messageService, loggerService);
  }

  getModelInstance(): Absence {
    return new Absence();
  }

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.employeeService.getAll().subscribe({
        next: (v) => this.employees = v,
        error: (e) => this.processError(e)
      }
    );
  }
}
