import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../core/component/base.component';
import {AlertService} from '../../../core/service/alert.service';
import {MessageService} from '../../../core/service/message.service';
import {SecurityService} from '../../../core/service/security.service';
import {Router} from '@angular/router';
import {BankAccountService} from '../../../service/bank-account.service';
import {BankAccount} from '../../../model/bank-account';
import {LoggerService} from '../../../service/logger.service';
import {Account, AccountType} from "../../../model/account";
import {AccountService} from "../../../service/account.service";

@Component({
  selector: 'app-bank-account',
  templateUrl: './bank-account.component.html'
})
export class BankAccountComponent extends BaseComponent<BankAccount> implements OnInit, OnDestroy {

  selectableAccounts: Account[] = [];

  constructor(private bankAccountService: BankAccountService,
              private alertService: AlertService,
              private accountService: AccountService,
              protected messageService: MessageService,
              protected router: Router,
              protected securityService: SecurityService,
              protected loggerService: LoggerService) {
    super(bankAccountService, 'bank-account', alertService, router, securityService, messageService, loggerService);
  }

  getModelInstance(): BankAccount {
    return new BankAccount();
  }

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>()
      .set('clientaccount.code', '')
      .set('clientaccount.bank', '')
      .set('clientaccount.accountId', '')
      .set('clientaccount.foundingSource', '');
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.accountService.getClientAccountsWithExactLevel([], undefined, 5, 'description', AccountType.ACCOUNTING)
      .subscribe(value => this.selectableAccounts = value);
  }
}
