import {EntityModel} from './entity.model';
import {OffsetDateTime, ZonedDateTime} from '@js-joda/core';

export class AuditableModel extends EntityModel {
  constructor(public id: number,
              public createdBy: number = 0,
              public created = ZonedDateTime.now(),
              public modifiedBy: number = 0,
              public modified= ZonedDateTime.now()) {
    super(id);
  }
}
