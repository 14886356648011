
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';


import { Observable } from 'rxjs';
import {delay} from 'rxjs/operators';

@Injectable()
export class DelayInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method === 'GET') {
      return next.handle(request);
    } else {
      return next.handle(request).pipe(delay(50));
    }
  }
}
