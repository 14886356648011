import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {BaseComponent} from '../../../core/component/base.component';
import {Employee} from '../../service/resource/employee';
import {EmployeeService} from '../../service/employee.service';
import {AlertService} from '../../../core/service/alert.service';
import {MessageService} from '../../../core/service/message.service';
import {SecurityService} from '../../../core/service/security.service';
import {LoggerService} from '../../../service/logger.service';
import {DepartmentService} from '../../service/department.service';
import {Department} from '../../service/resource/department';
import {JobPositionService} from '../../service/job-position.service';
import {JobPosition} from '../../service/resource/job-position';
import {WorkdayService} from '../../service/workday.service';
import {Workday} from '../../service/resource/workday';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html'
})
export class EmployeeComponent extends BaseComponent<Employee> implements OnInit, OnDestroy {

  departments: Department[] = [];
  jobPositions: JobPosition[] = [];
  workdays: Workday[] = [];

  constructor(private employeeService: EmployeeService,
              private alertService: AlertService,
              protected messageService: MessageService,
              protected logService: LoggerService,
              protected router: Router,
              protected securityService: SecurityService,
              protected departmentService: DepartmentService,
              protected jobPositionService: JobPositionService,
              protected workdayService: WorkdayService) {
    super(employeeService, 'employee', alertService, router, securityService, messageService, logService);
    this.sortBy = ['name', 'lastName'];
    this.sortOrder = 'asc';
  }

  getModelInstance(): Employee {
    return new Employee();
  }

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>(); // TODO add form values like .set('client.name', '').set('client.phone', '').set('client.email', '');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.departmentService.getAll().subscribe(
      {
        next: (v) => this.departments = v,
        error: (e) => this.processError(e)
      }
    );
    this.jobPositionService.getAll().subscribe(
      {
        next: (v) => this.jobPositions = v,
        error: (e) => this.processError(e)
      }
    );
    this.workdayService.getAll().subscribe(
      {
        next: (v) => this.workdays = v,
        error: (e) => this.processError(e)
      }
    );
  }
}
