<div class="container">

  <div class="loader" *ngIf="loading"></div>

  <div *ngIf="mode == 'EDIT' && !loading" class="animated fadeIn">
    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top">
      <div class="container">
        <span class="navbar-text">
          Empleado
        </span>
        <div class="pull-right">
          <button class="btn btn-link" (click)="cancelEvent()">
            Cerrar
          </button>
          <button class="btn btn-primary" (click)="saveEvent()">
            Guardar
          </button>
        </div>
      </div>
    </nav>

    <div class="row g-3">
      <div class="col-md-6">
        <label for="name">Nombre</label>
        <input type="text" class="form-control" id="name" name="name" [(ngModel)]="model.name">
        <div *ngIf="formErrors.get('employee.name')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('employee.name') }}
        </div>
      </div>
      <div class="col-md-6">
        <label for="lastName">Apellidos</label>
        <input type="text" class="form-control" id="lastName" name="lastName" [(ngModel)]="model.lastName">
        <div *ngIf="formErrors.get('employee.lastName')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('employee.lastName') }}
        </div>
      </div>
      <div class=" col-md-2">
        <label for="identificationType">Tipo de Identificación</label>
        <select class="form-select" id="identificationType" name="identificationType" [(ngModel)]="model.identificationType">
          <option value=""></option>
          <option value="JURIDICA">Jurídica</option>
          <option value="FISICA">Física</option>
        </select>
        <div *ngIf="formErrors.get('employee.identificationType')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('employee.identificationType') }}
        </div>
      </div>
      <div class="col-md-4">
        <label for="identification">Identificación</label>
        <input type="text" class="form-control" id="identification" name="identification"
               [(ngModel)]="model.identification">
        <div *ngIf="formErrors.get('employee.identification')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('employee.identification') }}
        </div>
      </div>
      <div class="col-md-6">
        <label for="email">Correo Electrónico</label>
        <input type="text" class="form-control" id="email" name="email" [(ngModel)]="model.email">
        <div *ngIf="formErrors.get('employee.email')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('employee.email') }}
        </div>
      </div>
      <div class="col-md-6">
        <label for="startDate">Fecha de Inicio</label>
        <mt-date-picker [(date)]="model.startDate" name="startDate" id="startDate" label="Fecha de Inicio"/>
        <div *ngIf="formErrors.get('employee.startDate')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('employee.startDate') }}
        </div>
      </div>
      <div class="col-md-6">
        <label for="endDate">Fecha de Salida</label>
        <mt-date-picker [(date)]="model.endDate" name="endDate" id="endDate" label="Fecha de Salida"/>
        <div *ngIf="formErrors.get('employee.endDate')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('employee.endDate') }}
        </div>
      </div>
      <div class="col-md-6">
        <label for="retentions">Retenciones</label>
        <input type="number" class="form-control" id="retentions" name="retentions" [(ngModel)]="model.retentions">
        <div *ngIf="formErrors.get('employee.retentions')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('employee.retentions') }}
        </div>
        <div *ngIf="formErrors.get('retentions')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('retentions') }}
        </div>
      </div>
      <div class="col-md-6">
        <label for="children">Hijos (cantidad)</label>
        <input type="number" class="form-control" id="children" name="children" [(ngModel)]="model.children">
        <div *ngIf="formErrors.get('employee.children')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('employee.children') }}
        </div>
        <div *ngIf="formErrors.get('children')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('children') }}
        </div>
      </div>
      <div class="col-md-6">
        <label for="alimony">Monto Pensión</label>
        <input type="number" class="form-control" id="alimony" name="alimony" [(ngModel)]="model.alimony">
        <div *ngIf="formErrors.get('employee.alimony')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('employee.alimony') }}
        </div>
        <div *ngIf="formErrors.get('alimony')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('alimony') }}
        </div>
      </div>
      <div class="col-md-6">
        <label for="embargoes">Monto Embargo</label>
        <input type="number" class="form-control" id="embargoes" name="embargoes" [(ngModel)]="model.embargoes">
        <div *ngIf="formErrors.get('employee.embargoes')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('employee.embargoes') }}
        </div>
        <div *ngIf="formErrors.get('embargoes')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('embargoes') }}
        </div>
      </div>
      <div class="col-md-6">
        <label for="bankAccount">Cuenta Bancaria</label>
        <input type="text" class="form-control" id="bankAccount" name="bankAccount" [(ngModel)]="model.bankAccount">
        <div *ngIf="formErrors.get('employee.bankAccount')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('employee.bankAccount') }}
        </div>
      </div>
      <div class="col-md-6">
        <label for="bank">Banco</label>
        <input type="text" class="form-control" id="bank" name="bank" [(ngModel)]="model.bank">
        <div *ngIf="formErrors.get('employee.bank')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('employee.bank') }}
        </div>
      </div>

      <div class="col-md-6">
        <label for="department">Departamento</label>
        <select class="form-select" id="department" name="department" [(ngModel)]="model.department">
          <option *ngFor="let department of departments" value="{{department.id}}">{{department.name}}</option>
        </select>
        <div *ngIf="formErrors.get('employee.department')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('employee.department') }}
        </div>
      </div>
      <div class="col-md-6">
        <label for="workday">Jornada</label>
        <select class="form-select" id="workday" name="department" [(ngModel)]="model.workday">
          <option *ngFor="let workday of workdays" value="{{workday.id}}">{{workday.name}}</option>
        </select>
        <div *ngIf="formErrors.get('employee.workday')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('employee.workday') }}
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="married" name="married" [(ngModel)]="model.married">
          <label  class="form-check-label"  for="married">
            Casado (a)
          </label>
          <div *ngIf="formErrors.get('employee.married')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('employee.married') }}
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="positionOfTrust" name="married" [(ngModel)]="model.positionOfTrust">
          <label  class="form-check-label"  for="positionOfTrust">
            Puesto de Confianza
          </label>
          <div *ngIf="formErrors.get('employee.positionOfTrust')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('employee.positionOfTrust') }}
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="extras" name="married" [(ngModel)]="model.extras">
          <label  class="form-check-label"  for="extras">
            Calcula Extras
          </label>
          <div *ngIf="formErrors.get('employee.extras')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('employee.extras') }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="mode == 'LIST' && !loading" class="animated fadeIn">

    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top">
      <div class="container">
        <span class="navbar-text">
          Empleados
        </span>
        <div class="pull-right">
          <button class="btn btn-link" (click)="refreshEvent()" title="Refrescar">
            <span class="fa fa-sync fa-lg"></span>
          </button>
          <button class="btn btn-primary" (click)="createEvent()">
            Nuevo
          </button>
        </div>
      </div>
    </nav>

    <mt-pagination (loadPage)="getPageEvent($event)" [page]="page"></mt-pagination>

    <table *ngIf="page.metadata.pages > 0" class="table table-hover">
      <tbody>
      <tr *ngFor="let employee of page.content">
        <td (click)="editEvent(employee)" class="mt-content-col">

          <strong>{{employee.name}} {{employee.lastName}}</strong><br>
          <span *ngIf="employee.email" class="text-muted">{{employee.email}}</span><br *ngIf="employee.email">
          <small *ngIf="employee.identification" class="text-muted">{{employee.identification}}</small><br *ngIf="employee.identification">
          <small *ngIf="employee.departmentName" class="text-muted">{{employee.departmentName}}</small>
        </td>
        <td class="mt-actions-col">
          <button class="btn btn-link btn-lg pull-right" data-bs-toggle="modal"
                  data-bs-target="#removeEmployeeConfirmation"
                  (click)="selectEvent(employee)" title="Borrar">
            <span class="fa fa-trash-alt fa-lg"></span>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
    <div *ngIf="page.metadata.pages == 0">
      No hay registros
    </div>

  </div>

  <!-- Modal -->
  <div class="modal fade" id="removeEmployeeConfirmation" tabindex="-1" role="dialog"
       aria-labelledby="removeConfirmationLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirmar</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Desea eliminar el registro?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="removeEvent(model)">Borrar
          </button>
        </div>
      </div>
    </div>
  </div>

</div>


