import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BaseService} from '../core/service/base.service';
import {SystemAccountingPeriod} from '../model/system-accounting-period';
import {Observable} from "rxjs";
import {Code} from "../model/code";
import {HeaderConstants} from "../core/utils/headers.constants";

@Injectable({
  providedIn: 'root'
})
export class SystemAccountingPeriodService extends BaseService<SystemAccountingPeriod> {

  serviceUrl(): string {
    return `/api/system-accounting-periods`;
  }

  constructor(protected http: HttpClient) {
    super(http);
  }

  getAll(): Observable<SystemAccountingPeriod[]> {
    const headersValue = new HttpHeaders().append(HeaderConstants.CACHED, 'true');
    const options = {headers: headersValue};
    return this.http.get<SystemAccountingPeriod[]>(`${this.serviceUrl()}/find-all`, options);
  }
}
