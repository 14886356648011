<div class="loader" *ngIf="loading"></div>

<div class="container" *ngIf="!loading">
  <div class="row justify-content-md-center animate__animated animate__fadeIn">
    <div class="col-md-6 col-md-offset-3">
      <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
        <div class="form-group mb-3" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
          <label class="form-label" for="username">Usuario</label>
          <input id="username" type="text" class="form-control" name="username" [(ngModel)]="model.username" autocomplete="username"
                 #username="ngModel"
                 required/>
          <div *ngIf="f.submitted && !username.valid" class="badge bg-danger animate__animated animate__fadeIn">
            Usuario es requerido
          </div>
        </div>
        <div class="form-group mb-3" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
          <label class="form-label" for="password">Contraseña</label>
          <input id="password" type="password" class="form-control" name="password" [(ngModel)]="model.password" autocomplete="current-password"
                 #password="ngModel"
                 required/>
          <div *ngIf="f.submitted && !password.valid" class="badge bg-danger animate__animated animate__fadeIn">
            Contraseña es requerida
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="float-start">
              <button class="btn btn-primary">Ingresar</button>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="float-end">
              <a [routerLink]="['/recover']" class="btn btn-link">Recuperar Contraseña</a>
            </div>
          </div>
        </div>
      </form>

    </div>
  </div>
</div>
