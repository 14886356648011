import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {PrivilegeName} from '../model/user';
import SecurityUtils from '../utils/security.utils';
import ServiceUtils from '../utils/service.utils';

@Injectable()
export class AuthGuard  {

  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (ServiceUtils.getItem('token')) {
      const privilegeNames = route.data.roles as Array<PrivilegeName>;
      return SecurityUtils.hasPermission(privilegeNames);
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
    return false;
  }
}
