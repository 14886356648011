import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TaxPayer} from '../model/tax-payer';
import {Product} from '../model/product';
import {DateTimeFormatter, DateTimeFormatterBuilder, LocalDate} from "@js-joda/core";
import {ExchangeType} from "../model/ExchangeType";

/**
 * Implements utility services from https://paper.dropbox.com/doc/API-Ministerio-de-Hacienda-znrOU6bGjTHcXjo8oUmBj
 */
@Injectable({
  providedIn: 'root'
})
export class HaciendaService {
  SERVER_URL = 'https://api.hacienda.go.cr';
  constructor(private httpClient: HttpClient) { }

  getTaxPayerDetails(identification: string): Observable<TaxPayer> {

    const paramsValue = new HttpParams().append('identificacion', identification);
    const options = {params: paramsValue};

    return this.httpClient
      .get<TaxPayer>(`${this.SERVER_URL}/fe/ae`, options);
  }

  getProductDetails(code: string): Observable<Product[]> {

    const paramsValue = new HttpParams().append('codigo', code);
    const options = {params: paramsValue};

    return this.httpClient
      .get<Product[]>(`${this.SERVER_URL}/fe/cabys`, options);
  }

  getExchangeType(from: LocalDate, to: LocalDate): Observable<ExchangeType[]> {
    const formatter = DateTimeFormatter.ofPattern('yyyy-MM-dd');
    const paramsValue = new HttpParams().append('d', from.format(formatter)).append('h', to.format(formatter));
    const options = {params: paramsValue};

    return this.httpClient
      .get<ExchangeType[]>(`${this.SERVER_URL}/indicadores/tc/dolar/historico`, options);
  }
}
