<div class="container">

  <div class="loader" *ngIf="loading"></div>

  <div *ngIf="mode == 'EDIT' && !loading" class="animated fadeIn">
    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top">
      <div class="container">
        <span class="navbar-text">
          Jornada
        </span>
        <div class="pull-right">
          <button class="btn btn-link" (click)="cancelEvent()">
            Cerrar
          </button>
          <button class="btn btn-primary" (click)="saveEvent()">
            Guardar
          </button>
        </div>
      </div>
    </nav>

    <div class="row g-3">
      <div class="col-md-6">
        <div class="form-group">
          <label for="name">Nombre</label>
          <input type="text" class="form-control" id="name" name="name" [(ngModel)]="model.name">
          <div *ngIf="formErrors.get('workday.name')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('workday.name') }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="dailyHours">Horas Diarias</label>
          <input type="text" class="form-control" id="dailyHours" name="dailyHours" [(ngModel)]="model.dailyHours">
          <div *ngIf="formErrors.get('workday.dailyHours')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('workday.dailyHours') }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="weeklyHours">Horas Semanales</label>
          <input type="text" class="form-control" id="weeklyHours" name="weeklyHours" [(ngModel)]="model.weeklyHours">
          <div *ngIf="formErrors.get('workday.weeklyHours')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('workday.weeklyHours') }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="maxExtraHoursDaily">Máx. Horas Extra Día</label>
          <input type="text" class="form-control" id="maxExtraHoursDaily" name="maxExtraHoursDaily" [(ngModel)]="model.maxExtraHoursDaily">
          <div *ngIf="formErrors.get('workday.maxExtraHoursDaily')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('workday.maxExtraHoursDaily') }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="maxExtraHoursWeekly">Máx. Horas Extra Semana</label>
          <input type="text" class="form-control" id="maxExtraHoursWeekly" name="maxExtraHoursWeekly" [(ngModel)]="model.maxExtraHoursWeekly">
          <div *ngIf="formErrors.get('workday.maxExtraHoursWeekly')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('workday.maxExtraHoursWeekly') }}
          </div>
        </div>
      </div>

    </div>
    <h5  class="mb-3 mt-3">Dias laborables</h5>
    <hr class="mb-4">
    <div class="row g-3">
      <div class="col-md-6">
        <div class="list-group">

          <a class="list-group-item list-group-item-action d-flex flex-row">
            <div class="d-flex align-items-center me-4">
              <input type="checkbox" value="MONDAY" (change)="selectedDayChange($event)" [checked]="hasDaySelected(DayOfWeek.MONDAY)">
            </div>
            <div class="flex-column align-items-start">
              <div class="d-flex w-100 justify-content-between">
                <p class="mb-0">Lunes</p>
              </div>
            </div>
          </a>
          <a class="list-group-item list-group-item-action d-flex flex-row">
            <div class="d-flex align-items-center me-4">
              <input type="checkbox" value="TUESDAY" (change)="selectedDayChange($event)" [checked]="hasDaySelected(DayOfWeek.TUESDAY)">
            </div>
            <div class="flex-column align-items-start">
              <div class="d-flex w-100 justify-content-between">
                <p class="mb-0">Martes</p>
              </div>
            </div>
          </a>
          <a class="list-group-item list-group-item-action d-flex flex-row">
            <div class="d-flex align-items-center me-4">
              <input type="checkbox" value="WEDNESDAY" (change)="selectedDayChange($event)" [checked]="hasDaySelected(DayOfWeek.WEDNESDAY)">
            </div>
            <div class="flex-column align-items-start">
              <div class="d-flex w-100 justify-content-between">
                <p class="mb-0">Miércoles</p>
              </div>
            </div>
          </a>
          <a class="list-group-item list-group-item-action d-flex flex-row">
            <div class="d-flex align-items-center me-4">
              <input type="checkbox" value="THURSDAY" (change)="selectedDayChange($event)" [checked]="hasDaySelected(DayOfWeek.THURSDAY)">
            </div>
            <div class="flex-column align-items-start">
              <div class="d-flex w-100 justify-content-between">
                <p class="mb-0">Jueves</p>
              </div>
            </div>
          </a>
          <a class="list-group-item list-group-item-action d-flex flex-row">
            <div class="d-flex align-items-center me-4">
              <input type="checkbox" value="FRIDAY" (change)="selectedDayChange($event)" [checked]="hasDaySelected(DayOfWeek.FRIDAY)">
            </div>
            <div class="flex-column align-items-start">
              <div class="d-flex w-100 justify-content-between">
                <p class="mb-0">Viernes</p>
              </div>
            </div>
          </a>

          <a class="list-group-item list-group-item-action d-flex flex-row">
            <div class="d-flex align-items-center me-4">
              <input type="checkbox" value="SATURDAY" (change)="selectedDayChange($event)" [checked]="hasDaySelected(DayOfWeek.SATURDAY)">
            </div>
            <div class="flex-column align-items-start">
              <div class="d-flex w-100 justify-content-between">
                <p class="mb-0">Sábado</p>
              </div>
            </div>
          </a>
          <a class="list-group-item list-group-item-action d-flex flex-row">
            <div class="d-flex align-items-center me-4">
              <input type="checkbox" value="SUNDAY" (change)="selectedDayChange($event)" [checked]="hasDaySelected(DayOfWeek.SUNDAY)">
            </div>
            <div class="flex-column align-items-start">
              <div class="d-flex w-100 justify-content-between">
                <p class="mb-0">Domingo</p>
              </div>
            </div>
          </a>


        </div>
      </div>
    </div>
  </div>

  <div *ngIf="mode == 'LIST' && !loading" class="animated fadeIn">

    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top">
      <div class="container">
        <span class="navbar-text">
          Jornadas
        </span>
        <div class="pull-right">
          <button class="btn btn-link" (click)="refreshEvent()" title="Refrescar">
            <span class="fa fa-sync fa-lg"></span>
          </button>
          <button class="btn btn-primary" (click)="createEvent()">
            Agregar
          </button>
        </div>
      </div>
    </nav>

    <mt-pagination (loadPage)="getPageEvent($event)" [page]="page"></mt-pagination>

    <table *ngIf="page.metadata.pages > 0" class="table table-hover">
      <thead>
        <tr>
          <th>
            Jornada
          </th>
          <th scope="col" class="text-end">
            Horas Diarias
          </th>
          <th scope="col" class="text-end">
            Horas Semanales
          </th>
          <th scope="col" class="text-end">
            Máx. Horas Extra Día
          </th>
          <th scope="col" class="text-end">
            Máx. Horas Extra Mes
          </th>
          <th>

          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let workday of page.content">
          <td (click)="editEvent(workday)" class="mt-content-col">
            {{workday.name}}
          </td>
          <td (click)="editEvent(workday)" class="mt-content-col text-end align-middle">
            {{workday.dailyHours}}
          </td>
          <td (click)="editEvent(workday)" class="mt-content-col text-end align-middle">
            {{workday.weeklyHours}}
          </td>
          <td (click)="editEvent(workday)" class="mt-content-col text-end align-middle">
            {{workday.maxExtraHoursDaily}}
          </td>
          <td (click)="editEvent(workday)" class="mt-content-col text-end align-middle">
            {{workday.maxExtraHoursWeekly}}
          </td>
          <td class="mt-actions-col">
            <button class="btn btn-link btn-lg pull-right" data-bs-toggle="modal" data-bs-target="#workdayConfirmation"
                    (click)="selectEvent(workday)" title="Borrar">
              <span class="fa fa-trash-alt fa-lg"></span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="page.metadata.pages == 0">
      No hay registros
    </div>

  </div>

  <!-- Modal -->
  <div class="modal fade" id="workdayConfirmation" tabindex="-1" role="dialog"
       aria-labelledby="removeConfirmationLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirmar</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Desea eliminar el registro?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="removeEvent(model)">Borrar</button>
        </div>
      </div>
    </div>
  </div>

</div>


