import {PrivilegeName, User} from '../model/user';
import {Tenant} from '../model/tenant';
import ServiceUtils from './service.utils';

export default class SecurityUtils {

  static hasPermission(permissions: Array<PrivilegeName>): boolean {
    const permissionsFromStorage = ServiceUtils.getItem('permissions');
    if ( permissionsFromStorage) {
      const userPermissions = JSON.parse(permissionsFromStorage) as PrivilegeName[];
      let allowed = false;
      if (permissions !== undefined) {
        permissions.forEach(permission => {
          if (userPermissions !== undefined) {
            userPermissions.forEach(userPermission => {
              if (permission === userPermission) {
                allowed = true;
              }
            });
          }
        });
      }
      return allowed;
    }
    return false;
  }
}
