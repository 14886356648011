import {Filter} from '../core/filter/filter';
import {BudgetFormStatus} from '../model/budget-form';
import {LocalDate} from '@js-joda/core';

export class BudgetFormFilter extends Filter {
  public sequence?: string;
  public status?: BudgetFormStatus;
  public from?: LocalDate;
  public to?: LocalDate;
}
