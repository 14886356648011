<div>

  <div class="loader" *ngIf="loading"></div>

  <div *ngIf="mode == 'EDIT' && !loading" class="animate__animated animate__fadeIn ">
    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">
      <div class="container-fluid">
        <div>
          <strong><span *ngIf="model.type == 'INVOICE'">Factura</span>
          <span *ngIf="model.type == 'PURCHASE_INVOICE'">Factura de Compra</span>
          <span *ngIf="model.type == 'CREDIT_NOTE'">Nota de Credito</span></strong><span> - {{model.sequence}}</span>

        </div>
        <div class="pull-right">
          <button class="btn btn-secondary" (click)="cancelEvent()" title="Volver">
            Volver
          </button>
        </div>
      </div>
    </nav>
    <div class="container">
      <div class="row mb-3">
        <div class="form-group col-md-6">
          <div class="card">
            <div class="card-header">
              Emisor
            </div>
            <div class="card-body">
              <span>{{model.issuer}}</span>
              <br>
              <small class="text-muted">{{model.issuerId}}</small>
              <br>

            </div>
          </div>
        </div>

        <div class="form-group col-md-6">
          <div class="card">
            <div class="card-header">
              Receptor
            </div>
            <div class="card-body">
              <span>{{model.receiver}}</span>
              <br>
              <small class="text-muted">{{model.receiverId}}</small>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-3" *ngIf="model.statusDetails">
        <div class="form-group col-md-12">
          <div class="card">
            <div class="card-header">
              Respuesta de Hacienda (Emisión Factura)
            </div>
            <div class="card-body">
              <p>{{model.statusDetails}}</p>
            </div>
          </div>
        </div>
      </div>
      <div  class="row mb-3" *ngIf="model.receivedStatusDetails">
        <div class="form-group col-md-12">
          <div class="card">
            <div class="card-header">
              Respuesta de Hacienda (Aceptación Factura)
            </div>
            <div class="card-body">
              <p>{{model.receivedStatusDetails}}</p>
            </div>
          </div>
        </div>
      </div>

      <div  class="row mb-3" *ngIf="model.notes">
        <div class="form-group col-md-12">
          <div class="card">
            <div class="card-header">
              Notas
            </div>
            <div class="card-body">
              <p>{{model.notes}}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="form-group col-md-6">
          <label class="form-label" for="date">Fecha</label>
          <input type="text" class="form-control " id="date" name="date" [ngModel]="model.date.toString() | date:'dd/MM/yyyy'" disabled>
        </div>
        <div class="form-group col-md-6">
          <label class="form-label" for="paymentMethod">Medio de Pago</label>
          <input type="text" class="form-control " id="paymentMethod" name="sellCondition" [ngModel]="paymentMethod" disabled>
        </div>
      </div>

      <div class="row mb-3">
        <div class="form-group col-md-3">
          <label class="form-label" for="currency">Moneda</label>
          <input type="text" class="form-control" id="currency" name="currency" [(ngModel)]="model.currency" disabled>

        </div>

        <div class="form-group col-md-3">
          <label class="form-label" for="exchange">Tipo Cambio</label>
          <input type="text" class="form-control" id="exchange" name="exchange" [(ngModel)]="model.exchange" disabled>
        </div>
        <div class="form-group col-md-6">
          <label class="form-label" for="sellCondition">Condición Venta</label>
          <input type="text" class="form-control " id="sellCondition" name="sellCondition" [ngModel]="sellCondition" disabled>
        </div>
      </div>

      <div class="form-group mb-3">
        <label class="form-label" for="activity">Actividad</label>
        <input type="text" class="form-control " id="activity" name="activity" [ngModel]="activityType" disabled>
      </div>

      <table class="table table-hover">
        <thead>
        <tr>
          <th scope="col">
            Linea
          </th>
          <th scope="col">
            Detalles
          </th>
          <th scope="col" class="text-end">
            Descuento
          </th>
          <th scope="col" class="text-end">
            Exoneración
          </th>
          <th scope="col" class="text-end">
            Sub Total
          </th>
          <th scope="col" class="text-end">
            Impuestos
          </th>
          <th scope="col" class="text-end">
            Total
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let line of model.lines">
          <td class="mt-content-col">
            {{line.lineNumber}}
          </td>
          <td>
            <span>{{line.detail}}</span> <br>
            <small class="text-muted">{{line.quantity}} {{line.units}}</small> <br>
            <small>{{line.cabysCode}}</small>
          </td>
          <td class="text-end">
            <p *ngFor="let discount of line.discountsDetails" >
              <span>{{discount.MontoDescuento | currency:model.currency:'code'}}</span><br>
            </p>
          </td>
          <td class="text-end">
            <p>
              <span *ngIf="line.exonerationDocument">{{line.exonerationDocument}}</span> <br>
              <small *ngIf="line.exonerationAmount" class="text-muted">{{line.exonerationAmount}}%</small>
            </p>
          </td>
          <td class="text-end">
            {{line.subTotal | currency:model.currency:'code'}}<br>
          </td>
          <td class="text-end">
            <p *ngFor="let tax of line.taxesDetails" >
              <span >{{tax.Monto | currency:model.currency:'code'}}</span> <br>
              <small class="text-muted">({{tax.CodigoTarifa}}) {{tax.Tarifa}}%</small>
            </p>
          </td>
          <td class="text-end">
            {{line.totalAmount | currency:model.currency:'code'}}
          </td>
        </tr>
        </tbody>
        <tfoot>
          <tr>
            <th scope="col">
            </th>
            <th scope="col">
            </th>
            <th scope="col">
            </th>
            <th scope="col">
            </th>
            <th scope="col" class="text-end">
            </th>
            <th scope="col" class="text-end">
              {{model.totalTax | currency:model.currency:'code'}}
            </th>
            <th scope="col" class="text-end">
              {{model.total | currency:model.currency:'code'}}
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>

  <div *ngIf="mode == 'LIST'" class="animate__animated animate__fadeIn">
    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">
      <div class="container-fluid">
        <form class="d-flex">
          <div class="input-group me-2">
            <input class="form-control" placeholder="aaaa-mm-dd"
                   name="from" [(ngModel)]="from" ngbDatepicker #d1="ngbDatepicker" id="from" (ngModelChange)="fromDateChanged($event)" title="Desde">
            <button class="btn btn-outline-secondary" (click)="d1.toggle()" type="button">
              <span class=" fa fa-calendar"></span>
            </button>
          </div>
          <div class="input-group me-2">
            <input class="form-control" placeholder="aaaa-mm-dd"
                   name="to" [(ngModel)]="to" ngbDatepicker #d2="ngbDatepicker" id="to" (ngModelChange)="toDateChanged($event)" title="Hasta">
              <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
                <span class=" fa fa-calendar"></span>
              </button>
          </div>
          <div class="input-group me-2">
            <input class="form-control me-2 " id="text" name="text" [(ngModel)]="text" (change)="getPageEvent(0)" title="Buscar..." placeholder="Buscar...">
          </div>
          <div class="dropdown me-4">
            <button type="button" class="btn btn-secondary dropdown-toggle" id="dropdownMenuOffset" data-bs-toggle="dropdown" aria-expanded="false" title="Filtros">
              <span class="fa fa-filter"></span>
            </button>
            <div class="dropdown-menu dropdown-large">
              <form class="px-4 py-3">

                <div class="mb-3">
                  <label for="status" class="form-label">Estado</label>
                  <select class="form-select me-2" id="status" name="status" [(ngModel)]="status" (ngModelChange)="statusChanged(status)" title="Estado">
                    <option value="ALL" selected>Todos</option>
                    <option value="DOCUMENT_PENDING" >Documento Pendiente</option>
                    <option value="DOCUMENT_APPROVED">Documento Aprobado</option>
                    <option value="DOCUMENT_REJECTED">Documento Rechazado</option>
                    <option value="RESPONSE_REJECTED">Respuesta Rechazada</option>
                    <option value="RESPONSE_APPROVED">Respuesta Aprobada</option>
                    <option value="RESPONSE_PENDING">Respuesta Pendiente</option>
                  </select>
                </div>

                <div class="mb-3">
                  <label for="documentType" class="form-label">Tipo documento</label>
                  <select class="form-select me-2 " id="documentType" name="documentType" [(ngModel)]="documentType" (ngModelChange)="documentTypeChanged(documentType)" title="Tipo Documento">
                    <option value="ALL" selected>Todos</option>
                    <option value="UNDEFINED">Sin Definir</option>
                    <option value="INVOICE">Factura</option>
                    <option value="PURCHASE_INVOICE">Factura de Compra</option>
                    <option value="CREDIT_NOTE">Nota de Crédito</option>
                    <option value="DEBIT_NOTE">Nota de Débito</option>
                    <option value="TICKET">Tiquete Electrónico</option>
                    <option value="EXPORT_INVOICE">Factura de Exportación</option>
                    <option value="PAYMENT_DOCUMENT">Pago</option>
                    <option value="EXTRA_PAYMENT">Saldo a Favor</option>
                    <option value="MANUAL_DOCUMENT">Ingreso Manual</option>
                    <option value="OTHER">Otro</option>
                  </select>
                </div>

                <div class="mb-3">
                  <label for="type" class="form-label">Compras o Ventas</label>
                  <select class="form-select me-2 "  id="type" name="type" [(ngModel)]="type" (ngModelChange)="typeChanged(type)" title="Compra o venta">
                    <option value="ALL" selected>Todos</option>
                    <option value="PURCHASE">Compras</option>
                    <option value="SELL">Ventas</option>
                  </select>
                </div>

                <div class="mb-3"  *ngIf="publicInstitution">
                  <div class="form-check">
                    <input class="form-check-input" name="budgetSelected" type="checkbox" id="budgetSelected" (change)="budgetSelectedChanged(budgetNotSelected)" [(ngModel)]="budgetNotSelected">
                    <label class="form-check-label" for="budgetSelected">
                      Sin Planilla Presupuestaria
                    </label>
                  </div>
                </div>

              </form>
            </div>
          </div>

        </form>
        <div class="pull-right">
          <ng-container *mtHasPermission="['ADMIN_DOCUMENTS']">
            <button *ngIf="countSelections > 1" type="button" class="btn btn-danger me-1"  data-bs-toggle="modal" data-bs-target="#removeSelectedDocumentConfirmation" title="Eliminar Seleccionados">
              <span class="fa fa-trash-alt fa-lg "></span>
            </button>
          </ng-container>
          <ng-container *mtHasPermission="['ADMIN_DOCUMENTS']">
            <!--button *ngIf="countSelections > 1" class="btn btn-warning pull-right me-1" data-bs-toggle="modal" data-bs-target="#acceptDocument"
                    (click)="selectDocumentsToAcceptEvent()" title="Aceptar">
              <span class="fa fa-check fa-lg"></span>
            </button-->
          </ng-container>
          <button type="button" class="btn btn-secondary me-1" (click)="refreshEvent()" title="Refrescar">
            <span class="fa fa-sync fa-lg "></span>
          </button>
          <button type="button" class="btn btn-secondary me-1" (click)="export()" title="Descargar Excel">
            <span class="fa fa-download fa-lg "></span>
          </button>
          <button type="button" *mtHasPermission="['ADMIN_DOCUMENTS']"  class="btn btn-secondary me-1" title="Cargar" (click)="cleanData()" data-bs-toggle="modal" data-bs-target="#loadFile">
            <span class="fa fa-upload fa-lg "></span>
          </button>
          <!--button type="button" *mtHasPermission="['ADMIN_DOCUMENTS']"  class="btn btn-secondary me-1" title="Crear manualmente" routerLink="/documentos/agregar">
            <span class="fa fa-plus fa-lg "></span>
          </button-->
          <button type="button" *mtHasPermission="['ADMIN_DOCUMENTS']"  class="btn btn-secondary me-1" title="Agregar documento" routerLink="/documentos/agregar-pago">
            <span class="fa fa-file-circle-plus fa-lg "></span>
          </button>
          <ng-container *ngIf="generateValueAddedTax">
            <button type="button" *mtHasPermission="['ADMIN_DOCUMENTS']"  class="btn btn-secondary me-1" title="Categorizar" routerLink="/documentos/categorizar">
              Categorizar
            </button>
          </ng-container>
          <button type="button" *mtHasPermission="['ADMIN_DOCUMENTS']" class="btn btn-secondary me-1" title="Clasificar" routerLink="/documentos/clasificar">
            Clasificar
          </button>
          <ng-container *ngIf="publicInstitution">
            <button type="button" *mtHasPermission="['ADMIN_DOCUMENTS']" class="btn btn-secondary me-1" title="Planilla" routerLink="/documentos/planilla">
              Planillas
            </button>
          </ng-container>
          <!--div class="btn-group">
            <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Emitir...
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="#" routerLink="/documents/add">Factura Electronica</a>
              <a class="dropdown-item" href="#" routerLink="/documents/add">Factura de Compra</a>
              <a class="dropdown-item" href="#" routerLink="/documents/add">Tiquete Electronico</a>
            </div>
          </div-->
        </div>
      </div>
    </nav>

    <div *ngIf="!loading">
      <mt-pagination (loadPage)="getPageEvent($event)" [page]="page" [showPages]="false"></mt-pagination>

      <table *ngIf="page.metadata.pages > 0" class="table table-hover">
        <thead >
        <tr>
          <th scope="col">
            <ng-container *mtHasPermission="['ADMIN_DOCUMENTS']">
              <input type="checkbox" [(ngModel)]="allSelected" (change)="changeSelection(allSelected)">
            </ng-container>
          </th>
          <th scope="col">
            Documento
          </th>
          <th *ngIf="publicInstitution">
            Planilla
          </th>
          <th>
            Tipo
          </th>
          <th>
            Estado
          </th>
          <th>
            Respuesta Receptor
          </th>
          <th>
            Emisor
          </th>
          <th>
            Receptor
          </th>
          <th scope="col">
            Fecha
          </th>
          <th scope="col" class="text-end">
            Total
          </th>
          <th>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let document of page.content">
          <td class="align-middle">
            <input *ngIf="!document.processed" type="checkbox" [(ngModel)]="document.selected" (change)="changeSingleSelection(document.selected)"/>
          </td>
          <td class="mt-content-col align-middle">
            <span>{{document.sequence}}</span>
            <span class="badge bg-success ms-2" *ngIf="document.categorizationCompleted" title="Categorización Completa">CA</span>
            <span class="badge bg-success ms-2" *ngIf="document.classificationCompleted" title="Clasificación Completa">CL</span>
            <span class="badge bg-info ms-2" *ngIf="document.budgetFormSelected" title="Incluido en Planilla">PL</span>
            <span class="badge bg-warning ms-2" *ngIf="document.manualEntry" title="Ingreso Manual">M</span>
            <span class="badge bg-danger ms-2" *ngIf="document.processed" title="Procesado en Cierre">P</span>
          </td>
          <td class="align-middle" *ngIf="publicInstitution">
            {{document.budgetFormSequence}}
          </td>
          <td (click)="editEvent(document)" class="mt-content-col align-middle">
            <span *ngIf="document.type == 'UNDEFINED'" >Sin Definir</span>
            <span *ngIf="document.type == 'INVOICE'" >Factura</span>
            <span *ngIf="document.type == 'PURCHASE_INVOICE'" >Factura de Compra</span>
            <span *ngIf="document.type == 'CREDIT_NOTE'" >Nota de Crédito</span>
            <span *ngIf="document.type == 'DEBIT_NOTE'" >Nota de Débito</span>
            <span *ngIf="document.type == 'TICKET'" >Tiquete Electrónico</span>
            <span *ngIf="document.type == 'EXPORT_INVOICE'" >Factura de Exportación</span>
            <span *ngIf="document.type == 'PAYMENT_DOCUMENT'" >Pago</span>
            <span *ngIf="document.type == 'EXTRA_PAYMENT'" >Saldo Favor</span>
            <span *ngIf="document.type == 'MANUAL_DOCUMENT'" >Ingreso Manual</span>
            <span *ngIf="document.type == 'OTHER'" >Otro</span>
            <span class="badge bg-light  ms-2">{{document.version}}</span>
          </td>
          <td (click)="editEvent(document)" class="mt-content-col align-middle">
            <span *ngIf="document.status == 'DOCUMENT_PENDING'" class="badge bg-secondary" >Documento Pendiente</span>
            <span *ngIf="document.status == 'DOCUMENT_APPROVED'" class="badge bg-success" >Documento Aprobado</span>
            <span *ngIf="document.status == 'DOCUMENT_REJECTED'" class="badge bg-danger" >Documento Rechazado</span>
            <span *ngIf="document.status == 'RESPONSE_PENDING'" class="badge bg-warning" >Respuesta Pendiente</span>
            <span *ngIf="document.status == 'RESPONSE_REJECTED'" class="badge bg-warning" >Respuesta Rechazada</span>
            <span *ngIf="document.status == 'RESPONSE_APPROVED'" class="badge bg-warning" >Respuesta Aprobada</span>
          </td>
          <td (click)="editEvent(document)" class="mt-content-col align-middle">
            <span *ngIf="document.receivedStatus == 'PENDING'" class="badge bg-secondary" >Pendiente</span>
            <span *ngIf="document.receivedStatus == 'ACCEPTED'" class="badge bg-success" >Aceptado</span>
            <span *ngIf="document.receivedStatus == 'REJECTED'" class="badge bg-danger" >Rechazado</span>
            <span *ngIf="document.receivedStatus == 'PARTIALLY_ACCEPTED'" class="badge bg-warning" >Aceptado Parcialmente</span>
          </td>
          <td (click)="editEvent(document)" class="mt-content-col align-middle">
            <span class="mt-large-text" [title]="document.issuer"> {{document.issuer}}</span>
          </td>
          <td (click)="editEvent(document)" class="mt-content-col align-middle">
            <span class="mt-large-text" [title]="document.receiver"> {{document.receiver}}</span>
          </td>
          <td (click)="editEvent(document)" class="mt-content-col align-middle">
            <span>{{document.date.toString()  | date:'dd/MM/yyyy'}}</span>
          </td>
          <td (click)="editEvent(document)" class="mt-content-col text-end align-middle">
            <span>{{document.total | currency:document.currency:'code'}}</span>
          </td>
          <td class="text-end  align-middle">
            <ng-container *mtHasPermission="['ADMIN_DOCUMENTS']">
              <ng-container *ngIf="acceptableDocument(document.type)">
                <!--button *ngIf="document.receivedStatus == 'PENDING' && document.operationType == 'PURCHASE' && !document.processed" class="btn btn-warning pull-right me-1" data-bs-toggle="modal" data-bs-target="#acceptDocument"
                        (click)="selectDocumentToAcceptEvent(document)" title="Aceptar">
                  <span class="fa fa-check fa-lg"></span>
                </button-->
              </ng-container>
              <button *ngIf="document.manualEntry && !document.processed" type="button" class="btn btn-secondary me-1" title="Editar documento" [routerLink]="'/documentos/editar-pago/' + document.id">
                <span class="fa fa-edit fa-lg "></span>
              </button>
              <button *ngIf="!document.processed" class="btn btn-danger pull-right me-1" data-bs-toggle="modal" data-bs-target="#removeDocumentConfirmation"
                      (click)="selectEvent(document)" title="Borrar">
                <span class="fa fa-trash-alt fa-lg"></span>
              </button>
              <mt-audit-button [auditableEntity]="document"
                               [(modifiedByActor)]="auditModal.modifiedByActor"
                               [(createdByActor)]="auditModal.createdByActor"
                               [(modifiedDate)]="auditModal.modified"
                               [(createdDate)]="auditModal.created"></mt-audit-button>
            </ng-container>
          </td>
        </tr>
        </tbody>
      </table>
      <div *ngIf="page.metadata.pages == 0" class="p-3">
        No hay registros
      </div>

    </div>
  </div>


  <!-- Modal -->
  <mt-audit-modal #auditModal ></mt-audit-modal>
  <div class="modal fade" id="removeDocumentConfirmation" tabindex="-1" role="dialog"
       aria-labelledby="removeConfirmationLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirmar</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Desea eliminar el registro?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="removeEvent(model)">Borrar</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="removeSelectedDocumentConfirmation" tabindex="-1" role="dialog"
       aria-labelledby="removeConfirmationLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirmar</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Desea eliminar los registros seleccionados?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="deleteSelectedEvent()">Borrar</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="acceptDocument" tabindex="-1" role="dialog"
       aria-labelledby="acceptDocumentLabel">
    <div class="modal-dialog  modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Aceptar documento</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

          <div class="row mb-3">
            <div class="form-group col-md-12">
              <label class="form-label">Aceptación</label>
              <select class="form-select"  [(ngModel)]="messageCode">
                <option value="1" selected>Aceptado</option>
                <option value="2" >Aceptado Parcialmente</option>
                <option value="3" >Rechazado</option>
              </select>
            </div>
          </div>

          <div class="row mb-3">
            <div class="form-group col-md-12">
              <label class="form-label">Actividad</label>
              <select class="form-select"  [(ngModel)]="activityCode">
                <option *ngFor="let activityCode of activityCodes" [value]="activityCode.code" >{{activityCode.description}}</option>
              </select>
            </div>
          </div>

          <div class="row mb-3">
            <div class="form-group col-md-12">
              <label class="form-label">Condición Impuesto</label>
              <select class="form-select" [(ngModel)]="taxCondition" (ngModelChange)="calculateAllPercentage()">
                <option value="01" >General Credito IVA</option>
                <option value="02" >General Crédito parcial del IVA</option>
                <option value="03" >Bienes de Capital</option>
                <option value="04" >Gasto corriente no genera crédito</option>
                <option value="05" >Proporcionalidad</option>
              </select>
            </div>
          </div>

          <div class="row mb-3">
            <div class="form-group col-md-4">
              <label class="form-label">Factor</label>
              <input class="form-control" [(ngModel)]="factor" [disabled]="taxCondition !== '03' && taxCondition !== '05'" (ngModelChange)="calculateAllPercentage()"/>
            </div>
          </div>

          <div class="row mb-3" *ngFor="let documentToAccept of documentsToAccept">
            <div class=" col-md-12">
              <div class="card">
                <div class="card-body">
                  <span>{{documentToAccept.document?.sequence}} - <i>{{documentToAccept.document?.issuer}}</i> </span>
                  <br>
                  <small>{{documentToAccept.document?.date?.toString() | date:'dd/MM/yyyy'}}</small>
                  <br>
                  <strong>{{documentToAccept.document?.total | currency:documentToAccept.document?.currency:'code'}}</strong> (Total)
                  <br>
                  <strong>{{documentToAccept.expensesToApply | currency:documentToAccept.document?.currency:'code'}}</strong> (Gasto Aplicable)
                  <br>
                  <strong>{{documentToAccept.taxToRecover | currency:documentToAccept.document?.currency:'code'}}</strong> (Impuesto Acreditar)

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cerrar</button>
          <!--button type="button" class="btn btn-success" data-bs-dismiss="modal" (click)="acceptAllEvent()">Aceptar</button-->
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="loadFile" tabindex="-1" role="dialog"
       aria-labelledby="loadFileLabel">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Cargar Archivos</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

          <p *ngIf="filesPendingCount == 0 && !currentFile && filesUploadedCount == 0">
            Seleccione los archivos que desee cargar en el sistema. Puede cargar los siguientes tipos de documentos:
          </p>
          <ul *ngIf="filesPendingCount == 0 && !currentFile && filesUploadedCount == 0">
            <li>Factura Electrónica</li>
            <li>Factura Electrónica Exportación</li>
            <li>Factura Electrónica Compra</li>
            <li>Tiquete Electrónico</li>
            <li>Nota de Crédito</li>
            <li>Nota de Débito</li>
            <li>Respuestas de Hacienda</li>
            <li>Archivos comprimidos en formato ZIP (no soporta archivos RAR)</li>
            <li><strong>Tamaño máximo por archivo: 200MB</strong></li>
          </ul>

          <ul *ngIf="filesPendingCount > 0 || currentFile || filesUploadedCount > 0" class="list-group list-group-flush">
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Archivos procesados
              <span class="badge bg-info bg-pill">{{filesUploadedCount}}</span>
            </li>
            <li  *ngIf="currentFile" class="list-group-item">
              <span id="file-label-current">{{currentFile.data.name}}</span><br>
              <small>{{currentFile.message}}</small>
              <div class="progress">
                <div class="progress-bar progress-bar-animated progress-bar-striped" [ngClass]="currentFile.status" role="progressbar" [ngStyle]="{width:currentFile.progress + '%' }" [attr.aria-valuenow]="currentFile.progress" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Archivos pendientes
              <span class="badge bg-warning bg-pill">{{filesPendingCount}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Errores encontrados
              <span class="badge bg-danger bg-pill">{{fileUploadErrors.length}}</span>
            </li>
            <li *ngFor="let error of fileUploadErrors" class="list-group-item">
              <span>{{error.file}}</span><br>
              <small>{{error.message}}</small>
            </li>
          </ul>

          <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" style="display:none;" />
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" (click)="uploadEvent()" title="Seleccionar...">
            Seleccionar...
          </button>
          <button type="button" class="btn btn-link" (click)="refreshEvent()" data-bs-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>

</div>


