import {Injectable} from '@angular/core';

import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {JobPosition} from './resource/job-position';
import {BaseService} from '../../core/service/base.service';
import {Observable} from 'rxjs';
import {HeaderConstants} from '../../core/utils/headers.constants';


@Injectable()
export class JobPositionService extends BaseService<JobPosition> {

  serviceUrl(): string {
    return `/api/t/{tenant}/job-positions`;
  }

  constructor(protected http: HttpClient) {
    super(http);
  }

  getAll(refresh = false): Observable<JobPosition[]> {
    const paramsData = new HttpParams();
    const options = {params: paramsData, headers: new HttpHeaders().set(HeaderConstants.CACHED, refresh ? 'false' : 'true')};
    return this.http
      .get<JobPosition[]>(`${this.serviceUrl()}/all`, options );
  }
}
