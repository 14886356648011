import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Page} from '../../../model/page';

@Component({
  selector: 'mt-pagination',
  templateUrl: './pagination.component.html'
})
export class PaginationComponent implements OnInit {

  @Output()
  loadPage = new EventEmitter<number>();

  @Input()
  page: Page<any> = new Page<any>({count: 0, page: 1, pages: 1});

  @Input()
  showPages = true;

  constructor() {
  }

  getPage(pageNumber: number): void {
    this.loadPage.emit(pageNumber);
  }

  ngOnInit(): void {
  }

}
