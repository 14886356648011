import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../core/component/base.component';
import {AlertService} from '../../../core/service/alert.service';
import {MessageService} from '../../../core/service/message.service';
import {SecurityService} from '../../../core/service/security.service';
import {Router} from '@angular/router';
import {DocumentService} from '../../../service/document.service';
import {Document, DocumentStatus} from '../../../model/document';
import {UploadService} from '../../../core/service/upload.service';
import {LocalDate} from '@js-joda/core';
import {ComprobanteElectronico} from '../../../model/v4_3/comprobante-electronico';
import {CodeService} from '../../../service/code.service';
import {Code, CodeType} from '../../../model/code';
import {DocumentFilter} from '../../../filter/document.filter';
import {OperationType} from '../../../model/document-line';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {ClientService} from '../../../service/client.service';
import {HttpErrorResponse} from '@angular/common/http';
import {Tenant} from '../../../core/model/tenant';
import {LoggerService} from '../../../service/logger.service';
import ServiceUtils from '../../../core/utils/service.utils';

@Component({
  selector: 'app-document',
  templateUrl: './accounts-receivable.component.html'
})
export class AccountsReceivableComponent extends BaseComponent<Document> implements OnInit, OnDestroy {

  @ViewChild('fileUpload', {static: false})
    // @ts-ignore
  fileUpload: ElementRef;
  invoice: ComprobanteElectronico = new ComprobanteElectronico();
  text = '';
  allSelected = false;
  countSelections = 0;
  status = DocumentStatus.ALL;
  type = OperationType.ALL;
  from: NgbDateStruct;
  to: NgbDateStruct;
  fromLocalDate: LocalDate;
  toLocalDate: LocalDate;
  sellConditions: Code[] = [];
  fileUploadErrors: ErrorEntry[] = [];
  selectedReferenceDocument?: Document;
  selectedDocument?: Document;

  constructor(private documentService: DocumentService,
              private alertService: AlertService,
              protected messageService: MessageService,
              protected router: Router,
              protected securityService: SecurityService,
              private uploadService: UploadService,
              private codeService: CodeService,
              protected clientService: ClientService,
              protected loggerService: LoggerService) {
    super(documentService, 'accounts-receivable', alertService, router, securityService, messageService, loggerService);
    this.sortBy.push('date');
    this.pageSize = 20;
    this.fromLocalDate = LocalDate.now().withDayOfMonth(1);
    this.toLocalDate = this.fromLocalDate.withDayOfMonth(this.fromLocalDate.lengthOfMonth());
    this.from = {
      year: this.fromLocalDate.year(),
      month: this.fromLocalDate.monthValue(),
      day: this.fromLocalDate.dayOfMonth()
    };
    this.to = {year: this.toLocalDate.year(), month: this.toLocalDate.monthValue(), day: this.toLocalDate.dayOfMonth()};
  }

  getModelInstance(): Document {
    return new Document(0, LocalDate.now(), '', '', false);
  }

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>(); // TODO add form values like .set('client.name', '').set('client.phone', '').set('client.email', '');
  }


  public cleanData(): void {
    super.cleanData();
    this.fileUploadErrors = [];
  }

  getPageEvent(pageNumber: number): void {
    this.cleanErrors();
    this.loading = true;
    const documentFilter = new DocumentFilter();
    documentFilter.from = this.fromLocalDate;
    documentFilter.to = this.toLocalDate;
    documentFilter.status = DocumentStatus.DOCUMENT_APPROVED;

    if (this.text) {
      documentFilter.text = this.text;
    }
    if (this.status && this.status !== DocumentStatus.ALL) {
      documentFilter.status = this.status;
    }
    if (this.type && this.type !== OperationType.ALL) {
      documentFilter.operationType = this.type;
    }
    // Creditos https://www.hacienda.go.cr/ATV/ComprobanteElectronico/docs/esquemas/2016/v4.3/ANEXOS%20Y%20ESTRUCTURAS_V4.3.pdf tabla 5
    documentFilter.sellConditions = ['02', '10'];
    this.service.searchByFilter(documentFilter, pageNumber, this.pageSize, this.sortBy, this.sortOrder)
      .subscribe(
        page => this.setPageEvent(page),
        error => this.processError(error));
  }

  changeSelection(allSelected: boolean): void {
    this.countSelections = 0;
    this.page.content?.forEach(value => {
      value.selected = allSelected;
      this.countSelections += value.selected ? 1 : 0;
    });
  }

  changeSingleSelection(selected: boolean): void {
    this.countSelections = 0;
    this.page.content?.forEach(value => {
      this.allSelected = this.allSelected && value.selected;
      this.countSelections += value.selected ? 1 : 0;
    });
  }

  typeChanged(type: string): void {
    this.refreshEvent();
  }

  fromDateChanged(date: NgbDateStruct): void {
    this.fromLocalDate = LocalDate.of(date.year, date.month, date.day);
    this.from = date;
    if (this.fromLocalDate.isAfter(this.toLocalDate)) {
      this.toLocalDate = LocalDate.of(date.year, date.month, this.fromLocalDate.lengthOfMonth());
      this.to = {year: date.year, month: date.month, day: this.fromLocalDate.lengthOfMonth()};
    }
    this.refreshEvent();
  }

  toDateChanged(date: NgbDateStruct): void {
    this.toLocalDate = LocalDate.of(date.year, date.month, date.day);
    this.to = date;
    if (this.toLocalDate.isBefore(this.fromLocalDate)) {
      this.fromLocalDate = LocalDate.of(date.year, date.month, 1);
      this.from = {year: date.year, month: date.month, day: 1};
    }
    this.refreshEvent();
  }

  uploadPayment(file: File): void {
    this.loading = true;
    const formData = new FormData();
    formData.append('file', file);
    this.fileUploadErrors = [];
    this.uploadService.uploadPayment(formData)
      .subscribe(value => {
          this.loading = false;
          this.refreshEvent();
          super.cleanData();
          this.fileUploadErrors = [];
          this.alert.success('Registros cargados exitósamente');
        }
        , (error: HttpErrorResponse) => {
          this.loading = false;
          const errorCode = error.error.error as string;
          if (['PARTIALLY_PROCESSED_FILE'].includes(errorCode)) {
            const errors = error.error.errors as ErrorEntry[];
            errors.forEach(value => this.fileUploadErrors.push(value));
          } else {
            this.processError(error);
          }
      });
  }

  validateCreditNotes(key?: string): void {
    if (key) {
      this.loading = true;
      this.documentService.validateCreditNotes(key).subscribe(value => {
        this.alertService.success('Datos procesados correctamente');
        this.loading = false;
        this.refreshEvent(true);
      }, error => this.processError(error));
    }
  }

  onFileSelected(event: any): void {
    for (const file of event.target.files) {
      if (file) {
        this.uploadPayment(file);
      }
    }
    event.target.value = '';
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.codeService.getCodes(CodeType.CONDICION_VENTA).subscribe( value => {
      this.sellConditions = value;
    });
  }

  loadReferences(document: Document): void {
    if (document.referencedBy || document.possiblePayments) {
      document.referencedBy = undefined;
      document.possiblePayments = undefined;
    } else {
      const filter = new DocumentFilter();
      filter.referenceId = document.id;
      this.documentService.searchByFilter(filter, 0, 100).subscribe(value => document.referencedBy = value.content);
      if ( document.receiverId && document.pending && document.pending > 0) {
        this.documentService.getNotAppliedByIssuerId(document.receiverId).subscribe(value => document.possiblePayments = value);
      }
    }
  }

  payDocument(payment: Document, documentToPay: Document): void {
    this.loading = true;
    this.documentService.payDocument(payment.id, documentToPay.id).subscribe(value => {
      this.alertService.success('Datos procesados correctamente');
      this.loading = false;
      this.refreshEvent(true);
    }, error => this.processError(error)
    );
  }

  selectDocuments(referenceDocument: Document, document: Document): void {
    this.selectedReferenceDocument = referenceDocument;
    this.selectedDocument = document;
  }

  unlinkDocument(referenceDocument?: Document, document?: Document): void {
    if (referenceDocument && document) {
      this.loading = true;
      this.documentService.unlinkPayment(referenceDocument.id, document.id).subscribe(value => {
          this.alertService.success('Datos procesados correctamente');
          this.loading = false;
          this.refreshEvent(true);
        }, error => this.processError(error)
      );
    }
  }

  exportPendingAccountsReceivable(): void {
    this.loading = true;
    const documentFilter = new DocumentFilter();
    documentFilter.from = this.fromLocalDate;
    documentFilter.to = this.toLocalDate;
    if (this.text) {
      documentFilter.text = this.text;
    }
    this.documentService.exportPendingAccountsReceivable(documentFilter)
      .subscribe(
        response => {
          this.loading = false;
          const tenantFromStorage = ServiceUtils.getItem('tenant');
          const a = document.createElement('a');
          const objectUrl = URL.createObjectURL(response);
          a.href = objectUrl;
          if (tenantFromStorage) {
            const tenant = JSON.parse(tenantFromStorage) as Tenant;
            a.download = `Cuentas_por_Cobrar_Pendientes_${tenant.name?.replace(/\./g, '_')}`;
          }
          a.click();
          URL.revokeObjectURL(objectUrl);
        }, error => this.processError(error)
      );
  }

  exportAccountsReceivable(): void {
    this.loading = true;
    const documentFilter = new DocumentFilter();
    documentFilter.from = this.fromLocalDate;
    documentFilter.to = this.toLocalDate;
    if (this.text) {
      documentFilter.text = this.text;
    }
    this.documentService.exportAccountsReceivable(documentFilter)
      .subscribe(
        response => {
          this.loading = false;
          const tenantFromStorage = ServiceUtils.getItem('tenant');
          const a = document.createElement('a');
          const objectUrl = URL.createObjectURL(response);
          a.href = objectUrl;
          if (tenantFromStorage) {
            const tenant = JSON.parse(tenantFromStorage) as Tenant;
            a.download = `Cuentas_por_Cobrar_${tenant.name?.replace(/\./g, '_')}`;
          }
          a.click();
          URL.revokeObjectURL(objectUrl);
        }, error => this.processError(error)
      );
  }

  validatePayments(key: number): void {
    this.loading = true;
    this.documentService.validatePayments(key).subscribe(value => {
      this.alertService.success('Datos validados correctamente');
      this.loading = false;
      this.refreshEvent(true);
    }, error => this.processError(error));
  }

  getSellConditionDescription(sellCondition: string | undefined): string | undefined {
    if (sellCondition === undefined) {
      return '';
    } else {
      return this.sellConditions.filter(value => value.code === sellCondition)[0]?.description;
    }
  }
}

class ErrorEntry {
  constructor(
    public message: string,
    public line: string
  ) {
  }
}


