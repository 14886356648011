import {Component, OnDestroy, OnInit} from '@angular/core';
import {CoreComponent} from '../../../core/component/core.component';
import {AlertService} from '../../../core/service/alert.service';
import {Router} from '@angular/router';
import {SecurityService} from '../../../core/service/security.service';
import {MessageService} from '../../../core/service/message.service';
import {ReportService} from '../../../service/report.service';
import {Tenant} from '../../../core/model/tenant';
import {LoggerService} from '../../../service/logger.service';
import {BudgetFormService} from '../../../service/budget-form.service';
import {Provider} from '../../../model/provider';
import {LocalDate} from '@js-joda/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import ServiceUtils from '../../../core/utils/service.utils';

@Component({
  selector: 'app-invoice-details-provider-report',
  templateUrl: './invoice-details-provider-report.component.html'
})
export class InvoiceDetailsProviderReportComponent extends CoreComponent implements OnInit, OnDestroy {

  providers: Provider[] = [];
  providerId = '';
  from: NgbDateStruct;
  to: NgbDateStruct;
  fromLocalDate: LocalDate;
  toLocalDate: LocalDate;

  constructor(private alertService: AlertService,
              protected router: Router,
              protected securityService: SecurityService,
              protected reportService: ReportService,
              protected messageService: MessageService,
              protected budgetFormService: BudgetFormService,
              protected loggerService: LoggerService) {
    super(alertService, router, securityService, loggerService);
    this.fromLocalDate = LocalDate.now().withDayOfMonth(1);
    this.toLocalDate = this.fromLocalDate.withDayOfMonth(this.fromLocalDate.lengthOfMonth());
    this.from = {
      year: this.fromLocalDate.year(),
      month: this.fromLocalDate.monthValue(),
      day: this.fromLocalDate.dayOfMonth()
    };
    this.to = {year: this.toLocalDate.year(), month: this.toLocalDate.monthValue(), day: this.toLocalDate.dayOfMonth()};

  }

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>();
  }

  ngOnInit(): void {
    this.loadProviders();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  providerChanged(providerId: string): void {
    this.providerId = providerId;
  }
  loadProviders(): void {
    this.loading = true;
    this.budgetFormService.getProviders(undefined, undefined, this.fromLocalDate, this.toLocalDate)
      .subscribe(value => {
        this.providers = value;
        this.loading = false;
      }, error => this.processError(error));
  }
  generateEvent(): void {
    this.loading = true;
    this.reportService.exportInvoiceDetailsByProviderToExcel(this.fromLocalDate, this.toLocalDate, this.providerId)
      .subscribe(
        response => {
          this.loading = false;
          const tenantFromStorage = ServiceUtils.getItem('tenant');
          const a = document.createElement('a');
          const objectUrl = URL.createObjectURL(response);
          a.href = objectUrl;
          if (tenantFromStorage) {
            const tenant = JSON.parse(tenantFromStorage) as Tenant;
            a.download = `Reporte_detalle_facturas_proveedor_${tenant.name?.replace(/\./g, '_')}`;
          }
          a.click();
          URL.revokeObjectURL(objectUrl);
        }, error => this.processError(error)
      );
  }

  fromDateChanged(date: NgbDateStruct): void {
    this.fromLocalDate = LocalDate.of(date.year, date.month, date.day);
    this.from = date;
    if (this.fromLocalDate.isAfter(this.toLocalDate)) {
      this.toLocalDate = LocalDate.of(date.year, date.month, this.fromLocalDate.lengthOfMonth());
      this.to = {year: date.year, month: date.month, day: this.fromLocalDate.lengthOfMonth()};
    }
    this.loadProviders();
  }

  toDateChanged(date: NgbDateStruct): void {
    this.toLocalDate = LocalDate.of(date.year, date.month, date.day);
    this.to = date;
    if (this.toLocalDate.isBefore(this.fromLocalDate)) {
      this.fromLocalDate = LocalDate.of(date.year, date.month, 1);
      this.from = {year: date.year, month: date.month, day: 1};
    }
    this.loadProviders();
  }
}
