import {EntityModel} from '../core/model/entity.model';
import {LocalDate} from '@js-joda/core';
import {DocumentLine} from './document-line';

export class BudgetForm extends EntityModel {
  constructor(public status: BudgetFormStatus,
              public date: LocalDate,
              public id?: number,
              public sequence?: number,
              public lines: BudgetFormLine[] = []) {
    super(id);
  }
}

export enum BudgetFormStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  ALL = 'ALL'
}

export class BudgetFormLine extends EntityModel {
  constructor(public id?: number,
              public paymentCode?: string,
              public paymentDate?: LocalDate,
              public recordNumber?: string,
              public padCode?: string,
              public selected = false,
              public documentLine?: DocumentLine) {
    super(id);
  }
}
