import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../core/component/base.component';
import {PrivilegeName, User} from '../../core/model/user';
import {TenantUserService} from '../../service/tenant-user.service';
import {AlertService} from '../../core/service/alert.service';
import {Router} from '@angular/router';
import {MessageService} from '../../core/service/message.service';
import {SecurityService} from '../../core/service/security.service';
import {LoggerService} from '../../service/logger.service';
import {TenantChangedMessage} from '../../core/message/tenant.message';
import ServiceUtils from '../../core/utils/service.utils';
import {Configuration} from '../../model/client';
import {Tenant} from '../../core/model/tenant';


@Component({
  selector: 'app-tenant-user',
  templateUrl: './tenant-user.component.html'
})
export class TenantUserComponent extends BaseComponent<User> implements OnInit, OnDestroy {

  privilegesSelected: PrivilegeName[] = [];
  publicInstitution = false;

  constructor(private userService: TenantUserService,
              private alertService: AlertService,
              protected router: Router,
              protected messageService: MessageService,
              protected securityService: SecurityService,
              protected loggerService: LoggerService) {
    super(userService, 'users', alertService, router, securityService, messageService, loggerService);
  }

  getModelInstance(): User {
    return new User();
  }

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>().set('name', '').set('lastName', '').set('email', '').set('password', '');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscriptions.push(this.messageService.of(TenantChangedMessage).subscribe(
      () => {
        this.refreshEvent();
      }
    ));
    const configurationFromStorage = ServiceUtils.getItem('configuration');
    if (configurationFromStorage) {
      const configuration = JSON.parse(configurationFromStorage) as Configuration;
      if (configuration.publicInstitution !== undefined) {
        this.publicInstitution = configuration.publicInstitution;
      } else {
        this.publicInstitution = false;
      }
    }
  }

  canRefresh(): boolean {
    return !!ServiceUtils.getItem('tenant');
  }

  onModelUpdate(): void {
    const tenantFromStorage = ServiceUtils.getItem('tenant');

    if (this.model.currentTenantPrivileges !== undefined && tenantFromStorage) {
      const tenant = JSON.parse(tenantFromStorage) as Tenant;
      this.privilegesSelected = [];
      this.model.currentTenantPrivileges.forEach(privilege => {
        if (privilege.privilegeName) {
          this.privilegesSelected.push(privilege.privilegeName);
        }
      });
    }
  }

  permissionChange($event: Event): void {

    // @ts-ignore
    const selected = $event.currentTarget.checked;
    // @ts-ignore
    const value = $event.currentTarget.value;

    if (selected) {
      this.privilegesSelected.push(value);
      console.log('selected');
    } else {
      console.log('removed');
      this.privilegesSelected.splice(this.privilegesSelected.indexOf(value), 1);
    }
    console.log(this.privilegesSelected);
  }

  hasPrivilege(value: string): boolean {
    // @ts-ignore
    const privilege = PrivilegeName[value];
    return this.privilegesSelected.includes(privilege);
  }

  saveEvent(): void {
    this.model.privilegeNames = this.privilegesSelected;
    super.saveEvent();
  }
}
