<div class="modal fade" id="auditModal" tabindex="-1" aria-labelledby="auditModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="list-group">
          <div class="list-group-item d-flex justify-content-between align-items-start" aria-current="true">
            <div class="ms-2 me-auto">
              <div class="fw-bold">{{createdByActor.name}} <small class="text-muted">{{createdByActor.email}}</small></div>
              {{created.toString() | zonedDateTime:'dd/MM/yyyy hh:mm:ss a'}}
            </div>
            <span class="badge bg-primary rounded-pill">Creado</span>
          </div>
          <div class="list-group-item d-flex justify-content-between align-items-start" aria-current="true">
            <div class="ms-2 me-auto">
              <div class="fw-bold">{{modifiedByActor.name}} <small class="text-muted">{{modifiedByActor.email}}</small></div>
              {{modified.toString() | zonedDateTime:'dd/MM/yyyy hh:mm:ss a'}}
            </div>
            <span class="badge bg-info rounded-pill">Modificado</span>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
      </div>
    </div>
  </div>
</div>
