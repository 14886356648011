<div>

  <div class="loader" *ngIf="loading"></div>

  <div class="animate__animated animate__fadeIn">
    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">

      <div class="container-fluid">
        <form class="d-flex">
          <div class="input-group me-2">
            <input class="form-control" placeholder="aaaa-mm-dd"
                   name="from" [(ngModel)]="from" ngbDatepicker #d1="ngbDatepicker" id="from" (ngModelChange)="fromDateChanged($event)" title="Desde">
            <button class="btn btn-outline-secondary" (click)="d1.toggle()" type="button">
              <span class=" fa fa-calendar"></span>
            </button>
          </div>

          <div class="input-group me-2">
            <input class="form-control" placeholder="aaaa-mm-dd"
                   name="to" [(ngModel)]="to" ngbDatepicker #d2="ngbDatepicker" id="to" (ngModelChange)="toDateChanged($event)" title="Hasta">
            <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
              <span class=" fa fa-calendar"></span>
            </button>
          </div>

          <div class="input-group me-2">
            <input class="form-control me-2 " id="text" name="text" [(ngModel)]="text" (change)="searchTermChanged(text)" title="Buscar..." placeholder="Buscar...">
          </div>

          <div class="dropdown me-4">
            <button type="button" class="btn btn-secondary dropdown-toggle" id="dropdownMenuOffset" data-bs-toggle="dropdown" aria-expanded="false" title="Filtros">
              <span class="fa fa-filter"></span>
            </button>
            <div class="dropdown-menu dropdown-large">
              <form class="px-4 py-3">

                <div class="mb-3">
                  <label for="status" class="form-label">Estado</label>
                  <select class="form-select me-2" id="status" name="status" [(ngModel)]="classified" (ngModelChange)="statusChanged(classified)" title="Estado">
                    <option value="false" selected>Pendiente</option>
                    <option value="true">Clasificados</option>
                  </select>
                </div>

                <div class="mb-3">
                  <label for="sellCondition" class="form-label">Condición de venta</label>
                  <select class="form-select me-2" id="sellCondition" name="sellCondition" [(ngModel)]="sellCondition" (ngModelChange)="sellConditionChanged(type)" title="Condición de venta">
                    <option value="" selected>Todos</option>
                    <option value="01" >Contado</option>
                    <option value="02" >Crédito</option>
                    <option value="03" >Consignación</option>
                    <option value="04" >Apartado</option>
                    <option value="05" >Arrendamiento con opción de compra</option>
                    <option value="06" >Arrendamiento en función financiera</option>
                    <option value="07" >Cobro a favor de un tercero</option>
                    <option value="08" >Servicios prestados al Estado a crédito</option>
                    <option value="09" >Pago del servicios prestado al Estado</option>
                    <option value="99" >otros (se debe indicar la condición de la venta)</option>
                  </select>
                </div>

                <div class="mb-3">
                  <label for="sellCondition" class="form-label">Método de pago</label>
                  <select class="form-select me-2" id="paymentMethod" name="sellCondition" [(ngModel)]="paymentMethod" (ngModelChange)="paymentMethodChanged(type)" title="Método de pago">
                    <option value="" selected>Todos</option>
                    <option value="01" >Efectivo</option>
                    <option value="02" >Tarjeta</option>
                    <option value="03" >Cheque</option>
                    <option value="04" >Transferencia – depósito bancario</option>
                    <option value="05" >Recaudado por terceros</option>
                    <option value="99" >Otros (se debe indicar el medio de pago)</option>
                  </select>
                </div>

                <div class="mb-3">
                  <label for="type" class="form-label">Compras o Ventas</label>
                  <select class="form-select me-2" id="type" name="type" [(ngModel)]="type" (ngModelChange)="typeChanged(type)" title="Tipo">
                    <option value="PURCHASE" selected>Compras</option>
                    <option value="SELL">Ventas</option>
                  </select>
                </div>

                <div class="mb-3">
                  <label for="percentage" class="form-label">Porcentaje</label>
                  <input class="form-control me-2" id="percentage" name="percentage"  [(ngModel)]="percentage" (change)="percentageChanged(percentage)" title="Porcentaje Impuesto..." placeholder="Porcentaje Impuesto">
                </div>

                <div class="mb-3">
                  <label for="account" class="form-label">Cuenta</label>
                  <input class="form-control me-2" id="account" name="account"  [(ngModel)]="account" (change)="accountChanged(account)" title="Cuenta ..." placeholder="Cuenta">
                </div>

                <div class="mb-3">
                  <div class="form-check">
                    <input class="form-check-input" name="withOutTaxes" type="checkbox" value="" id="withOutTaxes" (change)="withOutTaxesChanged(withOutTaxes)" [(ngModel)]="withOutTaxes">
                    <label class="form-check-label" for="withOutTaxes">
                      Sin Impuestos (Total = Subtotal)
                    </label>
                  </div>
                </div>

                <div class="mb-3">
                  <div class="form-check">
                    <input class="form-check-input" name="withOutTaxesAccount" type="checkbox" value="" id="withOutTaxesAccount" (change)="withOutTaxesAccountChanged(withOutTaxesAccount)" [(ngModel)]="withOutTaxesAccount">
                    <label class="form-check-label" for="withOutTaxesAccount">
                      Sin Cuenta de Impuestos
                    </label>
                  </div>
                </div>

                <div class="mb-3">
                  <div class="form-check">
                    <input class="form-check-input" name="exonerated" type="checkbox" value="" id="exonerated" (change)="exoneratedChanged(exonerated)" [(ngModel)]="exonerated">
                    <label class="form-check-label" for="exonerated">
                      Exonerado
                    </label>
                  </div>
                </div>

              </form>
            </div>
          </div>

        </form>
        <div class="pull-right">

          <button class="btn  pull-right btn-info me-1" *ngIf="classified"
                   data-bs-toggle="modal" data-bs-target="#analyzeModal" title="Analizar Documentos Clasificados">
            <span class="fa fa-user-secret fa-lg"></span>
          </button>

          <button class="btn btn-secondary me-1" (click)="export('EXCEL')" title="Descargar EXCEL">
            <span class="fa fa-download fa-lg "></span>
          </button>

          <button class="btn btn-secondary me-1" routerLink="/documentos/ver" >
            Cerrar
          </button>
          <button class="btn btn-primary" (click)="classifyEvent()" >
            Guardar
          </button>
        </div>
        </div>

    </nav>
    <div *ngIf="!loading">
      <div *ngIf="countSelections > 1" class="bg-light p-3 row mb-3 animate__animated animate__fadeIn">
          <div class="col-2">
            <select class="form-select"  [(ngModel)]="accountId">
              <option value=""></option>
              <option *ngFor="let account of activeAccounts" [value]="account.id" >{{account.description}} ({{account.code}})</option>
            </select>
          </div>
          <div class="col-2">
            <select class="form-select"  [(ngModel)]="taxRegistryAccountId">
              <option value=""></option>
              <option value="-1">Ninguna</option>
              <option *ngFor="let account of activeTaxRegistryAccounts" [value]="account.id" >{{account.description}} ({{account.code}})</option>
            </select>
          </div>
        <div class="col-2">
          <select class="form-select"  [(ngModel)]="counterPartAccountId">
            <option value=""></option>
            <option *ngFor="let account of activeCounterPartAccounts" [value]="account.id" >{{account.description}} ({{account.code}}) </option>
          </select>
        </div>
        <div class="col-2" *ngIf="!publicInstitution">
          <input class="form-control"  [(ngModel)]="bankReceipt"/>
        </div>
          <div class="text-end col-4">
            <button class="btn btn-primary" (click)="applySelectedEvent()" >
              Aplicar
            </button>
          </div>
      </div>
      <div>
        <div *ngIf="page.metadata.count > 0" class="text-center alert alert-info">
          Se encontraron {{page.content?.length}} registros
        </div>
        <cdk-virtual-scroll-viewport itemSize="50" class="document-list-viewport">
        <table *ngIf="page.metadata.count > 0" class="table table-hover">
          <thead>
          <tr>
            <th scope="col">
              <input type="checkbox" [(ngModel)]="allSelected" (change)="changeSelection(allSelected)">
            </th>
            <th scope="col">
              Linea
            </th>
            <th scope="col">
              Detalles
            </th>
            <th>
              Condición Venta
            </th>
            <th>
              Medio Pago
            </th>
            <th scope="col" class="text-end">
              Exoneración
            </th>
            <th scope="col" class="text-end">
              Sub Total
            </th>
            <th scope="col" class="text-end">
              Impuestos
            </th>
            <th scope="col" class="text-end">
              Total
            </th>
            <th scope="col">
              Cuenta
            </th>
            <th scope="col">
              Registro Impuestos
            </th>
            <th scope="col">
              Contrapartida
            </th>
            <th>
              Comprobante
            </th>
            <th>

            </th>
          </tr>
          </thead>
          <tbody>
          <!--ng-container *cdkVirtualFor="let line of page.content; let i = index"-->
            <tr *cdkVirtualFor="let line of page.content; let i = index" [class]="line.invalidClassification && line.classified? 'table-warning':''" [title]="line.invalidClassification? 'Diferencia encontrada: ' + line.invalidDifference : ''">
              <td>
                <input *ngIf="!line.processed" type="checkbox" [(ngModel)]="line.selected" (change)="changeSingleSelection(line.selected)"/>
              </td>
              <td>
                <span [title]="line.sequence" >{{line.lineNumber}}</span>
                <span class="badge bg-danger ms-2" *ngIf="line.processed" title="Procesado en Cierre">P</span>
              </td>
              <td>
                <span [title]="line.detail + '-' + line.quantity + line.units" class="mt-large-text">{{line.detail}} - {{line.quantity}} {{line.units}}</span>
                <small class="text-muted mt-content-col" title="Código Cabys" (click)="loadProductDetails(line)" data-bs-toggle="modal" data-bs-target="#productDetails">{{line.cabysCode}}</small>
              </td>
              <td>
                <span *ngIf="line.sellCondition == '01'" class="mt-large-text">Contado</span>
                <span *ngIf="line.sellCondition == '02'" class="mt-large-text">Crédito</span>
                <span *ngIf="line.sellCondition == '03'" class="mt-large-text">Consignación</span>
                <span *ngIf="line.sellCondition == '04'" class="mt-large-text">Apartado</span>
                <span *ngIf="line.sellCondition == '05'" class="mt-large-text">Arrendamiento con opción de compra</span>
                <span *ngIf="line.sellCondition == '06'" class="mt-large-text">Arrendamiento en función financiera</span>
                <span *ngIf="line.sellCondition == '07'" class="mt-large-text">Cobro a favor de un tercero</span>
                <span *ngIf="line.sellCondition == '08'" class="mt-large-text">Servicios prestados al Estado a crédito</span>
                <span *ngIf="line.sellCondition == '09'" class="mt-large-text">Pago del servicios prestado al Estado</span>
                <span *ngIf="line.sellCondition == '99'" class="mt-large-text">otros (se debe indicar la condición de la venta)</span>
              </td>
              <td>
                <span *ngIf="line.paymentMethod == '01'"  class="mt-large-text">Efectivo</span>
                <span *ngIf="line.paymentMethod == '02'"  class="mt-large-text">Tarjeta</span>
                <span *ngIf="line.paymentMethod == '03'"  class="mt-large-text">Cheque</span>
                <span *ngIf="line.paymentMethod == '04'"  class="mt-large-text">Transferencia – depósito bancario</span>
                <span *ngIf="line.paymentMethod == '05'"  class="mt-large-text">Recaudado por terceros</span>
                <span *ngIf="line.paymentMethod == '99'"  class="mt-large-text">Otros (se debe indicar el medio de pago)</span>
              </td>
              <td class="text-end">
                {{line.exonerationAmount | currency:line.currency:'code'}}
              </td>
              <td class="text-end">
                {{line.subTotal | currency:line.currency:'code'}}
              </td>
              <td class="text-end">
                <ng-container *ngFor="let tax of line.taxesDetails" >
                  <span title="{{tax.Tarifa}}%">{{tax.Monto | currency:line.currency:'code'}}</span>  <br>
                  <small class="text-muted" title="Código y porcentaje">({{tax.CodigoTarifa}}) {{tax.Tarifa}}%</small>
                </ng-container>
              </td>
              <td class="text-end">
                {{line.totalAmount | currency:line.currency:'code'}}
              </td>
              <td >
                <ng-container *ngIf="!line.state || line.processed">
                  <span class="mt-large-text mt-column-action" (click)="line.state = true">{{getAccountDescription(accounts, line.account)}}</span>
                </ng-container>
                <ng-container *ngIf="line.state && !line.processed">
                  <ng-select class="mt-large-ng-select custom"
                             name="activity-code-select-{{i}}" id="activity-code-select-{{i}}"
                             [(ngModel)]="line.account" [appendTo]="'body'">
                    <ng-option *ngFor="let account of activeAccounts" [value]="account.id">{{account.description}} ({{account.code}})</ng-option>
                  </ng-select>
                </ng-container>
              </td>
              <td>
                <!--ng-container *ngIf="!line.state || line.processed || line.subTotal == line.totalAmount"-->
                <ng-container *ngIf="!line.state || line.processed">
                  <span class="mt-large-text mt-column-action"  (click)="line.state = true">{{getAccountDescription(taxRegistryAccounts, line.taxRegistryAccount)}}</span>
                </ng-container>
                <!--ng-container *ngIf="line.state && !line.processed && line.subTotal < line.totalAmount"-->
                <ng-container *ngIf="line.state && !line.processed">
                  <ng-select class="mt-large-ng-select custom"
                             name="tax-registry-{{i}}" id="tax-registry-{{i}}"
                             [(ngModel)]="line.taxRegistryAccount" [appendTo]="'body'">
                    <ng-option *ngFor="let account of activeTaxRegistryAccounts" [value]="account.id">{{account.description}} ({{account.code}})</ng-option>
                  </ng-select>
                </ng-container>
              </td>
              <td>
                <ng-container *ngIf="!line.state || line.processed">
                  <span class="mt-large-text mt-column-action"  (click)="line.state = true">{{getAccountDescription(counterPartAccounts, line.counterPartAccount)}}</span>
                </ng-container>
                <ng-container *ngIf="line.state && !line.processed">
                  <ng-select class="mt-large-ng-select custom"
                             name="counterpart-{{i}}" id="counterpart-{{i}}"
                             [(ngModel)]="line.counterPartAccount" [appendTo]="'body'">
                    <ng-option *ngFor="let account of activeCounterPartAccounts" [value]="account.id">{{account.description}} ({{account.code}})</ng-option>
                  </ng-select>
                </ng-container>
              </td>
              <td>
                <ng-container *ngIf="(!line.state || line.processed) && !publicInstitution">
                  <span class="mt-large-text mt-column-action"  (click)="line.state = true">{{getBankReceiptDescription(line.bankReceipt)}}</span>
                </ng-container>
                <ng-container *ngIf="publicInstitution">
                  <span class="mt-large-text">{{getBankReceiptDescription(line.bankReceipt)}}</span>
                </ng-container>
                <ng-container *ngIf="line.state && !line.processed && !publicInstitution">
                  <input class="form-control" type="text" id="bank-recipt-{{i}}" title="Comprobante Bancario" [(ngModel)]="line.bankReceipt">
                </ng-container>
              </td>
              <td>
                <mt-audit-button [auditableEntity]="line"
                                 [(modifiedByActor)]="auditModal.modifiedByActor" [(createdByActor)]="auditModal.createdByActor"
                                 [(modifiedDate)]="auditModal.modified" [(createdDate)]="auditModal.created"></mt-audit-button>
              </td>
            </tr>
          <!--/ng-container-->
          <ng-container *ngIf="page.metadata.count < 10">
            <div class="mt-virtual-scroll-gap">

            </div>
          </ng-container>
          </tbody>
        </table>
          <div *ngIf="page.metadata.count == 0 " class="p-3">
            No hay registros
          </div>
        </cdk-virtual-scroll-viewport>

      </div>
    </div>
  </div>
</div>

<mt-audit-modal #auditModal ></mt-audit-modal>

<div class="modal fade" id="productDetails" tabindex="-1" role="dialog"
     aria-labelledby="loadFileLabel">
  <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Detalles código Cabys</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">

        <ul class="list-group" *ngIf="productSelected">
          <li class="list-group-item active">{{productSelected.descripcion}} {{productSelected.impuesto}}%</li>
        </ul>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-link" (click)="cleanProductDetails()" data-bs-dismiss="modal">Cerrar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="analyzeModal" tabindex="-1" role="dialog"
     aria-labelledby="loadFileLabel">
  <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Rangos</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <p>Si desea ignorar diferencias menores a o mayores a suministre un valor. Solo se puede suministrar un valor a la vez. En caso de querer diferencias diferentes a 0 dejar ambos campos en blanco.</p>

        <p>Una vez finalizado el proceso de validación, al dejar el puntero del mouse sobre las lineas anaranjadas se mostrará la diferencia encontrada.</p>

        <p>Se recomienda utilizar primero la herramienta de analizar periodos para poder encontrar la fecha del día con inconsistencias.</p>
        <div class="row mb-3">
          <div class="form-group col-md-4">
            <label class="form-label">Menor que</label>
            <input class="form-control" [(ngModel)]="lessThan" [disabled]="greaterThan != 0 && greaterThan != undefined" />
          </div>
        </div>

        <div class="row mb-3">
          <div class="form-group col-md-4">
            <label class="form-label">Mayor que</label>
            <input class="form-control" [(ngModel)]="greaterThan" [disabled]="lessThan != 0 && lessThan != undefined" />
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cerrar</button>
        <button type="button" class="btn btn-success" data-bs-dismiss="modal" (click)="analyzeAccountingEntries()">Validar</button>
      </div>
    </div>
  </div>
</div>

