import {Component, OnDestroy, OnInit} from '@angular/core';
import {Page} from '../../../model/page';
import {CoreComponent} from '../../../core/component/core.component';
import {AlertService} from '../../../core/service/alert.service';
import {Router} from '@angular/router';
import {SecurityService} from '../../../core/service/security.service';
import {MessageService} from '../../../core/service/message.service';
import {LocalDate} from '@js-joda/core';
import ServiceUtils from '../../../core/utils/service.utils';
import {ReportService} from '../../../service/report.service';
import {Tenant} from '../../../core/model/tenant';
import {LoggerService} from '../../../service/logger.service';
import {BudgetFormService} from '../../../service/budget-form.service';
import {BudgetForm, BudgetFormStatus} from '../../../model/budget-form';
import {BudgetFormFilter} from '../../../filter/budget-form.filter';

@Component({
  selector: 'app-budget-form-report',
  templateUrl: './budget-form-report.component.html'
})
export class BudgetFormReportComponent extends CoreComponent implements OnInit, OnDestroy {

  budgetForms: Page<BudgetForm> = new Page({count: 0, page: 0, pages: 0}, []);
  sequence = '';

  constructor(private alertService: AlertService,
              protected router: Router,
              protected securityService: SecurityService,
              protected reportService: ReportService,
              protected budgetFormService: BudgetFormService,
              protected messageService: MessageService,
              protected loggerService: LoggerService) {
    super(alertService, router, securityService, loggerService);

  }

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>();
  }

  ngOnInit(): void {
    this.getPageEvent(0);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  exportBudgetForm(budgetFrom: BudgetForm): void {
    this.loading = true;
    const revivedMonth = ServiceUtils.dateReviver('', budgetFrom.date) as LocalDate;
    if (budgetFrom.id) {
      this.reportService.exportBudgetFormToExcel(budgetFrom.id)
        .subscribe(
          response => {
            this.loading = false;
            // const typeContent = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8';
            // const blob: any = new Blob([response],
            //  {type: typeContent});
            // const url = window.URL.createObjectURL(blob);
            // window.open(url);
            const tenantFromStorage = ServiceUtils.getItem('tenant');
            const a = document.createElement('a');
            const objectUrl = URL.createObjectURL(response);
            a.href = objectUrl;
            if (tenantFromStorage) {
              const tenant = JSON.parse(tenantFromStorage) as Tenant;
              a.download = `Reporte_Planillas_${budgetFrom.sequence}_${tenant.name?.replace(/\./g, '_')}`;
            }
            a.click();
            URL.revokeObjectURL(objectUrl);
          }, error => this.processError(error)
        );
    }
  }


  getPageEvent(pageNumber: number): void {
    this.budgetFormService
      .searchByFilter(this.getFilter(), pageNumber, 12, ['date', 'sequence'], 'desc')
      .subscribe({
          next: (v) => this.budgetForms = v,
          error: (e) => this.processError(e)
        });
  }

  private getFilter(): BudgetFormFilter {
    const filter = new BudgetFormFilter();
    filter.sequence = this.sequence;
    return filter;
  }
}
