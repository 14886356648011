import {EntityModel} from '../model/entity.model';
import {BaseService} from '../service/base.service';
import { OnDestroy, OnInit, Directive } from '@angular/core';
import {Page} from '../../model/page';
import {AlertService} from '../service/alert.service';
import ServiceUtils from '../utils/service.utils';
import {Router} from '@angular/router';
import {CoreComponent} from './core.component';
import {SecurityService} from '../service/security.service';
import {Subscription} from 'rxjs';
import {TenantChangedMessage} from '../message/tenant.message';
import {MessageService} from '../service/message.service';
import {environment} from '../../../environments/environment';
import {LoggerService} from '../../service/logger.service';

@Directive()
export abstract class BaseComponent<T extends EntityModel> extends CoreComponent implements OnInit, OnDestroy {

  model: T = this.getModelInstance();

  page: Page<T> = new Page({count: 0, page: 0, pages: 0}, []);

  errorMessage = '';

  mode = 'LIST';

  hide = true;

  navigateToList = false;

  keepPageOnRefresh = false;

  cleanDateAfterSaveOrUpdate = true;

  subscriptions: Subscription[] = [];

  sortBy: string[]  = [];

  sortOrder = 'desc';

  pageSize = 10;

  env = environment;

  constructor(protected service: BaseService<T>,
              private name: string,
              protected alert: AlertService,
              protected router: Router,
              protected securityService: SecurityService,
              protected messageService: MessageService,
              protected loggerService: LoggerService) {
    super(alert, router, securityService, loggerService);
  }

  abstract getModelInstance(): T ;

  refreshEvent(keepPageOnRefresh = false): void {
    if (this.canRefresh()) {
      if (keepPageOnRefresh) {
        this.getPageEvent(this.page.metadata.page, true);
      } else {
        this.getPageEvent(0, true);
      }
    }
  }

  clearPage(): void {
    this.page = new Page({count: 0, page: 0, pages: 0}, []);
  }

  getPageEvent(pageNumber: number, refresh: boolean = false): void {
    this.cleanErrors();
    this.loading = true;
    this.service.getPage(pageNumber, this.pageSize, this.sortBy, this.sortOrder, refresh)
      .subscribe(
        page => this.setPageEvent(page),
        error => this.processError(error));
  }

  setPageEvent(page: Page<T>): void {
    this.page = page;
    this.loading = false;
  }

  saveEvent(reload = true): void {
    this.loading = true;
    this.cleanErrors();
    if (this.model.id) {
      this.service.update(this.model)
        .subscribe(
          completed => {
            if (reload) {
              this.refreshEvent(this.keepPageOnRefresh);
            }
            if (this.navigateToList) {
              this.mode = 'LIST';
            }
            if (this.cleanDateAfterSaveOrUpdate) {
              this.model = this.getModelInstance();
              this.cleanData();
            }
            this.loading = false;
            this.alert.success('Registro guardado exitósamente');
          },
          error => this.processError(error));
    } else {
      this.service.create(this.model)
        .subscribe(
          completed => {
            if (reload) {
              this.refreshEvent(this.keepPageOnRefresh);
            }
            if (this.navigateToList) {
              this.mode = 'LIST';
            }
            if (this.cleanDateAfterSaveOrUpdate) {
              this.model = this.getModelInstance();
              this.cleanData();
            } else {
              this.model.id = completed.id;
            }
            this.loading = false;
            this.alert.success('Registro guardado exitósamente');
            this.onSuccessfulCreate();
          },
          error => this.processError(error));
    }

  }

  editEvent(model: T): void {
    this.loading = true;
    this.mode = 'EDIT';
    this.cleanErrors();
    const selected = (JSON.parse(JSON.stringify(model), (key, value) => ServiceUtils.dateReviver(key, value))) as EntityModel;
    if (selected.id) {
      this.loadEntity(selected.id);
    }
  }

  loadEntity(id: number): void {
    this.loading = true;
    this.service.get(id)
      .subscribe(
        res => {
          this.model = res;
          this.onModelUpdate();
          this.loading = false;
        },
        error => this.processError(error));
  }

  cancelEvent(): void {
    this.mode = 'LIST';
    this.cleanErrors();
    this.model = this.getModelInstance();
    // this.onModelUpdate();
  }

  createEvent(): void {
    this.mode = 'EDIT';
    this.cleanErrors();
    this.model = this.getModelInstance();
    this.onModelUpdate();
  }

  removeEvent(value: EntityModel): void {
    this.loading = true;
    this.cleanErrors();
    if (value.id) {
      this.service.remove(value.id)
        .subscribe(
          complete => {
            this.refreshEvent();
            this.alert.success('Registro eliminado exitosamente');
          },
          error => this.processError(error));
    }
  }

  removeAllEvent(values: number []): void {
    this.loading = true;
    this.cleanErrors();
    if (values.length > 0) {
      this.service.removeAll(values)
        .subscribe(
          complete => {
            this.refreshEvent();
            this.alert.success('Registros eliminados exitosamente');
          },
          error => this.processError(error));
    }

  }

  selectEvent(model: T): void {
    if (model.id) {
      this.loadEntity(model.id);
    }
  }

  protected cleanErrors(): void {
    this.errorMessage = '';
    this.formErrors = this.clearFormErrors();
    this.alert.clear();
  }

  protected cleanData(): void {
  }

  ngOnInit(): void {
    this.getPageEvent(0);
    this.subscriptions.push(this.messageService.of(TenantChangedMessage).subscribe(
      message => {
        this.refreshEvent();
      }
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  onModelUpdate(): void {}

  onSuccessfulCreate(): void {}

  canRefresh(): boolean {
    return true;
  }
}
