import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {BaseComponent} from '../../../core/component/base.component';
import {JobPosition} from '../../service/resource/job-position';
import {JobPositionService} from '../../service/job-position.service';
import {AlertService} from '../../../core/service/alert.service';
import {MessageService} from '../../../core/service/message.service';
import {SecurityService} from '../../../core/service/security.service';
import {LoggerService} from '../../../service/logger.service';

@Component({
  selector: 'app-job-position',
  templateUrl: './job-position.component.html'
})
export class JobPositionComponent extends BaseComponent<JobPosition> implements OnInit, OnDestroy {

  constructor(private jobPositionService: JobPositionService,
              private alertService: AlertService,
              protected messageService: MessageService,
              protected loggerService: LoggerService,
              protected router: Router,
              protected securityService: SecurityService) {
    super(jobPositionService, 'job-position', alertService, router, securityService, messageService, loggerService);
  }

  getModelInstance(): JobPosition {
    return new JobPosition();
  }

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>(); // TODO add form values like .set('client.name', '').set('client.phone', '').set('client.email', '');
  }

}
