import {Component, OnDestroy, OnInit} from '@angular/core';
import {AlertService} from '../../core/service/alert.service';
import {SecurityService} from '../../core/service/security.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from '../../core/service/message.service';
import {CoreComponent} from '../../core/component/core.component';
import {DashboardService} from '../../service/dashboard.service';
import {DashboardSummary} from '../../model/dashboard-summary';
import {TenantChangedMessage} from '../../core/message/tenant.message';
import {LocalDate} from '@js-joda/core';
import {LoggerService} from '../../service/logger.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-client',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent extends CoreComponent implements OnInit, OnDestroy {

  summary: DashboardSummary = new DashboardSummary();
  summaryLoaded = false;
  month = 'February';
  year = 2022;

  env = environment;

  constructor(private alertService: AlertService,
              protected router: Router,
              protected securityService: SecurityService,
              protected dashboardService: DashboardService,
              protected messageService: MessageService,
              protected loggerService: LoggerService) {
    super(alertService, router, securityService, loggerService);

  }

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>();
  }

  ngOnInit(): void {
    const month = LocalDate.now().month().name().toLowerCase();
    const monthCapitalized = month.charAt(0).toUpperCase() + month.slice(1);

    this.month = monthCapitalized;
    this.year = LocalDate.now().year();
    console.log(this.month);
    this.loadData();
    this.subscriptions.push(this.messageService.of(TenantChangedMessage).subscribe(
      message => {
        this.loadData();
      }
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  monthChanged(month: string): void {
    this.month = month;
    this.loadData();
  }

  yearChanged(year: number): void {
    this.year = +year;
    this.loadData();
  }

  canRefresh(): boolean {
    return true;
  }

  private loadData(): void {
    this.loading = true;
    this.summaryLoaded = false;
    this.dashboardService.getSummary(this.month, this.year.toString(), true).subscribe(
      value => {
        this.summary = value;
        this.summaryLoaded = true;
        this.loading = false;
      },
      error =>  this.loading = false
      );
  }
}
