import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LocalDate} from "@js-joda/core";

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private SERVER_URL = '/api/t/{tenant}/reports';
  constructor(private httpClient: HttpClient) { }


  exportBalanceToExcel(monthParam: string, yearParam: string): Observable<any> {
    return this.httpClient.post(`${this.SERVER_URL}/balance`,
      {month : monthParam, year: yearParam}, {responseType: 'blob'});
  }

  exportResultsToExcel(monthParam: string, yearParam: string, accumulativeParam: boolean,
                       includeAllParam: boolean = false): Observable<any> {
    return this.httpClient.post(`${this.SERVER_URL}/results`,
      {month : monthParam, year: yearParam, accumulative: accumulativeParam, includeAll: includeAllParam}, {responseType: 'blob'});
  }

  exportExpensesToExcel(monthParam: string, yearParam: string): Observable<any> {
    return this.httpClient.post(`${this.SERVER_URL}/expenses`, {month : monthParam, year: yearParam}, {responseType: 'blob'});
  }

  exportCashPaymentsToExcel(monthParam: string, yearParam: string): Observable<any> {
    return this.httpClient.post(`${this.SERVER_URL}/payed`, {month : monthParam, year: yearParam}, {responseType: 'blob'});
  }

  exportBudgetFormToExcel(idParam: number): Observable<any> {
    return this.httpClient.post(`${this.SERVER_URL}/budget-form`, {id : idParam}, {responseType: 'blob'});
  }

  exportBudgetFormRetentionsToExcel(monthParam: string, yearParam: string): Observable<any> {
    return this.httpClient.post(`${this.SERVER_URL}/budget-form/retentions`, {month : monthParam, year: yearParam}, {responseType: 'blob'});
  }

  exportBudgetFormRetentionsProviderToWord(monthParam: string, yearParam: string, providerIdentificationParam: string): Observable<any> {
    return this.httpClient.post(`${this.SERVER_URL}/budget-form/retentions/provider`,
      {month : monthParam, year: yearParam, providerIdentification: providerIdentificationParam}, {responseType: 'blob'});
  }

  exportInvoiceDetailsByProviderToExcel(fromParam: LocalDate, toParam: LocalDate, providerIdentificationParam: string): Observable<any> {
    return this.httpClient.post(`${this.SERVER_URL}/budget-form/invoice-details-by-provider`,
      {from : fromParam, to: toParam, providerIdentification: providerIdentificationParam}, {responseType: 'blob'});
  }

  exportBudgetFormQuarterlyToExcel(monthParam: string, yearParam: string, foundingSourceParam: string): Observable<any> {
    return this.httpClient.post(`${this.SERVER_URL}/budget-form/quarterly`,
      {month : monthParam, year: yearParam, foundingSource: foundingSourceParam}, {responseType: 'blob'});
  }
  exportBudgetFormConsolidatedToExcel(monthParam: string, yearParam: string): Observable<any> {
    return this.httpClient.post(`${this.SERVER_URL}/budget-form/consolidated`,
      {month : monthParam, year: yearParam}, {responseType: 'blob'});
  }
  exportBudgetFormLastLevelConsolidatedToExcel(monthParam: string, yearParam: string): Observable<any> {
    return this.httpClient.post(`${this.SERVER_URL}/budget-form/consolidated-last-level`,
      {month : monthParam, year: yearParam}, {responseType: 'blob'});
  }

}
