import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../core/component/base.component';
import {AlertService} from '../../../core/service/alert.service';
import {MessageService} from '../../../core/service/message.service';
import {SecurityService} from '../../../core/service/security.service';
import {Router} from '@angular/router';
import {DocumentService} from '../../../service/document.service';
import {AcceptDocument, Document, DocumentStatus, DocumentType} from '../../../model/document';
import {catchError, map} from 'rxjs/operators';
import {HttpErrorResponse, HttpEventType} from '@angular/common/http';
import {of} from 'rxjs';
import {UploadService} from '../../../core/service/upload.service';
import {LocalDate} from '@js-joda/core';
import {CodeService} from '../../../service/code.service';
import {Code, CodeType} from '../../../model/code';
import {DocumentFilter} from '../../../filter/document.filter';
import {OperationType} from '../../../model/document-line';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {ClientService} from '../../../service/client.service';
import {Tenant} from '../../../core/model/tenant';
import {LoggerService} from '../../../service/logger.service';
import {Configuration} from '../../../model/client';
import {Impuesto} from '../../../model/v4_3/impuesto';
import {Descuento} from '../../../model/v4_3/descuento';
import ServiceUtils from '../../../core/utils/service.utils';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html'
})
export class DocumentComponent extends BaseComponent<Document> implements OnInit, OnDestroy {

  @ViewChild('fileUpload', {static: false})
    // @ts-ignore
  fileUpload: ElementRef;
  files: FileData[] = [];
  // filesUploaded: FileData[] = [];
  // invoice: ComprobanteElectronico = new ComprobanteElectronico();
  activityType = '';
  sellCondition = '';
  paymentMethod = '';
  text = '';
  responseFiles = new Map<string, FileData>();
  allSelected = false;
  countSelections = 0;
  status = DocumentStatus.ALL;
  type = OperationType.ALL;
  budgetNotSelected = false;
  documentType = DocumentType.ALL;
  fileUploadErrors: ErrorEntry[] = [];
  // filesUploaded: FileData[] = [];
  currentFile?: FileData;
  from: NgbDateStruct;
  to: NgbDateStruct;
  fromLocalDate: LocalDate;
  toLocalDate: LocalDate;
  filesUploadedCount = 0;
  filesPendingCount = 0;
  activityCodes: Code[] = [];
  factor = 0;
  documentsToAccept: AcceptDocument[] = [];
  taxCondition = '';
  messageCode = '';
  activityCode: string|undefined = '';
  // selectedDocuments: Document[] = [];
  generateValueAddedTax = false;
  publicInstitution = false;
  tenant = ServiceUtils.getTenant();
  selectedIds: number[] = [];
  protected readonly environment = environment;

  constructor(private documentService: DocumentService,
              private alertService: AlertService,
              protected messageService: MessageService,
              protected router: Router,
              protected securityService: SecurityService,
              private uploadService: UploadService,
              private codeService: CodeService,
              protected clientService: ClientService, protected loggerService: LoggerService) {
    super(documentService, 'document', alertService, router, securityService, messageService, loggerService);
    this.sortBy.push('date');
    this.pageSize = 20;
    this.fromLocalDate = LocalDate.now().withDayOfMonth(1);
    this.toLocalDate = this.fromLocalDate.withDayOfMonth(this.fromLocalDate.lengthOfMonth());
    this.from = {
      year: this.fromLocalDate.year(),
      month: this.fromLocalDate.monthValue(),
      day: this.fromLocalDate.dayOfMonth()
    };
    this.to = {year: this.toLocalDate.year(), month: this.toLocalDate.monthValue(), day: this.toLocalDate.dayOfMonth()};
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.clientService.getClientCodes().subscribe( value => {
      this.activityCodes = value;
    });
    const configurationFromStorage = ServiceUtils.getItem('configuration');
    if (configurationFromStorage) {
      const configuration = JSON.parse(configurationFromStorage) as Configuration;
      if (configuration.publicInstitution) {
        this.publicInstitution = configuration.publicInstitution;
      }
      if (configuration.generateValueAddedTax) {
        this.generateValueAddedTax = configuration.generateValueAddedTax;
      }
    }
  }

  getModelInstance(): Document {
    return new Document(0);
  }

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>(); // TODO add form values like .set('client.name', '').set('client.phone', '').set('client.email', '');
  }

  private uploadFile(files: FileData[]): void {
    const formData = new FormData();
    for (const fileData of files) {
      formData.append('file', fileData.data);
      if (fileData.data.size > 209715200) {
        fileData.inProgress = false;
        fileData.progress = 100;
        fileData.status = 'bg-danger';
        this.currentFile = fileData;
        this.fileUploadErrors.push(new ErrorEntry('Se excedió el tamaño máximo permitido para el archivo: 200MB', fileData.data.name));
        return;
      }
    }
    const file = files[0];
    file.inProgress = true;
    this.currentFile = file;
    this.uploadService.uploadDocument(formData).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded * 100 / event.total);
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        file.inProgress = false;
        file.progress = 100;
        file.status = 'bg-danger';
        const errorCode = error.error.error as string;
        if (['REFERENCE_DOCUMENT_ALREADY_EXIST', 'REFERENCE_DOCUMENT_NOT_FOUND', 'NOT_SUPPORT_FILE',
          'DOCUMENT_ALREADY_EXIST',
          'PENDING_CONFIGURATION', 'INVALID_DOCUMENT', 'NOT_SUPPORTED_XML_VERSION', 'INCOMPLETE_RESPONSE',
          'EMPTY_FILE', 'INVALID_XML'].includes(errorCode)) {
          file.message = error.error.message;
          this.fileUploadErrors.push(new ErrorEntry(file.message, file.data.name));
        } else if (['PARTIALLY_PROCESSED_FILE'].includes(errorCode)) {
          file.message = error.error.message;
          const errors = error.error.errors as ErrorEntry[];
          errors.forEach(value => this.fileUploadErrors.push(value));
        } else if (['MAX_UPLOAD_SIZE_ERROR'].includes(errorCode)) {
          file.message = 'Se excedió el tamaño máximo permitido para el archivo: 20MB';
          this.fileUploadErrors.push(new ErrorEntry(file.message, file.data.name));
        } else {
          if (error.status === 0) {
            file.message = 'Error de comunicación con el servidor, verifique su conexión a internet e intente nuevamente.';
          } else if (error.status === 500) {
            file.message = `Error no esperado al cargar el archivo código : ${error.error.uuid}`;
          } else {
            file.message = 'Error no esperado al cargar el archivo';
          }
          this.fileUploadErrors.push(new ErrorEntry(file.message, file.data.name));
        }
        this.filesUploadedCount += files.length;
        this.uploadNext();
        return of(`${file.data.name} upload failed.`);
      })).subscribe((event: any) => {
      if (typeof (event) === 'object') {
        console.log('File uploaded');
        file.message = 'Archivo cargado exitosamente.';

        this.filesUploadedCount += files.length;
        this.uploadNext();
      }
    });
  }

  private uploadNext(): void {
    this.currentFile = undefined;
    let maxChunkToUpload = 100;
    const partialUpload: FileData[] = [];
    while ( this.files.length > 0 && maxChunkToUpload > 0) {
      maxChunkToUpload--;
      const value = this.files.pop();
      if (value !== undefined) {
        partialUpload.push(value);
      }
    }
    this.filesPendingCount = this.files.length;
    this.uploadFile(partialUpload);
  }

  uploadEvent(): void {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      this.files = [];
      for (const file of fileUpload.files) {
        console.log(file.type);
        this.files.push({ data: file, inProgress: false, progress: 0, status: '', message: 'Cargando...'});
      }
      this.filesPendingCount = this.files.length;
      this.uploadFiles();
    };
    fileUpload.click();
  }

  private uploadFiles(): void {
    this.fileUpload.nativeElement.value = '';
    //  this.filesUploaded = [];

    // this.responseFiles = new Map<string, FileData>();
    // const nonResponseFiles = new Map<string, FileData>();

    // const file = this.files.pop();
    // if (file) {
    // this.uploadFile(this.files);
    this.uploadNext();
    // }
    // this.files.forEach(file => {
    //  this.uploadFile(file);
    // });
  }

  public cleanData(): void {
    this.files = [];
    this.fileUploadErrors = [];
    this.filesPendingCount = 0;
    this.filesUploadedCount = 0;
    super.cleanData();
  }

  getPageEvent(pageNumber: number): void {
    this.cleanErrors();
    this.loading = true;
    this.service.searchByFilter(this.getFilter(), pageNumber, this.pageSize, this.sortBy, this.sortOrder)
      .subscribe(
        page => this.setPageEvent(page),
        error => this.processError(error));
  }

  getFilter(): DocumentFilter {
    const documentFilter = new DocumentFilter();
    documentFilter.from = this.fromLocalDate;
    documentFilter.to = this.toLocalDate;
    documentFilter.budgetSelected = !this.budgetNotSelected;
    if (this.text !== undefined) {
      documentFilter.text = this.text;
    }
    if (this.status !== undefined && this.status !== DocumentStatus.ALL) {
      documentFilter.status = this.status;
    }
    if (this.type !== undefined && this.type !== OperationType.ALL) {
      documentFilter.operationType = this.type;
    }
    if (this.documentType !== undefined && this.documentType !== DocumentType.ALL) {
      documentFilter.documentType = this.documentType;
    }
    return documentFilter;
  }

  changeSelection(allSelected: boolean): void {
    this.countSelections = 0;
    this.page.content?.forEach(value => {
      value.selected = allSelected;
      this.countSelections += value.selected ? 1 : 0;
    });
  }

  changeSingleSelection(selected: boolean): void {
    this.countSelections = 0;
    this.page.content?.forEach(value => {
      this.allSelected = this.allSelected && value.selected;
      this.countSelections += value.selected ? 1 : 0;
    });
  }

  deleteSelectedEvent(): void {
    const ids = this.getSelectedIds();
    this.removeAllEvent(ids);
  }

  private getSelectedIds(): number[] {
    this.allSelected = false;
    const ids: number[] = [];
    this.page.content?.forEach(value => {
      if (value.selected) {
        this.countSelections--;
        ids.push(value.id);
        value.selected = false;
      }
    });
    return ids;
  }
  acceptSelectedEvent(): void {
    this.selectedIds = this.getSelectedIds();
  }

  statusChanged(status: string): void {
    this.refreshEvent();
  }

  typeChanged(type: string): void {
    this.refreshEvent();
  }

  budgetSelectedChanged(selected: boolean): void {
    this.refreshEvent();
  }

  documentTypeChanged(documentType: string): void {
    this.refreshEvent();
  }

  fromDateChanged(date: NgbDateStruct): void {
    this.fromLocalDate = LocalDate.of(date.year, date.month, date.day);
    this.from = date;
    if (this.fromLocalDate.isAfter(this.toLocalDate)) {
      this.toLocalDate = LocalDate.of(date.year, date.month, this.fromLocalDate.lengthOfMonth());
      this.to = {year: date.year, month: date.month, day: this.fromLocalDate.lengthOfMonth()};
    }
    this.refreshEvent();
  }

  toDateChanged(date: NgbDateStruct): void {
    this.toLocalDate = LocalDate.of(date.year, date.month, date.day);
    this.to = date;
    if (this.toLocalDate.isBefore(this.fromLocalDate)) {
      this.fromLocalDate = LocalDate.of(date.year, date.month, 1);
      this.from = {year: date.year, month: date.month, day: 1};
    }
    this.refreshEvent();
  }

  editEvent(document: Document): void {
    if (document.status !== DocumentStatus.RESPONSE_APPROVED && document.status !== DocumentStatus.DOCUMENT_PENDING &&
      document.status !== DocumentStatus.RESPONSE_REJECTED) {
      super.editEvent(document);
    }
  }

  onModelUpdate(): void {
    this.model.lines?.forEach(value => {
      value.taxesDetails = JSON.parse(value.taxes ? value.taxes : '[]') as Impuesto[];
      value.discountsDetails = JSON.parse(value.discounts ? value.discounts : '[]') as Descuento[];
      }
    );
    this.codeService.getCodes(CodeType.CONDICION_VENTA, undefined, undefined, this.model.sellCondition, true).subscribe(
      value => {
        if (value) {
          const valueFound = value.pop();
          if (valueFound) {
            this.sellCondition = valueFound.description ? valueFound.description : '';
          }
        }
      }
    );
    this.codeService.getCodes(CodeType.MEDIO_PAGO, undefined, undefined, this.model.paymentMethod, true).subscribe(
      value => {
        if (value) {
          const valueFound = value.pop();
          if (valueFound) {
            this.paymentMethod = valueFound.description ? valueFound.description : '';
          }
        }
      }
    );
  }

  export(): void {
    this.loading = true;
    this.documentService.export(this.getFilter())
      .subscribe(
        response => {
          this.loading = false;
          // const typeContent = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8';
          // const blob: any = new Blob([response],
          //  {type: typeContent});
          // const url = window.URL.createObjectURL(blob);
          // window.open(url);
          const tenantFromStorage = ServiceUtils.getItem('tenant');
          const a = document.createElement('a');
          const objectUrl = URL.createObjectURL(response);
          a.href = objectUrl;
          if (tenantFromStorage) {
            const tenant = JSON.parse(tenantFromStorage) as Tenant;
            a.download = `Documentos_${tenant.name?.replace(/\./g, '_')}`;
          }
          a.click();
          URL.revokeObjectURL(objectUrl);
        }, error => this.processError(error)
      );
  }

  acceptAllEvent(): void {
    this.acceptEvent(this.documentsToAccept);
  }
  private acceptEvent(acceptDocuments: AcceptDocument[]): void  {
    this.documentService.acceptDocuments(acceptDocuments)
      .subscribe(
        value => {
          this.alert.success('Respuesta de aceptación enviada');
          this.refreshEvent(true);
        },
        error => this.processError(error));
  }
  calculateAllPercentage(): void {
    this.documentsToAccept.forEach(value => {
      value.activityCode = this.activityCode;
      value.messageCode = this.messageCode;
      value.taxCondition = this.taxCondition;
      this.calculatePercentage(value);
    });
  }

  calculatePercentage(acceptDocument: AcceptDocument): void {
    /*
    Bienes de capital, permitir % factor -> gasto 0, imp % * impuesto
Genera credito parcial 0 y 0
Gasto corriente impp 0 y gasto = impuesto
Proporcinalidad % factor -> gasto 100 - % * impuesto, imp % * impuesto
Genera -> imp = uimput, gasto 0
     */
    const tax = acceptDocument.document?.totalTax;
    if (tax !== undefined) {
      if (acceptDocument.taxCondition === '01') {
        // General Credito IVA ok
        acceptDocument.expensesToApply = 0;
        acceptDocument.taxToRecover = tax;
        this.factor = 0;
      } else if (acceptDocument.taxCondition === '02') {
        // General Crédito parcial del IVA ok
        acceptDocument.expensesToApply = 0;
        acceptDocument.taxToRecover = 0;
        this.factor = 0;
      } else if (acceptDocument.taxCondition === '03') {
        // Bienes de Capital ok
        acceptDocument.expensesToApply = 0;
        acceptDocument.taxToRecover = tax * (this.factor / 100);
      } else if (acceptDocument.taxCondition === '04') {
        // Gasto corriente no genera crédito
        acceptDocument.expensesToApply = tax;
        acceptDocument.taxToRecover = 0;
        this.factor = 0;
      } else if (acceptDocument.taxCondition === '05') {
        // Proporcionalidad
        acceptDocument.expensesToApply = tax * ( (100 - this.factor) / 100);
        acceptDocument.taxToRecover = tax * (this.factor / 100);
      }
    }
  }
  acceptableDocument(type: string | undefined): boolean {
    if (type !== undefined) {
      return ['INVOICE', 'PURCHASE_INVOICE', 'CREDIT_NOTE', 'DEBIT_NOTE'].indexOf(type) > -1;
    }
    return false;
  }
  selectDocumentToAcceptEvent(document: Document): void {
    this.documentsToAccept = [];
    this.initAcceptData();
    const acceptDocument = this.createAcceptDocumentFromSelectedValues(document);
    this.documentsToAccept.push(acceptDocument);
    this.calculatePercentage(acceptDocument);
  }

  selectDocumentsToAcceptEvent(): void {
    this.documentsToAccept = [];
    this.initAcceptData();
    this.getSelectedIds().forEach(value => {
      const document = this.page.content?.find(valueToFind => valueToFind.id === value);
      if (document !== undefined &&
        document.receivedStatus === 'PENDING' &&
        document.operationType === 'PURCHASE' &&
        !document.processed && this.acceptableDocument(document.type)) {
        const acceptDocument = this.createAcceptDocumentFromSelectedValues(document);
        this.documentsToAccept.push(acceptDocument);
        this.calculatePercentage(acceptDocument);
      }
    });
  }

  private initAcceptData(): void {
    this.taxCondition = '01';
    this.messageCode = '1';
    this.factor = 0;
    if (this.activityCodes.length === 1) {
      this.activityCode = this.activityCodes[0].code;
    }
  }

  private createAcceptDocumentFromSelectedValues(document?: Document): AcceptDocument {
    const acceptDocument = new AcceptDocument();
    acceptDocument.document = document;
    acceptDocument.taxCondition = this.taxCondition;
    acceptDocument.messageCode = this.messageCode;
    acceptDocument.activityCode = this.activityCode;
    return acceptDocument;
  }
}

class FileData {
  constructor(public data: File ,
              public inProgress: boolean,
              public progress: number,
              public status: string,
              public message: string) { }
}

class ErrorEntry {
  constructor(
    public message: string,
    public file: string
  ) {
  }
}
