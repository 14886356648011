import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BaseService} from '../core/service/base.service';
import {AccountingPeriodMonth} from '../model/accounting-period-month';
import {Observable} from 'rxjs';
import {DashboardSummary} from '../model/dashboard-summary';
import {AccountingPeriod} from '../model/accounting-period';

@Injectable({
  providedIn: 'root'
})
export class AccountingPeriodMonthService extends BaseService<AccountingPeriodMonth> {

  serviceUrl(): string {
    return `/api/t/{tenant}/accounting-period-months`;
  }

  constructor(protected http: HttpClient) {
    super(http);
  }

  closeAccountingPeriod(monthParam: string, yearParam: string): Observable<DashboardSummary> {

    const paramsValue = new HttpParams();
    const headersValue = new HttpHeaders();

    const options = {headers: headersValue, params: paramsValue};

    return this.http
      .post(`${this.serviceUrl()}/close`, {month : monthParam, year: yearParam}, options);
  }

  closeFullAccountingPeriod(): Observable<DashboardSummary> {

    const paramsValue = new HttpParams();
    const headersValue = new HttpHeaders();

    const options = {headers: headersValue, params: paramsValue};

    return this.http
      .post(`${this.serviceUrl()}/close-full`,  '{}', options);
  }

  reopenAccountingPeriod(monthParam: string, yearParam: string): Observable<DashboardSummary> {

    const paramsValue = new HttpParams();
    const headersValue = new HttpHeaders();

    const options = {headers: headersValue, params: paramsValue};

    return this.http
      .post(`${this.serviceUrl()}/reopen`, {month : monthParam, year: yearParam}, options);
  }
  getCurrentPeriod(): Observable<AccountingPeriodMonth> {
    return this.http
      .get<AccountingPeriodMonth>(`${this.serviceUrl()}/current`);
  }
}
