import {Telefono} from './telefono';
import {Ubicacion} from './ubicacion';
import {Identificacion} from './identificacion';

export class Emisor {
  public Nombre?: string;
  public Identificacion: Identificacion = new Identificacion();
  public IdentificacionExtranjero?: string;
  public NombreComercial?: string;
  public Ubicacion?: Ubicacion;
  public OtrasSenasExtranjero?: string;
  public Telefono?: Telefono;
  public Fax?: Telefono;
  public CorreoElectronico?: string;
}
