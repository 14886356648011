<div class="container">

  <div class="loader" *ngIf="loading"></div>

  <div *ngIf="mode == 'EDIT' && !loading" class="animated fadeIn">
    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top">
      <div class="container">
        <span class="navbar-text">
          Salario
        </span>
        <div class="pull-right">
          <button class="btn btn-link" (click)="cancelEvent()">
            Cerrar
          </button>
          <button class="btn btn-primary" (click)="saveEvent()">
            Guardar
          </button>
        </div>
      </div>
    </nav>

    <div class="row g-3">
      <div class="col-md-6">
        <div class="form-group">
          <label for="employee">Empleado</label>
          <select class="form-select" id="employee" name="employee" [(ngModel)]="model.employee">
            <option *ngFor="let employee of employees" value="{{employee.id}}">{{employee.name}}</option>
          </select>
          <div *ngIf="formErrors.get('salary.employee')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('salary.employee') }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="from">Desde</label>
          <mt-date-picker [(date)]="model.from" name="from" id="from" label="Desde"/>
          <div *ngIf="formErrors.get('salary.from')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('salary.from') }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="to">Hasta</label>
          <mt-date-picker [(date)]="model.to" name="to" id="to" label="Desde"/>
          <div *ngIf="formErrors.get('salary.to')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('salary.to') }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="currency">Moneda</label>
          <select class="form-select" id="currency" name="currency" [(ngModel)]="model.currency">
            <option value="CRC">Colones</option>
            <option value="USD">Dólares</option>
          </select>
          <div *ngIf="formErrors.get('salary.currency')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('salary.currency') }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="amount">Monto</label>
          <input type="text" class="form-control" id="amount" name="amount" [(ngModel)]="model.amount">
          <div *ngIf="formErrors.get('salary.amount')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('salary.amount') }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="periodicity">Periodicidad</label>
          <select class="form-select" id="periodicity" name="periodicity" [(ngModel)]="model.periodicity">
            <option value="WEEKLY">Semanal</option>
            <option value="BIWEEKLY">Bisemanal</option>
            <option value="FORTNIGHTLY">Quincenal</option>
            <option value="MONTHLY">Mensual</option>
          </select>
          <div *ngIf="formErrors.get('salary.periodicity')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('salary.periodicity') }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <label for="jobPosition">Posición</label>
        <select class="form-select" id="jobPosition" name="jobPosition" [(ngModel)]="model.jobPosition">
          <option *ngFor="let jobPosition of jobPositions" value="{{jobPosition.id}}">{{jobPosition.name}}</option>
        </select>
        <div *ngIf="formErrors.get('employee.jobPosition')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('employee.jobPosition') }}
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="mode == 'LIST' && !loading" class="animated fadeIn">

    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top">
      <div class="container">
        <span class="navbar-text">
          Salarios
        </span>
        <div class="pull-right">
          <button class="btn btn-link" (click)="refreshEvent()" title="Refrescar">
            <span class="fa fa-sync fa-lg"></span>
          </button>
          <button class="btn btn-primary" (click)="createEvent()">
            Agregar
          </button>
        </div>
      </div>
    </nav>

    <mt-pagination (loadPage)="getPageEvent($event)" [page]="page"></mt-pagination>

    <table *ngIf="page.metadata.pages > 0" class="table table-hover">
      <thead>
        <tr>
          <th>
            Empleado
          </th>
          <th scope="col" >
            Desde
          </th>
          <th scope="col" >
            Hasta
          </th>
          <th scope="col" class="text-end">
            Monto
          </th>
          <th scope="col">
            Puesto
          </th>
          <th>

          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let salary of page.content">
          <td (click)="editEvent(salary)" class="mt-content-col">
            {{salary.employeeName}}
          </td>
          <td (click)="editEvent(salary)" class="mt-content-col">
            {{salary.from?.toString()  | date:'dd/MM/yyyy'}}
          </td>
          <td (click)="editEvent(salary)" class="mt-content-col">
            {{salary.to?.toString()  | date:'dd/MM/yyyy'}}
          </td>
          <td (click)="editEvent(salary)" class="mt-content-col text-end">
            {{salary.amount | currency:salary.currency:'code'}}
          </td>
          <td (click)="editEvent(salary)" class="mt-content-col">
            <span class="mt-large-text">{{salary.jobPositionName}}</span>
          </td>
          <td class="mt-actions-col">
            <button class="btn btn-link btn-lg pull-right" data-bs-toggle="modal" data-bs-target="#salaryConfirmation"
                    (click)="selectEvent(salary)" title="Borrar">
              <span class="fa fa-trash-alt fa-lg"></span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="page.metadata.pages == 0">
      No hay registros
    </div>

  </div>

  <!-- Modal -->
  <div class="modal fade" id="salaryConfirmation" tabindex="-1" role="dialog"
       aria-labelledby="removeConfirmationLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirmar</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Desea eliminar el registro?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="removeEvent(model)">Borrar</button>
        </div>
      </div>
    </div>
  </div>

</div>


