import {EntityModel} from '../../../core/model/entity.model';
import {LocalDate} from '@js-joda/core';
import {Employee} from './employee';

export class Bonus extends EntityModel {

  public description?: string;

  public amount?: number;

  public date?: LocalDate;

  public currency?: string;

  public recurrenceStart?: LocalDate;

  public recurrenceEnd?: LocalDate;

  public employees: Employee[] = [];
}
