<div class="container">
  <div class="loader" *ngIf="loading"></div>
  <div class="row justify-content-md-center animate__animated animate__fadeIn" *ngIf="!loading">
    <div class="col-md-6 col-md-offset-3">
        <div class="row">
          <div class="col-sm-6">
            <h4>Registrarse</h4>
          </div>
          <div class="col-sm-6">
            <div class="float-right">
              <button class="btn btn-link" routerLink="/login">
                Volver
              </button>
              <button class="btn btn-primary" (click)="registerUser()">
                Enviar
              </button>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label" for="name">Nombre</label>
          <input type="text" class="form-control" id="name" name="name" [(ngModel)]="model.name" placeholder="Nombre"
                 autocomplete="name">
          <div *ngIf="formErrors.get('name')" class="badge bg-danger animate__animated animate__fadeIn">
            {{ formErrors.get('name') }}
          </div>
        </div>
        <div class="form-group">
          <label class="form-label" for="lastName">Apellidos</label>
          <input type="text" class="form-control" id="lastName" name="lastName" [(ngModel)]="model.lastName"
                 placeholder="Apellidos">
          <div *ngIf="formErrors.get('lastName')" class="badge bg-danger animate__animated animate__fadeIn">
            {{ formErrors.get('lastName') }}
          </div>
        </div>
        <div class="form-group">
          <label class="form-label" for="email">Email</label>
          <input type="email" class="form-control" id="email" name="email" [(ngModel)]="model.email" placeholder="Email"
                 autocomplete="email">
          <div *ngIf="formErrors.get('email')" class="badge bg-danger animate__animated animate__fadeIn">
            {{ formErrors.get('email') }}
          </div>
        </div>
        <div class="form-group">
          <label class="form-label" for="companyName">Empresa</label>
          <input type="text" class="form-control" id="companyName" name="companyName" [(ngModel)]="model.companyName"
                 placeholder="Empresa" autocomplete="organization">
          <div *ngIf="formErrors.get('companyName')" class="badge bg-danger animate__animated animate__fadeIn">
            {{ formErrors.get('companyName') }}
          </div>
        </div>
        <div class="form-group">
          <label class="form-label" for="password">Password</label>
          <input type="password" class="form-control" id="password" name="password" [(ngModel)]="model.password"
                 placeholder="Password" autocomplete="new-password">
          <div *ngIf="formErrors.get('password')" class="badge bg-danger animate__animated animate__fadeIn">
            {{ formErrors.get('password') }}
          </div>
        </div>
    </div>
  </div>


</div>


