<div class="container">

  <div class="loader" *ngIf="loading"></div>

  <div *ngIf="!loading" class="animate__animated animate__fadeIn">
    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">
      <div class="container-fluid">
        <span class="navbar-text">
          Saldo Cuentas Presupuestarias
        </span>
        <div class="pull-right">
        </div>
      </div>
    </nav>

    <div>
      <div class="row pb-3">
        <div class="col-sm-3">

          <select class="form-select" [(ngModel)]="code" (ngModelChange)="codeChanged(code)" >
            <option value=""></option>
            <option *ngFor="let account of accounts" [value]="account.code">{{account.description}}</option>
          </select>

        </div>
        <div class="col-sm-3">
          <span><strong>{{balance | currency:'CRC':'code'}}</strong></span>
        </div>

      </div>
    </div>
  </div>
</div>


