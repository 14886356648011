<div class="container-fluid">

  <div class="loader" *ngIf="loading"></div>

  <div *ngIf="mode == 'EDIT' && !loading" class="animate__animated animate__fadeIn">
    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">
      <div class="container-fluid">
        <span class="navbar-text">
          Planilla Presupuestaria
        </span>
        <div class="pull-right">
          <button class="btn btn-link me-1" (click)="cancelEvent()">
            Cerrar
          </button>
          <ng-container *mtHasPermission="['ADMIN_BUDGET_FORMS']">
            <button class="btn btn-secondary me-1" *ngIf="model.id" data-bs-toggle="modal" data-bs-target="#loadFile" title="Cargar comprobantes">
              <span class="fa fa-upload fa-lg "></span>
            </button>
            <button class="btn btn-warning me-1" *ngIf="model.status != 'COMPLETED' && model.id" data-bs-toggle="modal" data-bs-target="#applyConfirmation">
              Aplicar
            </button>
            <button class="btn btn-warning me-1" *ngIf="model.status == 'COMPLETED' && model.id" data-bs-toggle="modal" data-bs-target="#revertConfirmation">
              Revertir
            </button>
            <button class="btn btn-primary" (click)="saveEvent()" *ngIf="model.status != 'COMPLETED'">
              Guardar
            </button>
            <button class="btn btn-primary" (click)="saveEvent()" *ngIf="model.status == 'COMPLETED'">
              Actualizar
            </button>
          </ng-container>
        </div>
      </div>
    </nav>

    <div class="row mb-3">

      <div class="form-group  col-md-3" *ngIf="model.status != 'COMPLETED'">
        <label class="form-label" for="date">Fecha</label>
        <div class="input-group me-2 col-md-6">
          <input class="form-control" placeholder="aaaa-mm-dd"
                 name="date" [(ngModel)]="date" ngbDatepicker #d1="ngbDatepicker" id="date"
                 (ngModelChange)="dateChanged($event)" title="Fecha">
          <button class="btn btn-outline-secondary" (click)="d1.toggle()" type="button">
            <span class=" fa fa-calendar"></span>
          </button>
        </div>
        <div *ngIf="formErrors.get('budgetform.date')" class="badge bg-danger animate__animated animate__fadeIn">
          {{ formErrors.get('budgetform.date') }}
        </div>
      </div>
      <div class="form-group  col-md-3" *ngIf="model.status == 'COMPLETED'">
        <label class="form-label" for="date">Fecha</label>
        <input type="text" class="form-control" [(ngModel)]="model.date" disabled/>
      </div>

      <div class="form-group  col-md-3">
        <label class="form-label" for="status">Estado</label>

        <select class="form-select" id="status" name="foundingSource" [(ngModel)]="model.status" disabled>
          <option value="PENDING">Pendiente</option>
          <option value="COMPLETED">Completado</option>
        </select>
        <div *ngIf="formErrors.get('budgetform.status')" class="badge bg-danger animate__animated animate__fadeIn">
          {{ formErrors.get('budgetform.status') }}
        </div>
      </div>
      <div class="form-group  col-md-3">
        <label class="form-label">Total</label>
        <span class="form-control">{{total| currency:'CRC':'code'}}</span>
      </div>
    </div>

    <div class="mb-3 card">
      <h5 class="card-header">
        Comprobantes
      </h5>
      <div class="card-body">
        <table *ngIf="attachments != undefined && attachments.length > 0" class="table table-hover">
          <thead>
          <tr>
            <td>Archivo</td>
            <td></td>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let attachment of attachments; let i = index">
            <td class="mt-content-col align-middle">
              <span [title]="attachment.name">{{attachment.name}}</span>
            </td>
            <td class="mt-actions-col">
              <button class="btn btn-secondary pull-right  me-1" (click)="downloadAttachment(attachment.id, attachment.name)" title="Descargar">
                <span class="fa fa-download fa-lg"></span>
              </button>
              <ng-container *mtHasPermission="['ADMIN_BUDGET_FORMS']">
                <button class="btn btn-warning pull-right"(click)="selectedAttachment = attachment" data-bs-toggle="modal" data-bs-target="#removeAttachmentConfirmation" title="Remover">
                  <span class="fa fa-trash fa-lg"></span>
                </button>
              </ng-container>
            </td>
          </tr>
          </tbody>
        </table>
        <div *ngIf="attachments == undefined || attachments.length == 0" class="p-3">
          No hay comprobantes adjuntos
        </div>
      </div>
    </div>

    <div class="mb-3 card">
      <h5 class="card-header">
        Documentos Seleccionados
      </h5>
      <div class="card-body">
        <cdk-virtual-scroll-viewport itemSize="50" class="document-list-viewport">
          <table *ngIf="model.lines != undefined && model.lines.length > 0" class="table table-hover">
            <thead>
            <tr>
              <th scope="col">
                <input type="checkbox" [(ngModel)]="allSelected" (change)="changeSelection(allSelected)">
              </th>
              <td>Emisor</td>
              <td>Secuencia</td>
              <td>Linea</td>
              <td>Detalle</td>
              <td>Total</td>
              <td>#comprobante bancario</td>
              <td>Cuenta presupuestaria</td>
              <td>Cuenta bancaria</td>

              <td>Fecha de pago</td>
              <td>Acta N / Acuerdo N</td>
              <td>Objetivo PAD</td>
              <td></td>
            </tr>
            </thead>
            <tbody>
            <tr *cdkVirtualFor="let budgetFormLine of model.lines; let i = index">
              <td>
                <input type="checkbox" [(ngModel)]="budgetFormLine.selected" (change)="changeSingleSelection(budgetFormLine.selected)" *mtHasPermission="['ADMIN_BUDGET_FORMS']"/>
              </td>
              <td class="mt-content-col align-middle">
                <span class="mt-large-text" [title]="budgetFormLine.documentLine?.document?.issuer">{{budgetFormLine.documentLine?.document?.issuer}}</span>
              </td>
              <td class="mt-content-col align-middle">
                {{budgetFormLine.documentLine?.document?.sequence}}
              </td>
              <td class="mt-content-col align-middle">
                {{budgetFormLine.documentLine?.lineNumber}}
              </td>
              <td class="mt-content-col align-middle">
                <span class="mt-large-text" [title]="budgetFormLine.documentLine?.detail">{{budgetFormLine.documentLine?.detail}}</span>
              </td>
              <td class="mt-content-col text-end align-middle">
                <span>{{budgetFormLine.documentLine?.totalAmount | currency:budgetFormLine.documentLine?.document?.currency:'code'}}</span>
              </td>
              <td class="mt-content-col">
                <input type="text" class="form-control" [(ngModel)]="budgetFormLine.paymentCode" />
                <div *ngIf="formErrors.get('budgetformline.paymentCode')" class="badge bg-danger animated fadeIn">
                  {{ formErrors.get('budgetformline.paymentCode') }}
                </div>
              </td>
              <td class="mt-content-col align-middle">
                <span class="mt-large-text" [title]="budgetFormLine.documentLine?.budgetAccount?.code">{{budgetFormLine.documentLine?.budgetAccount?.code}}</span>
              </td>
              <td class="mt-content-col align-middle">
                <span class="mt-large-text" [title]="budgetFormLine.documentLine?.bankAccount?.number">{{budgetFormLine.documentLine?.bankAccount?.number}}</span>
              </td>
              <td class="mt-content-col">
                <div class="form-group" *ngIf="model.status != 'COMPLETED'">
                  <input type="text" class="form-control" [(ngModel)]="budgetFormLine.paymentDate" />
                  <div *ngIf="formErrors.get('lines.'+i+'.paymentDate')" class="badge bg-danger animated fadeIn">
                    {{ formErrors.get('lines.'+i+'.paymentDate') }}
                  </div>
                </div>
                <span *ngIf="model.status == 'COMPLETED'">{{budgetFormLine.paymentDate}}</span>
              </td>
              <td class="mt-content-col">
                <input type="text" class="form-control" [(ngModel)]="budgetFormLine.recordNumber" />
                <div *ngIf="formErrors.get('budgetformline.recordNumber')" class="badge bg-danger animated fadeIn">
                  {{ formErrors.get('budgetformline.recordNumber') }}
                </div>
              </td>
              <td class="mt-content-col align-middle">
                <input type="text" class="form-control" [(ngModel)]="budgetFormLine.padCode" />
                <div *ngIf="formErrors.get('budgetformline.padCode')" class="badge bg-danger animated fadeIn">
                  {{ formErrors.get('budgetformline.padCode') }}
                </div>
              </td>
              <td class="mt-actions-col">
                <ng-container *mtHasPermission="['ADMIN_BUDGET_FORMS']">
                  <button class="btn btn-secondary pull-right me-1" *ngIf="countSelections > 1 && budgetFormLine.selected"
                          (click)="copyValues(budgetFormLine)" title="Copiar valores">
                    <span class="fa fa-copy fa-lg"></span>
                  </button>
                  <button class="btn btn-warning pull-right" *ngIf="model.status != 'COMPLETED'"
                          (click)="removeBudgetLine(budgetFormLine)" title="Remover">
                    <span class="fa fa-remove fa-lg"></span>
                  </button>
                </ng-container>
              </td>
            </tr>
            </tbody>
          </table>
          <div *ngIf="model.lines == undefined || model.lines.length == 0" class="p-3">
            No hay registros
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>

    <ng-container *ngIf="model.status != 'COMPLETED'">
      <ng-container *mtHasPermission="['ADMIN_BUDGET_FORMS']">
        <div class="mb-3 card">
        <h5 class="card-header">
          Documentos Disponibles
        </h5>
        <div class="card-body">
          <div class="d-flex">
            <div class="input-group  me-2">
              <input class="form-control" id="text" name="text" [(ngModel)]="text" (change)="searchTermChanged(text)" title="Buscar..." placeholder="Buscar...">
            </div>
            <mt-date-picker [(date)]="from" label="Desde" class="input-group me-2" (dateChange)="loadAvailableDocuments()"/>
            <mt-date-picker [(date)]="to" label="Hasta" class="input-group me-2" (dateChange)="loadAvailableDocuments()"/>
          </div>

          <cdk-virtual-scroll-viewport itemSize="50" class="document-list-viewport">
            <table *ngIf="availableDocumentLines.content != undefined && availableDocumentLines.content.length > 0 " class="table table-hover">
              <thead>
              <tr>
                <td>Emisor</td>
                <td>Secuencia</td>
                <td>Linea</td>
                <td>Detalle</td>
                <td>Total</td>
                <td></td>
              </tr>
              </thead>
              <tbody>
              <tr *cdkVirtualFor="let documentLine of availableDocumentLines.content">
                <td class="mt-content-col align-middle">
                  {{documentLine.document?.issuer}}
                </td>
                <td class="mt-content-col align-middle">
                  {{documentLine.document?.sequence}}
                </td>
                <td class="mt-content-col align-middle">
                  {{documentLine.lineNumber}}
                </td>
                <td class="mt-content-col align-middle">
                  {{documentLine.detail}}
                </td>
                <td class="mt-content-col text-end align-middle">
                  <span>{{documentLine.totalAmount | currency:documentLine.document?.currency:'code'}}</span>
                </td>
                <td class="mt-actions-col">
                  <button class="btn btn-primary pull-right"
                          (click)="addDocumentLine(documentLine)" title="Agregar">
                    <span class="fa fa-add fa-lg"></span>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
            <div *ngIf="availableDocumentLines.content == undefined || availableDocumentLines.content.length == 0" class="p-3">
            No hay registros
          </div>
          </cdk-virtual-scroll-viewport>
        </div>
      </div>
      </ng-container>
    </ng-container>
  </div>

  <div *ngIf="mode == 'LIST' && !loading" class="animate__animated animate__fadeIn">

    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">
      <div class="container-fluid">
        <form class="d-flex">
          <mt-date-picker [(date)]="from" label="Desde" class="input-group me-2" (dateChange)="getPageEvent(0)"/>
          <mt-date-picker [(date)]="to" label="Hasta" class="input-group me-2" (dateChange)="getPageEvent(0)"/>
          <input class="form-control me-2" id="sequence" name="text" [(ngModel)]="sequence" (change)="getPageEvent(0)" title="Buscar por consecutivo..." placeholder="Consecutivo...">
          <select class="form-select" id="statusSearch" name="foundingSource" [(ngModel)]="statusSearch" (change)="getPageEvent(0)" title="Estado">
            <option value="ALL" selected>Todos</option>
            <option value="PENDING">Pendiente</option>
            <option value="COMPLETED">Completado</option>
          </select>
        </form>
        <div class="pull-right">
          <button class="btn btn-secondary me-1" (click)="refreshEvent()" title="Refrescar">
            <span class="fa fa-refresh fa-lg"></span>
          </button>
          <button class="btn btn-primary" (click)="createEvent()">
            Agregar
          </button>
        </div>
      </div>
    </nav>

    <p *ngIf="periodYear" class="alert alert-info">Periodo actual {{periodYear}} </p>

    <mt-pagination (loadPage)="getPageEvent($event)" [page]="page"></mt-pagination>

    <table *ngIf="page.metadata.pages > 0" class="table table-hover">
      <thead>
      <tr>
        <td>Fecha</td>
        <td>Consecutivo</td>
        <td>Estado</td>
        <td></td>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let budgetForm of page.content">
        <td (click)="editEvent(budgetForm)" class="mt-content-col  align-middle">
          {{budgetForm.date}}
        </td>
        <td (click)="editEvent(budgetForm)" class=" align-middle">{{budgetForm.sequence}}-{{budgetForm.date.toString() | date:'y' }}</td>
        <td (click)="editEvent(budgetForm)" class="mt-content-col align-middle">
          <span *ngIf="budgetForm.status == 'PENDING'" >Pendiente</span>
          <span *ngIf="budgetForm.status == 'COMPLETED'" >Completado</span>
        </td>
        <td class="mt-actions-col">
          <ng-container *mtHasPermission="['ADMIN_BUDGET_FORMS']">
            <button class="btn btn-link btn-lg pull-right" *ngIf="budgetForm.status != 'COMPLETED'" data-bs-toggle="modal" data-bs-target="#removeCategoryConfirmation"
                    (click)="selectEvent(budgetForm)" title="Borrar">
              <span class="fa fa-trash-alt fa-lg"></span>
            </button>
          </ng-container>
        </td>
      </tr>
      </tbody>
    </table>
    <div *ngIf="page.metadata.pages == 0" class="p-3">
      No hay registros
    </div>

  </div>

  <!-- Modal -->
  <div class="modal fade" id="removeCategoryConfirmation" tabindex="-1" role="dialog"
       aria-labelledby="removeConfirmationLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirmar</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Desea eliminar el registro?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="removeEvent(model)">Borrar</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="applyConfirmation" tabindex="-1" role="dialog"
       aria-labelledby="removeConfirmationLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirmar</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Al aplicar la planilla el balance de las cuentas presupuestarias será actualizado, las planillas aplicadas ya no podrán ser modificadas ni eliminadas.</p>
          <p>Desea aplicar la planilla?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="applyEvent()">Aplicar</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="revertConfirmation" tabindex="-1" role="dialog"
       aria-labelledby="revertConfirmationLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirmar</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Al revertir la planilla el balance de las cuentas presupuestarias será actualizado y la planilla podrá ser modificada nuevamente.</p>
          <p>Desea revertir la planilla?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="revertEvent()">Revertir</button>
        </div>
      </div>
    </div>
  </div>

</div>


<div class="modal fade" id="loadFile" tabindex="-1" role="dialog"
     aria-labelledby="loadFileLabel">
  <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Cargar Archivo</h5>
      </div>
      <div class="modal-body">
        <p *ngIf="loading">Cargando archivo...</p>
        <p *ngIf="fileUploadErrors.length == 0 && !loading">Seleccione los comprobantes de pagos que desea asociar a la planilla</p>

        <input type="file" #fileUpload id="fileUpload" name="fileUpload" (change)="onFileSelected($event)" multiple="multiple" style="display:none;" />
        <ul *ngIf="fileUploadErrors.length > 0 && !loading" class="list-group list-group-flush">
          <li class="list-group-item d-flex justify-content-between align-items-center">
            Errores encontrados
            <span class="badge bg-danger bg-pill">{{fileUploadErrors.length}}</span>
          </li>
          <li *ngFor="let error of fileUploadErrors" class="list-group-item">
            <span>Línea {{error.line}}</span> <br> <small>{{error.message}}</small>
          </li>
        </ul>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" (click)="fileUpload.click()" title="Seleccionar..." [disabled]="loading">
          Seleccionar...
        </button>
        <button type="button" class="btn btn-link" data-bs-dismiss="modal" (click)="cleanData()" [disabled]="loading">Cerrar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="removeAttachmentConfirmation" tabindex="-1" role="dialog"
     aria-labelledby="removeConfirmationLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Confirmar</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>Desea eliminar el combrobante {{selectedAttachment?.name}}?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="removeAttachment(selectedAttachment?.id)">Borrar</button>
      </div>
    </div>
  </div>
</div>
