import {Injectable} from '@angular/core';

import {BaseService} from '../core/service/base.service';

import {HttpClient} from '@angular/common/http';
import {BankAccount} from '../model/bank-account';
import {Observable} from 'rxjs';

@Injectable()
export class BankAccountService extends BaseService<BankAccount> {

  serviceUrl(): string {
    return `/api/t/{tenant}/bank-accounts`;
  }

  constructor(protected http: HttpClient) {
    super(http);
  }

  findAll(): Observable<BankAccount[]> {

    return this.http
      .get<BankAccount[]>(`${this.serviceUrl()}/all`);
  }
}
