import {EntityModel} from '../../../core/model/entity.model';
import {LocalDate, LocalTime} from '@js-joda/core';
import {WorkdayLogEntry} from './workday-log-entry';

export class WorkdayLog extends EntityModel {

  public workedHours?: LocalTime;
  public acceptedHours?: LocalTime;
  public date?: LocalDate;
  public employee?: number;
  public employeeName?: string;
  public workday?: number;
  public workdayLogEntries: WorkdayLogEntry[] = [];
}
