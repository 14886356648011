import {Impuesto} from './impuesto';
import {Descuento} from './descuento';
import {CodigoComercial} from './codigo -comercial';

export class LineaDetalle {
    public NumeroLinea?: number;
    public PartidaArancelaria?: string;
    public Codigo?: string;
    public CodigoComercial?: CodigoComercial[];
    public Cantidad?: number;
    public UnidadMedida?: string;
    public UnidadMedidaComercial?: string;
    public Detalle?: string;
    public PrecioUnitario?: number;
    public MontoTotal?: number;
    public Descuento: Descuento[] = [];
    public DescuentoSimple: Descuento = new Descuento();
    public SubTotal?: number;
    public BaseImponible?: number;
    public Impuesto: Impuesto[] = [];
    public ImpuestoSimple: Impuesto = new Impuesto();
    public ImpuestoNeto?: number;
    public MontoTotalLinea?: number;

}
