<div class="container-fluid">

  <div class="loader" *ngIf="loading"></div>

  <div *ngIf="!loading" class="animate__animated animate__fadeIn">
    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">
      <div class="container-fluid">
        <span class="navbar-text">
          Modificación Presupuestaria
        </span>
        <div class="pull-right">
          <button type="button" class="btn btn-secondary me-1" (click)="refreshEvent()" title="Refrescar">
            <span class="fa fa-sync fa-lg "></span>
          </button>
          <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#applyConfirmation">
            Modificar
          </button>
        </div>
      </div>
    </nav>

    <div class="row mb-3">
      <p class="alert alert-info">Se recomienda que los totales del saldo y los del ajuste deben ser iguales para poder realizar la actualización.</p>
    </div>

    <table *ngIf="accountsIncomes.length > 0 || accountsOutcomes.length > 0 " class="table table-hover">
      <thead>
      <tr>
        <td>Cuenta</td>
        <td>Código</td>
        <td class=" text-end ">Presupuesto Inicial</td>
        <td class=" text-end ">Saldo</td>
        <td class=" text-end ">Ajuste</td>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let account of accountsIncomes; let i = index">
        <td class="mt-content-col align-middle">
          {{account.description}}
        </td>
        <td class="mt-content-col align-middle">
          {{account.code}}
        </td>
        <td class="mt-content-col text-end align-middle">
          <span>{{account.initialAmount | currency:'CRC': 'code'}}</span>
        </td>
        <td class="mt-content-col text-end align-middle">
          <span>{{account.balance | currency:'CRC': 'code'}}</span>
        </td>
        <td class="mt-content-col text-end align-middle">
          <input class="form-control text-end" name="new-balance-{{i}}" id="new-balance-{{i}}"
                 [(ngModel)]="account.adjustment" (change)="updateTotalsEvent()"/>
        </td>
      </tr>
      <tr>
        <th scope="col">
        </th>
        <th scope="col">
        </th>
        <th scope="col" class="text-end">
          {{initialTotalIncomes | currency:'CRC':'code'}}
        </th>
        <th scope="col" class="text-end">
          {{totalIncomes | currency:'CRC':'code'}}
        </th>
        <th scope="col" [className]="newTotalIncomes !== totalIncomes?'text-bg-danger text-end':'text-end'">
          {{newTotalIncomes | currency:'CRC':'code'}}
        </th>
      </tr>
      <tr>
        <th scope="col">
          &nbsp;
        </th>
        <th scope="col">
        </th>
        <th scope="col">
        </th>
        <th scope="col">
        </th>
        <th scope="col">
        </th>
      </tr>

      <tr *ngFor="let account of accountsOutcomes; let i = index">
        <td class="mt-content-col align-middle">
          {{account.description}}
        </td>
        <td class="mt-content-col align-middle">
          {{account.code}}
        </td>
        <td class="mt-content-col text-end align-middle">
          <span>{{account.initialAmount | currency:'CRC': 'code'}}</span>
        </td>
        <td class="mt-content-col text-end align-middle">
          <span>{{account.balance | currency:'CRC': 'code'}}</span>
        </td>
        <td class="mt-content-col text-end align-middle">
          <input class="form-control text-end" name="new-balance-{{i}}" id="new-balance-{{i}}"
                 [(ngModel)]="account.adjustment" (change)="updateTotalsEvent()"/>
        </td>
      </tr>
      <tr>
        <th scope="col">
        </th>
        <th scope="col">
        </th>
        <th scope="col" class="text-end">
          {{initialTotalOutcomes | currency:'CRC':'code'}}
        </th>
        <th scope="col" class="text-end">
          {{totalOutcomes | currency:'CRC':'code'}}
        </th>
        <th scope="col" [className]="newTotalOutcomes !== totalOutcomes?'text-bg-danger text-end':'text-end'">
          {{newTotalOutcomes | currency:'CRC':'code'}}
        </th>
      </tr>
      </tbody>
    </table>
    <div *ngIf="accountsIncomes.length == 0 && accountsOutcomes.length == 0" class="p-3">
      No hay registros
    </div>

  </div>

  <div class="modal fade" id="applyConfirmation" tabindex="-1" role="dialog"
       aria-labelledby="removeConfirmationLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirmar</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p *ngIf="newTotalOutcomes !== totalOutcomes || newTotalIncomes !== totalIncomes" class="text-danger">El ajuste está alterando el total, asegúrese de revisar los cambios antes de aceptar.</p>
          <p>Desea actualizar los saldos de las cuentas presupuestarias?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="saveEvent()">Aceptar</button>
        </div>
      </div>
    </div>
  </div>

</div>


