import {Component, OnDestroy, OnInit, WritableSignal} from '@angular/core';
import {BaseComponent} from '../../../core/component/base.component';

import {AlertService} from '../../../core/service/alert.service';
import {MessageService} from '../../../core/service/message.service';
import {Router} from '@angular/router';
import {SecurityService} from '../../../core/service/security.service';
import {LoggerService} from '../../../service/logger.service';

import {WorkdayLog} from '../../service/resource/workday-log';
import {WorkdayLogService} from '../../service/workday-log.service';
import {Employee} from '../../service/resource/employee';
import {EmployeeService} from '../../service/employee.service';
import {WorkdayLogEntry} from '../../service/resource/workday-log-entry';
import {WorkdayLogFilter} from '../../service/filter/workday-log.filter';
import {LocalDate} from '@js-joda/core';

@Component({
  selector: 'app-workday-log',
  templateUrl: './workday-log.component.html'
})
export class WorkdayLogComponent extends BaseComponent<WorkdayLog> implements OnInit, OnDestroy {

  employees: Employee[] = [];
  from: LocalDate = LocalDate.now();
  to: LocalDate = LocalDate.now();
  employeeId?: number = undefined;

  constructor(private workdayService: WorkdayLogService,
              private alertService: AlertService,
              protected messageService: MessageService,
              protected loggerService: LoggerService,
              protected router: Router,
              protected employeeService: EmployeeService,
              protected securityService: SecurityService) {
    super(workdayService, 'WorkdayLog', alertService, router, securityService, messageService, loggerService);
    this.sortBy = ['date', 'employeeId'];
    this.sortOrder = 'ASC';
    this.pageSize = 25;
    this.from = LocalDate.now().withDayOfMonth(1);
    this.to = this.from.withDayOfMonth(this.from.lengthOfMonth());
  }

  getModelInstance(): WorkdayLog {
    return new WorkdayLog();
  }

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.employeeService.getAll().subscribe({
        next: (v) => this.employees = v,
        error: (e) => this.processError(e)
      }
    );
  }

  getPageEvent(pageNumber: number, refresh: boolean = false): void {
    const filter = new WorkdayLogFilter();
    filter.fromDate = this.from;
    filter.toDate = this.to;
    if (this.employeeId !== undefined) {
      filter.employeeId = this.employeeId;
    }
    this.loading = true;
    this.service.searchByFilter(filter, pageNumber, this.pageSize, this.sortBy, this.sortOrder)
      .subscribe({
        next: value => {
          this.page = value;
          this.loading = false;
          },
        error: err => this.processError(err)
        });
  }

  addEntryEvent(): void {
    const newEntry = new WorkdayLogEntry();
    this.model.workdayLogEntries.push(newEntry);
  }

  removeEntryEvent(index: number): void {
    this.model.workdayLogEntries.splice(index, 1);
  }

  fromDateChanged($event: any): void {
  }

  toDateChanged($event: any): void {
  }

}
