import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../core/component/base.component';
import {Department} from '../../service/resource/department';

import {AlertService} from '../../../core/service/alert.service';
import {MessageService} from '../../../core/service/message.service';
import {Router} from '@angular/router';
import {SecurityService} from '../../../core/service/security.service';
import {LoggerService} from '../../../service/logger.service';
import {DepartmentService} from '../../service/department.service';
import {DepartmentLevelService} from '../../service/department-level.service';
import {DepartmentLevel} from '../../service/resource/department-level';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html'
})
export class DepartmentComponent extends BaseComponent<Department> implements OnInit, OnDestroy {

  departmentLevels: DepartmentLevel[] = [];

  constructor(private departmentService: DepartmentService,
              private alertService: AlertService,
              protected messageService: MessageService,
              protected loggerService: LoggerService,
              protected router: Router,
              protected securityService: SecurityService,
              protected departmentLevelService: DepartmentLevelService) {
    super(departmentService, 'department', alertService, router, securityService, messageService, loggerService);
  }

  getModelInstance(): Department {
    return new Department();
  }

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>().set('department.name', '');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.departmentLevelService.getAll().subscribe(
      {
        next: (v) => this.departmentLevels = v,
        error: (e) => this.processError(e)
      }
    );
  }
}
