import {Injectable} from '@angular/core';
import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';


@Injectable()
export class NgbDateCustomFormatter extends NgbDateParserFormatter {

  constructor() {
    super();
  }

  parse(value: string): NgbDateStruct | null{
    if (value) {
      const dateParts = value.trim().split('-');

      return {year: this.toInteger(dateParts[2]), month: this.toInteger(dateParts[1]), day: this.toInteger(dateParts[0])};

    }
    return null;
  }

  format(date: NgbDateStruct): string {
    return date ?
      `${date.year}-${this.isNumber(date.month) ?
        this.padNumber(date.month) :
        ''}-${this.isNumber(date.day) ? this.padNumber(date.day) : ''}` :
      '';
  }

  toInteger(value: any): number {
    return parseInt(`${value}`, 10);
  }

  isNumber(value: any): value is number {
    return !isNaN(this.toInteger(value));
  }

  padNumber(value: number): string {
    if (this.isNumber(value)) {
      return `0${value}`.slice(-2);
    } else {
      return '';
    }
  }

}
