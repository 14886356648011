import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import SecurityUtils from '../utils/security.utils';
import {PrivilegeName} from '../model/user';
import {Subscription} from 'rxjs';
import {MessageService} from '../service/message.service';
import {UserChangedMessage} from '../message/user-changed.message';

@Directive({ selector: '[mtHasPermission]'})
export class HasPermissionDirective implements OnInit{
  private hasView = false;
  private privileges: string[] = [];
  subscriptions: Subscription[] = [];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    protected messageService: MessageService)
  { }

  @Input() set mtHasPermission(privileges: string[]) {
    // SecurityUtils.hasPermission();
    this.privileges = privileges;
    this.validatePermissions();
  }

  private validatePermissions(): void {

    const permissions: PrivilegeName[] = [];
    this.privileges.forEach(value => {
      const privilege = (PrivilegeName as any)[value];
      permissions.push(privilege);
    });
    const condition = SecurityUtils.hasPermission(permissions);
    if (condition && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!condition && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

  ngOnInit(): void {
    this.subscriptions.push(this.messageService.of(UserChangedMessage).subscribe(
      message => {
        this.validatePermissions();
      }
    ));
  }


}
