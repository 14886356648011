<div *ngIf="page.metadata.pages > 1" class="text-center">
  <button *ngIf="page.metadata.page > 0" class="btn btn-link" (click)="getPage(page.metadata.page-1)">
    Anterior
  </button>
  <ng-container *ngIf="showPages"> {{page.metadata.page + 1}} de {{page.metadata.pages}}</ng-container>
  <ng-container *ngIf="!showPages"> Página {{page.metadata.page + 1}} </ng-container>
  <button *ngIf="page.metadata.page + 1 < page.metadata.pages" class="btn btn-link"
          (click)="getPage(page.metadata.page+1)">
    Siguiente
  </button>
</div>
