import {Injectable} from '@angular/core';


import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Tenant} from '../model/tenant';

@Injectable()
export class TenantService {

  constructor(protected http: HttpClient) {
  }

  getUserTenants(): Observable<Tenant[]> {
    return this.http
      .get<Tenant[]>(`/api/admin/users/me/tenants`);
  }

  createTenant(tenant: Tenant): Observable<any> {
    return this.http
      .post<Tenant[]>(`/api/system/tenants`, JSON.stringify(tenant));
  }

  deleteTenant(tenantId: number): Observable<string> {
    return this.http
      .delete(`/api/system/tenants/${tenantId}`, {responseType: 'text'});
  }

  deactivateTenant(tenantId: number): Observable<string> {
    return this.http
      .delete(`/api/system/tenants/${tenantId}/deactivate`, {responseType: 'text'});
  }
}
