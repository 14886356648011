import './rxjs-extensions';

import {BrowserModule} from '@angular/platform-browser';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  Inject,
  Injectable,
  InjectionToken,
  LOCALE_ID,
  NgModule
} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {
  NgbAlertModule,
  NgbDateParserFormatter,
  NgbDatepickerModule, NgbTimepicker,
  NgbToastModule
} from '@ng-bootstrap/ng-bootstrap';

import {AppComponent} from './app.component';
import {TenantUserService} from './service/tenant-user.service';

import {AppRoutingModule} from './app-routing.module';
import {AlertComponent} from './core/directive/alert.component';
import {AlertService} from './core/service/alert.service';
import {AuthGuard} from './core/guard/auth.guard';
import {RedirectComponent} from './core/component/redirect.component';
import {UserComponent} from './core/component/user/user.component';
import {ClientService} from './service/client.service';
import {ClientComponent} from './component/client/client.component';
import {PaginationComponent} from './core/component/pagination/pagination.component';
import {SecurityService} from './core/service/security.service';
import {TenantSelectorComponent} from './core/component/tenant-selector/tenant-selector.component';
import {MessageService} from './core/service/message.service';
import {CounterComponent} from './core/component/counter/counter.component';
import {I18n} from './service/i18n.service';
import {NgbDateCustomFormatter} from './service/calendar/datepicker-formatter.service';
import {RegistrationComponent} from './core/component/registration/registration.component';
import {PublicService} from './core/service/public.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HeadersInterceptor} from './core/interceptor/headers-interceptor';
import {TenantInterceptor} from './core/interceptor/tenant-interceptor';
import {TenantService} from './core/service/tenant.service';
import {ConfirmationComponent} from './core/component/confirmation/confirmation.component';
import {HasPermissionDirective} from './core/directive/security.directive';
import {ZonedDateTimePipe} from './core/pipes/joda-date-pipe';
import {DelayInterceptor} from './core/interceptor/delay-interceptor';
import {AuthInterceptor} from './core/interceptor/auth-interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {DocumentComponent} from './component/document/view/document.component';
import {DocumentService} from './service/document.service';
import {CreateDocumentComponent} from './component/document/add/create-document.component';
import {CacheInterceptor} from './core/interceptor/cache-interceptor';
import {ValidateComponent} from './component/document/validate/validate.component';
import {DocumentLineService} from './service/document-line.service';

import localeEs from '@angular/common/locales/es';
import {registerLocaleData} from '@angular/common';
import {MainClientComponent} from './component/configuration/general/main-client.component';
import {LoginComponent} from './core/component/login/login.component';
import {CompanyComponent} from './component/company/company.component';
import {DashboardComponent} from './component/dashboard/dashboard.component';
import {DocumentAccountComponent} from './component/document/document-account/document-account.component';
import {ClassifyComponent} from './component/document/classify/classify.component';
import {RecoverComponent} from './core/component/recover/recover.component';
import {AccountService} from './service/account.service';
import {ClientAccountComponent} from './component/configuration/accounts/client-account.component';
import {ClosePeriodComponent} from './component/close-period/close-period.component';
import {BalanceReportComponent} from './component/report/balance/balance-report.component';
import {ResultsReportComponent} from './component/report/results/results-report.component';
import {ConfigurationService} from './service/configuration.service';
import {AccountsReceivableComponent} from './component/auxiliaries/accounts-receivable/accounts-receivable.component';
import {ExpensesReportComponent} from './component/report/expenses/expenses-report.component';
import {FormD101Component} from './component/forms/d101/form-d101.component';
import {ScrollingModule} from '@angular/cdk/scrolling';


import {environment} from '../environments/environment';
import {CashPaymentsReportComponent} from './component/report/cash-payments/cash-payments-report.component';
import {BankAccountComponent} from './component/configuration/bank-accounts/bank-account.component';
import {BankAccountService} from './service/bank-account.service';
import {AccountingFormComponent} from './component/document/accounting-form/accounting-form.component';
import {BudgetFormComponent} from './component/budget-form/budget-form.component';
import {BudgetFormService} from './service/budget-form.service';
import {BudgetFormReportComponent} from './component/report/budget-form/budget-form-report.component';
import {RetentionReportComponent} from './component/report/retention/retention-report.component';
import {QuarterlyReportComponent} from './component/report/quarterly/quarterly-report.component';
import {BudgetAccountBalanceComponent} from './component/budget-account-balance/budget-account-balance.component';
import {ConsolidatedReportComponent} from './component/report/consolidated/consolidated-report.component';
import {
  ConsolidatedLastLevelReportComponent
} from './component/report/consolidated-last-level/consolidated-last-level-report.component';
import {BudgetModificationComponent} from './component/budget-modification/budget-modification.component';
import {
  RetentionProviderReportComponent
} from './component/report/retention-provider/retention-provider-report.component';
import {
  InvoiceDetailsProviderReportComponent
} from './component/report/invoice-details-provider/invoice-details-provider-report.component';
import {AuditButtonComponent} from './core/component/audit/audit-button.component';
import {ActorService} from './core/service/actor.service';
import {AuditModalComponent} from './core/component/audit/audit-modal.component';
import Rollbar from 'rollbar';
import {AddPaymentComponent} from './component/document/add-payment/add-payment.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {TenantUserComponent} from './component/tenant-user/tenant-user.component';
import {DepartmentComponent} from './rhplus/component/department/department.component';
import {VacationService} from './rhplus/service/vacation.service';
import {LeaveBalanceService} from './rhplus/service/leave-balance.service';
import {JobPositionService} from './rhplus/service/job-position.service';
import {EmployeeService} from './rhplus/service/employee.service';
import {DepartmentService} from './rhplus/service/department.service';
import {JobPositionComponent} from './rhplus/component/job-position/job-position.component';
import {EmployeeComponent} from './rhplus/component/employee/employee.component';
import {DatePickerComponent} from './core/component/date-picker/date-picker.component';
import {DepartmentLevelService} from './rhplus/service/department-level.service';
import {IncomeTaxRangeComponent} from './rhplus/component/income-tax-range/income-tax-range.component';
import {IncomeTaxRangeService} from './rhplus/service/income-tax-range.service';
import {WorkdayService} from './rhplus/service/workday.service';
import {WorkdayComponent} from './rhplus/component/workday/workday.component';
import {AbsenceService} from './rhplus/service/absence.service';
import {BonusService} from './rhplus/service/bonus.service';
import {BonusComponent} from './rhplus/component/bonus/bonus.component';
import {SalaryComponent} from './rhplus/component/salary/salary.component';
import {SalaryService} from './rhplus/service/salary.service';
import {AbsenceComponent} from './rhplus/component/absence/absence.component';
import {WorkdayLogComponent} from './rhplus/component/workday-log/workday-log.component';
import {WorkdayLogService} from './rhplus/service/workday-log.service';
import {TimePickerComponent} from './core/component/time-picker/time-picker.component';
import {HolidayComponent} from './rhplus/component/holiday/holiday.component';
import {HolidayService} from './rhplus/service/holiday.service';
import {PayrollComponent} from './rhplus/component/payroll/payroll.component';
import {PayrollService} from './rhplus/service/payroll.service';


registerLocaleData(localeEs);

const rollbarConfig = {
  accessToken: 'e0020850429b425b8824fd9d12f4fb38',
  environment: environment.environmentName,
  captureUncaught: true,
  captureUnhandledRejections: true,
};

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: any): void {
    if (environment.environmentName === 'prod') {
      this.rollbar.error(err.originalError || err);
    } else {
      console.log(err.originalError || err);
    }
  }
}

// tslint:disable-next-line:typedef
export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
    RedirectComponent,
    UserComponent,
    ClientComponent,
    DocumentComponent,
    CreateDocumentComponent,
    CompanyComponent,
    ValidateComponent,
    MainClientComponent,
    PaginationComponent,
    TenantSelectorComponent,
    CounterComponent,
    AuditButtonComponent,
    AuditModalComponent,
    RegistrationComponent,
    RecoverComponent,
    ConfirmationComponent,
    DashboardComponent,
    DocumentAccountComponent,
    ClassifyComponent,
    ClientAccountComponent,
    ClosePeriodComponent,
    BalanceReportComponent,
    ResultsReportComponent,
    ExpensesReportComponent,
    AccountsReceivableComponent,
    LoginComponent,
    FormD101Component,
    ZonedDateTimePipe,
    HasPermissionDirective,
    CashPaymentsReportComponent,
    BankAccountComponent,
    AccountingFormComponent,
    BudgetFormComponent,
    BudgetFormReportComponent,
    RetentionReportComponent,
    QuarterlyReportComponent,
    BudgetAccountBalanceComponent,
    ConsolidatedReportComponent,
    ConsolidatedLastLevelReportComponent,
    BudgetModificationComponent,
    RetentionProviderReportComponent,
    InvoiceDetailsProviderReportComponent,
    AddPaymentComponent,
    TenantUserComponent,
    DepartmentComponent,
    JobPositionComponent,
    EmployeeComponent,
    DatePickerComponent,
    IncomeTaxRangeComponent,
    WorkdayComponent,
    BonusComponent,
    AbsenceComponent,
    SalaryComponent,
    WorkdayLogComponent,
    TimePickerComponent,
    HolidayComponent,
    PayrollComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    NgbDatepickerModule,
    NgbAlertModule,
    NgbToastModule,
    BrowserAnimationsModule,
    ScrollingModule,
    NgSelectModule,
    NgbTimepicker
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TenantInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadersInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DelayInterceptor,
      multi: true
    },
    {provide: ErrorHandler, useClass: RollbarErrorHandler},
    {provide: RollbarService, useFactory: rollbarFactory},
    AlertService,
    TenantUserService,
    ClientService,
    DocumentService,
    DocumentLineService,
    SecurityService,
    AuthGuard,
    MessageService,
    PublicService,
    TenantService,
    AccountService,
    ConfigurationService,
    BankAccountService,
    BudgetFormService,
    ActorService,
    VacationService,
    LeaveBalanceService,
    JobPositionService,
    EmployeeService,
    DepartmentService,
    DepartmentLevelService,
    IncomeTaxRangeService,
    WorkdayService,
    AbsenceService,
    BonusService,
    SalaryService,
    WorkdayLogService,
    HolidayService,
    PayrollService,
    I18n,
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomFormatter},
    {provide: LOCALE_ID, useValue: 'es'}
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    PaginationComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
