<div class="container">

  <div class="loader" *ngIf="loading"></div>

  <div *ngIf="mode == 'EDIT' && !loading" class="animated fadeIn">
    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top">
      <div class="container">
        <span class="navbar-text">
          Feriado
        </span>
        <div class="pull-right">
          <button class="btn btn-link" (click)="cancelEvent()">
            Cerrar
          </button>
          <button class="btn btn-primary" (click)="saveEvent()">
            Guardar
          </button>
        </div>
      </div>
    </nav>

    <div class="row g-3">
      <div class="col-md-6">
        <div class="form-group">
          <label for="name">Nombre</label>
          <input type="text" class="form-control" id="name" name="name" [(ngModel)]="model.name">
          <div *ngIf="formErrors.get('holiday.name')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('holiday.name') }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="date">Fecha</label>
          <mt-date-picker [(date)]="model.date" name="date" id="date" label="Fecha"/>
          <div *ngIf="formErrors.get('holiday.date')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('holiday.date') }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="mode == 'LIST' && !loading" class="animated fadeIn">

    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top">
      <div class="container">
        <span class="navbar-text">
          Feriados
        </span>
        <div class="pull-right">
          <button class="btn btn-link" (click)="refreshEvent()" title="Refrescar">
            <span class="fa fa-sync fa-lg"></span>
          </button>
          <button class="btn btn-primary" (click)="createEvent()">
            Agregar
          </button>
        </div>
      </div>
    </nav>

    <mt-pagination (loadPage)="getPageEvent($event)" [page]="page"></mt-pagination>

    <table *ngIf="page.metadata.pages > 0" class="table table-hover">
      <thead>
        <tr>
          <th>
            Jornada
          </th>
          <th>
            Fecha
          </th>
          <th>

          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let holiday of page.content">
          <td (click)="editEvent(holiday)" class="mt-content-col">
            {{holiday.name}}
          </td>
          <td (click)="editEvent(holiday)" class="mt-content-col">
            {{holiday.date}}
          </td>
          <td class="mt-actions-col">
            <button class="btn btn-link btn-lg pull-right" data-bs-toggle="modal" data-bs-target="#holidayConfirmation"
                    (click)="selectEvent(holiday)" title="Borrar">
              <span class="fa fa-trash-alt fa-lg"></span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="page.metadata.pages == 0">
      No hay registros
    </div>

  </div>

  <!-- Modal -->
  <div class="modal fade" id="holidayConfirmation" tabindex="-1" role="dialog"
       aria-labelledby="removeConfirmationLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirmar</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Desea eliminar el registro?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="removeEvent(model)">Borrar</button>
        </div>
      </div>
    </div>
  </div>

</div>


