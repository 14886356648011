<div class="container">

  <div class="loader" *ngIf="loading"></div>

  <div *ngIf="!loading" class="animate__animated animate__fadeIn">
    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">
      <div class="container-fluid">
        <span class="navbar-text">
          Reporte Trimestral
        </span>
        <div class="pull-right">
          <button class="btn btn-primary" (click)="generateEvent()">
            Generar
          </button>
        </div>
      </div>
    </nav>

    <div>
      <div class="row pb-3">
        <div class="col-sm-3">
          <select class="form-select" id="month" [(ngModel)]="month" (ngModelChange)="monthChanged(month)" title="Trimestre">
            <option value="January" selected>Enero-Marzo</option>

            <option value="April" selected>Abril-Junio</option>

            <option value="July" selected>Julio-Setiembre</option>

            <option value="October" selected>Octubre-Diciembre</option>

          </select>
        </div>
        <div class="col-sm-3">
          <select class="form-select" id="year" [(ngModel)]="year" (ngModelChange)="yearChanged(year)" title="Año">
            <option value="{{year-3}}" >{{year-3}}</option>
            <option value="{{year-2}}" >{{year-2}}</option>
            <option value="{{year-1}}" >{{year-1}}</option>
            <option value="{{year}}" selected>{{year}}</option>
            <option value="{{year+1}}" >{{year+1}}</option>
            <option value="{{year+2}}" >{{year+2}}</option>
            <option value="{{year+3}}" >{{year+3}}</option>
          </select>
        </div>
        <div class="col-sm-3">
          <select class="form-select" id="foundingSource" name="foundingSource" [(ngModel)]="foundingSource">
            <option value="001">Ley 6746</option>
            <option value="002">Ley 7552</option>
            <option value="003">Otros Ingresos</option>
            <option value="004">PANEA</option>
          </select>
        </div>

      </div>
    </div>
  </div>
</div>


