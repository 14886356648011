import {ZonedDateTime} from '@js-joda/core';
import {LineaDetalle} from './linea-detalle';
import {Emisor} from './emisor';
import {InformacionReferencia} from './informacion-referencia';
import {ResumenFactura} from './resumen-factura';
import {Otros} from './otros';
import {OtrosCargos} from './otros-cargos';
import {Receptor} from './receptor';

export class ComprobanteElectronico {
    public CodigoActividad?: string;
    public Clave?: string;
    public NumeroConsecutivo?: string;
    public FechaEmision?: ZonedDateTime;
    public Emisor = new Emisor();
    public Receptor = new Receptor();
    public CondicionVenta?: string;
    public PlazoCredito?: string;
    public MedioPago?: string;
    public DetalleServicio?: LineaDetalle[];
    public OtrosCargos?: OtrosCargos;
    public ResumenFactura = new ResumenFactura();
    public InformacionReferencia = new InformacionReferencia();
    public Otros?: Otros;
}
