<div class="container">

  <div class="loader" *ngIf="loading"></div>

  <div *ngIf="!loading" class="animate__animated animate__fadeIn">
    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">
      <div class="container-fluid">
        <form class="d-flex">
          <div class="input-group me-2" *ngIf="periodConfigured">
            <input type="text" class="form-control" id="periodStartDate" name="periodStartDate" [(ngModel)]="periodConfiguration.startDate" [disabled]="periodConfigured" title="Inicio Periodo">
          </div>
          <div class="input-group me-2" *ngIf="periodConfigured">
            <input type="text" class="form-control" id="periodEndDate" name="periodEndDate" [(ngModel)]="periodConfiguration.endDate" [disabled]="periodConfigured" title="Final Periodo">
          </div>
        </form>
        <div class="pull-right">
          <ng-container  *ngIf="periodConfigured">
            <button type="button" class="btn btn-danger me-1" *mtHasPermission="['ADMIN_ACCOUNT']" data-bs-toggle="modal" data-bs-target="#revertLastPeriodConfirmation" title="Revertir Periodo">
              <span class="fa fa-delete-left fa-lg "></span>
            </button>
          </ng-container>
          <ng-container  *ngIf="periodConfigured">
            <button class="btn btn-primary" *mtHasPermission="['ADMIN_ACCOUNT']" data-bs-toggle="modal" data-bs-target="#closeFullPeriodConfirmation" title="Cerrar Periodo">Cerrar</button>

          </ng-container>
        </div>
      </div>
    </nav>

    <div>
      <div class="alert alert-info" *ngIf="!periodConfigured">
        El periodo aún no ha sido inicializado, seleccione el año y haga click en inicializar para iniciar un nuevo periodo a partir del año seleccionado.
      </div>
      <div class="row" *ngIf="!periodConfigured">
        <div class="col"></div>
        <div class="col">
          <select class="form-select" id="yearInit" [(ngModel)]="systemAccountingPeriodId" title="Año">
            <option *ngFor="let systemAccountingPeriod of systemAccountingPeriods" [value]="systemAccountingPeriod.id" >{{systemAccountingPeriod.name}}</option>
          </select>
        </div>
        <div class="col pull-right">
          <button class="btn btn-primary" *mtHasPermission="['ADMIN_ACCOUNT']" (click)="initPeriod()">Inicializar</button>
        </div>
      </div>
      <ng-container *ngIf="periodConfigured">
        <h4  class="mb-3 mt-3">Generar Asientos Contables</h4>
        <hr class="mb-4">
        <div class="row mb-3">
          <div class="form-group col-md-6">
            <label class="form-label" for="month">Mes</label>
            <select class="form-select" id="month" [(ngModel)]="month" title="Mes">
              <option value="January" selected>Enero</option>
              <option value="February" selected>Febrero</option>
              <option value="March" selected>Marzo</option>
              <option value="April" selected>Abril</option>
              <option value="May" selected>Mayo</option>
              <option value="June" selected>Junio</option>
              <option value="July" selected>Julio</option>
              <option value="August" selected>Agosto</option>
              <option value="September" selected>Setiembre</option>
              <option value="October" selected>Octubre</option>
              <option value="November" selected>Noviembre</option>
              <option value="December" selected>Diciembre</option>
            </select>
          </div>
          <div class="form-group col-md-6">
            <label class="form-label" for="year">Año</label>
            <select class="form-select" id="year" [(ngModel)]="+year" title="Año">
              <option value="{{+year-3}}" >{{+year-3}}</option>
              <option value="{{+year-2}}" >{{+year-2}}</option>
              <option value="{{+year-1}}" >{{+year-1}}</option>
              <option value="{{+year}}" selected>{{+year}}</option>
              <option value="{{+year+1}}" >{{+year+1}}</option>
              <option value="{{+year+2}}" >{{+year+2}}</option>
              <option value="{{+year+3}}" >{{+year+3}}</option>
            </select>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 text-end">
            <button class="btn btn-primary" (click)="generateAccountingEntries()">Generar</button>
          </div>
        </div>

        <h4  class="mb-3 mt-3">Meses generados</h4>
        <hr class="mb-4">

        <mt-pagination (loadPage)="getPageEvent($event)" [page]="accountingPeriods" [showPages]="false"></mt-pagination>
        <table *ngIf="accountingPeriods.metadata.pages > 0" class="table table-hover">
          <thead>
          <tr>
            <th>
              Mes
            </th>
            <th>
              Estado
            </th>
            <th>

            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let accountingPeriod of accountingPeriods.content; let i = index;">
            <td class="align-middle">{{accountingPeriod.startDate.toString() | date:'MMMM, y'}}</td>
            <td class="align-middle">{{accountingPeriod.closed? 'Cerrado' : 'Abierto'}}</td>
            <td class="text-end  align-middle">
              <button class="btn  pull-right btn-info me-2"
                      (click)="analyzeAccountingEntries(accountingPeriod.startDate, true)" data-bs-toggle="modal" data-bs-target="#analyzeModal" title="Analizar Periodo">
                <span class="fa fa-user-secret fa-lg"></span>
              </button>
              <ng-container *mtHasPermission="['ADMIN_ACCOUNT', 'ADMIN_ASSISTANT']">
                <button class="btn pull-right btn-danger me-2" *ngIf="accountingPeriod.closed && i == 0" data-bs-toggle="modal" data-bs-target="#openPeriodConfirmation"
                        (click)="selectPeriod(accountingPeriod)" title="Reabrir Mes">
                  <span class="fa fa-lock-open fa-lg"></span>
                </button>
              </ng-container>
              <ng-container *mtHasPermission="['ADMIN_ACCOUNT', 'ADMIN_ASSISTANT']">
                <button class="btn pull-right btn-primary me-2" *ngIf="!accountingPeriod.closed" data-bs-toggle="modal" data-bs-target="#closePeriodConfirmation"
                        (click)="selectPeriod(accountingPeriod)" title="Cerrar Mes">
                  <span class="fa fa-lock fa-lg"></span>
                </button>
              </ng-container>
              <button class="btn  pull-right btn-danger me-2" *ngIf="!accountingPeriod.closed && i == 0"
                      (click)="removeAccountingEntries(accountingPeriod.startDate)" title="Remover Asientos">
                <span class="fa fa-trash fa-lg"></span>
              </button>
              <button class="btn  pull-right btn-secondary me-2"
                      (click)="exportAccountingEntries(accountingPeriod.startDate)" title="Descargar Asientos">
                <span class="fa fa-download fa-lg"></span>
              </button>
              <mt-audit-button [auditableEntity]="accountingPeriod"
                               [(modifiedByActor)]="auditModal.modifiedByActor"
                               [(createdByActor)]="auditModal.createdByActor"
                               [(modifiedDate)]="auditModal.modified"
                               [(createdDate)]="auditModal.created"></mt-audit-button>
            </td>
          </tr>
          </tbody>
        </table>
        <div *ngIf="accountingPeriods.metadata.pages == 0" class="p-3">
          No hay registros
        </div>
      </ng-container>
      </div>
  </div>
</div>

<!-- Modal -->
<mt-audit-modal #auditModal></mt-audit-modal>
<div class="modal fade" id="closePeriodConfirmation" tabindex="-1" role="dialog"
     aria-labelledby="removeConfirmationLabel">
  <div class="modal-dialog  modal-dialog-centered modal-dialog-scrollable modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Confirmar</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
          aria-hidden="true"></span></button>
      </div>
      <div class="modal-body">
        <p *ngIf="(periodAnalyzeValue.patrimony + periodAnalyzeValue.passives - periodAnalyzeValue.actives) == 0">
          Al cerrar el periodo este no podrá ser modificado. Desea cerrar el periodo <strong>{{selectedPeriod.startDate.toString() | date:'MMMM, y'}}</strong>?
        </p>
        <p *ngIf="(periodAnalyzeValue.patrimony + periodAnalyzeValue.passives - periodAnalyzeValue.actives) != 0" class="alert alert-danger">
          No recomienda cerrar el periodo <strong>{{selectedPeriod.startDate.toString() | date:'MMMM, y'}}</strong> la diferencia debe ser igual a 0.
        </p>
        <p *ngIf="(periodAnalyzeValue.patrimony + periodAnalyzeValue.passives - periodAnalyzeValue.actives) != 0">
          Para identificar el día a partir del cual se da la diferencia, se recomienda usar la funcionalidad de Analizar Periodo. Una vez identificado y corregido el origen de la diferencia, remueva los asientos del periodo y vuelva a generarlos.
        </p>
        <table class="table table-striped table-bordered table-hover">
          <thead>
          <tr>
            <th>Fecha</th>
            <th>Activos</th>
            <th>Pasivos</th>
            <th>Capital</th>
            <th>Diferencia</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              {{periodAnalyzeValue.date.toString() | date:'dd/MM/yyyy'}}
            </td>
            <td>
              {{periodAnalyzeValue.actives | currency:'CRC'}}
            </td>
            <td>
              {{periodAnalyzeValue.passives | currency:'CRC'}}
            </td>
            <td>
              {{periodAnalyzeValue.patrimony | currency:'CRC'}}
            </td>
            <td>
              {{(periodAnalyzeValue.patrimony + periodAnalyzeValue.passives - periodAnalyzeValue.actives) | currency:'CRC'}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cancelar</button>
        <!--button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="closePeriod()" *ngIf="(periodAnalyzeValue.patrimony + periodAnalyzeValue.passives - periodAnalyzeValue.actives) == 0">Aceptar</button-->
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="closePeriod()" >Aceptar</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="closeFullPeriodConfirmation" tabindex="-1" role="dialog"
     aria-labelledby="removeConfirmationLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Confirmar</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
          aria-hidden="true"></span></button>
      </div>
      <div class="modal-body">
        <p>Al hacer el cierre final del periodo este no podrá ser modificado. Desea hacer el cierre final del periodo de <strong>{{periodConfiguration.startDate.toString() | date:'MMMM y'}}</strong> a <strong>{{periodConfiguration.endDate.toString() | date:'MMMM y'}}</strong>?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="closeFullPeriod()">Aceptar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="revertLastPeriodConfirmation" tabindex="-1" role="dialog"
     aria-labelledby="removeConfirmationLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Confirmar</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
          aria-hidden="true"></span></button>
      </div>
      <div class="modal-body">
        <p>Para poder revertir el periodo primero debe revertir y eliminar cualquier mes procesado del periodo actual.</p>
        <p>Desea revertir el periodo: <strong>{{periodConfiguration.startDate.toString() | date:'MMMM y'}}</strong> a <strong>{{periodConfiguration.endDate.toString() | date:'MMMM y'}}</strong>?</p>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="revertLastPeriod()">Aceptar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="openPeriodConfirmation" tabindex="-1" role="dialog"
     aria-labelledby="removeConfirmationLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Confirmar</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <p>Desea reabrir el periodo <strong>{{selectedPeriod.startDate.toString() | date:'MMMM, y'}}</strong>?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="reopenPeriod()">Aceptar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="analyzeModal" tabindex="-1" role="dialog"
     aria-labelledby="removeConfirmationLabel">
  <div class="modal-dialog  modal-dialog-centered modal-dialog-scrollable modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Analizar Periodo</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
          aria-hidden="true"></span></button>
      </div>
      <div class="modal-body">
        <table class="table table-striped table-bordered table-hover">
          <thead>
          <tr>
            <th>Fecha</th>
            <th>Activos</th>
            <th>Pasivos</th>
            <th>Capital</th>
            <th>Diferencia</th>
          </tr>
          </thead>
          <tbody>
            <tr *ngFor="let periodAnalyzeValue of periodAnalyzeValues">
              <td>
                {{periodAnalyzeValue.date.toString() | date:'dd/MM/yyyy'}}
              </td>
              <td>
                {{periodAnalyzeValue.actives | currency:'CRC'}}
              </td>
              <td>
                {{periodAnalyzeValue.passives | currency:'CRC'}}
              </td>
              <td>
                {{periodAnalyzeValue.patrimony | currency:'CRC'}}
              </td>
              <td>
                {{(periodAnalyzeValue.patrimony + periodAnalyzeValue.passives - periodAnalyzeValue.actives) | currency:'CRC'}}
              </td>
            </tr>
          </tbody>
        </table>

      </div>
      <div class="modal-footer">
        <button class="btn  pull-right btn-info me-2"
                (click)="analyzeAccountingEntries(currentDate, false)" title="Analizar Siguiente Periodo">
          Siguiente
        </button>
        <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cerrar</button>
      </div>
    </div>
  </div>
</div>

