<div>

  <div class="loader" *ngIf="loading"></div>

  <div  class="animate__animated animate__fadeIn">
    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">

      <div class="container-fluid">
        <form class="d-flex">
          <div class="input-group me-2">
            <input class="form-control" placeholder="aaaa-mm-dd"
                   name="from" [(ngModel)]="from" ngbDatepicker #d1="ngbDatepicker" id="from" (ngModelChange)="fromDateChanged($event)" title="Desde">
              <button class="btn btn-outline-secondary" (click)="d1.toggle()" type="button">
                <span class=" fa fa-calendar"></span>
              </button>
          </div>

          <div class="input-group me-2">
            <input class="form-control" placeholder="aaaa-mm-dd"
                   name="to" [(ngModel)]="to" ngbDatepicker #d2="ngbDatepicker" id="to" (ngModelChange)="toDateChanged($event)" title="Hasta">
              <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
                <span class=" fa fa-calendar"></span>
              </button>
          </div>

          <div class="input-group me-2">
            <input class="form-control me-2" id="text" name="text" [(ngModel)]="text" (change)="searchTermChanged(text)" title="Buscar..." placeholder="Buscar...">
          </div>

          <div class="dropdown me-4">
            <button type="button" class="btn btn-secondary dropdown-toggle" id="dropdownMenuOffset" data-bs-toggle="dropdown" aria-expanded="false" title="Filtros">
              <span class="fa fa-filter"></span>
            </button>
            <div class="dropdown-menu dropdown-large">
              <form class="px-4 py-3">

                <div class="mb-3">
                  <select class="form-select me-2" id="status" name="status" [(ngModel)]="categorized" (ngModelChange)="statusChanged(categorized)" title="Estado">
                    <option value="false" selected>Pendiente</option>
                    <option value="true">Categorizado</option>
                  </select>
                </div>

                <div class="mb-3">
                  <select class="form-select me-2" id="type" name="type" [(ngModel)]="type" (ngModelChange)="typeChanged(type)" title="Tipo">
                    <option value="PURCHASE" selected>Compras</option>
                    <option value="SELL">Ventas</option>
                  </select>
                </div>


                <div class="mb-3">
                  <label for="percentage" class="form-label">Porcentaje Impuesto</label>
                  <input class="form-control me-2" id="percentage" name="percentage"  [(ngModel)]="percentage" (change)="percentageChanged(percentage)" title="Porcentaje Impuesto" placeholder="Porcentaje Impuesto...">
                </div>

                <div class="mb-3">
                  <div class="form-check">
                    <input class="form-check-input" name="exonerated" type="checkbox" value="" id="exonerated" (change)="exoneratedChanged(exonerated)" [(ngModel)]="exonerated">
                    <label class="form-check-label" for="exonerated">
                      Exonerado
                    </label>
                  </div>
                </div>
                <div class="mb-3">
                  <div class="form-check">
                    <input class="form-check-input" name="invalidOnly" type="checkbox" value="" id="invalidOnly" (change)="showInvalidOnlyChanged(showWithInvalidCategoryOnly)" [(ngModel)]="showWithInvalidCategoryOnly">
                    <label class="form-check-label" for="invalidOnly">
                      Categorización incorrecta
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>

        </form>
        <div class="pull-right">

          <button class="btn btn-secondary me-1" (click)="export('EXCEL')" title="Descargar Excel">
            <span class="fa fa-download fa-lg "></span>
          </button>

          <button class="btn btn-secondary me-1" routerLink="/documentos/ver" >
            Cerrar
          </button>
          <button class="btn btn-primary" (click)="validateEvent()" >
            Guardar
          </button>
        </div>
        </div>

    </nav>
    <div *ngIf="countSelections > 1" class="bg-light p-3 row mb-3 animate__animated animate__fadeIn">
        <div *ngIf="type === 'PURCHASE'" class="col-3">
          <ng-container *ngIf="codes.length > 1">
            <select class="form-select"  [(ngModel)]="commercialActivityCode">
              <option value="000000" >Todas</option>
              <option *ngFor="let code of codes" [value]="code.code" >{{code.description}}</option>
            </select>
          </ng-container>
          <ng-container *ngIf="codes.length == 1">
            {{codes[0].description}}
          </ng-container>
          <ng-container *ngIf="codes.length == 0">
            <span class="text-nowrap">Sin actividades</span>
          </ng-container>
        </div>
      <div *ngIf="type === 'SELL'" class="col-3">
        <ng-container *ngIf="codes.length > 1">
          <select class="form-select"  [(ngModel)]="commercialActivityCode">
            <option *ngFor="let code of codes" [value]="code.code" >{{code.description}}</option>
          </select>
        </ng-container>
        <ng-container *ngIf="codes.length == 1">
          {{codes[0].description}}
        </ng-container>
        <ng-container *ngIf="codes.length == 0">
          <span class="text-nowrap">Sin actividades</span>
        </ng-container>
      </div>
        <div class="col-3">
          <select class="form-select"  [(ngModel)]="categoryId">
            <option *ngFor="let category of categories" [value]="category.id" >{{category.description}}</option>
          </select>
        </div>
        <div class="text-end col-6">
          <button class="btn btn-primary" (click)="applySelectedEvent()" >
            Aplicar
          </button>
        </div>
    </div>

    <!--div class="alert alert-danger" role="alert">
      {{first8BussinessDays.toString()}}
    </div-->

    <div *ngIf="!loading">
      <div *ngIf="page.metadata.count > 0" class="text-center alert alert-info">
        Se encontraron {{page.content?.length}} registros
      </div>

      <cdk-virtual-scroll-viewport itemSize="50" class="document-list-viewport">
      <table *ngIf="page.metadata.count > 0" class="table table-hover" >
        <thead>
        <tr>
          <th scope="col">
            <input type="checkbox" [(ngModel)]="allSelected" (change)="changeSelection(allSelected)">
          </th>
          <th scope="col">
            Linea
          </th>
          <th scope="col">
            Detalles
          </th>
          <th scope="col" class="text-end">
            Descuento
          </th>
          <th scope="col" class="text-end">
            Exoneración
          </th>
          <th scope="col" class="text-end">
            Sub Total
          </th>
          <th scope="col" class="text-end">
            Impuestos
          </th>
          <th scope="col" class="text-end">
            Total
          </th>
          <th scope="col">
            Actividad
          </th>
          <th scope="col">
            Categoría
          </th>
          <th></th>
        </tr>
        </thead>
        <tbody>

          <tr *cdkVirtualFor="let line of page.content; let i = index">
            <td>
              <input *ngIf="!line.processed" type="checkbox" [(ngModel)]="line.selected" (change)="changeSingleSelection(line.selected)"/>
            </td>
            <td>
              <span [title]="line.sequence" >{{line.lineNumber}}</span>
              <span class="badge bg-danger ms-2" *ngIf="line.processed" title="Procesado en Cierre">P</span>
            </td>
            <td>
              <span [title]="line.detail + '-' + line.quantity + line.units" class="mt-large-text">{{line.detail}} - {{line.quantity}} {{line.units}}</span>
              <small class="text-muted mt-content-col" title="Código Cabys" (click)="loadProductDetails(line)" data-bs-toggle="modal" data-bs-target="#productDetails">{{line.cabysCode}}</small>
            </td>
            <td class="text-end">
              <ng-container *ngFor="let discount of line.discountsDetails" >
                <span>{{discount.MontoDescuento | currency:line.currency:'code'}}</span>
              </ng-container>
            </td>
            <td class="text-end">
                <span *ngIf="line.exonerationAmount">{{line.exonerationAmount | currency:line.currency:'code'}}</span>
            </td>
            <td class="text-end">
              {{line.subTotal | currency:line.currency:'code'}}
            </td>
            <td class="text-end">
              <ng-container *ngFor="let tax of line.taxesDetails" >
                <span>{{tax.Monto | currency:line.currency:'code'}}</span><br>
                <small class="text-muted" title="Porcentaje">{{tax.Tarifa}}%</small>
              </ng-container>
            </td>
            <td class="text-end">
              {{line.totalAmount | currency:line.currency:'code'}}
            </td>
            <td >
              <ng-container *ngIf="!line.state  || line.processed">
                <span [title]="getCodeDescription(line.commercialActivityCode)" class="mt-large-text  mt-column-action" (click)="line.state = true">{{getCodeDescription(line.commercialActivityCode)}}</span>
              </ng-container>
              <ng-container *ngIf="line.state  && !line.processed">
                <ng-container *ngIf="codes.length > 1 && type === 'PURCHASE'">
                  <select class="form-select mt-large-select" title="Actividad Comercial" [(ngModel)]="line.commercialActivityCode" (selectionchange)="line.state = false">
                    <option value="000000" >Todas las Actividades</option>
                    <option *ngFor="let code of codes" [value]="code.code" >({{code.code}}) {{code.description}}</option>
                  </select>
                </ng-container>
                <ng-container *ngIf="codes.length === 1 && type === 'PURCHASE'">
                  <select class="form-select mt-large-select" title="Actividad Comercial" [(ngModel)]="line.commercialActivityCode" (selectionchange)="line.state = false">
                    <option *ngFor="let code of codes" [value]="code.code" >({{code.code}}) {{code.description}}</option>
                  </select>
                </ng-container>
                <ng-container *ngIf="type === 'SELL' && line.allowCommercialActivityCodeEdit">
                  <select class="form-select mt-large-select " title="Actividad Comercial" [(ngModel)]="line.commercialActivityCode" (selectionchange)="line.state = false">
                    <option *ngFor="let code of codes" [value]="code.code" >({{code.code}}) {{code.description}}</option>
                  </select>
                </ng-container>
                <ng-container *ngIf="type === 'SELL' && !line.allowCommercialActivityCodeEdit">
                  <span [title]="getCodeDescription(line.commercialActivityCode)" class="mt-large-text  mt-column-action animate__animated animate__shakeX">{{getCodeDescription(line.commercialActivityCode)}}</span>
                  <input class="form-control" id="activity-code-input-{{i}}" [(ngModel)]="line.commercialActivityCode" hidden>
                </ng-container>
                <ng-container *ngIf="codes.length == 0">
                  <span class="text-nowrap">Sin actividades</span>
                </ng-container>
              </ng-container>
            </td>
            <td class="{{line.validCategory? '' : 'bg-warning'}}">
              <ng-container *ngIf="!line.state  || line.processed">
                <span [title]="getCategoryDescription(line.categoryId)" class="mt-large-text  mt-column-action"  (click)="line.state = true">{{getCategoryDescription(line.categoryId)}}</span>
              </ng-container>
              <ng-container *ngIf="line.state  && !line.processed">
                <select class="form-select mt-large-select " id="category-{{i}}"  title="Categoria" [(ngModel)]="line.categoryId" (select)="line.state = false">
                  <option *ngFor="let category of filter(categories, line)" [value]="category.id" >{{category.description}}</option>
                </select>
              </ng-container>
            </td>
            <td>
              <mt-audit-button [auditableEntity]="line"
                               [(modifiedByActor)]="auditModal.modifiedByActor" [(createdByActor)]="auditModal.createdByActor"
                               [(modifiedDate)]="auditModal.modified" [(createdDate)]="auditModal.created"></mt-audit-button>
            </td>
          </tr>
        </tbody>
      </table>
        <div *ngIf="page.metadata.count == 0 " class="p-3">
          No hay registros
        </div>
      </cdk-virtual-scroll-viewport>


    </div>

  </div>
</div>

<mt-audit-modal #auditModal ></mt-audit-modal>

<div class="modal fade" id="productDetails" tabindex="-1" role="dialog"
     aria-labelledby="loadFileLabel">
  <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Detalles código Cabys</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">

        <ul class="list-group" *ngIf="productSelected">
          <li class="list-group-item active">{{productSelected.descripcion}} {{productSelected.impuesto}}%</li>
        </ul>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-link" (click)="cleanProductDetails()" data-bs-dismiss="modal">Cerrar</button>
      </div>
    </div>
  </div>
</div>
