<div class="container">

  <div class="loader" *ngIf="loading"></div>

  <div *ngIf="!loading" class="animate__animated animate__fadeIn">
    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">
      <div class="container-fluid">
        <span class="navbar-text">
          Reporte de Facturas
        </span>
        <div class="pull-right">
          <button class="btn btn-primary" (click)="generateEvent()" [disabled]="providerId==''">
            Generar
          </button>
        </div>
      </div>
    </nav>

    <div>
      <div class="row pb-3">
        <div class="col-sm-3">
        <div class="input-group me-2">
          <input class="form-control" placeholder="aaaa-mm-dd"
                 name="from" [(ngModel)]="from" ngbDatepicker #d1="ngbDatepicker" id="from" (ngModelChange)="fromDateChanged($event)" title="Desde">
          <button class="btn btn-outline-secondary" (click)="d1.toggle()" type="button">
            <span class=" fa fa-calendar"></span>
          </button>
        </div>
        </div>
      <div class="col-sm-3">
        <div class="input-group me-2">
          <input class="form-control" placeholder="aaaa-mm-dd"
                 name="to" [(ngModel)]="to" ngbDatepicker #d2="ngbDatepicker" id="to" (ngModelChange)="toDateChanged($event)" title="Hasta">
          <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
            <span class=" fa fa-calendar"></span>
          </button>
        </div>
        </div>

        <div class="col-sm-3">
          <select class="form-select" id="provider" [(ngModel)]="providerId" (ngModelChange)="providerChanged(providerId)" title="Proveedor">
            <option value="" selected>Seleccionar Proveedor</option>
            <option *ngFor="let provider of providers" value="{{provider.id}}" >{{provider.name + '(' + provider.id + ')'}}</option>
          </select>
        </div>

      </div>
    </div>
  </div>
</div>


