import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HeaderConstants} from '../core/utils/headers.constants';
import {Category} from '../model/category';
import {OperationType} from '../model/document-line';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  SERVER_URL = '/api/categories';
  constructor(private httpClient: HttpClient) { }

  getCategories(description?: string, operationType?: OperationType, refresh?: false): Observable<Category[]> {
    let paramsValue = new HttpParams();

    if (description) {
      paramsValue = paramsValue.append('description', description);
    }
    if (operationType) {
      paramsValue = paramsValue.append('operationType', operationType);
    }
    const options = {params: paramsValue, headers: new HttpHeaders().set(HeaderConstants.CACHED, refresh ? 'false' : 'true')};
    return this.httpClient
      .get<Category[]>(`${this.SERVER_URL}`, options);
  }

}
