<div *ngIf="message" [ngClass]="animationClass" class="p-3 bg-{{message.type}} animate__animated fixed-bottom shadow-sm alert-float text-light font-weight-bolder text-center opacity-75">{{message.text}}

</div>


<!--ngb-toast *ngFor="let toast of toasts"
           [class]="'bg-{{message.type}} text-light toast-container position-fixed top-0 end-0 p-3'"
           [style]="'z-index: 1200'"
           [autohide]="autohide"
           (mouseenter)="autohide = false"
           (mouseleave)="autohide = true"
           [header]="toast.header"
           [delay]="toast.delay || 5000"
           (hide)="remove(toast)" >
  {{toast.text}}
</ngb-toast-->
