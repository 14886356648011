import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HeaderConstants} from '../utils/headers.constants';


@Injectable({
  providedIn: 'root'
})
export class UploadService {
  SERVER_URL = '/api/t/{tenant}/documents';
  constructor(private httpClient: HttpClient) { }

  public uploadDocument(formData: FormData): Observable<any> {
    return this.httpClient.post<any>(`${this.SERVER_URL}/file`, formData, {
      reportProgress: true,
      observe: 'events',
      headers: new HttpHeaders().set(HeaderConstants.IGNORE_CONTENT_TYPE, 'true')
    });
  }

  public uploadPayment(formData: FormData): Observable<any> {
    return this.httpClient.post<any>(`${this.SERVER_URL}/payments`, formData, {
      headers: new HttpHeaders().set(HeaderConstants.IGNORE_CONTENT_TYPE, 'true')
    });
  }

}
