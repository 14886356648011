import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {BaseService} from '../../core/service/base.service';
import {WorkdayLog} from './resource/workday-log';

@Injectable()
export class WorkdayLogService extends BaseService<WorkdayLog> {

  serviceUrl(): string {
    return `/api/t/{tenant}/workday-logs`;
  }

  constructor(protected http: HttpClient) {
    super(http);
  }
}
