<div class="container">

  <div class="loader" *ngIf="loading"></div>

  <div *ngIf="mode == 'EDIT' && !loading" class="animate__animated animate__fadeIn">
    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">
      <div class="container-fluid">
        <span class="navbar-text">
          Cliente
        </span>
        <div class="pull-right">
          <button class="btn btn-secondary me-1" (click)="cancelEvent()">
            Cerrar
          </button>
          <button class="btn btn-primary" (click)="saveEvent()">
            Guardar
          </button>
        </div>
      </div>
    </nav>

    <p class="alert alert-info row">Si modifica la identificación del cliente se actualizaran todos los documentos ingresados previamente manualmente, pero no así los generados a partir de facturas emitidas a hacienda.</p>

    <div class="row mb-3">
      <div class="col-md-6">
        <label class="form-label" for="name">Nombre/Razón Social</label>
        <input type="text" class="form-control" id="name" name="name" [(ngModel)]="model.name">

        <div *ngIf="formErrors.get('client.name')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('client.name') }}
        </div>
      </div>

      <div class="form-group col-md-6">
        <label class="form-label" for="email">Correo Electrónico</label>
        <input type="email" class="form-control" id="email" name="email" [(ngModel)]="model.email" >
        <div *ngIf="formErrors.get('client.email')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('client.email') }}
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="form-group col-md-6">
        <label class="form-label" for="identificationType">Tipo Identificación</label>
        <select id="identificationType" class="form-select" name="identificationType" [(ngModel)]="model.identificationType" >
          <option *ngFor="let identificationTypeOption of identificationTypes" value="{{identificationTypeOption.code}}">{{identificationTypeOption.description}}</option>
          <option value="-1">Extranjero</option>
        </select>

        <div *ngIf="formErrors.get('client.identificationType')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('client.identificationType') }}
        </div>
      </div>

      <div class="form-group col-md-6">
        <label class="form-label" for="identification">Identificación</label>
        <input type="text" class="form-control" id="identification" name="identification" [(ngModel)]="model.identification" >
        <div *ngIf="formErrors.get('client.identification')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('client.identification') }}
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="form-group col-md-12">
        <label class="form-label" for="commercialName">Nombre Comercial</label>
        <input type="text" class="form-control" id="commercialName" name="commercialName"
               [(ngModel)]="model.commercialName" >
        <div *ngIf="formErrors.get('client.commercialName')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('client.commercialName') }}
        </div>
      </div>
    </div>


    <div class="row mb-3">
      <div class="form-group col-md-2">
        <label class="form-label" for="phoneCountryCode">Código de Pais</label>
        <input type="text" class="form-control" id="phoneCountryCode" name="phoneCountryCode" [(ngModel)]="model.phoneCountryCode" >
        <div *ngIf="formErrors.get('client.phoneCountryCode')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('client.phoneCountryCode') }}
        </div>
      </div>

      <div class="form-group col-md-4">
        <label class="form-label" for="phone">Teléfono</label>
        <input type="tel" class="form-control" id="phone" name="phone" [(ngModel)]="model.phone" >
        <div *ngIf="formErrors.get('client.phone')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('client.phone') }}
        </div>
      </div>
      <div class="form-group col-md-2">
        <label class="form-label" for="faxCountryCode">Código de Pais</label>
        <input type="text" class="form-control" id="faxCountryCode" name="faxCountryCode"
               [(ngModel)]="model.faxCountryCode" >
        <div *ngIf="formErrors.get('client.faxCountryCode')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('client.faxCountryCode') }}
        </div>
      </div>

      <div class="form-group col-md-4">
        <label class="form-label" for="fax">Fax</label>
        <input type="text" class="form-control" id="fax" name="fax" [(ngModel)]="model.fax" >
        <div *ngIf="formErrors.get('client.fax')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('client.fax') }}
        </div>
      </div>

    </div>


    <h4  class="mb-3">Ubicación</h4>
    <hr class="mb-4">

    <div class="row mb-3">

      <div class="form-group col-md-3">
        <label class="form-label" for="locationLevel1">Provincia</label>
        <select id="locationLevel1" class="form-select" name="locationLevel1" [(ngModel)]="model.locationLevel1" (ngModelChange)="onLocationChangeEvent($event,'location1')" >
          <option></option>
          <option *ngFor="let locationLevelOption of location1" value="{{locationLevelOption.code}}">{{locationLevelOption.description}}</option>
        </select>

        <div *ngIf="formErrors.get('client.locationLevel1')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('client.locationLevel1') }}
        </div>
      </div>

      <div class="form-group col-md-3">
        <label class="form-label" for="locationLevel2">Cantón</label>
        <select id="locationLevel2" class="form-select" name="locationLevel2" [(ngModel)]="model.locationLevel2" (ngModelChange)="onLocationChangeEvent($event,'location2')">
          <option></option>
          <option *ngFor="let locationLevelOption of location2" value="{{locationLevelOption.code}}">{{locationLevelOption.description}}</option>
        </select>
        <div *ngIf="formErrors.get('client.locationLevel2')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('client.locationLevel2') }}
        </div>
      </div>

      <div class="form-group col-md-3">
        <label class="form-label" for="locationLevel3">Distrito</label>
        <select id="locationLevel3" class="form-select" name="locationLevel3" [(ngModel)]="model.locationLevel3" (ngModelChange)="onLocationChangeEvent($event,'location3')">
          <option></option>
          <option *ngFor="let locationLevelOption of location3" value="{{locationLevelOption.code}}">{{locationLevelOption.description}}</option>
        </select>
        <div *ngIf="formErrors.get('client.locationLevel3')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('client.locationLevel3') }}
        </div>
      </div>

      <div class="form-group col-md-3">
        <label class="form-label" for="locationLevel4">Barrio</label>
        <select id="locationLevel4" class="form-select" name="locationLevel4" [(ngModel)]="model.locationLevel4" >
          <option></option>
          <option *ngFor="let locationLevelOption of location4" value="{{locationLevelOption.code}}">{{locationLevelOption.description}}</option>
        </select>
        <div *ngIf="formErrors.get('client.locationLevel4')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('client.locationLevel4') }}
        </div>
      </div>

    </div>

    <div class="row mb-3">
      <div class="form-group">
        <label class="form-label" for="location">Otras Señas</label>
        <textarea type="text" class="form-control" id="location" name="location" [(ngModel)]="model.location" rows="3"></textarea>
        <div *ngIf="formErrors.get('client.location')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('client.location') }}
        </div>
      </div>
    </div>

    <h4  class="mb-3">Opciones avanzadas</h4>
    <hr class="mb-4">

    <div class="row mb-3">
      <div class="form-group col-md-6">
        <label class="form-label" for="sellCondition">Condición de venta</label>
        <select class="form-select" id="sellCondition" [(ngModel)]="model.configuration.defaultSellCondition">
          <option *ngFor="let code of sellConditions" value="{{code.code}}" >{{code.description}}</option>
        </select>
        <div *ngIf="formErrors.get('client.configuration.defaultSellCondition')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('client.configuration.defaultSellCondition') }}
        </div>
      </div>

      <div class="form-group col-md-6">
        <label class="form-label" for="paymentMethod" >Método de pago</label>
        <select class="form-select" id="paymentMethod" [(ngModel)]="model.configuration.defaultPaymentMethod">
          <option *ngFor="let code of paymentMethods" value="{{code.code}}" >{{code.description}}</option>
        </select>
        <div *ngIf="formErrors.get('client.configuration.defaultPaymentMethod')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('client.configuration.defaultPaymentMethod') }}
        </div>
      </div>

    </div>


  </div>

  <div *ngIf="mode == 'LIST' && !loading" class="animate__animated animate__fadeIn">

    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">
      <div class="container-fluid">
        <span class="navbar-text">
          Clientes
        </span>
        <div class="pull-right">
          <button class="btn btn-secondary me-1" (click)="export()" title="Exportar CSV">
            <span class="fa fa-download fa-lg "></span>
          </button>
          <button class="btn  btn-secondary me-1" (click)="refreshEvent()" title="Refrescar">
            <span class="fa fa-sync fa-lg"></span>
          </button>
          <button class="btn  btn-primary" (click)="createEvent()" title="Agregar">
            Agregar
          </button>
        </div>
      </div>
    </nav>

    <mt-pagination (loadPage)="getPageEvent($event)" [page]="page"></mt-pagination>

    <table *ngIf="page.metadata.pages > 0" class="table table-hover">
      <tbody>
      <tr *ngFor="let client of page.content">
        <td (click)="editEvent(client)" class="mt-content-col">
          <strong>{{client.name}}</strong><br>
          <span *ngIf="client.email" class="text-muted">{{client.email}}</span><br *ngIf="client.email">
          <small *ngIf="client.identification" class="text-muted">{{client.identification}}</small>
        </td>
        <td class="mt-actions-col">
          <button class="btn btn-link btn-lg pull-right" data-bs-toggle="modal" data-bs-target="#removeCategoryConfirmation"
                  (click)="selectEvent(client)" title="Borrar">
            <span class="fa fa-trash-alt fa-lg"></span>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
    <div *ngIf="page.metadata.pages == 0">
      No hay registros
    </div>

  </div>

  <!-- Modal -->
  <div class="modal fade" id="removeCategoryConfirmation" tabindex="-1" role="dialog"
       aria-labelledby="removeConfirmationLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirmar</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Desea eliminar el registro?</p>
          <p>No se eliminará ningún documento cargado al sistema asociado a este cliente.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="removeEvent(model)">Borrar</button>
        </div>
      </div>
    </div>
  </div>

</div>


