import {Component, forwardRef, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {OffsetDateTime, ZonedDateTime} from '@js-joda/core';
import {Actor} from '../../model/actor';

@Component({
  selector: 'mt-audit-modal',
  templateUrl: './audit-modal.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AuditModalComponent),
    multi: true
  }]
})
export class AuditModalComponent  {

  @Input() public createdByActor: Actor = new Actor();
  @Input() public modifiedByActor: Actor = new Actor();

  @Input() public modified = ZonedDateTime.now();

  @Input() public created = ZonedDateTime.now();

}
