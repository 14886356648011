import {EntityModel} from './entity.model';
import {Tenant} from './tenant';
import {Configuration} from '../../model/client';

export class User extends EntityModel {
  constructor(public id?: number,
              public name?: string,
              public lastName?: string,
              public email?: string,
              public password?: string,
              public active?: boolean,
              public currentTenantPrivileges?: Privilege[],
              public privilegeNames?: PrivilegeName[]) {
    super(id);
  }
}

export class Privilege {
  constructor(public privilegeName?: PrivilegeName) {
  }
}

export enum PrivilegeName {
  ADMIN_USERS = 'ADMIN_USERS',
  ADMIN_CLIENTS = 'ADMIN_CLIENTS',
  ADMIN_DOCUMENTS = 'ADMIN_DOCUMENTS',
  ADMIN_ACCOUNT = 'ADMIN_ACCOUNT',
  ADMIN_ASSISTANT = 'ADMIN_ASSISTANT',
  VIEW_REPORTS = 'VIEW_REPORTS',
  VIEW_DOCUMENTS = 'VIEW_DOCUMENTS',
  ADMIN_BUDGET_FORMS = 'ADMIN_BUDGET_FORMS',
  VIEW_BUDGET_FORMS = 'VIEW_BUDGET_FORMS',
  ADMIN_SYSTEM = 'ADMIN_SYSTEM',
  ADMIN_RRHH = 'ADMIN_RRHH',
}

export class UserFullInfo {
  constructor(public user?: User,
              public tenants?: Tenant[],
              public privilegeNames?: PrivilegeName[],
              public configuration?: Configuration) {
  }
}
