import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {ZonedDateTime} from '@js-joda/core';
import {ActorService} from '../../service/actor.service';
import {Actor} from '../../model/actor';
import {AuditableModel} from '../../model/auditable.model';

@Component({
  selector: 'mt-audit-button',
  templateUrl: './audit-button.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AuditButtonComponent),
    multi: true
  }]
})
export class AuditButtonComponent {


  @Output() public createdByActorChange = new EventEmitter<Actor>();
  @Input() public createdByActor = new Actor();

  @Output() public modifiedByActorChange = new EventEmitter<Actor>();
  @Input() public modifiedByActor = new Actor();

  @Output() public modifiedDateChange = new EventEmitter<ZonedDateTime>();
  @Input() public modifiedDate = ZonedDateTime.now();

  @Output() public createdDateChange = new EventEmitter<ZonedDateTime>();
  @Input() public createdDate = ZonedDateTime.now();

  @Input() public auditableEntity: AuditableModel = new AuditableModel(0);

  constructor(protected actorService: ActorService) {
  }

  showDialogEvent(): void {
    this.actorService.getActorInfo(this.auditableEntity.createdBy).subscribe(actor => {
      this.createdByActorChange.emit(actor as Actor);
      this.createdDateChange.emit(this.auditableEntity.created);
    });
    this.actorService.getActorInfo(this.auditableEntity.modifiedBy).subscribe(actor => {
      this.modifiedByActorChange.emit(actor as Actor);
      this.modifiedDateChange.emit(this.auditableEntity.modified);
    });
  }
}
