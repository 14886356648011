import {Injectable} from '@angular/core';

import {BaseService} from '../core/service/base.service';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BudgetForm} from '../model/budget-form';
import {Observable} from 'rxjs';
import {HeaderConstants} from '../core/utils/headers.constants';
import {FileResource} from '../model/file';
import {Provider} from '../model/provider';
import {LocalDate} from '@js-joda/core';

@Injectable()
export class BudgetFormService extends BaseService<BudgetForm> {

  serviceUrl(): string {
    return `/api/t/{tenant}/budget-forms`;
  }

  constructor(protected http: HttpClient) {
    super(http);
  }

  apply(id: number): Observable<any> {
    return this.http.post(`${this.serviceUrl()}/${id}/apply`, {});
  }

  revert(id: number): Observable<any> {
    return this.http.post(`${this.serviceUrl()}/${id}/revert`, {});
  }

  getAttachments(id: number): Observable<FileResource[]> {
    return this.http
      .get<FileResource[]>(`${this.serviceUrl()}/${id}/attachment`);
  }
  getProviders(monthParam?: string, yearParam?: string, fromParam?: LocalDate, toParam?: LocalDate): Observable<Provider[]> {
    return this.http.post<Provider[]>(`${this.serviceUrl()}/providers`, {month : monthParam, year: yearParam, from: fromParam, to: toParam});
  }

  deleteAttachment(id: number, attachmentId: number): Observable<any> {
    return this.http
      .delete(`${this.serviceUrl()}/${id}/attachment/${attachmentId}`);
  }

  uploadAttachments(formData: FormData, budgetFormId: number): Observable<any> {
    return this.http.post<any>(`${this.serviceUrl()}/${budgetFormId}/attachment`, formData, {
      headers: new HttpHeaders().set(HeaderConstants.IGNORE_CONTENT_TYPE, 'true')
    });
  }
  downloadAttachment(id: number, attachmentId: number): Observable<any> {
    return this.http.get(`${this.serviceUrl()}/${id}/attachment/${attachmentId}`, {responseType: 'blob'});
  }
}
