import {Injectable} from '@angular/core';


import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Tenant} from '../model/tenant';

@Injectable()
export class ActorService {

  constructor(protected http: HttpClient) {
  }

  getActorInfo(reference: number): Observable<string> {
    const paramsValue = new HttpParams().append('reference', reference);
    const options = {params: paramsValue};
    return this.http
      .get<string>(`/api/actors`, options);
  }
}
