import {Filter} from '../core/filter/filter';
import {DocumentStatus, DocumentType} from '../model/document';
import {OperationType} from '../model/document-line';
import {LocalDate} from '@js-joda/core';

export class DocumentFilter extends Filter {
  public text?: string;
  public status?: DocumentStatus;
  public operationType?: OperationType;
  public from?: LocalDate;
  public to?: LocalDate;
  public sellConditions?: string[];
  public accountAssigned?: boolean;
  public budgetSelected?: boolean;
  public referenceId?: number;
  public documentType?: DocumentType;
  public pending?: number;
  public issuerId?: string;
  public receiverId?: string;
}
