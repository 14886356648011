<div>

  <div class="loader" *ngIf="loading"></div>

  <div class="animate__animated animate__fadeIn">
    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">

      <div class="container-fluid">
        <form class="d-flex">
          <div class="input-group me-2">
            <input class="form-control" placeholder="aaaa-mm-dd"
                   name="from" [(ngModel)]="from" ngbDatepicker #d1="ngbDatepicker" id="from"
                   (ngModelChange)="fromDateChanged($event)" title="Desde">
            <button class="btn btn-outline-secondary" (click)="d1.toggle()" type="button">
              <span class=" fa fa-calendar"></span>
            </button>
          </div>

          <div class="input-group me-2">
            <input class="form-control" placeholder="aaaa-mm-dd"
                   name="to" [(ngModel)]="to" ngbDatepicker #d2="ngbDatepicker" id="to"
                   (ngModelChange)="toDateChanged($event)" title="Hasta">
            <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
              <span class=" fa fa-calendar"></span>
            </button>
          </div>

          <div class="input-group me-2">
            <input class="form-control me-2 " id="text" name="text" [(ngModel)]="text"
                   (change)="searchTermChanged(text)" title="Buscar..." placeholder="Buscar...">
          </div>

          <div class="dropdown me-4">
            <button type="button" class="btn btn-secondary dropdown-toggle" id="dropdownMenuOffset"
                    data-bs-toggle="dropdown" aria-expanded="false" title="Filtros">
              <span class="fa fa-filter"></span>
            </button>
            <div class="dropdown-menu dropdown-large">
              <form class="px-4 py-3">

                <div class="mb-3">
                  <label for="status" class="form-label">Estado</label>
                  <select class="form-select me-2" id="status" name="status" [(ngModel)]="budgetConfigured"
                          (ngModelChange)="statusChanged(budgetConfigured)" title="Estado">
                    <option value="false" selected>Pendiente</option>
                    <option value="true">Presupuestado</option>
                  </select>
                </div>

                <div class="mb-3">
                  <label for="type" class="form-label">Compras o Ventas</label>
                  <select class="form-select me-2" id="type" name="type" [(ngModel)]="type"
                          (ngModelChange)="typeChanged(type)" title="Tipo">
                    <option value="PURCHASE" selected>Compras</option>
                    <option value="SELL">Ventas</option>
                  </select>
                </div>

                <div class="mb-3">
                  <label for="percentage" class="form-label">Porcentaje</label>
                  <input class="form-control me-2" id="percentage" name="percentage" [(ngModel)]="percentage"
                         (change)="percentageChanged(percentage)" title="Porcentaje Impuesto..."
                         placeholder="Porcentaje Impuesto">
                </div>

              </form>
            </div>
          </div>

        </form>
        <div class="pull-right">
          <button class="btn btn-secondary me-1" (click)="export('EXCEL')" title="Descargar EXCEL">
            <span class="fa fa-download fa-lg "></span>
          </button>

          <button class="btn btn-secondary me-1" routerLink="/documentos/ver">
            Cerrar
          </button>
          <button class="btn btn-primary" (click)="configureBudgetEvent()">
            Guardar
          </button>
        </div>
      </div>

    </nav>
    <div *ngIf="!loading">
      <div *ngIf="countSelections > 1" class="bg-light p-3 row mb-3 animate__animated animate__fadeIn">
        <div class="col-2">
          <select class="form-select" [(ngModel)]="budgetAccountId">
            <option value=""></option>
            <option *ngFor="let account of accounts" [value]="account.id">{{account.description}} ({{account.code}})
            </option>
          </select>
        </div>
        <div class="col-2">
          <select class="form-select" [(ngModel)]="bankAccountId">
            <option value=""></option>
            <option *ngFor="let bankAccount of bankAccounts" [value]="bankAccount.id" title="{{bankAccount.bank}}">{{bankAccount.number}} ({{bankAccount.foundingSourceDescription}})
            </option>
          </select>
        </div>
        <div class="text-end col-6">
          <button class="btn btn-primary" (click)="applySelectedEvent()">
            Aplicar
          </button>
        </div>
      </div>
      <div>
        <div *ngIf="page.metadata.count > 0" class="text-center alert alert-info">
          Se encontraron {{page.content?.length}} registros
        </div>
        <cdk-virtual-scroll-viewport itemSize="50" class="document-list-viewport">
          <table *ngIf="page.metadata.count > 0" class="table table-hover">
            <thead>
            <tr>
              <th scope="col">
                <input type="checkbox" [(ngModel)]="allSelected" (change)="changeSelection(allSelected)">
              </th>
              <th scope="col">
                Linea
              </th>
              <th scope="col">
                Detalles
              </th>
              <th>
                Condición Venta
              </th>
              <th>
                Medio Pago
              </th>
              <th scope="col" class="text-end">
                Sub Total
              </th>
              <th scope="col" class="text-end">
                Impuestos
              </th>
              <th scope="col" class="text-end">
                Total
              </th>
              <th scope="col">
                Cuenta Presupuestaria
              </th>
              <th scope="col">
                Cuenta Bancaria
              </th>
            </tr>
            </thead>
            <tbody>
            <ng-container *cdkVirtualFor="let line of page.content; let i = index">
              <tr>
                <td>
                  <input *ngIf="!line.processed" type="checkbox" [(ngModel)]="line.selected"
                         (change)="changeSingleSelection(line.selected)"/>
                </td>
                <td>
                  <span [title]="line.sequence">{{line.lineNumber}}</span>
                  <span class="badge bg-danger ms-2" *ngIf="line.processed" title="Procesado en Cierre">P</span>
                </td>
                <td>
                  <span [title]="line.detail + '-' + line.quantity + line.units" class="mt-large-text">{{line.detail}}
                    - {{line.quantity}} {{line.units}}</span>
                  <small class="text-muted mt-content-col" title="Código Cabys" (click)="loadProductDetails(line)"
                         data-bs-toggle="modal" data-bs-target="#productDetails">{{line.cabysCode}}</small>
                </td>
                <td>
                  <span *ngIf="line.sellCondition == '01'" class="mt-large-text">Contado</span>
                  <span *ngIf="line.sellCondition == '02'" class="mt-large-text">Crédito</span>
                  <span *ngIf="line.sellCondition == '03'" class="mt-large-text">Consignación</span>
                  <span *ngIf="line.sellCondition == '04'" class="mt-large-text">Apartado</span>
                  <span *ngIf="line.sellCondition == '05'"
                        class="mt-large-text">Arrendamiento con opción de compra</span>
                  <span *ngIf="line.sellCondition == '06'"
                        class="mt-large-text">Arrendamiento en función financiera</span>
                  <span *ngIf="line.sellCondition == '07'" class="mt-large-text">Cobro a favor de un tercero</span>
                  <span *ngIf="line.sellCondition == '08'"
                        class="mt-large-text">Servicios prestados al Estado a crédito</span>
                  <span *ngIf="line.sellCondition == '09'"
                        class="mt-large-text">Pago del servicios prestado al Estado</span>
                  <span *ngIf="line.sellCondition == '99'" class="mt-large-text">otros (se debe indicar la condición de la venta)</span>
                </td>
                <td>
                  <span *ngIf="line.paymentMethod == '01'" class="mt-large-text">Efectivo</span>
                  <span *ngIf="line.paymentMethod == '02'" class="mt-large-text">Tarjeta</span>
                  <span *ngIf="line.paymentMethod == '03'" class="mt-large-text">Cheque</span>
                  <span *ngIf="line.paymentMethod == '04'"
                        class="mt-large-text">Transferencia – depósito bancario</span>
                  <span *ngIf="line.paymentMethod == '05'" class="mt-large-text">Recaudado por terceros</span>
                  <span *ngIf="line.paymentMethod == '99'" class="mt-large-text">Otros (se debe indicar el medio de pago)</span>
                </td>
                <td class="text-end">
                  {{line.subTotal | currency:line.currency:'code'}}
                </td>
                <td class="text-end">
                  <ng-container *ngFor="let tax of line.taxesDetails">
                    <span title="{{tax.Tarifa}}%">{{tax.Monto | currency:line.currency:'code'}}</span> <br>
                    <small class="text-muted" title="Código y porcentaje">({{tax.CodigoTarifa}}) {{tax.Tarifa}}%</small>
                  </ng-container>
                </td>
                <td class="text-end">
                  {{line.totalAmount | currency:line.currency:'code'}}
                </td>
                <td>
                  <ng-container *ngIf="!line.state">
                    <span class="mt-large-text mt-column-action"
                          (click)="line.state = true">{{getAccountDescription(accounts, line.budgetAccountId)}}</span>
                  </ng-container>
                  <ng-container *ngIf="line.state">
                    <ng-select class="mt-large-ng-select custom"
                               name="activity-code-select-{{i}}" id="activity-code-select-{{i}}"
                               [(ngModel)]="line.budgetAccountId" [appendTo]="'body'" title="Cuenta">
                      <ng-option *ngFor="let account of accounts" [value]="account.id">{{account.description}} ({{account.code}})</ng-option>
                    </ng-select>
                  </ng-container>
                </td>
                <td>
                  <ng-container *ngIf="!line.state">
                    <span class="mt-large-text mt-column-action"
                          (click)="line.state = true">{{getBankAccountDescription(bankAccounts, line.bankAccountId)}}</span>
                  </ng-container>
                  <ng-container *ngIf="line.state">
                    <select class="form-select mt-large-select" name="activity-code-select-{{i}}"
                            id="activity-code-select-{{i}}" title="Cuenta Bancaria" [(ngModel)]="line.bankAccountId"
                            (selectionchange)="line.state = false">
                      <option *ngFor="let bankAccount of bankAccounts"
                              [value]="bankAccount.id" title="{{bankAccount.bank}}">{{bankAccount.number}} ({{bankAccount.foundingSourceDescription}})</option>
                    </select>
                  </ng-container>
                </td>
              </tr>
            </ng-container>
            </tbody>
          </table>
          <div *ngIf="page.metadata.count == 0 " class="p-3">
            No hay registros
          </div>
        </cdk-virtual-scroll-viewport>

      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="productDetails" tabindex="-1" role="dialog"
     aria-labelledby="loadFileLabel">
  <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Detalles código Cabys</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">

        <ul class="list-group" *ngIf="productSelected">
          <li class="list-group-item active">{{productSelected.descripcion}} {{productSelected.impuesto}}%</li>
        </ul>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-link" (click)="cleanProductDetails()" data-bs-dismiss="modal">Cerrar
        </button>
      </div>
    </div>
  </div>
</div>
