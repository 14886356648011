import {EntityModel} from '../../../core/model/entity.model';
import {LocalDate} from '@js-joda/core';
import {Periodicity} from './salary';

export class Payroll extends EntityModel {
  public date?: LocalDate;
  public employee?: number;
  public employeeName?: string;
  public identification?: string;
  public details?: PayrollDetails;
  public applied?: boolean;
}

class PayrollDetails {
  public salary?: number;
  public taxes?: number;
  public socialSecurity?: number;
  public salaryAfterDeductions?: number;
  public monthlySalary?: number;
  public workdayName?: string;
  public jobPositionName?: string;
  public periodicity?: Periodicity;
  public reportedHours?: number;
  public acceptedHours?: number;
  public extraHours?: number;
  public ordinaryHours?: number;
  public totalWorkableHours?: number;
  public totalFreeHours?: number;
  public nonPaidHours?: number;
  public vacationHours?: number;
  public medicalDisabilityHours?: number;
  public otherNonPaidHours?: number;
  public nonReportedHours?: number;
  public extraHoursAmount?: number;
  public holidayHours?: number;
  public embargoes?: number;
  public alimony?: number;
  public retentions?: number;
  public bonus?: number;
  public totalNonPaid?: number;
  public totalPeriodSalary?: number;
}
