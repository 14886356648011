<div class="container">

  <div class="loader" *ngIf="loading"></div>

  <div *ngIf="!loading" class="animate__animated animate__fadeIn">
    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">
      <div class="container-fluid">
        <span class="navbar-text">
          Reporte de Retenciones
        </span>
        <div class="pull-right">
          <button class="btn btn-primary" (click)="generateEvent()" [disabled]="providerId==''">
            Generar
          </button>
        </div>
      </div>
    </nav>

    <div>
      <div class="row pb-3">
        <div class="col-sm-3">
          <select class="form-select" id="month" [(ngModel)]="month" (ngModelChange)="monthChanged(month)" title="Trimestre">
            <option value="January" selected>Enero</option>
            <option value="February" selected>Febrero</option>
            <option value="March" selected>Marzo</option>
            <option value="April" selected>Abril</option>
            <option value="May" selected>Mayo</option>
            <option value="June" selected>Junio</option>
            <option value="July" selected>Julio</option>
            <option value="August" selected>Agosto</option>
            <option value="September" selected>Setiembre</option>
            <option value="October" selected>Octubre</option>
            <option value="November" selected>Noviembre</option>
            <option value="December" selected>Diciembre</option>

          </select>
        </div>
        <div class="col-sm-3">
          <select class="form-select" id="year" [(ngModel)]="year" (ngModelChange)="yearChanged(year)" title="Año">
            <option value="{{year-3}}" >{{year-3}}</option>
            <option value="{{year-2}}" >{{year-2}}</option>
            <option value="{{year-1}}" >{{year-1}}</option>
            <option value="{{year}}" selected>{{year}}</option>
            <option value="{{year+1}}" >{{year+1}}</option>
            <option value="{{year+2}}" >{{year+2}}</option>
            <option value="{{year+3}}" >{{year+3}}</option>
          </select>
        </div>

        <div class="col-sm-3">
          <select class="form-select" id="provider" [(ngModel)]="providerId" (ngModelChange)="providerChanged(providerId)" title="Proveedor">
            <option value="" selected>Seleccionar Proveedor</option>
            <option *ngFor="let provider of providers" value="{{provider.id}}" >{{provider.name + '(' + provider.id + ')'}}</option>
          </select>
        </div>

      </div>
    </div>
  </div>
</div>


