import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../core/component/base.component';
import {AlertService} from '../../../core/service/alert.service';
import {SecurityService} from '../../../core/service/security.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from '../../../core/service/message.service';
import {CodeService} from '../../../service/code.service';
import {DocumentLine, DocumentLineCategorized, OperationType} from '../../../model/document-line';
import {DocumentLineService} from '../../../service/document-line.service';
import {Page} from '../../../model/page';
import {DayOfWeek, LocalDate} from '@js-joda/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {DocumentLineFilter} from '../../../filter/document-line.filter';
import {DocumentStatus} from '../../../model/document';
import {HaciendaService} from '../../../service/hacienda.service';
import {Product} from '../../../model/product';
import {CategoryService} from '../../../service/category.service';
import {Category} from '../../../model/category';
import {Code} from '../../../model/code';
import {ClientService} from '../../../service/client.service';
import {Impuesto} from '../../../model/v4_3/impuesto';
import {Descuento} from '../../../model/v4_3/descuento';
import {LoggerService} from '../../../service/logger.service';

@Component({
  selector: 'app-validate',
  templateUrl: './validate.component.html',

})
export class ValidateComponent extends BaseComponent<DocumentLine> implements OnInit, OnDestroy {

  from: NgbDateStruct;
  to: NgbDateStruct;
  fromLocalDate: LocalDate;
  toLocalDate: LocalDate;
  categorized = false;
  type = OperationType.SELL;
  percentage = '';
  first8BussinessDays: LocalDate;
  allSelected = false;
  productSelected?: Product;
  categories: Category[] = [];
  codes: Code[] = [];
  commercialActivityCode = '';
  categoryId = 0;
  countSelections = 0;
  text = '';
  exonerated = false;
  showWithInvalidCategoryOnly = false;

  constructor(private documentLineService: DocumentLineService,
              private alertService: AlertService,
              public router: Router,
              protected securityService: SecurityService,
              protected messageService: MessageService,
              protected codeService: CodeService,
              protected haciendaService: HaciendaService,
              protected categoryService: CategoryService,
              protected clientService: ClientService,
              private route: ActivatedRoute,
              protected loggerService: LoggerService) {
    super(documentLineService, 'documentLine', alertService, router, securityService, messageService, loggerService);
    this.fromLocalDate = LocalDate.now().minusMonths(1).withDayOfMonth(1);
    this.toLocalDate = this.fromLocalDate.withDayOfMonth(this.fromLocalDate.lengthOfMonth());
    this.from = {
      year: this.fromLocalDate.year(),
      month: this.fromLocalDate.monthValue(),
      day: this.fromLocalDate.dayOfMonth()
    };
    this.to = {year: this.toLocalDate.year(), month: this.toLocalDate.monthValue(), day: this.toLocalDate.dayOfMonth()};

    const currentDate = LocalDate.now();
    this.first8BussinessDays = currentDate.withDayOfMonth(1);
    let businessDaysCount = 8;
    do {
      const weekendDays = [DayOfWeek.SATURDAY, DayOfWeek.SUNDAY];
      if (!weekendDays.includes(this.first8BussinessDays.dayOfWeek())) {
        businessDaysCount -= 1;
      }
      this.first8BussinessDays = this.first8BussinessDays.plusDays(1);
    } while (businessDaysCount > 1);
    this.pageSize = 100000;
  }

  ngOnInit(): void {
    this.loadCategories();
    this.clientService.getClientCodes().subscribe( value => {
      this.codes = value;
    });
    super.ngOnInit();
    this.subscriptions.push(this.route.queryParams.subscribe(params => {
      const pageSize = params.pageSize;
      if (pageSize) {
        this.pageSize = pageSize;
      }
    }));
  }

  getModelInstance(): DocumentLine {
    return new DocumentLine(0, false, false, '', '', '', '', false, 0, '', '', '');
  }

  loadCategories(): void {
    this.categoryService.getCategories(undefined, this.type).subscribe(value => {
      this.categories = value.sort((n1, n2) => {
        if (n1.percentage !== undefined && n2.percentage !== undefined && n1.description !== undefined && n2.description !== undefined) {
          return n1.percentage - n2.percentage || n1.description.localeCompare(n2.description);
        }
        return 0;
      });
    });
  }

  setPageEvent(page: Page<DocumentLine>): void {
    page.content?.forEach(value => {
      value.taxesDetails = JSON.parse(value.taxes ? value.taxes : '[]') as Impuesto[];
      value.discountsDetails = JSON.parse(value.discounts ? value.discounts : '[]') as Descuento[];
      value.validCategory = this.validateCategory(value);
      if (this.codes.length === 1 && this.codes[0].code && !value.commercialActivityCode) {
        value.commercialActivityCode = this.codes[0].code;
      }
    });
    if (this.showWithInvalidCategoryOnly) {
      page.content = page.content?.filter(value => !value.validCategory);
    }
    super.setPageEvent(page);
  }

  fromDateChanged(date: NgbDateStruct): void {
    this.fromLocalDate = LocalDate.of(date.year, date.month, date.day);
    this.from = date;
    if (this.fromLocalDate.isAfter(this.toLocalDate)) {
      this.toLocalDate = LocalDate.of(date.year, date.month, this.fromLocalDate.lengthOfMonth());
      this.to = {year: date.year, month: date.month, day: this.fromLocalDate.lengthOfMonth()};
    }
    this.refreshEvent();
  }

  toDateChanged(date: NgbDateStruct): void {
    this.toLocalDate = LocalDate.of(date.year, date.month, date.day);
    this.to = date;
    if (this.toLocalDate.isBefore(this.fromLocalDate)) {
      this.fromLocalDate = LocalDate.of(date.year, date.month, 1);
      this.from = {year: date.year, month: date.month, day: 1};
    }
    this.refreshEvent();
  }

  statusChanged(status: boolean): void {
    this.refreshEvent();
  }

  typeChanged(type: string): void {
    this.loadCategories();
    this.refreshEvent();
  }

  percentageChanged(percentage: string): void {
    this.refreshEvent();
  }

  searchTermChanged(text: string): void {
    this.refreshEvent();
  }

  exoneratedChanged(exonerated: boolean): void {
    this.refreshEvent();
  }

  showInvalidOnlyChanged(showInvalidOnly: boolean): void {
    this.refreshEvent();
  }

  getPageEvent(pageNumber: number): void {
    this.cleanErrors();
    this.loading = true;
    const documentLineFilter = this.getFilter();
    this.countSelections = 0;
    this.clearPage();
    this.service.searchByFilter(documentLineFilter, pageNumber, this.pageSize, this.sortBy, this.sortOrder)
      .subscribe(
        page => this.setPageEvent(page),
        error => this.processError(error));
  }

  private getFilter(): DocumentLineFilter {
    const documentLineFilter = new DocumentLineFilter();
    documentLineFilter.from = this.fromLocalDate;
    documentLineFilter.to = this.toLocalDate;

    documentLineFilter.categorized = this.categorized;

    documentLineFilter.documentStatus = DocumentStatus.DOCUMENT_APPROVED;
    documentLineFilter.type = this.type;
    documentLineFilter.percentage = this.percentage;
    if (this.text) {
      documentLineFilter.text = this.text;
    }
    if (this.exonerated) {
      documentLineFilter.exonerated = true;
    }
    return documentLineFilter;
  }

  changeSelection(allSelected: boolean): void {
    this.countSelections = 0;
    this.page.content?.forEach(value => {
      value.selected = allSelected;
      this.countSelections += value.selected ? 1 : 0;
    });
  }

  changeSingleSelection(selected: boolean): void {
    this.countSelections = 0;
    this.page.content?.forEach(value => {
      this.allSelected = this.allSelected && value.selected;
      this.countSelections += value.selected ? 1 : 0;
    });
  }

  loadProductDetails(documentLine: DocumentLine): void {
    const code = documentLine.cabysCode;
    if (code) {
      this.haciendaService.getProductDetails(code).subscribe(value => {
        if (value.length > 0) {
          this.productSelected = value[0];
        }
      });
    }
  }

  cleanProductDetails(): void {
    this.productSelected = undefined;
  }

  validateEvent(): void {
    let lines = this.page.content;
    if (lines) {
      this.loading = true;
      lines = lines.filter(value => value.categoryId && value.commercialActivityCode);
      if (lines.length > 0) {
        lines.forEach(value => {
          // value.cabysCode = value.details.Codigo; // TODO required?
          value.operationType = this.type;
        });
        const documentLinesCategorized =
          lines.map(value => new DocumentLineCategorized(value.id, value.categoryId, value.commercialActivityCode));
        this.documentLineService.categorize(documentLinesCategorized).subscribe(value => {
          this.loading = false;
          this.refreshEvent();
        }, error1 => this.processError(error1));
      } else {
        this.loading = false;
      }
    }
  }

  applySelectedEvent(): void {
    this.loading = true;
    console.log('apply event');
    try {
      this.page.content?.forEach(value => {
        if (value.selected && !value.processed) {
          if (this.commercialActivityCode && this.type === 'PURCHASE') {
            value.commercialActivityCode = this.commercialActivityCode;
          } else if (this.commercialActivityCode && this.type === 'SELL' && value.allowCommercialActivityCodeEdit) {
            value.commercialActivityCode = this.commercialActivityCode;
          }
          if (this.categoryId) {
            value.categoryId = this.categoryId;
          }
          value.state = false;
        }
      });
    } finally {
      this.loading = false;
    }
  }

  export(format: string): void {
    this.loading = true;
    this.documentLineService.exportToExcel(this.getFilter(), format)
      .subscribe(
        response => {
          let typeContent = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8';
          if (format === 'CSV') {
            typeContent = 'text/csv; charset=utf-8';
          }
          const blob: any = new Blob([response],
            {type: typeContent });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
          this.loading = false;
          }, error => this.loading = false
      );
  }

  getCodeDescription(commercialActivityCode: string): string {
    let description;
    if (commercialActivityCode) {
      const filteredCodes = this.codes.filter(value => value.code === commercialActivityCode);
      if (filteredCodes.length > 0) {
        description = `(${commercialActivityCode}) ${filteredCodes[0].description}`;
      } else if (commercialActivityCode === '000000' && this.type === 'PURCHASE') {
        description = 'Todas';
      } else if (commercialActivityCode === '000000' && this.type === 'SELL') {
        description = '[Sin Seleccionar]';
      }else {
        description = `(${commercialActivityCode}) Actividad no configurada para el cliente`;
      }
    }
    return description ? description : '[Sin Seleccionar]';
  }

  getCategoryDescription(categoryId: number): string {
    let description;
    const category = this.getCategory(categoryId);
    if (category) {
        description = category.description;
    } else if (categoryId) {
      description = `(${categoryId}) Categoría no existente`;
    }
    return description ? description : '[Sin Seleccionar]';
  }

  filter(categories: Category[], documentLine: DocumentLine): Category[] {
    // const taxPercentage = this.getPercentage(documentLine);
    // if (taxPercentage === -1) {
    return categories; // TODO return all while validating categorization is working fine
    // } else {
    //  return categories.filter(value => value.percentage === taxPercentage);
    // }
  }

  getPercentage(documentLine: DocumentLine): number {
    let taxPercentage = -1;
    let exonerationPercentage;
    documentLine.taxesDetails.forEach(value => {
      if (value.Tarifa && value.Tarifa >= 0 && ['01'].includes(value.Codigo ? value.Codigo : '')) { // 01 -> IVA
        taxPercentage = value.Tarifa;
      }
      if (value.Exoneracion?.PorcentajeExoneracion) {
        exonerationPercentage = value.Exoneracion?.PorcentajeExoneracion;
      }
    });
    // some lines doesnt have tax info at all, consider them as 0
    if (taxPercentage === -1) {
      taxPercentage = 0;
    }
    if (exonerationPercentage && taxPercentage) {
      taxPercentage = taxPercentage - exonerationPercentage;
    }
    return taxPercentage;
  }
  getCategory(categoryId: number | undefined): Category | undefined {
    if (categoryId) {
      const filteredCategories = this.categories.filter(value => value.id === +categoryId);
      if (filteredCategories.length > 0) {
        return filteredCategories[0];
      }
    }
    return undefined;
  }

  validateCategory(documentLine: DocumentLine): boolean {
    const percentage = this.getPercentage(documentLine);
    const category = this.getCategory(documentLine.categoryId);
    if (category) {
      if (category.percentage !== percentage) {
        return false;
      }
    }
    return true;
  }
}
