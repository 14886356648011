import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UserDetails} from '../model/user-details.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {UserConfirmation} from '../model/user-confirmation.model';
import {UserRecover} from '../model/user-recover.model';

@Injectable()
export class PublicService {

  constructor(protected http: HttpClient) {
  }

  registerUser(model: UserDetails): Observable<any> {
    return this.http
      .post('/api/public/users/create', JSON.stringify(model), {responseType: 'text'});
  }

  confirmUser(code: string, model: UserConfirmation): Observable<any> {
    const paramsValue = new HttpParams().set('code', code);
    return this.http
      .post('/api/public/users/activate', JSON.stringify(model), {params: paramsValue, responseType: 'text'});
  }

  recoverUser(model: UserRecover): Observable<any> {
    return this.http
      .post('/api/public/users/recover', JSON.stringify(model), {responseType: 'text'});
  }

  ping(): Observable<any> {
    return this.http
      .get('/_ah/warmup', {responseType: 'text'});
  }

}
