<div class="container">

  <div class="loader" *ngIf="loading"></div>

  <div *ngIf="mode == 'EDIT' && !loading" class="animated fadeIn">
    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top">
      <div class="container">
        <span class="navbar-text">
          Rango de Impuesto de Renta
        </span>
        <div class="pull-right">
          <button class="btn btn-link" (click)="cancelEvent()">
            Cerrar
          </button>
          <button class="btn btn-primary" (click)="saveEvent()">
            Guardar
          </button>
        </div>
      </div>
    </nav>

    <div class="row g-3">
      <div class="col-md-6">
        <div class="form-group">
          <label for="year">Año</label>
          <input type="text" class="form-control" id="year" name="year" [(ngModel)]="model.year">
          <div *ngIf="formErrors.get('incometaxrange.year')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('incometaxrange.year') }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="percentage">Porcentaje</label>
          <input type="text" class="form-control" id="percentage" name="percentage" [(ngModel)]="model.percentage">
          <div *ngIf="formErrors.get('incometaxrange.percentage')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('incometaxrange.percentage') }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="lowRange">Rango Inferior</label>
          <input type="text" class="form-control" id="lowRange" name="lowRange" [(ngModel)]="model.lowRange">
          <div *ngIf="formErrors.get('incometaxrange.lowRange')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('incometaxrange.lowRange') }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="highRange">Rango Superior</label>
          <input type="text" class="form-control" id="highRange" name="highRange" [(ngModel)]="model.highRange">
          <div *ngIf="formErrors.get('incometaxrange.highRange')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('incometaxrange.highRange') }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="mode == 'LIST' && !loading" class="animated fadeIn">

    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top">
      <div class="container">
        <span class="navbar-text">
          Rangos de Impuesto de Renta
        </span>
        <div class="pull-right">
          <button class="btn btn-link" (click)="refreshEvent()" title="Refrescar">
            <span class="fa fa-sync fa-lg"></span>
          </button>
          <button class="btn btn-primary" (click)="createEvent()">
            Agregar
          </button>
        </div>
      </div>
    </nav>

    <mt-pagination (loadPage)="getPageEvent($event)" [page]="page"></mt-pagination>

    <table *ngIf="page.metadata.pages > 0" class="table table-hover">
      <thead>
        <tr>
          <th>
            Año
          </th>
          <th scope="col" class="text-end">
            Rango Inferior
          </th>
          <th scope="col" class="text-end">
            Rango Superior
          </th>
          <th scope="col" class="text-end">
            Porcentaje
          </th>
          <th>

          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let incomeTaxRange of page.content">
          <td (click)="editEvent(incomeTaxRange)" class="mt-content-col">
            {{incomeTaxRange.year}}
          </td>
          <td (click)="editEvent(incomeTaxRange)" class="mt-content-col text-end align-middle">
            {{incomeTaxRange.lowRange}}
          </td>
          <td (click)="editEvent(incomeTaxRange)" class="mt-content-col text-end align-middle">
            {{incomeTaxRange.highRange}}
          </td>
          <td (click)="editEvent(incomeTaxRange)" class="mt-content-col text-end align-middle">
            {{incomeTaxRange.percentage}}
          </td>
          <td class="mt-actions-col">
            <button class="btn btn-link btn-lg pull-right" data-bs-toggle="modal" data-bs-target="#incomeTaxRangeConfirmation"
                    (click)="selectEvent(incomeTaxRange)" title="Borrar">
              <span class="fa fa-trash-alt fa-lg"></span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="page.metadata.pages == 0">
      No hay registros
    </div>

  </div>

  <!-- Modal -->
  <div class="modal fade" id="incomeTaxRangeConfirmation" tabindex="-1" role="dialog"
       aria-labelledby="removeConfirmationLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirmar</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Desea eliminar el registro?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="removeEvent(model)">Borrar</button>
        </div>
      </div>
    </div>
  </div>

</div>


