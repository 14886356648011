import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {Holiday} from './resource/holiday';
import {BaseService} from '../../core/service/base.service';

@Injectable()
export class HolidayService extends BaseService<Holiday> {

  serviceUrl(): string {
    return `/api/holidays`;
  }

  constructor(protected http: HttpClient) {
    super(http);
  }
}
