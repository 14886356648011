import {EntityModel} from '../../../core/model/entity.model';

export class IncomeTaxRange extends EntityModel {

  public year?: number;

  public lowRange?: number;

  public highRange?: number;

  public percentage?: number;
}
