import {LocalDate} from '@js-joda/core';

export class BalanceValidationValues {
  constructor(public date: LocalDate,
              public actives: number,
              public passives: number,
              public patrimony: number
  ) {
  }
}
