import {EntityModel} from '../core/model/entity.model';
import {DocumentLine} from './document-line';
import {LocalDate, ZonedDateTime} from '@js-joda/core';
import {AuditableModel} from '../core/model/auditable.model';

export class Document extends AuditableModel {
  constructor(public id: number,
              public date: LocalDate = LocalDate.now(),
              public key?: string,
              public sequence?: string,
              public selected: boolean = false,
              public state: boolean = false,
              public receiver?: string,
              public receiverId?: string,
              public issuer?: string,
              public issuerId?: string,
              public type?: string,
              public total?: number,
              public totalTax?: number,
              public taxes?: string,
              public currency?: string,
              public exchange?: number,
              public version?: string,
              public status?: DocumentStatus,
              public receivedStatus?: ReceivedStatus,
              public lines?: DocumentLine[],
              public referencedBy?: Document[],
              public possiblePayments?: Document[],
              public account?: string,
              public operationType?: OperationType,
              public receivedStatusDetails?: string,
              public statusDetails?: string,
              public categorizationCompleted?: boolean,
              public classificationCompleted?: boolean,
              public budgetConfigurationCompleted?: boolean,
              public budgetFormSelected?: boolean,
              public manualEntry?: boolean,
              public processed?: boolean,
              public pending?: number,
              public negativeAmount?: number,
              public budgetFormSequence?: string,
              public notes?: string,
              public sellCondition?: string,
              public paymentMethod?: string,
              public referenceNumber?: string,
              public referenceId?: number){
    super(id);
  }
}

export class AcceptDocument {
  public details?: string;
  public taxCondition?: string;
  public messageCode?: string;
  public taxToRecover = 0;
  public expensesToApply = 0;
  public activityCode?: string;
  public document?: Document;
}

export enum DocumentStatus {
  DOCUMENT_PENDING= 'DOCUMENT_PENDING',
  DOCUMENT_APPROVED= 'DOCUMENT_APPROVED',
  DOCUMENT_REJECTED= 'DOCUMENT_REJECTED',
  RESPONSE_PENDING= 'RESPONSE_PENDING',
  RESPONSE_APPROVED= 'RESPONSE_APPROVED',
  RESPONSE_REJECTED= 'RESPONSE_REJECTED',
  ALL = 'ALL'
}

export enum ReceivedStatus {
  PENDING= 'PENDING',
  ACCEPTED= 'ACCEPTED',
  PARTIALLY_ACCEPTED= 'PARTIALLY_ACCEPTED',
  REJECTED= 'REJECTED'
}

export enum OperationType {
  SELL= 'SELL',
  PURCHASE= 'PURCHASE'
}

export enum DocumentType {
  UNDEFINED= 'UNDEFINED',
  MANUAL_DOCUMENT= 'MANUAL_DOCUMENT',
  PAYMENT_DOCUMENT= 'PAYMENT_DOCUMENT',
  OTHER= 'OTHER',
  INVOICE= 'INVOICE',
  PURCHASE_INVOICE= 'PURCHASE_INVOICE',
  CREDIT_NOTE= 'CREDIT_NOTE',
  DEBIT_NOTE= 'DEBIT_NOTE',
  TICKET= 'TICKET',
  EXPORT_INVOICE= 'EXPORT_INVOICE',
  ACCEPTED_CONFIRMATION= 'ACCEPTED_CONFIRMATION',
  PARTIALLY_ACCEPTED_CONFIRMATION= 'PARTIALLY_ACCEPTED_CONFIRMATION',
  REJECTED_CONFIRMATION= 'REJECTED_CONFIRMATION',
  ALL = 'ALL'
}
