import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {MessageService} from './message.service';
import {LogoutMessage} from '../message/logout.message';
import {HeaderConstants} from '../utils/headers.constants';
import {Tenant} from '../model/tenant';
import {TenantUserService} from '../../service/tenant-user.service';
import ServiceUtils from '../utils/service.utils';
import {UserChangedMessage} from '../message/user-changed.message';

@Injectable()
export class SecurityService {

  constructor(protected http: HttpClient,
              protected router: Router,
              private userService: TenantUserService,
              protected messageService: MessageService) {
  }

  logout(): void {
    // remove all items including token
    localStorage.clear();
    sessionStorage.clear();
    this.router
      .navigate(['/login'], {queryParams: {returnUrl: this.router.url}})
      .then(value => {
        this.messageService.publish(new LogoutMessage(true));
        window.location.reload();
      });
  }

  login(username: string, password: string): Observable<any>{
    // let request =
    return this.http.post('/auth/token',
      JSON.stringify({username, password}));
  }

  refresh(): Observable<any> {
    const tenantFromStorage = ServiceUtils.getItem('tenant');
    const currentTenant = JSON.parse(tenantFromStorage ? tenantFromStorage : '{}') as Tenant;
    return this.http.post<any>('/auth/refresh',
      JSON.stringify({token: ServiceUtils.getItem('token'), tenantId: currentTenant.id}),
      {headers: new HttpHeaders().set(HeaderConstants.SKIP_AUTH, 'true')})
      .pipe(tap((data) => {
        ServiceUtils.setItem('token', data.token);
        this.userService.getUserDetails().subscribe(value => {
            ServiceUtils.setItem('user', JSON.stringify(value.user));
            ServiceUtils.setItem('configuration', JSON.stringify(value.configuration));
            ServiceUtils.setItem('permissions', JSON.stringify(value.privilegeNames));
            console.log('configuration updated');
            this.messageService.publish(new UserChangedMessage(true));
          }
        );
    }));
  }

}
