import {Component, OnDestroy, OnInit} from '@angular/core';
import {AlertService} from '../../core/service/alert.service';
import {SecurityService} from '../../core/service/security.service';
import {Router} from '@angular/router';
import {MessageService} from '../../core/service/message.service';
import {CoreComponent} from '../../core/component/core.component';
import {LocalDate, TemporalAdjusters} from '@js-joda/core';
import {AccountingEntryService} from '../../service/accounting-entry.service';
import {AccountingPeriodMonthService} from '../../service/accounting-period-month.service';
import {AccountingPeriodMonth} from '../../model/accounting-period-month';
import {Page} from '../../model/page';
import ServiceUtils from '../../core/utils/service.utils';
import {environment} from '../../../environments/environment';
import {Tenant} from '../../core/model/tenant';
import {AccountingPeriodService} from '../../service/accounting-period.service';
import {AccountingPeriod} from '../../model/accounting-period';
import {LoggerService} from '../../service/logger.service';
import {BalanceValidationValues} from '../../model/balance-validation-values';
import {SystemAccountingPeriodService} from '../../service/system-accounting-period.service';
import {SystemAccountingPeriod} from '../../model/system-accounting-period';

@Component({
  selector: 'app-client',
  templateUrl: './close-period.component.html'
})
export class ClosePeriodComponent extends CoreComponent implements OnInit, OnDestroy {

  month = 'JULY';
  year = 2022;
  accountingPeriods: Page<AccountingPeriodMonth> = new Page({count: 0, page: 0, pages: 0}, []);
  selectedPeriod: AccountingPeriodMonth = new AccountingPeriodMonth(0);
  currentPeriod: AccountingPeriodMonth = new AccountingPeriodMonth(0);
  env = environment;
  periodConfiguration = new AccountingPeriod();
  periodConfigured = false;
  periodAnalyzeValues: BalanceValidationValues[] = [];
  periodAnalyzeValue: BalanceValidationValues = new BalanceValidationValues(LocalDate.now(), 0, 0, 0);
  currentDate: LocalDate = LocalDate.now();
  initialDate: LocalDate = LocalDate.now();
  systemAccountingPeriods: SystemAccountingPeriod[] = [];
  systemAccountingPeriodId = 0;

  constructor(private alertService: AlertService,
              protected router: Router,
              protected securityService: SecurityService,
              protected accountingEntryService: AccountingEntryService,
              protected accountingPeriodMonthService: AccountingPeriodMonthService,
              protected messageService: MessageService,
              protected accountingPeriodService: AccountingPeriodService,
              protected systemAccountingPeriodService: SystemAccountingPeriodService,
              protected loggerService: LoggerService) {
    super(alertService, router, securityService, loggerService);

  }

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>();
  }

  ngOnInit(): void {
    // const month = LocalDate.now().month().name().toLowerCase();
    // this.month = month.charAt(0).toUpperCase() + month.slice(1);
    // this.year = LocalDate.now().year();
    this.loading = true;
    this.getPageEvent(0);
    this.loadConfiguration();
  }

  loadConfiguration(): void {
    this.loading = true;
    this.periodConfigured = false;
    this.accountingPeriodService.getPeriodConfiguration()
      .subscribe(value => {
        this.periodConfiguration.startDate = ServiceUtils.dateReviver('', value.startDate) as LocalDate;
        this.periodConfiguration.endDate = ServiceUtils.dateReviver('', value.endDate) as LocalDate;
        this.periodConfigured = true;
        this.loadCurrentPeriod();
        this.loading = false;
      }, error => this.processError(error));
    this.systemAccountingPeriodService.getAll().subscribe(value => this.systemAccountingPeriods = value);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  generateAccountingEntries(): void {
    this.loading = true;
    this.accountingEntryService.generateAccountingEntries(this.month.toUpperCase(), this.year.toString()).subscribe(value => {
      this.loading = false;
      this.alertService.success(`Asientos contables generados exitosamente`);
      this.getPageEvent(0);
      this.loadCurrentPeriod();
    }, error => this.processError(error));
  }

  removeAccountingEntries(month: LocalDate): void {
    this.loading = true;
    const revivedMonth = ServiceUtils.dateReviver('', month) as LocalDate;
    this.accountingEntryService.removeAccountingEntries(revivedMonth.month().name().toUpperCase(), revivedMonth.year().toString())
      .subscribe(value => {
        this.loading = false;
        this.alertService.success(`Asientos contables removidos exitosamente`);
        this.getPageEvent(0);
        this.loadCurrentPeriod();
        }, error => this.processError(error));
  }
  analyzeAccountingEntries(month: LocalDate, first: boolean): void {
    this.loading = true;
    const revivedDate = ServiceUtils.dateReviver('', month) as LocalDate;
    if (first) {
      this.periodAnalyzeValues = [];
      this.initialDate = revivedDate;
    }
    this.currentDate = revivedDate.plusDays(1);
    if (revivedDate.month().equals(this.initialDate.month())) {
      this.accountingEntryService.analyzePeriod(revivedDate)
        .subscribe({
          next: value => {
            this.loading = false;
            this.alertService.success(`Asientos contables analizados exitosamente`);
            this.periodAnalyzeValues = value;
          },
          error: error => this.processError(error)
        });
    } else {
      this.loading = false;
      this.alertService.error(`Ya se generaron todos los dias del periodo`);
    }
  }

  exportAccountingEntries(month: LocalDate): void {
    this.loading = true;
    const revivedMonth = ServiceUtils.dateReviver('', month) as LocalDate;
    this.accountingEntryService.exportToExcel(revivedMonth.month().name().toUpperCase(), revivedMonth.year().toString())
      .subscribe(
        response => {
          this.loading = false;
          const tenantFromStorage = ServiceUtils.getItem('tenant');
          const a = document.createElement('a');
          const objectUrl = URL.createObjectURL(response);
          a.href = objectUrl;
          if (tenantFromStorage) {
            const tenant = JSON.parse(tenantFromStorage) as Tenant;
            a.download = `Asientos_Mensuales_${revivedMonth.month().value()}_${tenant.name?.replace(/\./g, '_')}`;
          }
          a.click();
          URL.revokeObjectURL(objectUrl);
        }, error => this.processError(error)
      );
  }

  getPageEvent(pageNumber: number): void {
    this.accountingPeriodMonthService.getPage(pageNumber, 12, ['startDate'], 'desc', true).subscribe(
      value => this.accountingPeriods = value,
      error => this.processError(error));
  }

  selectPeriod(accountingPeriod: AccountingPeriodMonth): void {
    this.loading = true;
    this.accountingEntryService.analyzePeriod(accountingPeriod.endDate)
      .subscribe({
        next: value => {
          this.loading = false;
          this.alertService.success(`Asientos contables analizados exitosamente`);
          this.periodAnalyzeValue = value[value.length - 1];
          this.selectedPeriod = accountingPeriod;
        },
        error: error => this.processError(error)
      });
  }

  closePeriod(): void {
    this.loading = true;
    const revivedMonth = ServiceUtils.dateReviver('', this.selectedPeriod.startDate) as LocalDate;
    this.accountingPeriodMonthService.closeAccountingPeriod(revivedMonth.month().name().toUpperCase(), revivedMonth.year().toString())
      .subscribe(value => {
        this.loading = false;
        this.alertService.success(`El periodo ha sido cerrado exitosamente`);
        this.getPageEvent(0);
        this.loadCurrentPeriod();
      }, error => this.processError(error));
  }

  closeFullPeriod(): void {
    this.loading = true;
    this.accountingPeriodMonthService.closeFullAccountingPeriod()
      .subscribe(value => {
        this.loading = false;
        this.alertService.success(`El periodo ha sido cerrado exitosamente`);
        this.getPageEvent(0);
        this.loadConfiguration();
      }, error => this.processError(error));
  }

  reopenPeriod(): void {
    this.loading = true;
    const revivedMonth = ServiceUtils.dateReviver('', this.selectedPeriod.startDate) as LocalDate;
    this.accountingPeriodMonthService.reopenAccountingPeriod(revivedMonth.month().name().toUpperCase(), revivedMonth.year().toString())
      .subscribe(value => {
        this.loading = false;
        this.alertService.success(`El periodo ha sido reabierto exitosamente`);
        this.loadCurrentPeriod();
        this.getPageEvent(0);
      }, error => this.processError(error));
  }

  initPeriod(): void {
    this.loading = true;
    this.periodConfiguration.closed = false;
    this.accountingPeriodService.initPeriod(this.systemAccountingPeriodId)
      .subscribe({
        next: value => {
          this.alertService.success(`El periodo ha sido inicializado exitosamente`);
          this.loadConfiguration();
          this.loading = false;
          },
        error: error => this.processError(error)});
  }

  revertLastPeriod(): void {
    this.loading = true;
    this.accountingPeriodService.revertLastPeriod()
      .subscribe({
        next: value => {
          this.alertService.success(`El periodo ha sido revertido exitosamente`);
          this.loadConfiguration();
          this.loading = false;
        },
        error: error => this.processError(error)});
  }

  loadCurrentPeriod(): void {
    this.accountingPeriodMonthService.getCurrentPeriod().subscribe(value => {
      if (value !== null) {
        this.currentPeriod = value;
        let revivedMonth = ServiceUtils.dateReviver('', value.startDate) as LocalDate;
        revivedMonth = revivedMonth.plusMonths(1);
        this.year = revivedMonth.year();
        const month = revivedMonth.month().name().toLowerCase();
        this.month = month.charAt(0).toUpperCase() + month.slice(1);
      } else {
        this.year = this.periodConfiguration.startDate.year();
        const month = this.periodConfiguration.startDate.month().name().toLowerCase();
        this.month = month.charAt(0).toUpperCase() + month.slice(1);
      }
    }, error => this.processError(error));
  }
}
