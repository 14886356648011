import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Tenant} from '../model/tenant';
import ServiceUtils from '../utils/service.utils';

@Injectable()
export class TenantInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let url = req.url as string;
    const tenantFromStorage = ServiceUtils.getItem('tenant');

    if (url.indexOf('{tenant}') > 0 && tenantFromStorage) {

      const tenant = JSON.parse(tenantFromStorage) as Tenant;

      url = url.replace('{tenant}', '' + tenant.id);

      req = req.clone({url});
    }

    return next.handle(req);
  }
}
