<div class="container">

  <div class="loader" *ngIf="loading"></div>

  <div *ngIf="mode == 'EDIT' && !loading" class="animate__animated animate__fadeIn">
    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">
      <div class="container-fluid">
        <span class="navbar-text">
          Cuenta
        </span>
        <div class="pull-right">
          <button class="btn btn-link" (click)="cancelEvent()">
            Cerrar
          </button>
          <button class="btn btn-primary" (click)="saveEvent()">
            Guardar
          </button>
        </div>
      </div>
    </nav>

    <div class="row mb-3" *ngIf="model.level !== 1">
      <div class="form-group col-md-6">
        <label class="form-label" for="parent">Cuenta Padre</label>
        <select class="form-select" id="parent" name="parent" [(ngModel)]="model.parent" (ngModelChange)="validateParent()">
          <option value="">Ninguna</option>
          <option *ngFor="let parentAccount of selectableParents" [ngValue]="parentAccount.id">{{parentAccount.code + ' ' + parentAccount.description}}</option>
        </select>
        <div *ngIf="formErrors.get('account.parent')" class="badge bg-danger animate__animated animate__fadeIn">
          {{ formErrors.get('account.parent') }}
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="form-group col-md-6">
        <label class="form-label" for="code">Código</label>
        <input type="text" class="form-control" id="code" name="code" [(ngModel)]="model.code" placeholder="0.00.00.000.0000" >
        <div *ngIf="formErrors.get('account.code')" class="badge bg-danger animate__animated animate__fadeIn">
          {{ formErrors.get('account.code') }}
        </div>
      </div>

      <div class="form-group col-md-6">
        <label class="form-label" for="description">Descripción</label>
        <input type="description" class="form-control" id="description" name="description" [(ngModel)]="model.description" >
        <div *ngIf="formErrors.get('account.description')" class="badge bg-danger animate__animated animate__fadeIn">
          {{ formErrors.get('account.description') }}
        </div>
      </div>
    </div>
    <div class="row mb-3" *ngIf="model.level === 5">
      <div class="form-group col-md-6">
        <label class="form-label" for="initialAmount">Monto Inicial</label>
        <input type="text" class="form-control" id="initialAmount" name="initialAmount" [(ngModel)]="model.initialAmount" >
        <div *ngIf="formErrors.get('initialAmount')" class="badge bg-danger animate__animated animate__fadeIn">
          {{ formErrors.get('initialAmount') }}
        </div>
        <div *ngIf="formErrors.get('account.initialAmount')" class="badge bg-danger animate__animated animate__fadeIn">
          {{ formErrors.get('account.initialAmount')  }}
        </div>
      </div>
      <div class="form-group col-md-6" *ngIf="accountType == 'BUDGET'">
        <label class="form-label" for="initialAmount">Saldo</label>
        <input type="text" class="form-control" id="balance" name="initialAmount" [(ngModel)]="model.balance" >
        <div *ngIf="formErrors.get('balance')" class="badge bg-danger animate__animated animate__fadeIn">
          {{ formErrors.get('balance') }}
        </div>
        <div *ngIf="formErrors.get('account.initialAmount')" class="badge bg-danger animate__animated animate__fadeIn">
          {{ formErrors.get('account.initialAmount')  }}
        </div>
      </div>
      <div class="form-group col-md-6" *ngIf="accountType == 'ACCOUNTING'">
        <label class="form-label" for="accountingImpact">Afectación Contable</label>
        <select class="form-select" id="accountingImpact" name="accountingImpact" [(ngModel)]="model.accountingImpact">
          <option value="DEBIT">Débitos</option>
          <option value="CREDIT">Créditos</option>
        </select>
        <div *ngIf="formErrors.get('account.accountingImpact')" class="badge bg-danger animate__animated animate__fadeIn">
          {{ formErrors.get('account.accountingImpact') }}
        </div>
      </div>
    </div>

    <div class="row mb-3" *ngIf="model.level === 5 && accountType == 'ACCOUNTING'">
      <div class="form-group col-md-6">
        <label class="form-label" for="initialAmount">Casilla Formulario D101 (Para asociar varias casillas ingresar valores separados por coma)</label>
        <input type="text" class="form-control" id="dataFormD101" name="dataFormD101" [(ngModel)]="dataFormD101" >
        <div *ngIf="formErrors.get('dataFormD101.0')" class="badge bg-danger animate__animated animate__fadeIn">
          {{ formErrors.get('dataFormD101.0') }}
        </div>
        <div *ngIf="formErrors.get('account.dataFormD101')" class="badge bg-danger animate__animated animate__fadeIn">
          {{ formErrors.get('account.dataFormD101')  }}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <h4>Configuración</h4>
      </div>
      <div class="col-sm-12">
        <p>Seleccione las configuraciones para la cuenta.</p>
      </div>
    </div>

    <div class="list-group mb-4">
      <a class="list-group-item list-group-item-action d-flex flex-row"  *ngIf="model.level === 5">
        <div class="d-flex align-items-center me-4">
          <input type="checkbox" value="" id="active" name="active" [(ngModel)]="model.active" >
        </div>
        <div class="flex-column align-items-start">
          <div class="d-flex w-100 justify-content-between">
            <p class="mb-0">Activa</p>
          </div>
          <small class="text-muted">Permite seleccionar la cuenta al clasificar, solo cuentas de 5to nivel.</small>
          <div *ngIf="formErrors.get('account.active')" class="badge bg-danger animate__animated animate__fadeIn">
            {{ formErrors.get('account.active') }}
          </div>
        </div>
      </a>
      <a class="list-group-item list-group-item-action d-flex flex-row">
        <div class="d-flex align-items-center me-4">
          <input type="checkbox" value="" id="showTotal" name="active" [(ngModel)]="model.showTotal" >
        </div>
        <div class="flex-column align-items-start">
          <div class="d-flex w-100 justify-content-between">
            <p class="mb-0">Mostrar total</p>
          </div>
          <small class="text-muted">Sumariza el total de las subcuentas</small>
          <div *ngIf="formErrors.get('account.showTotal')" class="badge bg-danger animate__animated animate__fadeIn">
            {{ formErrors.get('account.showTotal') }}
          </div>
        </div>
      </a>
      <a class="list-group-item list-group-item-action d-flex flex-row"   *ngIf="model.level === 5 && accountType == 'ACCOUNTING'">
        <div class="d-flex align-items-center me-4">
          <input type="checkbox" value="" id="depreciation" name="active" [(ngModel)]="model.depreciation" >
        </div>
        <div class="flex-column align-items-start">
          <div class="d-flex w-100 justify-content-between">
            <p class="mb-0">Cuenta de depreciación</p>
          </div>
          <small class="text-muted">Indica si esta cuenta debe ser considerada como de depreciación</small>
          <div *ngIf="formErrors.get('account.depreciation')" class="badge bg-danger animate__animated animate__fadeIn">
            {{ formErrors.get('account.depreciation') }}
          </div>
        </div>
      </a>
      <a class="list-group-item list-group-item-action d-flex flex-row"   *ngIf="model.level === 5 && accountType == 'ACCOUNTING'">
        <div class="d-flex align-items-center me-4">
          <input type="checkbox" value="" id="taxAccountPurchase" name="active" [(ngModel)]="model.taxAccountPurchase" (ngModelChange)="taxAccountPurchaseChangeEvent()">
        </div>
        <div class="flex-column align-items-start">
          <div class="d-flex w-100 justify-content-between">
            <p class="mb-0">Cuenta de impuestos de compra</p>
          </div>
          <small class="text-muted">Indica si esta cuenta debe ser considerada como una cuenta de impuestos de compras al clasificar</small>
          <div *ngIf="formErrors.get('account.taxAccountPurchase')" class="badge bg-danger animate__animated animate__fadeIn">
            {{ formErrors.get('account.taxAccountPurchase') }}
          </div>
        </div>
      </a>
      <a class="list-group-item list-group-item-action d-flex flex-row"   *ngIf="model.level === 5 && accountType == 'ACCOUNTING'">
        <div class="d-flex align-items-center me-4">
          <input type="checkbox" value="" id="taxAccountSell" name="active" [(ngModel)]="model.taxAccountSell" (ngModelChange)="taxAccountSellChangeEvent()" >
        </div>
        <div class="flex-column align-items-start">
          <div class="d-flex w-100 justify-content-between">
            <p class="mb-0">Cuenta de impuestos de venta</p>
          </div>
          <small class="text-muted">Indica si esta cuenta debe ser considerada como una cuenta de impuestos de ventas al clasificar</small>
          <div *ngIf="formErrors.get('account.taxAccountSell')" class="badge bg-danger animate__animated animate__fadeIn">
            {{ formErrors.get('account.taxAccountSell') }}
          </div>
        </div>

      </a>
    </div>

    <div *ngIf="childrenAccounts.length > 0">
      <div class="row">
        <div class="col-sm-12">
          <h4>Sub cuentas existentes</h4>
        </div>
        <div class="col-sm-12">
          <p>Lista de subcuentas de la cuenta padre seleccionada, se muestran como referencia.</p>
        </div>
      </div>
      <ul class="list-group">
        <li *ngFor="let children of childrenAccounts"  class="list-group-item"><strong>{{children.code}}</strong> {{children.description}}</li>
      </ul>
    </div>
  </div>

  <div *ngIf="mode == 'LIST' && !loading" class="animate__animated animate__fadeIn">

    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">
      <div class="container-fluid">
        <form class="d-flex">
          <input class="form-control me-2" id="text" name="text" [(ngModel)]="text" (change)="searchTermChanged(text)" title="Buscar..." placeholder="Buscar...">
          <select class="form-select me-2" id="status" name="status" [(ngModel)]="level" (ngModelChange)="levelChanged(level)" title="Nivel">
            <option value="0" selected>Todos</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </form>
        <div class="pull-right">
          <button class="btn btn-secondary me-1" (click)="export()" title="Exportar CSV">
            <span class="fa fa-download fa-lg "></span>
          </button>

          <button class="btn btn-secondary me-1" data-bs-toggle="modal" data-bs-target="#loadFile" title="Importar CSV">
            <span class="fa fa-upload fa-lg "></span>
          </button>

          <button class="btn  btn-secondary me-1" (click)="refreshEvent()" title="Refrescar">
            <span class="fa fa-sync fa-lg"></span>
          </button>
          <button class="btn btn-secondary me-1" data-bs-toggle="modal" data-bs-target="#startPeriodConfirmation" title="Iniciar periodo" *ngIf="accountType == 'BUDGET'">
            Iniciar Periodo
          </button>
          <button class="btn  btn-primary" (click)="createEvent()" title="Agregar">
            Agregar
          </button>
        </div>
      </div>
    </nav>

    <p *ngIf="accountType == 'BUDGET'" class="alert alert-info">Periodo actual {{periodYear}} </p>

    <mt-pagination (loadPage)="getPageEvent($event)" [page]="page"></mt-pagination>

    <table *ngIf="page.metadata.pages > 0" class="table table-hover">
      <thead>
        <tr>
          <td>Código</td>
          <td>Descripción</td>
          <td>Activa</td>
          <td></td>
        </tr>
      </thead>
      <tbody>
      <tr *ngFor="let clientAccount of page.content">
        <td (click)="editEvent(clientAccount)" class="mt-content-col  align-middle">
          <strong>{{clientAccount.code}}</strong><br>
        </td>
        <td (click)="editEvent(clientAccount)" class="mt-content-col  align-middle">
          {{clientAccount.description}} <span  *ngIf="clientAccount.level === 5 && (clientAccount.accountingImpact === undefined || clientAccount.initialAmount === undefined) && clientAccount.type == 'ACCOUNTING' "
                                               class="fa fa-exclamation-triangle text-danger" title="Parcialmente configurada"></span>
        </td>
        <td class="align-middle">
          <ng-container *ngIf="clientAccount.level === 5">
            <button  class="btn btn-link btn-lg " (click)="selectAccount(clientAccount, false)" title="Deseleccionar" *ngIf="clientAccount.active"><span class="far fa-check-square fa-lg"></span></button>
            <button  class="btn btn-link btn-lg " (click)="selectAccount(clientAccount, true)" title="Seleccionar" *ngIf="!clientAccount.active"><span class="far fa-square fa-lg"></span></button>
          </ng-container>
        </td>
        <td class="mt-actions-col align-middle">
          <button class="btn btn-link btn-lg pull-right" data-bs-toggle="modal" data-bs-target="#removeCategoryConfirmation"
                  (click)="selectEvent(clientAccount)" title="Borrar">
            <span class="fa fa-trash-alt fa-lg"></span>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
    <div *ngIf="page.metadata.pages == 0">
      No hay registros
    </div>

  </div>

  <!-- Modal -->
  <div class="modal fade" id="removeCategoryConfirmation" tabindex="-1" role="dialog"
       aria-labelledby="removeConfirmationLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirmar</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Desea eliminar la cuenta <strong>{{model.code}}</strong> y sus subcuentas?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="removeEvent(model)">Borrar</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="startPeriodConfirmation" tabindex="-1" role="dialog"
       aria-labelledby="removeConfirmationLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirmar</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Desea cerrar el periodo actual {{periodYear}} e iniciar un nuevo periodo presupuestario para el siguiente año? </p>
          <p>El saldo inicial y balance del periodo actual serán almacenados, y las cuentas se configuraran en 0 para el nuevo periodo.</p>
          <p><strong>Este proceso no se puede revertir.</strong></p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="startNewBudgetPeriod()">Aceptar</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="loadFile" tabindex="-1" role="dialog"
       aria-labelledby="loadFileLabel">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Carga Archivo</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

          <p class="alert alert-warning" *ngIf="page.metadata.count > 0">Ya existen cuentas configuradas, para realizar una carga debe eliminarlas primero.</p>

          <p *ngIf="page.metadata.count == 0">Seleccione el archivo que desee cargar en el sistema en formato EXCEL. Debe contener las columnas en el siguiente orden:</p>

          <ol class="list-group" *ngIf="page.metadata.count == 0">
            <li class="list-group-item"><strong class="me-2">Código de cuenta</strong> <span class="badge bg-warning">requerido</span><br><small>formato: 0.00.00.000.0000</small></li>
            <li class="list-group-item"><strong class="me-2">Descripción</strong> <span class="badge bg-warning">requerido</span><br><small>texto</small></li>
            <li class="list-group-item"><strong class="me-2">Activo</strong>  <span class="badge bg-warning">opcional</span><br><small> true o false , dejar columna en blanco sino lo suministra, aplica para cuentas de 5to nivel</small></li>
            <li class="list-group-item"><strong class="me-2">Código de cuenta padre</strong>  <span class="badge bg-warning">opcional</span><br><small>  dejar columna en blanco sino lo suministra</small></li>
            <li class="list-group-item"><strong class="me-2">Mostrar total</strong>  <span class="badge bg-warning">opcional</span><br><small> true o false , dejar columna en blanco sino lo suministra, aplica para cuentas de 5to nivel</small></li>
            <li class="list-group-item"><strong class="me-2">Impacto contable</strong>  <span class="badge bg-warning">opcional</span><br><small>  DEBIT o CREDIT , dejar columna en blanco sino lo suministra, aplica para cuentas de 5to nivel</small></li>
            <li class="list-group-item"><strong class="me-2">Monto inicial</strong>  <span class="badge bg-warning">opcional</span><br> <small> dejar columna en blanco sino lo suministra, aplica para cuentas de 5to nivel</small></li>
            <li class="list-group-item"><strong class="me-2">Nivel</strong>  <span class="badge bg-warning">opcional</span><br><small>  numérico de 1 a 5 , dejar columna en blanco sino lo suministra</small></li>
            <li class="list-group-item"><strong class="me-2">Cuenta de depreciación</strong>  <span class="badge bg-warning">opcional</span><br><small>  true o false, dejar columna en blanco sino lo suministra, aplica para cuentas de 5to nivel</small></li>
            <li class="list-group-item"><strong class="me-2">Casilla Formulario 101</strong>  <span class="badge bg-warning">opcional</span><br><small> numérico, dejar columna en blanco sino lo suministra, aplica para cuentas de 5to nivel</small></li>

          </ol>
          <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" style="display:none;" />
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" (click)="uploadEvent()" title="Seleccionar..." data-bs-dismiss="modal" *ngIf="page.metadata.count == 0">
            Seleccionar...
          </button>
          <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>

</div>


