import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DashboardSummary} from '../model/dashboard-summary';
import {HeaderConstants} from "../core/utils/headers.constants";
import {BalanceValidationValues} from "../model/balance-validation-values";
import {LocalDate} from "@js-joda/core";

@Injectable({
  providedIn: 'root'
})
export class AccountingEntryService {
  private SERVER_URL = '/api/t/{tenant}/accounting-entries';
  constructor(private httpClient: HttpClient) { }

  exportToExcel(monthParam: string, yearParam: string): Observable<any> {
    return this.httpClient.post(`${this.SERVER_URL}/export`, {month : monthParam, year: yearParam}, {responseType: 'blob'});
  }

  generateAccountingEntries(monthParam: string, yearParam: string): Observable<DashboardSummary> {

    const paramsValue = new HttpParams();
    const headersValue = new HttpHeaders();

    const options = {headers: headersValue, params: paramsValue};

    return this.httpClient
      .post(`${this.SERVER_URL}/generate`, {month : monthParam, year: yearParam}, options);
  }

  analyzePeriod(dateParam: LocalDate): Observable<BalanceValidationValues[]> {

    const paramsValue = new HttpParams();
    const headersValue = new HttpHeaders();

    const options = {headers: headersValue, params: paramsValue};

    return this.httpClient
      .post<BalanceValidationValues[]>(`${this.SERVER_URL}/analyze`, {date : dateParam}, options);
  }

  removeAccountingEntries(monthParam: string, yearParam: string): Observable<DashboardSummary> {

    const paramsValue = new HttpParams();
    const headersValue = new HttpHeaders();

    const options = {headers: headersValue, params: paramsValue};

    return this.httpClient
      .post(`${this.SERVER_URL}/remove`, {month : monthParam, year: yearParam}, options);
  }

  getForm101Data(): Observable<any> {

    const paramsValue = new HttpParams();
    const headersValue = new HttpHeaders();

    const options = {headers: headersValue, params: paramsValue};

    return this.httpClient
      .get<any>(`${this.SERVER_URL}/form-101-data`, options);
  }

  getForm101DataXml(): Observable<any> {

    const headersValue = new HttpHeaders().append('Accept', 'application/xml');

    return this.httpClient
      .get(`${this.SERVER_URL}/form-101-data`, {headers: headersValue, responseType: 'blob'});
  }

  public fillForm(formData: FormData): Observable<any> {
    return this.httpClient.post(`${this.SERVER_URL}/form-101-data`, formData, {headers: new HttpHeaders().set(HeaderConstants.IGNORE_CONTENT_TYPE, 'true'), responseType: 'blob'});
  }


}
