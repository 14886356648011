<div class="container">

  <div class="loader" *ngIf="loading"></div>

  <div *ngIf="!loading" class="animate__animated animate__fadeIn">

    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">
      <div class="container-fluid">
        <span class="navbar-text">
          Configuración General
        </span>
        <div class="pull-right">
          <button type="button" class="btn btn-secondary me-1" (click)="loadTaxPayerData(model.client.identification)"
                  title="Recargar Actividades">
            <span class="fa fa-sync fa-lg "></span>
          </button>
          <button class="btn btn-danger  me-1" data-bs-toggle="modal" data-bs-target="#removeAccountConfirmation"
                  title="Borrar">
            Borrar
          </button>
          <button class="btn btn-warning  me-1" data-bs-toggle="modal" data-bs-target="#deactivateAccountConfirmation"
                  title="Borrar">
            Desactivar
          </button>
          <button class="btn btn-primary" (click)="saveEvent()">
            Guardar
          </button>
        </div>
      </div>
    </nav>

    <div class="mb-3 card">
      <h5 class="card-header">
        Datos Generales
      </h5>
      <div class="card-body">


        <div class="row mb-3">
          <div class="form-group col-md-6">
            <label class="form-label" for="identificationType">Tipo Identificación</label>
            <select id="identificationType" class="form-select" name="identificationType"
                    [(ngModel)]="model.client.identificationType" [disabled]="model.client.id !== undefined">
              <option *ngFor="let identificationTypeOption of identificationTypes"
                      value="{{identificationTypeOption.code}}">{{identificationTypeOption.description}}</option>
              <option value="-1">Extranjero</option>
            </select>

            <div *ngIf="formErrors.get('client.identificationType')" class="badge bg-danger animated fadeIn">
              {{ formErrors.get('client.identificationType') }}
            </div>
          </div>

          <div class="form-group col-md-6">
            <label class="form-label" for="identification">Identificación</label>
            <input type="text" class="form-control" id="identification" name="identification"
                   [disabled]="model.client.id !== undefined" [(ngModel)]="model.client.identification"
                   (change)="loadTaxPayerData(model.client.identification)">
            <div *ngIf="formErrors.get('client.identification')" class="badge bg-danger animated fadeIn">
              {{ formErrors.get('client.identification') }}
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="form-group col-md-4">
            <label class="form-label" for="name">Nombre/Razón Social</label>
            <input type="text" class="form-control" id="name" name="name" [(ngModel)]="model.client.name">
            <div *ngIf="formErrors.get('client.name')" class="badge bg-danger animated fadeIn">
              {{ formErrors.get('client.name') }}
            </div>
          </div>

          <div class="form-group col-md-4">
            <label class="form-label" for="email">Correo Electrónico</label>
            <input type="email" class="form-control" id="email" name="email" [(ngModel)]="model.client.email">
            <div *ngIf="formErrors.get('client.email')" class="badge bg-danger animated fadeIn">
              {{ formErrors.get('client.email') }}
            </div>
          </div>
          <div class="form-group col-md-4">
            <label class="form-label" for="systemEmail">Correo Electrónico (ContaPlus)</label>
            <input type="email" class="form-control" id="systemEmail" name="email"
                   [(ngModel)]="model.configuration.systemEmail" readonly>
            <div *ngIf="formErrors.get('client.systemEmail')" class="badge bg-danger animated fadeIn">
              {{ formErrors.get('client.systemEmail') }}
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="form-group">
            <label class="form-label" for="commercialName">Actividades Comerciales</label>
            <ul class="list-group">
              <li class="list-group-item" *ngFor="let activity of model.client.configuration.commercialActivities">

                <div class="d-flex w-100 justify-content-between">
                  <span class="mb-1">({{activity.code}}) {{activity.description}} </span>
                  <button class="btn btn-link" (click)="editPercentage(activity.code, activity.description)"
                          data-bs-toggle="modal" data-bs-target="#productDetails">Porcentaje Acreditación
                  </button>
                </div>
              </li>
              <li class="list-group-item list-group-item-warning"
                  *ngIf="!model.client.configuration.commercialActivities || model.client.configuration.commercialActivities.length == 0">
                Sin Actividades
              </li>
            </ul>
          </div>
        </div>

        <div class="row mb-3">
          <div class="form-group">
            <label class="form-label" for="commercialName">Nombre Comercial</label>
            <input type="text" class="form-control" id="commercialName" name="commercialName"
                   [(ngModel)]="model.client.commercialName">
            <div *ngIf="formErrors.get('client.commercialName')" class="badge bg-danger animated fadeIn">
              {{ formErrors.get('client.commercialName') }}
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="form-group col-md-2">
            <label class="form-label" for="phoneCountryCode">Código de Pais</label>
            <input type="text" class="form-control" id="phoneCountryCode" name="phoneCountryCode"
                   [(ngModel)]="model.client.phoneCountryCode">
            <div *ngIf="formErrors.get('client.phoneCountryCode')" class="badge bg-danger animated fadeIn">
              {{ formErrors.get('client.phoneCountryCode') }}
            </div>
          </div>

          <div class="form-group col-md-4">
            <label class="form-label" for="phone">Teléfono</label>
            <input type="tel" class="form-control" id="phone" name="phone" [(ngModel)]="model.client.phone">
            <div *ngIf="formErrors.get('client.phone')" class="badge bg-danger animated fadeIn">
              {{ formErrors.get('client.phone') }}
            </div>
          </div>
          <div class="form-group col-md-2">
            <label class="form-label" for="faxCountryCode">Código de Pais</label>
            <input type="text" class="form-control" id="faxCountryCode" name="faxCountryCode"
                   [(ngModel)]="model.client.faxCountryCode">
            <div *ngIf="formErrors.get('client.faxCountryCode')" class="badge bg-danger animated fadeIn">
              {{ formErrors.get('client.faxCountryCode') }}
            </div>
          </div>

          <div class="form-group col-md-4">
            <label class="form-label" for="fax">Fax</label>
            <input type="text" class="form-control" id="fax" name="fax" [(ngModel)]="model.client.fax">
            <div *ngIf="formErrors.get('client.fax')" class="badge bg-danger animated fadeIn">
              {{ formErrors.get('client.fax') }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <p>Los cambios en esta sección requieren salir y volver a ingresar a la aplicación.</p>
    <div class="list-group mb-4">
      <a class="list-group-item list-group-item-action d-flex flex-row">
        <div class="d-flex align-items-center me-4">
          <input type="checkbox" value="" id="publicInstitution" name="active" [(ngModel)]="model.configuration.publicInstitution" >
        </div>
        <div class="flex-column align-items-start">
          <div class="d-flex w-100 justify-content-between">
            <p class="mb-0">Institución pública</p>
          </div>
          <small class="text-muted">Muestra las opciones de administración de instituciones públicas</small>
          <div *ngIf="formErrors.get('configuration.publicInstitution')" class="badge bg-danger animate__animated animate__fadeIn">
            {{ formErrors.get('configuration.publicInstitution') }}
          </div>
        </div>
      </a>
      <a class="list-group-item list-group-item-action d-flex flex-row">
        <div class="d-flex align-items-center me-4">
          <input type="checkbox" value="" id="active" name="generateValueAddedTax" [(ngModel)]="model.configuration.generateValueAddedTax" >
        </div>
        <div class="flex-column align-items-start">
          <div class="d-flex w-100 justify-content-between">
            <p class="mb-0">Genera IVA</p>
          </div>
          <small class="text-muted">Muestra las opciones para compañías que generan IVA</small>
          <div *ngIf="formErrors.get('configuration.generateValueAddedTax')" class="badge bg-danger animate__animated animate__fadeIn">
            {{ formErrors.get('configuration.generateValueAddedTax') }}
          </div>
        </div>
      </a>
    </div>

    <div class="mb-3 card animate__animated animate__fadeIn" *ngIf="model.configuration.publicInstitution">
      <h5 class="card-header">
        Instituciones públicas
      </h5>
      <div class="card-body">

        <!-- Presidente -->
        <div class="row mb-3">
          <div class="form-group col-md-6">
            <label class="form-label" for="president">Presidenta/e</label>
            <input type="text" class="form-control" id="president" name="president" [(ngModel)]="model.configuration.publicInstitutionReportData.president">
            <div *ngIf="formErrors.get('configuration.reportConfiguration.president')" class="badge bg-danger animated fadeIn">
              {{ formErrors.get('configuration.reportConfiguration.president') }}
            </div>
          </div>

          <div class="form-group col-md-6">
            <label class="form-label" for="presidentIdentification">Identificación</label>
            <input type="email" class="form-control" id="presidentIdentification" name="presidentIdentification" [(ngModel)]="model.configuration.publicInstitutionReportData.presidentIdentification">
            <div *ngIf="formErrors.get('configuration.reportConfiguration.presidentIdentification')" class="badge bg-danger animated fadeIn">
              {{ formErrors.get('configuration.reportConfiguration.presidentIdentification') }}
            </div>
          </div>
        </div>

        <!-- Secretaria -->
        <div class="row mb-3">
          <div class="form-group col-md-6">
            <label class="form-label" for="secretary">Secretaria/o</label>
            <input type="text" class="form-control" id="secretary" name="secretary" [(ngModel)]="model.configuration.publicInstitutionReportData.secretary">
            <div *ngIf="formErrors.get('configuration.reportConfiguration.secretary')" class="badge bg-danger animated fadeIn">
              {{ formErrors.get('configuration.reportConfiguration.secretary') }}
            </div>
          </div>

          <div class="form-group col-md-6">
            <label class="form-label" for="secretaryIdentification">Identificación</label>
            <input type="email" class="form-control" id="secretaryIdentification" name="secretaryIdentification" [(ngModel)]="model.configuration.publicInstitutionReportData.secretaryIdentification">
            <div *ngIf="formErrors.get('configuration.reportConfiguration.secretaryIdentification')" class="badge bg-danger animated fadeIn">
              {{ formErrors.get('configuration.reportConfiguration.secretaryIdentification') }}
            </div>
          </div>
        </div>

        <!-- Principal -->
        <div class="row mb-3">
          <div class="form-group col-md-6">
            <label class="form-label" for="principal">Directora/or</label>
            <input type="text" class="form-control" id="principal" name="secretary" [(ngModel)]="model.configuration.publicInstitutionReportData.principal">
            <div *ngIf="formErrors.get('configuration.reportConfiguration.principal')" class="badge bg-danger animated fadeIn">
              {{ formErrors.get('configuration.reportConfiguration.principal') }}
            </div>
          </div>

          <div class="form-group col-md-6">
            <label class="form-label" for="principalIdentification">Identificación</label>
            <input type="email" class="form-control" id="principalIdentification" name="principalIdentification" [(ngModel)]="model.configuration.publicInstitutionReportData.principalIdentification">
            <div *ngIf="formErrors.get('configuration.reportConfiguration.principalIdentification')" class="badge bg-danger animated fadeIn">
              {{ formErrors.get('configuration.reportConfiguration.principalIdentification') }}
            </div>
          </div>
        </div>

        <!--otros datos -->
        <div class="row mb-3">

          <div class="form-group col-md-4">
            <label class="form-label" for="circuitCode">Circuito</label>
            <input type="email" class="form-control" id="circuitCode" name="principalIdentification" [(ngModel)]="model.configuration.publicInstitutionReportData.circuitCode">
            <div *ngIf="formErrors.get('configuration.reportConfiguration.circuitCode')" class="badge bg-danger animated fadeIn">
              {{ formErrors.get('configuration.reportConfiguration.circuitCode') }}
            </div>
          </div>

          <div class="form-group col-md-4">
            <label class="form-label" for="directorate">Dirección</label>
            <input type="email" class="form-control" id="directorate" name="principalIdentification" [(ngModel)]="model.configuration.publicInstitutionReportData.directorate">
            <div *ngIf="formErrors.get('configuration.reportConfiguration.directorate')" class="badge bg-danger animated fadeIn">
              {{ formErrors.get('configuration.reportConfiguration.directorate') }}
            </div>
          </div>

          <div class="form-group col-md-4">
            <label class="form-label" for="budgetCode">Código de presupuesto</label>
            <input type="email" class="form-control" id="budgetCode" name="principalIdentification" [(ngModel)]="model.configuration.publicInstitutionReportData.budgetCode">
            <div *ngIf="formErrors.get('configuration.reportConfiguration.budgetCode')" class="badge bg-danger animated fadeIn">
              {{ formErrors.get('configuration.reportConfiguration.budgetCode') }}
            </div>
          </div>

        </div>


      </div>
    </div>

    <div class="mb-3 card  bg-light">
      <h5 class="card-header">
        Ubicación
      </h5>
      <div class="card-body">
        <div class="row mb-3">

          <div class="form-group col-md-3">
            <label class="form-label" for="locationLevel1">Provincia</label>
            <select id="locationLevel1" class="form-select" name="locationLevel1"
                    [(ngModel)]="model.client.locationLevel1"
                    (ngModelChange)="onLocationChangeEvent($event,'location1')">
              <option></option>
              <option *ngFor="let locationLevelOption of location1"
                      value="{{locationLevelOption.code}}">{{locationLevelOption.description}}</option>
            </select>

            <div *ngIf="formErrors.get('client.locationLevel1')" class="badge bg-danger animated fadeIn">
              {{ formErrors.get('client.locationLevel1') }}
            </div>
          </div>

          <div class="form-group col-md-3">
            <label class="form-label" for="locationLevel2">Cantón</label>
            <select id="locationLevel2" class="form-select" name="locationLevel2"
                    [(ngModel)]="model.client.locationLevel2"
                    (ngModelChange)="onLocationChangeEvent($event,'location2')">
              <option></option>
              <option *ngFor="let locationLevelOption of location2"
                      value="{{locationLevelOption.code}}">{{locationLevelOption.description}}</option>
            </select>
            <div *ngIf="formErrors.get('client.locationLevel2')" class="badge bg-danger animated fadeIn">
              {{ formErrors.get('client.locationLevel2') }}
            </div>
          </div>

          <div class="form-group col-md-3">
            <label class="form-label" for="locationLevel3">Distrito</label>
            <select id="locationLevel3" class="form-select" name="locationLevel3"
                    [(ngModel)]="model.client.locationLevel3"
                    (ngModelChange)="onLocationChangeEvent($event,'location3')">
              <option></option>
              <option *ngFor="let locationLevelOption of location3"
                      value="{{locationLevelOption.code}}">{{locationLevelOption.description}}</option>
            </select>
            <div *ngIf="formErrors.get('client.locationLevel3')" class="badge bg-danger animated fadeIn">
              {{ formErrors.get('client.locationLevel3') }}
            </div>
          </div>

          <div class="form-group col-md-3">
            <label class="form-label" for="locationLevel4">Barrio</label>
            <select id="locationLevel4" class="form-select" name="locationLevel4"
                    [(ngModel)]="model.client.locationLevel4">
              <option></option>
              <option *ngFor="let locationLevelOption of location4"
                      value="{{locationLevelOption.code}}">{{locationLevelOption.description}}</option>
            </select>
            <div *ngIf="formErrors.get('client.locationLevel4')" class="badge bg-danger animated fadeIn">
              {{ formErrors.get('client.locationLevel4') }}
            </div>
          </div>

        </div>

        <div class="form-group">
          <label class="form-label" for="location">Otras Señas</label>
          <textarea type="text" class="form-control" id="location" name="location" [(ngModel)]="model.client.location"
                    rows="3"></textarea>
          <div *ngIf="formErrors.get('client.location')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('client.location') }}
          </div>
        </div>
      </div>
    </div>

    <div class="mb-3 card">
      <h5 class="card-header">
        Facturación
      </h5>
      <div class="card-body">
        <div class="row mb-3">
          <div class="form-group col-md-6">
            <label class="form-label" for="user">Usuario</label>
            <input type="text" class="form-control" id="user" name="user"
                   [(ngModel)]="model.configuration.invoicingUser">
            <div *ngIf="formErrors.get('configuration.invoicingUser')" class="badge bg-danger animated fadeIn">
              {{ formErrors.get('configuration.invoicingUser') }}
            </div>
          </div>
          <div class="form-group col-md-6">
            <label class="form-label" for="password">Contraseña</label>
            <input type="password" class="form-control" id="password" name="password"
                   [(ngModel)]="model.configuration.invoicingPassword" placeholder="******">
            <div *ngIf="formErrors.get('configuration.invoicingPassword')" class="badge bg-danger animated fadeIn">
              {{ formErrors.get('configuration.invoicingPassword') }}
            </div>
            <div *ngIf="!model.configuration.hasPasswordConfigured" class="badge bg-warning">
              No tiene password configurado
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="form-group col-md-6">
            <label class="form-label" for="certificate">Certificado</label>
            <input type="file" class="form-control" id="certificate" name="certificate" (change)="getBase64($event)">
            <div *ngIf="formErrors.get('configuration.invoicingCertificate')" class="badge bg-danger animated fadeIn">
              {{ formErrors.get('configuration.invoicingCertificate') }}
            </div>
            <div *ngIf="!model.configuration.hasCertificateConfigured" class="badge bg-warning">
              No tiene certificado configurado
            </div>
          </div>
          <div class="form-group col-md-6">
            <label class="form-label" for="pin">Pin</label>
            <input type="password" class="form-control" id="pin" name="pin"
                   [(ngModel)]="model.configuration.invoicingPin"
                   placeholder="******">
            <div *ngIf="formErrors.get('configuration.invoicingPin')" class="badge bg-danger animated fadeIn">
              {{ formErrors.get('configuration.invoicingPin') }}
            </div>
            <div *ngIf="!model.configuration.hasPinConfigured" class="badge bg-warning">
              No tiene pin configurado
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 100px">

    </div>
  </div>
</div>

<div class="modal fade" id="productDetails" tabindex="-1" role="dialog"
     aria-labelledby="loadFileLabel">
  <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Porcentaje de Acreditación</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="row mb-3">
          <div class="form-group">
            <label class="form-label">({{codeSelected}}) {{descriptionSelected}}</label>
            <input class="form-control" [(ngModel)]="percentageSelected"/>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cerrar</button>
        <button type="button" class="btn btn-primary" (click)="savePercentage()" data-bs-dismiss="modal">Guardar
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="removeAccountConfirmation" tabindex="-1" role="dialog"
     aria-labelledby="removeConfirmationLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Confirmar</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <p>Desea <strong>borrar</strong> la cuenta incluyendo sus documentos y clientes? Los datos serán eliminados permanentemente y no podrán ser recuperados</p>
        <p class="mt-3">Escriba 'Borrar' para poder borrar todos los datos de la cuenta <strong>{{model.client.name}}</strong></p>
        <input type="text" [(ngModel)]="accepted" class="form-control">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-link" data-bs-dismiss="modal" (click)="accepted=''">Cancelar</button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="deleteTenant()"
                [disabled]="accepted!='Borrar'">Borrar
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="deactivateAccountConfirmation" tabindex="-1" role="dialog"
     aria-labelledby="removeConfirmationLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Confirmar</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <p>Desea <strong>desactivar</strong> la cuenta? Los datos no serán eliminados, pero la cuenta no podrá ser utilizada o visualizada hasta ser activada nuevamente por el administrador del sistema.</p>
        <p class="mt-3">Escriba 'Desactivar' para poder desactivar la cuenta <strong>{{model.client.name}}</strong></p>
        <input type="text" [(ngModel)]="accepted"  class="form-control">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-link" data-bs-dismiss="modal" (click)="accepted=''">Cancelar</button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="deactivateTenant()"
                [disabled]="accepted!='Desactivar'">Desactivar
        </button>
      </div>
    </div>
  </div>
</div>


