import {Component, OnDestroy, OnInit} from '@angular/core';
import {CoreComponent} from '../../../core/component/core.component';
import {AlertService} from '../../../core/service/alert.service';
import {Router} from '@angular/router';
import {SecurityService} from '../../../core/service/security.service';
import {MessageService} from '../../../core/service/message.service';
import {ReportService} from '../../../service/report.service';
import {Tenant} from '../../../core/model/tenant';
import {LoggerService} from '../../../service/logger.service';
import ServiceUtils from '../../../core/utils/service.utils';

@Component({
  selector: 'app-consolidated-last-level-report',
  templateUrl: './consolidated-last-level-report.component.html'
})
export class ConsolidatedLastLevelReportComponent extends CoreComponent implements OnInit, OnDestroy {

  month = 'January';
  year = 2022;

  constructor(private alertService: AlertService,
              protected router: Router,
              protected securityService: SecurityService,
              protected reportService: ReportService,
              protected messageService: MessageService,
              protected loggerService: LoggerService) {
    super(alertService, router, securityService, loggerService);

  }

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  monthChanged(month: string): void {
    this.month = month;
  }

  yearChanged(year: number): void {
    this.year = +year;
  }


  generateEvent(): void {
    this.loading = true;
    this.reportService.exportBudgetFormLastLevelConsolidatedToExcel(this.month.toUpperCase(), this.year.toString())
      .subscribe(
        response => {
          this.loading = false;
          const tenantFromStorage = ServiceUtils.getItem('tenant');
          const a = document.createElement('a');
          const objectUrl = URL.createObjectURL(response);
          a.href = objectUrl;
          if (tenantFromStorage) {
            const tenant = JSON.parse(tenantFromStorage) as Tenant;
            a.download = `Reporte_Segregado_${this.month.toUpperCase()}_${tenant.name?.replace(/\./g, '_')}`;
          }
          a.click();
          URL.revokeObjectURL(objectUrl);
        }, error => this.processError(error)
      );
  }
}
