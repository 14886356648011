import {Injectable} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';


@Injectable()
export class AlertService {

  private subject = new Subject<{type: string, text: string}>();

  private keepAfterNavigationChange = false;

  constructor(private router: Router) {
    // clear alert message on route change
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // only keep for a single location change
          this.keepAfterNavigationChange = false;
        } else {
          // clear alert
          this.subject.next({type: '', text: ''});
        }
      }
    });
  }

  success(message: string, keepAfterNavigationChange = false): void {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({type: 'success', text: this.escapeHtml(message)});
  }

  error(message: string, keepAfterNavigationChange = false): void {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({type: 'danger', text: this.escapeHtml(message)});
  }

  clear(): void {
    this.subject.next({type: '', text: ''});
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  getSubject(): Subject<any> {
    return this.subject;
  }

  escapeHtml(unsafe: string): string {
    if (unsafe !== undefined) {
      return unsafe
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;');
    } else {
      return '';
    }
  }
}
