import {EntityModel} from '../../../core/model/entity.model';
import {DayOfWeek} from '@js-joda/core';

export class Workday extends EntityModel {

  public name?: string;

  public weeklyHours?: number;

  public dailyHours?: number;

  public coverLunchHour?: boolean;

  public maxExtraHoursWeekly?: number;

  public maxExtraHoursDaily?: number;

  public workingDays: WorkingDay[] = [];
}

export class WorkingDay extends EntityModel {
  public dayOfWeek?: DayOfWeek;
}
