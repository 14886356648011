import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Account, AccountType} from '../../../model/account';
import {BaseComponent} from '../../../core/component/base.component';
import {AccountService} from '../../../service/account.service';
import {AlertService} from '../../../core/service/alert.service';
import {SecurityService} from '../../../core/service/security.service';
import {MessageService} from '../../../core/service/message.service';
import {ClientAccountFilter} from '../../../filter/client-account.filter';
import {Tenant} from '../../../core/model/tenant';
import {LocalDate} from '@js-joda/core';
import {LoggerService} from '../../../service/logger.service';
import ServiceUtils from '../../../core/utils/service.utils';

@Component({
  selector: 'app-client',
  templateUrl: './client-account.component.html'
})
export class ClientAccountComponent extends BaseComponent<Account> implements OnInit, OnDestroy {

  @ViewChild('fileUpload', {static: false})
    // @ts-ignore
  fileUpload: ElementRef;
  text = '';
  selectableParents: Account[] = [];
  childrenAccounts: Account[] = [];
  level = 0;
  accountType = AccountType.ACCOUNTING;
  periodYear = 2000;
  dataFormD101?: string;

  constructor(private clientAccountService: AccountService,
              private alertService: AlertService,
              protected router: Router,
              protected securityService: SecurityService,
              protected messageService: MessageService,
              private route: ActivatedRoute,
              protected loggerService: LoggerService) {
    super(clientAccountService, 'client-accounts', alertService, router, securityService, messageService, loggerService);
    this.sortBy.push('code');
    this.sortOrder = 'asc';
    this.navigateToList = true;
    this.keepPageOnRefresh = true;
  }

  getModelInstance(): Account {
    const clientAccount = new Account();
    clientAccount.type = this.accountType;
    return clientAccount;
  }

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>().set('clientaccount.code', '').set('clientaccount.description', '');
  }

  ngOnInit(): void {
    this.route.paramMap
      .subscribe(params => {
          const valueSelected = params.get('type');
          if (valueSelected && ['contables', 'presupuestarias'].includes(valueSelected)) {
            this.accountType = valueSelected === 'contables' ? AccountType.ACCOUNTING : AccountType.BUDGET;
            console.log(params.get('type'));
            this.clientAccountService.getClientAccounts([], 4, undefined, this.accountType)
              .subscribe(value => {
                this.selectableParents = value;
                this.clientAccountService.getCurrentBudgetPeriod().subscribe(periodValue => this.periodYear = periodValue);
              });
            super.ngOnInit();
          }
        }
      );
  }

  canRefresh(): boolean {
    return !!ServiceUtils.getItem('tenant');
  }

  protected cleanData(): void {
  }
  saveEvent(reload: boolean = true): void {
    this.model.dataFormD101 = this.dataFormD101?.split(',');
    super.saveEvent(reload);
  }

  selectAccount(account: Account, selected: boolean): void {
    account.active = selected;
    this.model = account;
    this.dataFormD101 = account.dataFormD101?.join(',');
    this.saveEvent(false);
  }

  searchTermChanged(text: string): void {
    this.refreshEvent(false);
  }

  levelChanged(level: number): void {
    this.refreshEvent(false);
  }

  getPageEvent(pageNumber: number): void {
    this.cleanErrors();
    this.loading = true;
    const filter = new ClientAccountFilter();
    filter.text = this.text;
    if (this.level !== 0) {
      filter.level = this.level;
    }
    filter.type = this.accountType;
    this.service.searchByFilter(filter, pageNumber, this.pageSize, this.sortBy, this.sortOrder)
      .subscribe(
        page => this.setPageEvent(page),
        error => this.processError(error));
  }

  export(): void {
    this.loading = true;
    this.clientAccountService.export(this.accountType)
      .subscribe(
        response => {
          this.loading = false;
          const date = LocalDate.now();
          const tenantFromStorage = ServiceUtils.getItem('tenant');
          const a = document.createElement('a');
          const objectUrl = URL.createObjectURL(response);
          a.href = objectUrl;
          if (tenantFromStorage) {
            const tenant = JSON.parse(tenantFromStorage) as Tenant;
            a.download = `Catalogo_contable_${date.year()}_${date.month().value()}_${date.dayOfMonth()}_${tenant.name?.replace(/\./g, '_')}.xlsx`;
          }
          this.loading = false;
          a.click();
          URL.revokeObjectURL(objectUrl);
        }, error => this.processError(error)
      );
  }
  editEvent(model: Account): void {
    this.childrenAccounts = [];
    this.clientAccountService.getClientAccounts([], 4, undefined, this.accountType).subscribe(value => this.selectableParents = value);
    this.dataFormD101 = model.dataFormD101?.join(',');
    super.editEvent(model);
  }

  uploadEvent(): void {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (const file of fileUpload.files) {
        this.uploadFile(file);
      }
    };
    fileUpload.click();
  }

  uploadFile(file: File): void {
    this.fileUpload.nativeElement.value = '';
    const formData = new FormData();
    formData.append('file', file);
    this.loading = true;
    this.clientAccountService.upload(formData, this.accountType)
      .subscribe(value => {
          this.refreshEvent();
          this.loading = false;
          this.alert.success('Registros cargados exitósamente');
        }
        , error => this.processError(error));
  }
  createEvent(): void {
    this.clientAccountService.getClientAccounts([], 4, undefined, this.accountType).subscribe(value => this.selectableParents = value);
    this.childrenAccounts = [];
    this.model.parent = undefined;
    this.model.code = '';
    super.createEvent();
  }

  validateParent(): void {
    const parentId = this.model.parent;
    const filteredValues = this.selectableParents.filter(value => value.id === parentId);
    if (filteredValues.length > 0) {
      const selectedParent = filteredValues[0];
      this.model.level = selectedParent.level ? selectedParent.level + 1 : 1;
      const splitParentCode = selectedParent.code?.split('.');
      let splitPrefixCode: string[] = [];
      if (splitParentCode) {
        for (const code of splitParentCode) {
          const codeNumber = +code;
          // first level is allowed to have 0
          if (codeNumber !== 0 || splitPrefixCode.length === 0) {
            splitPrefixCode = splitPrefixCode.concat(code);
          }
        }
      }
      const prefixCode = splitPrefixCode.join('.');
      this.clientAccountService.getClientAccounts([prefixCode],
        selectedParent.level ? selectedParent.level + 1 : 1, undefined, this.accountType)
        .subscribe( value => {
          this.childrenAccounts = value;
          this.childrenAccounts.shift();
          let lastValue = this.childrenAccounts[this.childrenAccounts.length - 1];
          if (!lastValue) {
            lastValue = new Account();
            lastValue.code = selectedParent.code;
            lastValue.level = selectedParent.level ? selectedParent.level + 1 : 1;
          }
          const splitLastCode = lastValue.code?.split('.');
          if (splitLastCode && lastValue.level) {
            const code = splitLastCode[lastValue.level - 1];
            splitLastCode[lastValue.level - 1] = ((+code + 1) + '').padStart(code.length, '0');
            this.model.code = splitLastCode.join('.');
          }
        });
    } else {
      this.childrenAccounts = [];
      this.model.parent = undefined;
      this.model.code = '';
    }
  }

  startNewBudgetPeriod(): void {
    this.loading = true;
    this.clientAccountService.startNewBudgetPeriod().subscribe(
      value => {
        this.clientAccountService.getCurrentBudgetPeriod().subscribe(periodValue => this.periodYear = periodValue);
        this.loading = false;
        this.alert.success('Se ha iniciado un nuevo periodo presupuestario');
      }, error => this.processError(error)
    );
  }

  taxAccountPurchaseChangeEvent(): void {
    if (this.model.taxAccountPurchase) {
      this.model.taxAccountSell = false;
    }
  }

  taxAccountSellChangeEvent(): void {
    if (this.model.taxAccountSell) {
      this.model.taxAccountPurchase = false;
    }
  }
}
