import {EntityModel} from '../core/model/entity.model';

export class BankAccount extends EntityModel {
  constructor(public id?: number,
              public number?: string,
              public bank?: string,
              public foundingSource?: string,
              public foundingSourceDescription?: string,
              public accountId?: number) {
    super(id);
  }
}

export enum BudgetFormFoundingSource {
  _001 = 'Ley 6746',
  _002 = 'Ley 7552',
  _003 = 'Otros Ingresos',
  _004 = 'PANEA'
}
