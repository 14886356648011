import {Component, OnDestroy, OnInit} from '@angular/core';

import {AlertService} from '../../core/service/alert.service';
import {MessageService} from '../../core/service/message.service';
import {SecurityService} from '../../core/service/security.service';
import {Router} from '@angular/router';
import {LoggerService} from '../../service/logger.service';
import {CoreComponent} from '../../core/component/core.component';
import {Account, AccountType} from '../../model/account';
import {AccountService} from '../../service/account.service';
import {LocalDate} from '@js-joda/core';
import {Tenant} from '../../core/model/tenant';
import ServiceUtils from '../../core/utils/service.utils';

@Component({
  selector: 'app-budget-modification',
  templateUrl: './budget-modification.component.html'
})
export class BudgetModificationComponent extends CoreComponent implements OnInit, OnDestroy {

  accountsIncomes: Account[] = [];
  accountsOutcomes: Account[] = [];
  totalIncomes = 0;
  newTotalIncomes = 0;
  initialTotalIncomes = 0;
  totalOutcomes = 0;
  newTotalOutcomes = 0;
  initialTotalOutcomes = 0;

  constructor(private clientAccountService: AccountService,
              private alertService: AlertService,
              protected messageService: MessageService,
              protected router: Router,
              protected securityService: SecurityService,
              protected loggerService: LoggerService) {
    super(alertService, router, securityService, loggerService);
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.refreshEvent();
  }

  saveEvent(): void {
    this.loading = true;
    this.clientAccountService
      .updateAll(this.accountsIncomes.concat(this.accountsOutcomes))
      .subscribe({
        next: (response) => {
          this.alert.success('Modificación realizada con éxito');
          this.loading = false;
          const date = LocalDate.now();
          const tenantFromStorage = ServiceUtils.getItem('tenant');
          const a = document.createElement('a');
          const objectUrl = URL.createObjectURL(response);
          a.href = objectUrl;
          if (tenantFromStorage) {
            const tenant = JSON.parse(tenantFromStorage) as Tenant;
            a.download = `modificacion_presupuestaria_${date.year()}_${date.month().value()}_${date.dayOfMonth()}_${tenant.name?.replace(/\./g, '_')}`;
          }
          a.click();
          URL.revokeObjectURL(objectUrl);
          this.refreshEvent();
        },
        error: (e) => this.processError(e)
      });
  }

  updateTotalsEvent(): void {
    this.totalIncomes = 0;
    this.newTotalIncomes = 0;
    let totalAdjustment = 0;
    for (const account of this.accountsIncomes) {
      if (account.balance) {
        this.totalIncomes = this.totalIncomes + Number(account.balance);
      }
      if (account.adjustment) {
        totalAdjustment = totalAdjustment + Number(account.adjustment);
      }
      if (account.initialAmount) {
        this.initialTotalIncomes = this.initialTotalIncomes + Number(account.initialAmount);
      }
    }
    this.newTotalIncomes = this.totalIncomes + totalAdjustment;

    this.totalOutcomes = 0;
    this.newTotalOutcomes = 0;
    totalAdjustment = 0;
    for (const account of this.accountsOutcomes) {
      if (account.balance) {
        this.totalOutcomes = this.totalOutcomes + Number(account.balance);
      }
      if (account.adjustment) {
        totalAdjustment = totalAdjustment + Number(account.adjustment);
      }
      if (account.initialAmount) {
        this.initialTotalOutcomes = this.initialTotalOutcomes + Number(account.initialAmount);
      }
    }
    this.newTotalOutcomes = this.totalOutcomes + totalAdjustment;
  }

  refreshEvent(): void {
    this.loading = true;
    this.clientAccountService
      .getClientAccountsWithExactLevel(undefined, undefined, 5, undefined, AccountType.BUDGET)
      .subscribe({
        next: (v) => {
          this.accountsIncomes = v.filter(value => value.active && value.code?.startsWith('7'));
          this.accountsOutcomes = v.filter(value => value.active && !value.code?.startsWith('7'));
          this.updateTotalsEvent();
          this.loading = false;
        },
        error: (e) => this.processError(e)
      });
  }
}
