import {EntityModel} from '../core/model/entity.model';

export class Code extends EntityModel {
  constructor(public id?: number,
              public description?: string,
              public code?: string,
              public type?: CodeType,
              public parentId?: number,
              public data?: string
  ) {
    super(id);
  }
}

export enum CodeType {
  TIPO_IDENTIFICACION= 'TIPO_IDENTIFICACION',
  CONDICION_VENTA= 'CONDICION_VENTA',
  MEDIO_PAGO = 'MEDIO_PAGO',
  CODIGO_IMPUESTO = 'CODIGO_IMPUESTO',
  TARIFA_IMPUESTO_VALOR_AGREGADO = 'TARIFA_IMPUESTO_VALOR_AGREGADO',
  CODIGO_REFERENCIA = 'CODIGO_REFERENCIA',
  TIPO_DOCUMENTO_REFERENCIA = 'TIPO_DOCUMENTO_REFERENCIA',
  TIPO_DOCUMENTO_EXONERACION_AUTORIZACION = 'TIPO_DOCUMENTO_EXONERACION_AUTORIZACION',
  CODIGO_MENSAJE = 'CODIGO_MENSAJE',
  TIPO_CODIGO_PRODUCTO = 'TIPO_CODIGO_PRODUCTO',
  UNIDAD_MEDIDA = 'UNIDAD_MEDIDA',
  TIPO_DOCUMENTO = 'TIPO_DOCUMENTO',
  CONDICION_IMPUESTO = 'CONDICION_IMPUESTO',
  UBICACION1 = 'UBICACION1', // PROVINCIA
  UBICACION2 = 'UBICACION2', // CANTON
  UBICACION3 = 'UBICACION3', // DISTRITO
  UBICACION4 = 'UBICACION4', // BARRIO
  CODIGO_MONEDA = 'CODIGO_MONEDA',
  ACTIVIDAD_ECONOMICA = 'ACTIVIDAD_ECONOMICA'
}

export class PercentageData {
  percentage?: number;
}
