<div class="container">

  <div class="loader" *ngIf="loading"></div>

  <div *ngIf="mode == 'EDIT'  && !loading" class="animate__animated animate__fadeIn">

    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">
      <div class="container-fluid">
        <span class="navbar-text">
          Usuario
        </span>
        <div class="pull-right">
          <button class="btn btn-link" (click)="cancelEvent()">
            Cerrar
          </button>
          <button class="btn btn-primary" (click)="saveEvent()">
            Guardar
          </button>
        </div>
      </div>
    </nav>

    <div class="form-group mb-3">
      <label class="form-label" for="name">Nombre</label>
      <input type="text" class="form-control" id="name" name="name" [(ngModel)]="model.name" placeholder="Nombre">
      <div *ngIf="formErrors.get('user.name')" class="badge bg-danger animated fadeIn">
        {{ formErrors.get('user.name') }}
      </div>
    </div>
    <div class="form-group mb-3">
      <label class="form-label" for="lastName">Apellidos</label>
      <input type="text" class="form-control" id="lastName" name="lastName" [(ngModel)]="model.lastName"
             placeholder="Apellidos">
      <div *ngIf="formErrors.get('user.lastName')" class="badge bg-danger animated fadeIn">
        {{ formErrors.get('user.lastName') }}
      </div>
    </div>
    <div class="form-group mb-3">
      <label class="form-label" for="email">Email</label>
      <input type="text" class="form-control" id="email" name="email" [(ngModel)]="model.email" placeholder="Email"
             autocomplete="email">
      <div *ngIf="formErrors.get('user.email')" class="badge bg-danger animated fadeIn">
        {{ formErrors.get('user.email') }}
      </div>
    </div>
    <div class="form-group mb-3" *ngIf="model.active != undefined">
      <label class="form-label">Estado</label>
      <input *ngIf="!model.active" class="form-control" value="Inactivo" readonly>
      <input *ngIf="model.active" class="form-control" value="Activo" readonly>
    </div>
    <div class="row mb-3">
      <div class="col-sm-12">
        <h4>Permisos</h4>
      </div>
      <div class="col-sm-12">
        <p>Seleccione los permisos que desea otorgar al usuario.</p>
      </div>
    </div>

    <div class="list-group">

      <a class="list-group-item list-group-item-action d-flex flex-row">
        <div class="d-flex align-items-center me-4">
          <input type="checkbox" value="ADMIN_CLIENTS" (change)="permissionChange($event)" [checked]="hasPrivilege('ADMIN_CLIENTS')">
        </div>
        <div class="flex-column align-items-start">
          <div class="d-flex w-100 justify-content-between">
            <p class="mb-0">Clientes</p>
          </div>
          <small class="text-muted">Ver, crear, modificar y eliminar clientes</small>
        </div>
      </a>

      <a class="list-group-item list-group-item-action d-flex flex-row">
        <div class="d-flex align-items-center me-4">
          <input type="checkbox" value="ADMIN_USERS" (change)="permissionChange($event)" [checked]="hasPrivilege('ADMIN_USERS')" >
        </div>
        <div class="flex-column align-items-start">
          <div class="d-flex w-100 justify-content-between">
            <p class="mb-0">Usuarios</p>
          </div>
          <small class="text-muted">Ver, crear, modificar y eliminar usuarios</small>
        </div>
      </a>

      <a class="list-group-item list-group-item-action d-flex flex-row">
        <div class="d-flex align-items-center me-4">
          <input type="checkbox" value="ADMIN_DOCUMENTS"  (change)="permissionChange($event)" [checked]="hasPrivilege('ADMIN_DOCUMENTS')">
        </div>
        <div class="flex-column align-items-start">
          <div class="d-flex w-100 justify-content-between">
            <p class="mb-0">Administrar Documentos</p>
          </div>
          <small class="text-muted">Ver, cargar, eliminar, categorizar y validar documentos</small>
        </div>
      </a>

      <a class="list-group-item list-group-item-action d-flex flex-row">
        <div class="d-flex align-items-center me-4">
          <input type="checkbox" value="ADMIN_ACCOUNT"  (change)="permissionChange($event)" [checked]="hasPrivilege('ADMIN_ACCOUNT')">
        </div>
        <div class="flex-column align-items-start">
          <div class="d-flex w-100 justify-content-between">
            <p class="mb-0">Administrador</p>
          </div>
          <small class="text-muted">Administrar y configurar la compañía</small>
        </div>
      </a>
      <a class="list-group-item list-group-item-action d-flex flex-row">
        <div class="d-flex align-items-center me-4">
          <input type="checkbox" value="ADMIN_ASSISTANT"  (change)="permissionChange($event)" [checked]="hasPrivilege('ADMIN_ASSISTANT')">
        </div>
        <div class="flex-column align-items-start">
          <div class="d-flex w-100 justify-content-between">
            <p class="mb-0">Asistente Administrador</p>
          </div>
          <small class="text-muted">Realizar tareas de asistente de administrador: Cierre mensual.</small>
        </div>
      </a>
      <a class="list-group-item list-group-item-action d-flex flex-row">
        <div class="d-flex align-items-center me-4">
          <input type="checkbox" value="VIEW_REPORTS"  (change)="permissionChange($event)" [checked]="hasPrivilege('VIEW_REPORTS')">
        </div>
        <div class="flex-column align-items-start">
          <div class="d-flex w-100 justify-content-between">
            <p class="mb-0">Ver Reportes</p>
          </div>
          <small class="text-muted">Generar y ver reportes</small>
        </div>
      </a>
      <a class="list-group-item list-group-item-action d-flex flex-row">
        <div class="d-flex align-items-center me-4">
          <input type="checkbox" value="VIEW_DOCUMENTS"  (change)="permissionChange($event)" [checked]="hasPrivilege('VIEW_DOCUMENTS')">
        </div>
        <div class="flex-column align-items-start">
          <div class="d-flex w-100 justify-content-between">
            <p class="mb-0">Ver Documentos</p>
          </div>
          <small class="text-muted">Ver documentos</small>
        </div>
      </a>
      <a class="list-group-item list-group-item-action d-flex flex-row" *ngIf="publicInstitution">
        <div class="d-flex align-items-center me-4">
          <input type="checkbox" value="VIEW_BUDGET_FORMS"  (change)="permissionChange($event)" [checked]="hasPrivilege('VIEW_BUDGET_FORMS')">
        </div>
        <div class="flex-column align-items-start">
          <div class="d-flex w-100 justify-content-between">
            <p class="mb-0">Ver Planillas presupuestarias</p>
          </div>
          <small class="text-muted">Ver Planillas presupuestarias</small>
        </div>
      </a>
      <a class="list-group-item list-group-item-action d-flex flex-row" *ngIf="publicInstitution">
        <div class="d-flex align-items-center me-4">
          <input type="checkbox" value="ADMIN_BUDGET_FORMS"  (change)="permissionChange($event)" [checked]="hasPrivilege('ADMIN_BUDGET_FORMS')">
        </div>
        <div class="flex-column align-items-start">
          <div class="d-flex w-100 justify-content-between">
            <p class="mb-0">Administrar Planillas presupuestarias</p>
          </div>
          <small class="text-muted">Administrar Planillas presupuestarias</small>
        </div>
      </a>

      <a class="list-group-item list-group-item-action d-flex flex-row">
        <div class="d-flex align-items-center me-4">
          <input type="checkbox" value="ADMIN_RRHH"  (change)="permissionChange($event)" [checked]="hasPrivilege('ADMIN_RRHH')">
        </div>
        <div class="flex-column align-items-start">
          <div class="d-flex w-100 justify-content-between">
            <p class="mb-0">Administrar Recursos Humanos</p>
          </div>
          <small class="text-muted">Administrar Módulo de Recursos Humanos</small>
        </div>
      </a>
    </div>
  </div>

  <div *ngIf="mode == 'LIST'  && !loading" class="animate__animated animate__fadeIn">

    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">
      <div class="container-fluid">
        <span class="navbar-text">
          Usuarios
        </span>
        <div class="pull-right">
          <button class="btn  btn-secondary me-1" (click)="refreshEvent()" title="Refrescar">
            <span class="fa fa-sync fa-lg"></span>
          </button>
          <button class="btn  btn-primary" (click)="createEvent()" title="Agregar">
            Agregar
          </button>
        </div>
      </div>
    </nav>

    <mt-pagination (loadPage)="getPageEvent($event)" [page]="page"></mt-pagination>

    <table *ngIf="page.metadata.pages > 0" class="table table-hover">
      <tbody>
      <tr *ngFor="let model of page.content">

        <td (click)="editEvent(model)" class="mt-content-col">
          <strong>{{model.name}}</strong> -
          <span *ngIf="!model.active" class="badge bg-warning">Inactivo</span>
          <span *ngIf="model.active" class="badge bg-success">Activo</span>
          <br>
          <small>{{model.email}}</small>
        </td>
        <td class="mt-actions-col">
          <button class="btn btn-link btn-lg pull-right" data-bs-toggle="modal" data-bs-target="#removeUserConfirmation"
                  (click)="selectEvent(model)" title="Borrar">
            <span class="fa fa-trash-alt fa-lg"></span>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
    <div *ngIf="page.metadata.pages == 0">
      No hay registros
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="removeUserConfirmation" tabindex="-1" role="dialog"
       aria-labelledby="removeConfirmationLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Confirmar</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Desea eliminar el registro?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="removeEvent(model)">Borrar</button>
        </div>
      </div>
    </div>
  </div>

</div>


