<div class="container">

  <div class="loader" *ngIf="loading"></div>

  <div *ngIf="!loading" class="animate__animated animate__fadeIn">
    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">
      <div class="container-fluid">
        <form class="d-flex">
          <input class="form-control me-2" id="sequence" name="text" [(ngModel)]="sequence" (change)="getPageEvent(0)" title="Buscar por consecutivo..." placeholder="Consecutivo...">
        </form>
      </div>
    </nav>

    <div>
      <mt-pagination (loadPage)="getPageEvent($event)" [page]="budgetForms" [showPages]="false"></mt-pagination>
      <table *ngIf="budgetForms.metadata.pages > 0" class="table table-hover">
        <thead>
        <tr>
          <th>
            Fecha
          </th>
          <th>
            Consecutivo
          </th>
          <th>
            Estado
          </th>
          <th>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let budgetForm of budgetForms.content">
          <td class=" align-middle">{{budgetForm.date.toString() | date:'dd MMMM, y' }}</td>
          <td class=" align-middle">{{budgetForm.sequence}}-{{budgetForm.date.toString() | date:'y' }}</td>
          <td class=" align-middle">
            <span *ngIf="budgetForm.status == 'PENDING'" >Pendiente</span>
            <span *ngIf="budgetForm.status == 'COMPLETED'" >Completado</span>
          </td>
          <td class="text-end  align-middle">
            <button class="btn btn-link pull-right" data-bs-toggle="modal" data-bs-target="#removeCategoryConfirmation"
                    (click)="exportBudgetForm(budgetForm)" title="Descargar">
              <span class="fa fa-download fa-lg"></span>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <div *ngIf="budgetForms.metadata.pages == 0" class="p-3">
        No hay registros
      </div>
    </div>
  </div>
</div>


