import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {Absence} from './resource/absence';
import {BaseService} from '../../core/service/base.service';

@Injectable()
export class AbsenceService extends BaseService<Absence> {

  serviceUrl(): string {
    return `/api/t/{tenant}/absences`;
  }

  constructor(protected http: HttpClient) {
    super(http);
  }

}
