import {Filter} from '../core/filter/filter';
import {LocalDate} from '@js-joda/core';
import { OperationType} from '../model/document-line';
import {DocumentStatus} from '../model/document';

export class DocumentLineFilter extends Filter {
  public from?: LocalDate;
  public to?: LocalDate;
  public categorized?: boolean;
  public classified?: boolean;
  public budgetConfigured?: boolean;
  public budgetFormSelected?: boolean;
  public exonerated?: boolean;
  public documentStatus?: DocumentStatus;
  public type?: OperationType;
  public percentage?: string;
  public text?: string;
  public sellCondition?: string;
  public paymentMethod?: string;
  public withOutTaxes?: boolean;
  public withOutTaxesAccount?: boolean;
  public account?: string;
  public analyzeData?: boolean;
  public greaterThan?: number;
  public lessThan?: number;
}
