import {EntityModel} from '../../../core/model/entity.model';
import {LocalDate} from '@js-joda/core';
import {JobPosition} from './job-position';
import {Department} from './department';

export class Employee extends EntityModel {
  public name?: string;

  public lastName?: string;

  public identification?: string;

  public identificationType?: string;

  public email?: string;

  public startDate?: LocalDate;

  public endDate?: LocalDate;

  public married?: boolean;

  public children?: number;

  public alimony?: number;

  public embargoes?: number;

  public bankAccount?: string;

  public bank?: string;

  public retentions?: number;

  public positionOfTrust?: boolean;

  public extras?: boolean;

  public department?: number;

  public departmentName?: string;

  public workday?: number;
}
