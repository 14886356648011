import {Injectable} from '@angular/core';



import {HttpClient} from '@angular/common/http';
import {BaseService} from '../../core/service/base.service';
import {Salary} from './resource/salary';


@Injectable()
export class SalaryService extends BaseService<Salary> {

  serviceUrl(): string {
    return `/api/t/{tenant}/salaries`;
  }

  constructor(protected http: HttpClient) {
    super(http);
  }

}
