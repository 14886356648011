import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Code, CodeType} from '../model/code';
import {HeaderConstants} from '../core/utils/headers.constants';

@Injectable({
  providedIn: 'root'
})
export class CodeService {
  SERVER_URL = '/api/codes';
  constructor(private httpClient: HttpClient) { }

  getCodes(type: CodeType, description?: string, parentId?: number, code?: string, refresh = false): Observable<Code[]> {

    let paramsData = new HttpParams().append('type', type);
    if (description) {
      paramsData = paramsData.append('description', description);
    }
    if (parentId) {
      paramsData = paramsData.append('parentId', parentId);
    }
    if (code) {
      paramsData = paramsData.append('code', code);
    }
    const options = {params: paramsData, headers: new HttpHeaders().set(HeaderConstants.CACHED, refresh ? 'false' : 'true')};
    return this.httpClient
      .get<Code[]>(`${this.SERVER_URL}`, options );
  }

  updateCodeData(type: CodeType, code: string, data: Code): Observable<any> {
    const paramsData = new HttpParams()
      .append('type', type)
      .append('code', code);
    const options = {params: paramsData};
    return this.httpClient.put(`${this.SERVER_URL}`, JSON.stringify(data), options);
  }
}
