import {Injectable} from '@angular/core';

import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Employee} from './resource/employee';
import {BaseService} from '../../core/service/base.service';
import {Observable} from 'rxjs';
import {HeaderConstants} from '../../core/utils/headers.constants';

@Injectable()
export class EmployeeService extends BaseService<Employee> {

  serviceUrl(): string {
    return `/api/t/{tenant}/employees`;
  }

  constructor(protected http: HttpClient) {
    super(http);
  }

  getAll(refresh = false): Observable<Employee[]> {
    const paramsData = new HttpParams();
    const options = {params: paramsData, headers: new HttpHeaders().set(HeaderConstants.CACHED, refresh ? 'false' : 'true')};
    return this.http
      .get<Employee[]>(`${this.serviceUrl()}/all`, options );
  }
}
