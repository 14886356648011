<div class="container">

  <div class="loader" *ngIf="loading"></div>

  <div *ngIf="!loading" class="animate__animated animate__fadeIn">
    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">
      <div class="container">
        <span class="navbar-text">
          Inicio
        </span>
      </div>
    </nav>

    <div>
      <div *ngIf="!summaryLoaded">
        <p class="alert alert-info">No existen datos para el periodo seleccionado, intente con un mes o año distinto.</p>
      </div>
      <div class="row pb-3">
        <div class="col-sm-3">
          <select class="form-select" id="month" [(ngModel)]="month" (ngModelChange)="monthChanged(month)" title="Estado">
            <option value="January" selected>Enero</option>
            <option value="February" selected>Febrero</option>
            <option value="March" selected>Marzo</option>
            <option value="April" selected>Abril</option>
            <option value="May" selected>Mayo</option>
            <option value="June" selected>Junio</option>
            <option value="July" selected>Julio</option>
            <option value="August" selected>Agosto</option>
            <option value="September" selected>Setiembre</option>
            <option value="October" selected>Octubre</option>
            <option value="November" selected>Noviembre</option>
            <option value="December" selected>Diciembre</option>
          </select>
        </div>
        <div class="col-sm-3">
          <select class="form-select" id="year" [(ngModel)]="year" (ngModelChange)="yearChanged(year)" title="Estado">
            <option value="{{year-3}}" >{{year-3}}</option>
            <option value="{{year-2}}" >{{year-2}}</option>
            <option value="{{year-1}}" >{{year-1}}</option>
            <option value="{{year}}" selected>{{year}}</option>
            <option value="{{year+1}}" >{{year+1}}</option>
            <option value="{{year+2}}" >{{year+2}}</option>
            <option value="{{year+3}}" >{{year+3}}</option>
          </select>
        </div>
        <div class="col-sm-6" *ngIf="summaryLoaded">
          <div class="card text-dark bg-info bg-opacity-50">
            <div class="card-header"><strong>Estimado de Impuesto de Renta:</strong></div>
            <div class="card-body">
              <p class="card-text text-end">{{summary.taxToPayAccumulated | currency:summary.currency:'code'}}</p>
            </div>
          </div>
        </div>

      </div>



      <ng-container *ngIf="summaryLoaded">
            <h4 class="mt-3">Valores Mensuales</h4>

            <div class="row mb-3">
              <div class="col-sm-6">
                <div class="card text-dark bg-success bg-opacity-50">
                  <div class="card-header"><strong>Ventas</strong></div>
                  <div class="card-body">
                    <p class="card-text text-end">{{summary.subTotalSell | currency:summary.currency:'code'}}</p>

                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="card text-dark bg-warning bg-opacity-50 ">
                  <div class="card-header"><strong>Compras / Gastos</strong></div>
                  <div class="card-body">
                    <p class="card-text text-end">{{summary.subTotalPurchase | currency:summary.currency:'code'}}</p>

                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <div class="card text-dark bg-success bg-opacity-10">
                  <div class="card-header"><strong>Relación mensual IVA cobrado</strong></div>
                  <div class="card-body">
                    <p class="card-text text-end">{{summary.valueAddedTaxSell | currency:summary.currency:'code'}}</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="card text-dark bg-success bg-opacity-10">
                  <div class="card-header"><strong>Monto exonerado Ventas</strong></div>
                  <div class="card-body">
                    <p class="card-text text-end">{{summary.exoneratedSell | currency:summary.currency:'code'}}</p>

                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="card text-dark bg-warning bg-opacity-10 ">
                  <div class="card-header"><strong>Relación mensual IVA pagado</strong></div>
                  <div class="card-body">
                    <p class="card-text text-end">{{summary.valueAddedTaxPurchase | currency:summary.currency:'code'}}</p>

                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="card text-dark bg-warning bg-opacity-10 ">
                  <div class="card-header"><strong>Monto exonerado Compras</strong></div>
                  <div class="card-body">
                    <p class="card-text text-end">{{summary.exoneratedPurchase | currency:summary.currency:'code'}}</p>
                  </div>
                </div>
              </div>
            </div>

            <h4 class="mt-3">Acumulado del periodo</h4>

            <div class="row mb-3">
              <div class="col-sm-6">
                <div class="card text-dark bg-success bg-opacity-50">
                  <div class="card-header"><strong>Ventas</strong></div>
                  <div class="card-body">
                    <p class="card-text text-end">{{summary.subTotalSellAccumulated | currency:summary.currency:'code'}}</p>

                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="card text-dark bg-warning bg-opacity-50 ">
                  <div class="card-header"><strong>Compras / Gastos</strong></div>
                  <div class="card-body">
                    <p class="card-text text-end">{{summary.subTotalPurchaseAccumulated | currency:summary.currency:'code'}}</p>

                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <div class="card text-dark bg-success bg-opacity-10">
                  <div class="card-header"><strong>Relación mensual IVA cobrado</strong></div>
                  <div class="card-body">
                    <p class="card-text text-end">{{summary.valueAddedTaxSellAccumulated | currency:summary.currency:'code'}}</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="card text-dark bg-success bg-opacity-10">
                  <div class="card-header"><strong>Monto exonerado Ventas</strong></div>
                  <div class="card-body">
                    <p class="card-text text-end">{{summary.exoneratedSellAccumulated | currency:summary.currency:'code'}}</p>

                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="card text-dark bg-warning bg-opacity-10 ">
                  <div class="card-header"><strong>Relación mensual IVA pagado</strong></div>
                  <div class="card-body">
                    <p class="card-text text-end">{{summary.valueAddedTaxPurchaseAccumulated | currency:summary.currency:'code'}}</p>

                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="card text-dark bg-warning bg-opacity-10 ">
                  <div class="card-header"><strong>Monto exonerado Compras</strong></div>
                  <div class="card-body">
                    <p class="card-text text-end">{{summary.exoneratedPurchaseAccumulated | currency:summary.currency:'code'}}</p>
                  </div>
                </div>
              </div>
            </div>
      </ng-container>
    </div>

  </div>
</div>


