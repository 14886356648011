import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {Payroll} from './resource/payroll';
import {BaseService} from '../../core/service/base.service';
import {Observable} from 'rxjs';
import {LocalDate} from '@js-joda/core';
import {PayrollDate} from './resource/payroll-date';

@Injectable()
export class PayrollService extends BaseService<Payroll> {

  serviceUrl(): string {
    return `/api/t/{tenant}/payrolls`;
  }

  constructor(protected http: HttpClient) {
    super(http);
  }

  generate(selectedDate: LocalDate): Observable<Payroll> {
    const payrollDate = new PayrollDate();
    payrollDate.date = selectedDate;
    return this.http
      .post<Payroll>(`${this.serviceUrl()}/generate`, JSON.stringify(payrollDate));
  }

}
