import {Injectable} from '@angular/core';


import {BaseService} from '../core/service/base.service';
import {User, UserFullInfo} from '../core/model/user';
import {Observable} from 'rxjs';
import {Tenant} from '../core/model/tenant';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class TenantUserService extends BaseService<User> {

  serviceUrl(): string {
    return '/api/t/{tenant}/users';
  }

  constructor(protected http: HttpClient) {
    super(http);
  }

  getUserTenants(): Observable<Tenant[]> {
    return this.http
      .get<Tenant[]>(`/api/admin/users/me/tenants`);
  }

  getUserDetails(): Observable<UserFullInfo> {
    return this.http
      .get('/api/admin/users/me/all');
  }
}
