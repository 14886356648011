import {Component, OnInit} from '@angular/core';
import {UserDetails} from '../../model/user-details.model';
import {CoreComponent} from '../core.component';
import {AlertService} from '../../service/alert.service';
import {Router} from '@angular/router';
import {SecurityService} from '../../service/security.service';
import {PublicService} from '../../service/public.service';
import {LogoutMessage} from '../../message/logout.message';
import {MessageService} from '../../service/message.service';
import {LoggerService} from "../../../service/logger.service";

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html'
})
export class RegistrationComponent extends CoreComponent implements OnInit {

  model: UserDetails = new UserDetails();

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>().set('name', '').set('lastName', '').set('email', '').set('password', '').set('companyName', '');
  }

  constructor(protected alert: AlertService,
              protected router: Router,
              protected messageService: MessageService,
              protected securityService: SecurityService,
              protected publicService: PublicService,
              protected loggerService: LoggerService) {
    super(alert, router, securityService, loggerService);
  }

  ngOnInit(): void {
    this.messageService.publish(new LogoutMessage(true));
  }


  registerUser(): void {
    this.clearFormErrors();
    this.loading = true;
    this.publicService
      .registerUser(this.model)
      .subscribe(
        completed => {
          this.alert.success('Usuario registrado exitósamente');
          this.loading = false;
          this.router.navigate(['/login']);
        },
        error => this.processError(error));

  }


}
