import {Component, OnDestroy, OnInit} from '@angular/core';
import {AlertService} from '../../../core/service/alert.service';
import {SecurityService} from '../../../core/service/security.service';
import {Router} from '@angular/router';
import {DocumentService} from '../../../service/document.service';
import {ComprobanteElectronico} from '../../../model/v4_3/comprobante-electronico';
import {ClientService} from '../../../service/client.service';
import {Client, ClientType, CommercialActivity} from '../../../model/client';
import {MessageService} from '../../../core/service/message.service';
import {Code, CodeType} from '../../../model/code';
import {CodeService} from '../../../service/code.service';
import {CoreComponent} from '../../../core/component/core.component';
import {LineaDetalle} from '../../../model/v4_3/linea-detalle';
import {HaciendaService} from '../../../service/hacienda.service';
import '@js-joda/timezone';
import {Page} from '../../../model/page';
import {ClientFilter} from '../../../filter/client.filter';
import {LocalDate, ZonedDateTime, ZoneId} from '@js-joda/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {DocumentFilter} from '../../../filter/document.filter';
import {Document, DocumentStatus} from '../../../model/document';
import {InformacionReferencia} from "../../../model/v4_3/informacion-referencia";
import {LoggerService} from "../../../service/logger.service";

@Component({
  selector: 'app-create-document',
  templateUrl: './create-document.component.html'
})
export class CreateDocumentComponent extends CoreComponent implements OnInit {
  invoice: ComprobanteElectronico = new ComprobanteElectronico();
  identification = '';
  documentSearch = '';
  clientsFound: Page<Client> = new Page<Client>({count : 0 , page : 0, pages : 0});
  documentsFound: Page<Document> = new Page<Document>({count : 0 , page : 0, pages : 0});
  searchDone = false;
  selectedClient?: Client;
  selectedDocument?: Document;
  currentClient?: Client;
  currencies: Code[] = [];
  paymentMethods: Code[] = [];
  sellConditions: Code[] = [];
  referenceCodes: Code[] = [];
  documentTypes: Code[] = [];
  economicActivity: Code[] = [];
  units: Code[] = [];
  taxes: Code[] = [];
  rates: Code[] = [];
  invoiceEntry = false;
  responseMessage = -1;
  editableDocumentLine = new LineaDetalle();
  simpleDocumentEntry = false;
  dateLocalDate = LocalDate.now();
  date: NgbDateStruct = {
    year: this.dateLocalDate.year(),
    month: this.dateLocalDate.monthValue(),
    day: this.dateLocalDate.dayOfMonth()
  };
  constructor(private documentService: DocumentService,
              private alertService: AlertService,
              public router: Router,
              protected securityService: SecurityService,
              protected clientService: ClientService,
              protected messageService: MessageService,
              protected haciendaService: HaciendaService,
              protected codeService: CodeService,
              protected loggerService: LoggerService) {
    super(alertService, router, securityService, loggerService);

  }

  ngOnInit(): void {
    this.codeService.getCodes(CodeType.CODIGO_MONEDA).subscribe(
      codes => this.currencies = codes
    );
    this.codeService.getCodes(CodeType.MEDIO_PAGO).subscribe(
      codes => this.paymentMethods = codes
    );
    this.codeService.getCodes(CodeType.CONDICION_VENTA).subscribe(
      codes => this.sellConditions = codes
    );
    this.codeService.getCodes(CodeType.CODIGO_REFERENCIA).subscribe(
      codes => this.referenceCodes = codes
    );
    this.codeService.getCodes(CodeType.TIPO_DOCUMENTO_REFERENCIA).subscribe(
      codes => this.documentTypes = codes
    );
    this.codeService.getCodes(CodeType.UNIDAD_MEDIDA).subscribe(
      codes => this.units = codes
    );
    this.codeService.getCodes(CodeType.CODIGO_IMPUESTO).subscribe(
      codes => this.taxes = codes
    );
    this.codeService.getCodes(CodeType.TARIFA_IMPUESTO_VALOR_AGREGADO).subscribe(
      codes => this.rates = codes
    );
    this.clear();
  }

  clear(): void {

    this.invoice = new ComprobanteElectronico();
    this.identification = '';
    this.clientsFound = new Page<Client>({count : 0 , page : 0, pages : 0});
    this.searchDone = false;
    this.invoiceEntry = false;
    this.responseMessage = -1;
    this.editableDocumentLine = new LineaDetalle();
    this.simpleDocumentEntry = false;
    this.selectedClient = undefined;
    this.currentClient = undefined;

    this.dateLocalDate = LocalDate.now();
    this.invoice.FechaEmision =  ZonedDateTime.of(this.dateLocalDate.atStartOfDay(), ZoneId.of('America/Costa_Rica'));
    this.date = {
      year: this.dateLocalDate.year(),
      month: this.dateLocalDate.monthValue(),
      day: this.dateLocalDate.dayOfMonth()
    };
    this.loadMainClient();
    this.invoice.DetalleServicio = [];
    this.invoice.ResumenFactura.CodigoTipoMoneda.CodigoMoneda = 'CRC';
    this.invoice.ResumenFactura.CodigoTipoMoneda.TipoCambio = 1;
    this.invoice.CondicionVenta = '01';
    this.invoice.MedioPago = '99';
  }

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>(); // TODO add form values like .set('client.name', '').set('client.phone', '').set('client.email', '');
  }

  updateExchangeType(): void {
    if (this.invoice.ResumenFactura.CodigoTipoMoneda.CodigoMoneda === 'USD') {
      this.haciendaService.getExchangeType(this.dateLocalDate, this.dateLocalDate)
        .subscribe(value => {
          if (value.length > 0) {
            const exchangeType = value[0];
            this.invoice.ResumenFactura.CodigoTipoMoneda.TipoCambio = exchangeType.venta;
          }
        });
    } else if (this.invoice.ResumenFactura.CodigoTipoMoneda.CodigoMoneda === 'CRC') {
      this.invoice.ResumenFactura.CodigoTipoMoneda.TipoCambio = 1;
    }
  }

  searchEvent(): void {
    const clientFilter = new ClientFilter();
    clientFilter.text = this.identification;
    this.loading = true;
    this.clientService.searchByFilter(clientFilter).subscribe(
      value => {
        this.clientsFound = value;
        this.searchDone = true;
        this.loading = false;
      },
      error => this.processError(error));
  }

  searchDocumentEvent(): void {
    const documentFilter = new DocumentFilter();
    documentFilter.text = this.documentSearch;
    documentFilter.status = DocumentStatus.DOCUMENT_APPROVED;
    documentFilter.pending = 0;
    documentFilter.receiverId = this.invoice.Emisor.Identificacion.Numero;
    // Creditos https://www.hacienda.go.cr/ATV/ComprobanteElectronico/docs/esquemas/2016/v4.3/ANEXOS%20Y%20ESTRUCTURAS_V4.3.pdf tabla 5
    documentFilter.sellConditions = ['02'];
    this.loading = true;
    this.documentService.searchByFilter(documentFilter).subscribe(
      value => {
        this.documentsFound = value;
        this.searchDone = true;
        this.loading = false;
      },
      error => this.processError(error));
  }



  selectClientEvent(client: Client): void {
    this.selectedClient = client;
    this.simpleDocumentEntry = true;
    this.searchDone = false;
    this.invoice.Emisor.Nombre = client.name;
    this.invoice.Emisor.Identificacion.Numero = client.identification;
    this.invoice.Emisor.Identificacion.Tipo = client.identificationType;

    if (this.currentClient) {
      this.invoice.Receptor.Nombre = this.currentClient.name;
      this.invoice.Receptor.Identificacion.Numero = this.currentClient.identification;
      this.invoice.Receptor.Identificacion.Tipo = this.currentClient.identificationType;
    }
  }

  createEvent(): void {
    this.loading = true;
    this.documentService.importDocument(this.invoice).subscribe(
      value => {
        this.clear();
        this.loading = false;
        this.alertService.success('Documento guardado con exito');
        },
      error => this.processError(error));
  }

  addDetailLine(): void {
    if (!this.invoice.DetalleServicio) {
      this.invoice.DetalleServicio = [];
    }
    this.editableDocumentLine = new LineaDetalle();
    this.invoice.DetalleServicio?.push(this.editableDocumentLine);
  }

  copyDetailLine(invoiceDetailLine: number): void {
    if (this.invoice.DetalleServicio) {
      const elementToCopy = this.invoice.DetalleServicio[invoiceDetailLine];
      this.invoice.DetalleServicio?.push(JSON.parse(JSON.stringify(elementToCopy)));
      this.updateLines();
    }
  }

  updateDetailLine(): void {
    if (this.invoice.DetalleServicio) {
      const line = this.editableDocumentLine;
      if (!line.Cantidad) {
        line.Cantidad = 1;
      }
      line.MontoTotal = (line.PrecioUnitario ? line.PrecioUnitario : 0) * (line.Cantidad ? line.Cantidad : 0);
      if (line.DescuentoSimple.MontoDescuento) {
        line.SubTotal = line.MontoTotal - line.DescuentoSimple.MontoDescuento;
        line.Descuento = [];
        line.Descuento.push(line.DescuentoSimple);
      } else {
        line.SubTotal = line.MontoTotal;
      }
      if (line.ImpuestoSimple.Codigo) {
        line.ImpuestoSimple.Monto = line.SubTotal * ((line.ImpuestoSimple.Tarifa ? line.ImpuestoSimple.Tarifa : 1) / 100);
        line.Impuesto = [];
        line.Impuesto.push(line.ImpuestoSimple);
      }
      line.MontoTotalLinea = line.SubTotal + (line.ImpuestoSimple.Monto ? line.ImpuestoSimple.Monto : 0);
    }
    this.updateLines();
  }

  updateLines(): void {
    if (this.invoice.DetalleServicio) {
      let i = 1;
      let total = 0;
      let totalTax = 0;
      for (const detail of this.invoice.DetalleServicio) {
        detail.NumeroLinea = i;
        if (detail.MontoTotalLinea) {
          total += detail.MontoTotalLinea;
        }
        if (detail.Impuesto) {
          for (const tax of detail.Impuesto) {
            if (tax.Monto) {
              totalTax += tax.Monto;
            }
          }
        }
        i++;
      }
      if (this.invoice.ResumenFactura) {
        this.invoice.ResumenFactura.TotalComprobante = total;
        this.invoice.ResumenFactura.TotalImpuesto = totalTax;
      }
    }
  }

  removeDetailLine(invoiceDetailLine: number): void {
    this.invoice.DetalleServicio?.splice(invoiceDetailLine, 1);
    this.updateLines();
  }

  loadTaxPayerData(): void {
    if (this.invoice.Emisor.Identificacion.Numero) {
      this.loading = true;
      this.haciendaService.getTaxPayerDetails(this.invoice.Emisor.Identificacion.Numero).subscribe(
        value => {
          this.economicActivity = [];
          value.actividades?.forEach(activity => {
            const commercialActivity = new CommercialActivity();
            commercialActivity.code = activity.codigo ? activity.codigo : '';
            this.economicActivity.push(commercialActivity);
            this.codeService.getCodes( CodeType.ACTIVIDAD_ECONOMICA, undefined, undefined, activity.codigo)
              .subscribe(codeValue => commercialActivity.description = codeValue[0].description ? codeValue[0].description : '');
          });
          if (this.economicActivity.length === 0) {
            this.alertService.error('No se encontraron actividades económicas asignadas para el cliente seleccionado');
          }
          this.loading = false;
        }, error => this.processError(error));
    }
  }

  dateChanged(date: NgbDateStruct): void {
    this.dateLocalDate = LocalDate.of(date.year, date.month, date.day);
    this.invoice.FechaEmision =  ZonedDateTime.of(this.dateLocalDate.atStartOfDay(), ZoneId.of('America/Costa_Rica'));
    this.updateExchangeType();
  }

  searchByKeyEvent(): void {
    // 50619072100 4000042139 00100003010004982152102436011
    // 4000042139
    this.loading = true;
    if (this.invoice.Clave) {
      this.identification = this.invoice.Clave.slice(9, 20).replace(/^0+/, '');
      this.invoice.NumeroConsecutivo = this.invoice.Clave.slice(21, 21 + 20);
      // this.searchEvent();
      this.documentService.importResponse(this.invoice.Clave).subscribe(
        value => {
          this.alertService.success('Respuesta importada desde Hacienda con éxito');
          this.invoice.Emisor.Nombre = value.NombreEmisor;
          this.invoice.Emisor.Identificacion.Numero = value.NumeroCedulaEmisor;
          this.invoice.Emisor.Identificacion.Tipo = value.TipoIdentificacionEmisor;
          this.invoice.Receptor.Nombre = value.NombreReceptor;
          this.invoice.Receptor.Identificacion.Numero = value.NumeroCedulaReceptor;
          this.invoice.Receptor.Identificacion.Tipo = value.IipoIdentificacionReceptor;
          this.invoice.FechaEmision = value.FechaEmision;
          this.invoiceEntry = true;
          this.loading = false;
          this.responseMessage = value.Mensaje ? value.Mensaje : -1;
          this.loadTaxPayerData();
        },
        errorValue => {
          this.invoiceEntry = false;
          this.processError(errorValue);
        }
      );
    } else {
      this.loading = false;
    }
  }

  updateTax(): void {
    if (this.editableDocumentLine.Codigo) {
      this.haciendaService.getProductDetails(this.editableDocumentLine.Codigo).subscribe(
        value => {
          if (value.length > 0) {
            const product = value[0];
            this.editableDocumentLine.ImpuestoSimple.Tarifa = product.impuesto;
          }
        });
    }
  }

  editLine(i: number): void {
    if (this.invoice.DetalleServicio) {
      this.editableDocumentLine = this.invoice.DetalleServicio[i];
    }
  }

  loadMainClient(): void {
    const searchExample = new Client();
    searchExample.type = ClientType.MAIN;
    this.loading = true;
    this.clientService.search(searchExample).subscribe(
      value => {
        const existentMainClient = value.content?.pop();
        if (existentMainClient) {
          this.currentClient = existentMainClient;
        }
        this.loading = false;
      },
      error => this.processError(error));
  }

  switchIssuerAndReceiver(): void {
    const issuer = this.invoice.Emisor;
    const receiver = this.invoice.Receptor;
    this.invoice.Emisor = receiver;
    this.invoice.Receptor = issuer;
  }

  private updateSimpleDocumentEntryData(): void {
    this.invoice.DetalleServicio = [];
    const simpleDetail = new LineaDetalle();
    simpleDetail.NumeroLinea = 1;
    simpleDetail.Detalle = 'Línea única';
    simpleDetail.MontoTotalLinea = this.invoice.ResumenFactura.TotalComprobante;
    simpleDetail.SubTotal = this.invoice.ResumenFactura.TotalComprobante;
    simpleDetail.Cantidad = 1;
    simpleDetail.PrecioUnitario = this.invoice.ResumenFactura.TotalComprobante;
    this.invoice.DetalleServicio.push(simpleDetail);
    this.invoice.ResumenFactura.TotalImpuesto = 0;
  }

  selectDocumentEvent(document: Document): void {
    this.selectedDocument = document;
    const reference = new InformacionReferencia();
    reference.Numero = document.key;
    this.invoice.InformacionReferencia = reference;
  }
}
