<nav class="navbar navbar-dark bg-dark fixed-top navbar-expand-lg" role="navigation">
  <div class="container-fluid">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarMenu"
            aria-controls="navbarMenu" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand" routerLink="/inicio">ContaPlus <span class="badge bg-danger text-uppercase" *ngIf="env.environmentName !== 'aws-prod'">{{env.environmentName}}</span></a>
    <!--div *ngIf="loggedIn" class="dropdown d-lg-none">

      <a class="btn btn-secondary dropdown-toggle"  href="#" role="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {{user?.name}}
      </a>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <mt-tenant-selector></mt-tenant-selector>
        <div class="dropdown-divider"></div>
        <button class="dropdown-item btn-link" (click)="logout()">Salir</button>
      </div>
    </div-->
    <div *ngIf="loggedIn" class="collapse navbar-collapse" id="navbarMenu">
      <ul class="navbar-nav me-auto">
        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" *mtHasPermission="['ADMIN_CLIENTS']">
          <a class="nav-link" *mtHasPermission="['ADMIN_CLIENTS']" routerLink="/clientes" routerLinkActive="active" >Clientes</a>
        </li>
        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" *mtHasPermission="['ADMIN_DOCUMENTS', 'VIEW_DOCUMENTS']">
          <a class="nav-link" *mtHasPermission="['ADMIN_DOCUMENTS', 'VIEW_DOCUMENTS']" routerLink="/documentos/ver"
             [class.active]="router.isActive('/documentos/ver', {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'}) ||
             router.isActive('/documentos/agregar', {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'}) ||
             router.isActive('/documentos/categorizar', {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'}) ||
            router.isActive('/documentos/clasificar', {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'})"
          >Documentos</a>
        </li>

        <li class="nav-item  dropdown" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" *mtHasPermission="['ADMIN_DOCUMENTS']" >
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownProcess" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
             [class.active]="router.isActive('/procesos/cierre-periodo', {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'}) ||
             router.isActive('/procesos/modificacion-presupuestaria', {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'}) ||
             router.isActive('/procesos/planilla-presupuestaria', {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'})"
          >
            Procesos
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownProcess">
            <a class="dropdown-item" routerLink="/procesos/cierre-periodo"  *mtHasPermission="['ADMIN_DOCUMENTS']" routerLinkActive="active">Cierre</a>
            <ng-container *ngIf="publicInstitution">
              <a class="dropdown-item" routerLink="/procesos/planilla-presupuestaria"  *mtHasPermission="['VIEW_BUDGET_FORMS', 'ADMIN_BUDGET_FORMS']" routerLinkActive="active">Planillas Presupuestarias</a>
              <a class="dropdown-item" routerLink="/procesos/modificacion-presupuestaria"  *mtHasPermission="['ADMIN_DOCUMENTS']" routerLinkActive="active">Modificación Presupuestaria</a>
            </ng-container>
          </div>
        </li>

        <li class="nav-item  dropdown" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" *mtHasPermission="['ADMIN_ACCOUNT', 'ADMIN_USERS']">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
             [class.active]="router.isActive('/configuracion/general', {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'}) ||
             router.isActive('/configuracion/cuentas-bancarias', {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'}) ||
             router.isActive('/configuracion/cuentas/contables', {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'}) ||
             router.isActive('/configuracion/cuentas/presupuestarias', {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'}) ||
            router.isActive('/users', {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'})"
          >
            Configuración
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" routerLink="/configuracion/general"  *mtHasPermission="['ADMIN_ACCOUNT']" routerLinkActive="active">General</a>
              <a class="dropdown-item" routerLink="/configuracion/cuentas-bancarias"  *mtHasPermission="['ADMIN_ACCOUNT']" routerLinkActive="active">Cuentas Bancarias</a>
              <a class="dropdown-item" routerLink="/configuracion/cuentas/contables"  *mtHasPermission="['ADMIN_ACCOUNT']" routerLinkActive="active">Cuentas</a>
              <ng-container *ngIf="publicInstitution">
                <a class="dropdown-item" routerLink="/configuracion/cuentas/presupuestarias"  *mtHasPermission="['ADMIN_ACCOUNT']" routerLinkActive="active">Cuentas Presupuestarias</a>
              </ng-container>
              <a class="dropdown-item" routerLink="/configuracion/usuarios" *mtHasPermission="['ADMIN_USERS']" routerLinkActive="active">Usuarios</a>
          </div>
        </li>
        <li class="nav-item  dropdown" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" *mtHasPermission="['VIEW_REPORTS']">
          <a class="nav-link dropdown-toggle" href="#" id="reportsDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
             [class.active]="router.isActive('/reportes/balance', {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'}) ||
             router.isActive('/reportes/resultados', {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'}) ||
             router.isActive('/reportes/pagos-efectivo', {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'}) ||
             router.isActive('/reportes/gastos', {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'}) ||
             router.isActive('/reportes/retenciones', {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'}) ||
             router.isActive('/reportes/retenciones-proveedor', {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'}) ||
             router.isActive('/reportes/detalles-facturas-proveedor', {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'}) ||
             router.isActive('/reportes/quarterly', {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'}) ||
             router.isActive('/reportes/consolidado', {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'}) ||
             router.isActive('/reportes/segregado', {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'}) ||
             router.isActive('/reportes/planillas', {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'})"
          >
            Reportes
          </a>
          <div class="dropdown-menu" aria-labelledby="reportsDropdown">
            <a class="dropdown-item" routerLink="/reportes/balance"  *mtHasPermission="['VIEW_REPORTS']" routerLinkActive="active">Reporte de Balance</a>
            <a class="dropdown-item" routerLink="/reportes/resultados"  *mtHasPermission="['VIEW_REPORTS']" routerLinkActive="active">Reporte de Resultados</a>
            <a class="dropdown-item" routerLink="/reportes/gastos"  *mtHasPermission="['VIEW_REPORTS']" routerLinkActive="active">Reporte de Gastos</a>
            <a class="dropdown-item" routerLink="/reportes/pagos-efectivo"  *mtHasPermission="['VIEW_REPORTS']" routerLinkActive="active">Reporte de Pagos en Efectivo</a>
            <ng-container *ngIf="publicInstitution">
              <a class="dropdown-item" routerLink="/reportes/planillas"  *mtHasPermission="['VIEW_REPORTS']" routerLinkActive="active">Reporte de Planillas</a>
              <a class="dropdown-item" routerLink="/reportes/retenciones"  *mtHasPermission="['VIEW_REPORTS']" routerLinkActive="active">Reporte de Retenciones</a>
              <a class="dropdown-item" routerLink="/reportes/trimestral"  *mtHasPermission="['VIEW_REPORTS']" routerLinkActive="active">Reporte Trimestral</a>
              <a class="dropdown-item" routerLink="/reportes/consolidado"  *mtHasPermission="['VIEW_REPORTS']" routerLinkActive="active">Reporte Consolidado</a>
              <a class="dropdown-item" routerLink="/reportes/segregado"  *mtHasPermission="['VIEW_REPORTS']" routerLinkActive="active">Reporte Segregado</a>
              <a class="dropdown-item" routerLink="/reportes/retenciones-proveedor"  *mtHasPermission="['VIEW_REPORTS']" routerLinkActive="active">Reporte Retenciones Proveedor</a>
              <a class="dropdown-item" routerLink="/reportes/detalles-facturas-proveedor"  *mtHasPermission="['VIEW_REPORTS']" routerLinkActive="active">Reporte Facturas Proveedor</a>
            </ng-container>
          </div>
        </li>
        <li class="nav-item  dropdown" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" *mtHasPermission="['ADMIN_DOCUMENTS']">
          <a class="nav-link dropdown-toggle" href="#" id="auxiliariesDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
             [class.active]="router.isActive('/auxiliares/cuentas-por-cobrar', {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'})||
             router.isActive('/auxiliares/balance-cuentas-presupuestarias', {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'})"
          >
            Auxiliares
          </a>
          <div class="dropdown-menu" aria-labelledby="auxiliariesDropdown">
            <a class="dropdown-item" routerLink="/auxiliares/cuentas-por-cobrar"  *mtHasPermission="['ADMIN_DOCUMENTS']" routerLinkActive="active">Cuentas por Cobrar</a>
            <ng-container *ngIf="publicInstitution">
              <a class="dropdown-item" routerLink="/auxiliares/balance-cuentas-presupuestarias"  *mtHasPermission="['ADMIN_DOCUMENTS']" routerLinkActive="active">Saldo Cuentas Presupuestarias</a>
            </ng-container>
          </div>
        </li>

        <ng-container>
          <li class="nav-item  dropdown" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" *mtHasPermission="['ADMIN_DOCUMENTS']">
            <a class="nav-link dropdown-toggle" href="#" id="formsDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
               [class.active]="router.isActive('/formularios/d101', {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'})"
            >
              Formularios
            </a>
            <div class="dropdown-menu" aria-labelledby="formsDropdown">
              <a class="dropdown-item" routerLink="/formularios/d101"  *mtHasPermission="['ADMIN_DOCUMENTS']" routerLinkActive="active">D101</a>
            </div>
          </li>
        </ng-container>

        <li class="nav-item  dropdown" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" *mtHasPermission="['ADMIN_SYSTEM']">
          <a class="nav-link dropdown-toggle" href="#" id="systemDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
             [class.active]="router.isActive('/sistema/', {paths: 'subset', queryParams: 'ignored', fragment: 'ignored', matrixParams: 'ignored'})"
          >
            Sistema
          </a>
          <div class="dropdown-menu" aria-labelledby="systemDropdown">
            <a class="dropdown-item" routerLink="/sistema/usuarios"  *mtHasPermission="['ADMIN_SYSTEM']" routerLinkActive="active">Usuarios</a>
          </div>
        </li>

        <ng-container *ngIf="env.environmentName !== 'aws-prod'">
          <li class="nav-item  dropdown" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" *mtHasPermission="['ADMIN_RRHH']">
            <a class="nav-link dropdown-toggle" href="#" id="rrhhDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
               [class.active]="router.isActive('/recursos-humanos/departamentos', {paths: 'subset', queryParams: 'ignored', fragment: 'ignored', matrixParams: 'ignored'})||
                              router.isActive('/recursos-humanos/posiciones-laborales', {paths: 'subset', queryParams: 'ignored', fragment: 'ignored', matrixParams: 'ignored'})||
                              router.isActive('/recursos-humanos/empleados', {paths: 'subset', queryParams: 'ignored', fragment: 'ignored', matrixParams: 'ignored'})||
                              router.isActive('/recursos-humanos/registro-horas', {paths: 'subset', queryParams: 'ignored', fragment: 'ignored', matrixParams: 'ignored'})||
                              router.isActive('/recursos-humanos/jornadas', {paths: 'subset', queryParams: 'ignored', fragment: 'ignored', matrixParams: 'ignored'})||
                              router.isActive('/recursos-humanos/pagos', {paths: 'subset', queryParams: 'ignored', fragment: 'ignored', matrixParams: 'ignored'})||
                              router.isActive('/recursos-humanos/feriados', {paths: 'subset', queryParams: 'ignored', fragment: 'ignored', matrixParams: 'ignored'})||
                              router.isActive('/recursos-humanos/bonos', {paths: 'subset', queryParams: 'ignored', fragment: 'ignored', matrixParams: 'ignored'})||
                              router.isActive('/recursos-humanos/salarios', {paths: 'subset', queryParams: 'ignored', fragment: 'ignored', matrixParams: 'ignored'})||
                              router.isActive('/recursos-humanos/ausencias', {paths: 'subset', queryParams: 'ignored', fragment: 'ignored', matrixParams: 'ignored'})"
            >
              Recursos Humanos
            </a>
            <div class="dropdown-menu" aria-labelledby="rrhhDropdown">
              <a class="dropdown-item" routerLink="/recursos-humanos/departamentos"  *mtHasPermission="['ADMIN_RRHH']" routerLinkActive="active">Departamentos</a>
              <a class="dropdown-item" routerLink="/recursos-humanos/posiciones-laborales"  *mtHasPermission="['ADMIN_RRHH']" routerLinkActive="active">Posiciones Laborales</a>
              <a class="dropdown-item" routerLink="/recursos-humanos/rangos-impuesto-renta"  *mtHasPermission="['ADMIN_RRHH']" routerLinkActive="active">Rangos Impuesto Renta</a>
              <a class="dropdown-item" routerLink="/recursos-humanos/jornadas"  *mtHasPermission="['ADMIN_RRHH']" routerLinkActive="active">Jornadas</a>
              <a class="dropdown-item" routerLink="/recursos-humanos/feriados"  *mtHasPermission="['ADMIN_RRHH']" routerLinkActive="active">Feriados</a>
              <a class="dropdown-item" routerLink="/recursos-humanos/pagos"  *mtHasPermission="['ADMIN_RRHH']" routerLinkActive="active">Pagos</a>
              <a><hr class="dropdown-divider"></a>
              <a class="dropdown-item" routerLink="/recursos-humanos/empleados"  *mtHasPermission="['ADMIN_RRHH']" routerLinkActive="active">Empleados</a>
              <a class="dropdown-item" routerLink="/recursos-humanos/registro-horas"  *mtHasPermission="['ADMIN_RRHH']" routerLinkActive="active">Registro de Horas</a>
              <a class="dropdown-item" routerLink="/recursos-humanos/salarios"  *mtHasPermission="['ADMIN_RRHH']" routerLinkActive="active">Salarios</a>
              <a class="dropdown-item" routerLink="/recursos-humanos/bonos"  *mtHasPermission="['ADMIN_RRHH']" routerLinkActive="active">Bonos</a>
              <a class="dropdown-item" routerLink="/recursos-humanos/ausencias"  *mtHasPermission="['ADMIN_RRHH']" routerLinkActive="active">Ausencias</a>
            </div>
          </li>
        </ng-container>
      </ul>
    </div>
    <mt-tenant-selector *ngIf="loggedIn"></mt-tenant-selector>
    <ul *ngIf="loggedIn"  class="navbar-nav">
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{user?.name + ' ' + user?.lastName}}
        </a>
        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
          <button class="dropdown-item btn-link" (click)="logout()">Salir</button>
        </div>
      </li>
    </ul>
  </div>
</nav>
<div class="container">
  <mt-alert></mt-alert>
</div>
<router-outlet></router-outlet>
<!--div class="container-fluid">
  <div class="mt-content-wrapper">
    <div class="mt-left-panel-wrapper bg-dark">
      <ul class="nav  flex-column">
        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
          <a class="nav-link text-light" *mtHasPermission="['ADMIN_CLIENTS']" routerLink="/clients" routerLinkActive="active" >Clientes</a>
        </li>
        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
          <a class="nav-link text-light" *mtHasPermission="['ADMIN_DOCUMENTS']" routerLink="/documents/view" routerLinkActive="active" >Documentos</a>
        </li>
        <li class="nav-item dropright" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
          <a class="nav-link dropdown-toggle text-light" href="#" id="navbarDropdown1" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Configuración
          </a>
          <div class="dropdown-menu bg-dark" aria-labelledby="navbarDropdown1">
            <a class="dropdown-item text-light" routerLink="/configuration/general"  *mtHasPermission="['ADMIN_ACCOUNT']" routerLinkActive="active" >General</a>
            <a class="dropdown-item text-light" routerLink="/users" *mtHasPermission="['ADMIN_USERS']" routerLinkActive="active" >Usuarios</a>
          </div>
        </li>
      </ul>
    </div>
    <div class="container-fluid mt-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div-->

