<div class="container">

  <div class="loader" *ngIf="loading"></div>

  <div *ngIf="mode == 'EDIT'  && !loading" class="animate__animated animate__fadeIn">

    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">
      <div class="container-fluid">
        <span class="navbar-text">
          Usuario
        </span>
        <div class="pull-right">
          <button class="btn btn-link" (click)="cancelEvent()">
            Cerrar
          </button>
          <button class="btn btn-primary" (click)="saveEvent()">
            Guardar
          </button>
        </div>
      </div>
    </nav>

    <div class="form-group mb-3">
      <label class="form-label" for="name">Nombre</label>
      <input type="text" class="form-control" id="name" name="name" [(ngModel)]="model.name" placeholder="Nombre">
      <div *ngIf="formErrors.get('user.name')" class="badge bg-danger animated fadeIn">
        {{ formErrors.get('user.name') }}
      </div>
    </div>
    <div class="form-group mb-3">
      <label class="form-label" for="lastName">Apellidos</label>
      <input type="text" class="form-control" id="lastName" name="lastName" [(ngModel)]="model.lastName"
             placeholder="Apellidos">
      <div *ngIf="formErrors.get('user.lastName')" class="badge bg-danger animated fadeIn">
        {{ formErrors.get('user.lastName') }}
      </div>
    </div>
    <div class="form-group mb-3">
      <label class="form-label" for="email">Email</label>
      <input type="text" class="form-control" id="email" name="email" [(ngModel)]="model.email" placeholder="Email"
             autocomplete="email">
      <div *ngIf="formErrors.get('user.email')" class="badge bg-danger animated fadeIn">
        {{ formErrors.get('user.email') }}
      </div>
    </div>
    <div class="form-group mb-3" *ngIf="model.active != undefined">
      <label class="form-label">Estado</label>
      <input *ngIf="!model.active" class="form-control" value="Inactivo" readonly>
      <input *ngIf="model.active" class="form-control" value="Activo" readonly>
    </div>
  </div>

  <div *ngIf="mode == 'LIST'  && !loading" class="animate__animated animate__fadeIn">

    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">
      <div class="container-fluid">
        <span class="navbar-text">
          Usuarios
        </span>
        <div class="pull-right">
          <button class="btn  btn-secondary me-1" (click)="refreshEvent()" title="Refrescar">
            <span class="fa fa-sync fa-lg"></span>
          </button>
          <button class="btn  btn-primary" (click)="createEvent()" title="Agregar">
            Agregar
          </button>
        </div>
      </div>
    </nav>

    <mt-pagination (loadPage)="getPageEvent($event)" [page]="page"></mt-pagination>

    <table *ngIf="page.metadata.pages > 0" class="table table-hover">
      <tbody>
      <tr *ngFor="let model of page.content">

        <td (click)="editEvent(model)" class="mt-content-col">
          <strong>{{model.name}}</strong> -
          <span *ngIf="!model.active" class="badge bg-warning">Inactivo</span>
          <span *ngIf="model.active" class="badge bg-success">Activo</span>
          <br>
          <small>{{model.email}}</small>
        </td>
        <td class="mt-actions-col">
          <button class="btn btn-link btn-lg pull-right" data-bs-toggle="modal" data-bs-target="#removeUserConfirmation"
                  (click)="selectEvent(model)" title="Borrar">
            <span class="fa fa-trash-alt fa-lg"></span>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
    <div *ngIf="page.metadata.pages == 0">
      No hay registros
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="removeUserConfirmation" tabindex="-1" role="dialog"
       aria-labelledby="removeConfirmationLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Confirmar</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Desea eliminar el registro?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="removeEvent(model)">Borrar</button>
        </div>
      </div>
    </div>
  </div>

</div>


