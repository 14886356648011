import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../core/component/base.component';

import {AlertService} from '../../../core/service/alert.service';
import {MessageService} from '../../../core/service/message.service';
import {Router} from '@angular/router';
import {SecurityService} from '../../../core/service/security.service';
import {LoggerService} from '../../../service/logger.service';

import {SalaryService} from '../../service/salary.service';
import {Salary} from '../../service/resource/salary';
import {EmployeeService} from '../../service/employee.service';
import {Employee} from '../../service/resource/employee';
import {JobPosition} from '../../service/resource/job-position';
import {JobPositionService} from '../../service/job-position.service';

@Component({
  selector: 'app-salary',
  templateUrl: './salary.component.html'
})
export class SalaryComponent extends BaseComponent<Salary> implements OnInit, OnDestroy {

  employees: Employee[] = [];
  jobPositions: JobPosition[] = [];

  constructor(private salaryService: SalaryService,
              private alertService: AlertService,
              protected messageService: MessageService,
              protected loggerService: LoggerService,
              protected router: Router,
              protected securityService: SecurityService,
              protected employeeService: EmployeeService,
              protected jobPositionService: JobPositionService) {
    super(salaryService, 'Salary', alertService, router, securityService, messageService, loggerService);
  }

  getModelInstance(): Salary {
    return new Salary();
  }

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.employeeService.getAll().subscribe({
        next: (v) => this.employees = v,
        error: (e) => this.processError(e)
      }
    );
    this.jobPositionService.getAll().subscribe(
      {
        next: (v) => this.jobPositions = v,
        error: (e) => this.processError(e)
      }
    );
  }
}
