import {Injectable} from '@angular/core';

import {BaseService} from '../core/service/base.service';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Client} from '../model/client';
import {Observable} from 'rxjs';
import {Code} from '../model/code';
import {HeaderConstants} from '../core/utils/headers.constants';

@Injectable()
export class ClientService extends BaseService<Client> {

  serviceUrl(): string {
    return `/api/t/{tenant}/clients`;
  }

  constructor(protected http: HttpClient) {
    super(http);
  }

  getClientCodes(): Observable<Code[]> {
    const headersValue = new HttpHeaders().append(HeaderConstants.CACHED, 'true');
    const options = {headers: headersValue};
    return this.http.get<Code[]>(`${this.serviceUrl()}/codes`, options);
  }

  export(): Observable<any> {
    return this.http.post(`${this.serviceUrl()}/export`, '{}', {responseType: 'blob'});
  }
}
