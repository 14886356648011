import {EntityModel} from '../../../core/model/entity.model';
import {LocalDate} from '@js-joda/core';


export class Salary extends EntityModel {

  public employee?: number;
  public employeeName?: string;
  public jobPosition?: number;
  public jobPositionName?: string;
  public from?: LocalDate;
  public to?: LocalDate;
  public amount?: number;
  public currency?: string;
  public periodicity?: Periodicity;
}

export enum Periodicity {
  WEEKLY= 'WEEKLY',
  MONTHLY= 'MONTHLY',
  BIWEEKLY= 'BIWEEKLY',
  FORTNIGHTLY= 'FORTNIGHTLY'
}
