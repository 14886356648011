import {Component, OnDestroy, OnInit} from '@angular/core';
import {CoreComponent} from '../../core/component/core.component';
import {AlertService} from '../../core/service/alert.service';
import {Router} from '@angular/router';
import {SecurityService} from '../../core/service/security.service';
import {MessageService} from '../../core/service/message.service';
import {LoggerService} from '../../service/logger.service';
import {AccountService} from '../../service/account.service';
import {Account, AccountType} from '../../model/account';

@Component({
  selector: 'app-budget-account-balance',
  templateUrl: './budget-account-balance.component.html'
})
export class BudgetAccountBalanceComponent extends CoreComponent implements OnInit, OnDestroy {

  balance = 0;
  accounts: Account[] = [];
  code = '';
  constructor(private alertService: AlertService,
              protected router: Router,
              protected securityService: SecurityService,
              protected accountService: AccountService,
              protected clientAccountService: AccountService,
              protected messageService: MessageService,
              protected loggerService: LoggerService) {
    super(alertService, router, securityService, loggerService);

  }

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>();
  }

  ngOnInit(): void {
    this.clientAccountService.getClientAccountsWithExactLevel([], undefined, 3, 'description', AccountType.BUDGET)
      .subscribe(value => this.accounts = value);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  codeChanged(code: string): void {
    this.loading = true;
    this.accountService.getBudgetAccountBalance(this.code)
      .subscribe({
        next: (v) => {
          if (v.balance !== undefined) {
            this.balance = v.balance;
          }
          this.loading = false;
        },
        error: (e) => this.processError(e)
      });
  }
}
