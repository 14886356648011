export class DashboardSummary {
  constructor(public subTotalSell?: number,
              public subTotalPurchase?: number,
              public valueAddedTaxSell?: number,
              public valueAddedTaxPurchase?: number,
              public exoneratedSell?: number,
              public exoneratedPurchase?: number,
              public subTotalSellAccumulated?: number,
              public subTotalPurchaseAccumulated?: number,
              public valueAddedTaxSellAccumulated?: number,
              public valueAddedTaxPurchaseAccumulated?: number,
              public exoneratedSellAccumulated?: number,
              public exoneratedPurchaseAccumulated?: number,
              public taxToPayAccumulated?: number,
              public currency?: string
  ) {
  }
}
