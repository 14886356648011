import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CoreComponent} from '../../core/component/core.component';
import {AlertService} from '../../core/service/alert.service';
import {SecurityService} from '../../core/service/security.service';
import {TenantService} from '../../core/service/tenant.service';
import {Tenant} from '../../core/model/tenant';
import {MessageService} from '../../core/service/message.service';
import {TenantsReloadedMessage} from '../../core/message/tenant.message';
import {TenantUserService} from '../../service/tenant-user.service';
import {LoggerService} from '../../service/logger.service';
import ServiceUtils from '../../core/utils/service.utils';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html'
})
export class CompanyComponent extends CoreComponent implements OnInit {

  model: Tenant = new Tenant();

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>().set('tenant.name', '');
  }

  constructor(protected alert: AlertService,
              protected router: Router,
              protected securityService: SecurityService,
              private messageService: MessageService,
              private tenantService: TenantService,
              private userService: TenantUserService,
              protected loggerService: LoggerService) {
    super(alert, router, securityService, loggerService);
  }

  ngOnInit(): void {
  }


  createCompany(): void {
    this.tenantService
      .createTenant(this.model)
      .subscribe(
        completed => {
          this.alert.success('Compañía registrada exitósamente');
          this.userService.getUserDetails()
            .subscribe(user => {
              ServiceUtils.setItem('tenants', JSON.stringify(user.tenants));
              ServiceUtils.setItem('user', JSON.stringify(user.user));
              ServiceUtils.setItem('permissions', JSON.stringify(user.privilegeNames));
              this.messageService.publish(new TenantsReloadedMessage());
            });
        },
        error => this.processError(error));
  }
}
