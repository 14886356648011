import {Inject, Injectable} from '@angular/core';

import {RollbarService} from '../app.module';
import * as Rollbar from 'rollbar';
import {environment} from '../../environments/environment';
import ServiceUtils from '../core/utils/service.utils';

@Injectable({
  providedIn: 'root'
})
export class LoggerService  {

  env = environment;

  constructor(@Inject(RollbarService) private rollbar: Rollbar) {
  }

  public info(params: any[]): void {
    this.addSessionData(params);
    if (this.env.environmentName !== 'local') {
      this.rollbar.info(params);
    }else {
      // use console for local logging
      console.log(params);
    }
  }

  public error(params: any[]): void {
    this.addSessionData(params);
    if (this.env.environmentName !== 'local') {
      this.rollbar.error(params);
    }else {
      // use console for local logging
      console.log(params);
    }
  }

  public debug(params: any[]): void {
    this.addSessionData(params);
    if (this.env.environmentName !== 'local') {
      this.rollbar.debug(params);
    } else {
      // use console for local logging
      console.log(params);
    }
  }

  private addSessionData(params: any[]): void {
    const user = ServiceUtils.getItem('user');
    const tenant = ServiceUtils.getItem('tenant');
    params.push(user);
    params.push(tenant);
  }
}
