<div class="input-group me-2">
  <input class="form-control"
         placeholder="aaaa-mm-dd"
         name="{{name}}"
         [(ngModel)]="model"
         ngbDatepicker
         #d1="ngbDatepicker"
         id="{{id}}"
         (ngModelChange)="dateChanged($event)"
         title="{{label}}">
  <button class="btn btn-outline-secondary" (click)="d1.toggle()" type="button">
    <span class=" fa fa-calendar"></span>
  </button>
</div>
