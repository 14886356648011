<div class="container">

  <div class="loader" *ngIf="loading"></div>

  <div *ngIf="mode == 'EDIT' && !loading" class="animated fadeIn">
    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top">
      <div class="container">
        <span class="navbar-text">
          Bono
        </span>
        <div class="pull-right">
          <button class="btn btn-link" (click)="cancelEvent()">
            Cerrar
          </button>
          <button class="btn btn-primary" (click)="saveEvent()">
            Guardar
          </button>
        </div>
      </div>
    </nav>

    <div class="row g-3">
      <div class="col-md-4">
        <div class="form-group">
          <label for="date">Fecha</label>
          <mt-date-picker [(date)]="model.date" name="date" id="date" label="Fecha"/>
          <div *ngIf="formErrors.get('bonus.date')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('bonus.date') }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="currency">Moneda</label>
          <select class="form-select" id="currency" name="currency" [(ngModel)]="model.currency">
            <option value="CRC">Colones</option>
            <option value="USD">Dólares</option>
          </select>
          <div *ngIf="formErrors.get('bonus.currency')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('bonus.currency') }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="amount">Monto</label>
          <input type="text" class="form-control" id="amount" name="amount" [(ngModel)]="model.amount">
          <div *ngIf="formErrors.get('bonus.amount')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('bonus.amount') }}
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label for="description">Detalles</label>
          <input type="text" class="form-control" id="description" name="description" [(ngModel)]="model.description">
          <div *ngIf="formErrors.get('bonus.description')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('bonus.description') }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="recurrenceStart">Recurrencia Inicio</label>
          <mt-date-picker [(date)]="model.recurrenceStart" name="recurrenceStart" id="recurrenceStart" label="Final"/>
          <div *ngIf="formErrors.get('bonus.recurrenceStart')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('bonus.recurrenceStart') }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="recurrenceEnd">Recurrencia Final</label>
          <mt-date-picker [(date)]="model.recurrenceEnd" name="recurrenceEnd" id="recurrenceEnd" label="Final"/>
          <div *ngIf="formErrors.get('bonus.recurrenceEnd')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('bonus.recurrenceEnd') }}
          </div>
        </div>
      </div>
    </div>

    <h4  class="mb-3 mt-3">Empleados</h4>
    <hr class="mb-4">

    <div class="row g-3">

      <div class="col-md-6">
        <div class="form-group">
          <div class="input-group">
            <select class="form-select" id="employee" name="employee" [(ngModel)]="employeeId">
              <option *ngFor="let employee of employees" value="{{employee.id}}">{{employee.name}}</option>
            </select>

            <button class="btn btn-primary" (click)="addEmployee()">
              Agregar
            </button>
          </div>
        </div>
      </div>

      <div class="col-12">
        <ul class="list-group">
          <li *ngFor="let employee of model.employees"  class="list-group-item">
            <strong>{{employee.name}} {{employee.lastName}}</strong> /
            <span *ngIf="employee.email" class="text-muted">{{employee.email}}</span> /
            <span *ngIf="employee.identification" class="text-muted">{{employee.identification}}</span> /
            <span *ngIf="employee.departmentName" class="text-muted">{{employee.departmentName}}</span>
          </li>
        </ul>
      </div>

    </div>
  </div>

  <div *ngIf="mode == 'LIST' && !loading" class="animated fadeIn">

    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top">
      <div class="container">
        <span class="navbar-text">
          Bonos
        </span>
        <div class="pull-right">
          <button class="btn btn-link" (click)="refreshEvent()" title="Refrescar">
            <span class="fa fa-sync fa-lg"></span>
          </button>
          <button class="btn btn-primary" (click)="createEvent()">
            Agregar
          </button>
        </div>
      </div>
    </nav>

    <mt-pagination (loadPage)="getPageEvent($event)" [page]="page"></mt-pagination>

    <table *ngIf="page.metadata.pages > 0" class="table table-hover">
      <thead>
        <tr>
          <th scope="col" >
            Fecha
          </th>
          <th scope="col" class="text-end">
            Monto
          </th>
          <th scope="col">
            Detalles
          </th>
          <th>

          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let bonus of page.content">
          <td (click)="editEvent(bonus)" class="mt-content-col">
            {{bonus.date?.toString()  | date:'dd/MM/yyyy'}}
          </td>
          <td (click)="editEvent(bonus)" class="mt-content-col text-end">
            {{bonus.amount | currency:bonus.currency:'code'}}
          </td>
          <td (click)="editEvent(bonus)" class="mt-content-col">
            <span class="mt-large-text">{{bonus.description}}</span>
          </td>
          <td class="mt-actions-col">
            <button class="btn btn-link btn-lg pull-right" data-bs-toggle="modal" data-bs-target="#bonusConfirmation"
                    (click)="selectEvent(bonus)" title="Borrar">
              <span class="fa fa-trash-alt fa-lg"></span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="page.metadata.pages == 0">
      No hay registros
    </div>

  </div>

  <!-- Modal -->
  <div class="modal fade" id="bonusConfirmation" tabindex="-1" role="dialog"
       aria-labelledby="removeConfirmationLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirmar</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Desea eliminar el registro?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="removeEvent(model)">Borrar</button>
        </div>
      </div>
    </div>
  </div>

</div>


