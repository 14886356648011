import {EntityModel} from '../../../core/model/entity.model';
import {Employee} from './employee';
import {LocalDate} from '@js-joda/core';

export class Absence extends EntityModel {
  public employee?: number;
  public employeeName?: string;
  public date?: LocalDate;
  public hours?: number;
  public details?: string;
  public type?: AbsenceType;
}

export enum AbsenceType {
  PAID_VACATION= 'PAID_VACATION',
  MEDICAL_DISABILITY= 'MEDICAL_DISABILITY',
  UNPAID_VACATION= 'UNPAID_VACATION',
  OTHER= 'OTHER'
}
