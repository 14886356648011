import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {ConfigurationAndClient, PeriodConfiguration} from '../model/client';
import {Observable} from 'rxjs';

@Injectable()
export class ConfigurationService {

  serviceUrl(): string {
    return `/api/t/{tenant}/configuration`;
  }

  constructor(protected http: HttpClient) {
  }

  create(model: ConfigurationAndClient): Observable<ConfigurationAndClient> {
    return this.http
      .post<ConfigurationAndClient>(this.serviceUrl(), JSON.stringify(model));
  }

  update(model: ConfigurationAndClient): Observable<ConfigurationAndClient> {
    return this.http
      .put<ConfigurationAndClient>(this.serviceUrl(), JSON.stringify(model));
  }

  get(): Observable<ConfigurationAndClient> {
    return this.http
      .get<ConfigurationAndClient>(this.serviceUrl());
  }



}
