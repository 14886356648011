import {ZonedDateTime} from '@js-joda/core';


export class InformacionReferencia {
    public TipoDoc?: string;
    public Numero?: string;
    public FechaEmision?: ZonedDateTime;
    public Codigo?: string;
    public Razon?: string;
}
