import {Component, OnDestroy, OnInit} from '@angular/core';
import {AlertService} from '../../../core/service/alert.service';
import {SecurityService} from '../../../core/service/security.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DocumentService} from '../../../service/document.service';
import {ClientService} from '../../../service/client.service';
import {Client, ClientType} from '../../../model/client';
import {MessageService} from '../../../core/service/message.service';
import {Code, CodeType} from '../../../model/code';
import {CodeService} from '../../../service/code.service';
import {CoreComponent} from '../../../core/component/core.component';
import {HaciendaService} from '../../../service/hacienda.service';
import '@js-joda/timezone';
import {Page} from '../../../model/page';
import {ClientFilter} from '../../../filter/client.filter';
import {LocalDate} from '@js-joda/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {DocumentFilter} from '../../../filter/document.filter';
import {Document, DocumentStatus, DocumentType, OperationType, ReceivedStatus} from '../../../model/document';
import {LoggerService} from '../../../service/logger.service';
import {DocumentLine} from '../../../model/document-line';
import ServiceUtils from "../../../core/utils/service.utils";

@Component({
  selector: 'app-add-payment',
  templateUrl: './add-payment.component.html'
})
export class AddPaymentComponent extends CoreComponent implements OnInit, OnDestroy  {
  private sub: any;
  private id = '';

  invoice: Document = new Document(0, LocalDate.now(), '', '', false);
  identification = '';
  documentSearch = '';
  clientsFound: Page<Client> = new Page<Client>({count : 0 , page : 0, pages : 0});
  documentsFound: Page<Document> = new Page<Document>({count : 0 , page : 0, pages : 0});
  searchDone = false;
  selectedClient?: Client;
  selectedDocument?: Document;
  currentClient?: Client;
  currencies: Code[] = [];
  paymentMethods: Code[] = [];
  sellConditions: Code[] = [];
  referenceCodes: Code[] = [];
  documentTypes: Code[] = [];
  editableDocumentLine = new DocumentLine(0, false, false, '', '',
    'CRC', '', false, 0, '', '', '');
  dateLocalDate = LocalDate.now();
  date: NgbDateStruct = {
    year: this.dateLocalDate.year(),
    month: this.dateLocalDate.monthValue(),
    day: this.dateLocalDate.dayOfMonth()
  };
  constructor(private documentService: DocumentService,
              private alertService: AlertService,
              protected router: Router,
              private route: ActivatedRoute,
              protected securityService: SecurityService,
              protected clientService: ClientService,
              protected messageService: MessageService,
              protected haciendaService: HaciendaService,
              protected codeService: CodeService,
              protected loggerService: LoggerService) {
    super(alertService, router, securityService, loggerService);

  }

  ngOnInit(): void {
    this.codeService.getCodes(CodeType.CODIGO_MONEDA).subscribe(
      codes => this.currencies = codes
    );
    this.codeService.getCodes(CodeType.MEDIO_PAGO).subscribe(
      codes => this.paymentMethods = codes
    );
    this.codeService.getCodes(CodeType.CONDICION_VENTA).subscribe(
      codes => this.sellConditions = codes
    );
    this.codeService.getCodes(CodeType.CODIGO_REFERENCIA).subscribe(
      codes => this.referenceCodes = codes
    );
    this.codeService.getCodes(CodeType.TIPO_DOCUMENTO_REFERENCIA).subscribe(
      codes => this.documentTypes = codes
    );
    this.clear();
    this.sub = this.route.params.subscribe(params => {
      const id = params['id' as string];
      if (id) {
        this.id = id;
        this.loadEntity(id);
      }
    });
  }

  loadEntity(id: number): void {
    this.loading = true;
    this.documentService.get(id)
      .subscribe(
        res => {
          this.invoice = res;
          this.dateLocalDate = ServiceUtils.dateReviver('', res.date) as LocalDate;
          this.date = {
            year: this.dateLocalDate.year(),
            month: this.dateLocalDate.monthValue(),
            day: this.dateLocalDate.dayOfMonth()
          };
          this.selectedClient = new Client(); // TODO improve
          this.loading = false;
        },
        error => this.processError(error));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  clear(): void {
    this.identification = '';
    this.clientsFound = new Page<Client>({count : 0 , page : 0, pages : 0});
    this.searchDone = false;
    this.editableDocumentLine = new DocumentLine();
    this.selectedClient = undefined;
    this.currentClient = undefined;
    this.selectedClient = undefined;
    this.dateLocalDate = LocalDate.now();
    this.date = {
      year: this.dateLocalDate.year(),
      month: this.dateLocalDate.monthValue(),
      day: this.dateLocalDate.dayOfMonth()
    };

    this.loadMainClient();

    this.invoice = new Document(0);
    this.invoice.budgetConfigurationCompleted = false;
    this.invoice.classificationCompleted = false;
    this.invoice.categorizationCompleted = false;
    this.invoice.budgetFormSelected = false;
    this.invoice.type = DocumentType.MANUAL_DOCUMENT;
    this.invoice.status = DocumentStatus.DOCUMENT_APPROVED;
    this.invoice.receivedStatus = ReceivedStatus.PENDING;
    this.invoice.date =  this.dateLocalDate;
    this.invoice.lines = [];
    this.invoice.currency = 'CRC';
    this.invoice.exchange = 1;
    this.invoice.sellCondition = '01';
    this.invoice.paymentMethod = '99';
    this.invoice.negativeAmount = 1;
    this.invoice.operationType = OperationType.SELL;
    this.invoice.manualEntry = true;
    this.invoice.processed = false;
    this.invoice.categorizationCompleted = true;
    this.invoice.classificationCompleted = false;
    this.formErrors = this.clearFormErrors();
  }

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>().set('document.sequence', '');
    // TODO add form values like .set('client.name', '').set('client.phone', '').set('client.email', '');
  }

  updateExchangeType(): void {
    if (this.invoice.currency === 'USD') {
      this.haciendaService.getExchangeType(this.dateLocalDate, this.dateLocalDate)
        .subscribe(value => {
          if (value.length > 0) {
            const exchangeType = value[0];
            this.invoice.exchange = exchangeType.venta;
          }
        });
    } else if (this.invoice.currency === 'CRC') {
      this.invoice.exchange = 1;
    }
  }

  searchEvent(): void {
    const clientFilter = new ClientFilter();
    clientFilter.text = this.identification;
    this.loading = true;
    this.clientService.searchByFilter(clientFilter).subscribe(
      value => {
        this.clientsFound = value;
        this.searchDone = true;
        this.loading = false;
      },
      error => this.processError(error));
  }

  searchDocumentEvent(): void {
    const documentFilter = new DocumentFilter();
    documentFilter.text = this.documentSearch;
    documentFilter.status = DocumentStatus.DOCUMENT_APPROVED;
    documentFilter.pending = 0;
    documentFilter.receiverId = this.invoice.issuerId;
    // Creditos https://www.hacienda.go.cr/ATV/ComprobanteElectronico/docs/esquemas/2016/v4.3/ANEXOS%20Y%20ESTRUCTURAS_V4.3.pdf tabla 5
    documentFilter.sellConditions = ['02', '10'];
    this.loading = true;
    this.documentService.searchByFilter(documentFilter).subscribe(
      value => {
        this.documentsFound = value;
        this.searchDone = true;
        this.loading = false;
      },
      error => this.processError(error));
  }



  selectClientEvent(client: Client): void {
    this.selectedClient = client;
    this.searchDone = false;
    this.invoice.issuer = client.name;
    this.invoice.issuerId = client.identification;

    if (this.currentClient) {
      this.invoice.receiver = this.currentClient.name;
      this.invoice.receiverId = this.currentClient.identification;
    }
    if (this.invoice.issuerId !== this.currentClient?.identification) {
      this.invoice.operationType = OperationType.PURCHASE;
    }
  }

  createEvent(): void {
    this.loading = true;
    if (this.invoice.id) {
      this.documentService.update(this.invoice).subscribe(
        value => {
          this.clear();
          this.loading = false;
          this.alertService.success('Documento actualizado con éxito');
        },
        error => this.processError(error));
    } else {
      this.documentService.create(this.invoice).subscribe(
        value => {
          this.clear();
          this.loading = false;
          this.alertService.success('Documento guardado con éxito');
        },
        error => this.processError(error));
    }
  }

  addDetailLineEvent(): void {
    if (!this.invoice.lines) {
      this.invoice.lines = [];
    }
    this.editableDocumentLine = new DocumentLine();
    this.editableDocumentLine.budgetFormSelected = false;
    this.editableDocumentLine.budgetConfigured = false;
    this.editableDocumentLine.categorized = true;
    this.editableDocumentLine.classified = false;
    this.editableDocumentLine.allowCommercialActivityCodeEdit = false;
    this.editableDocumentLine.taxes = '[]';
    this.editableDocumentLine.valueAddedTax = 0;
    this.editableDocumentLine.exonerationAmount = 0;
    this.editableDocumentLine.otherTax = 0;
    this.invoice.lines?.push(this.editableDocumentLine);
  }

  copyDetailLineEvent(invoiceDetailLine: number): void {
    if (this.invoice.lines) {
      const elementToCopy = this.invoice.lines[invoiceDetailLine];
      this.invoice.lines?.push(JSON.parse(JSON.stringify(elementToCopy)));
      this.updateLines();
    }
  }

  updateDetailLineEvent(): void {
    if (this.invoice.lines) {
      const line = this.editableDocumentLine;
      line.quantity = 1;
      line.subTotal = line.totalAmount;
    }
    this.updateLines();
  }

  private updateLines(): void {
    if (this.invoice.lines) {
      let i = 1;
      let total = 0;
      for (const detail of this.invoice.lines) {
        detail.lineNumber = i;
        if (detail.totalAmount) {
          total += +detail.totalAmount;
        }
        i++;
      }
      this.invoice.total = total;
      this.invoice.totalTax = 0;
    }
  }

  removeDetailLineEvent(invoiceDetailLine: number): void {
    this.invoice.lines?.splice(invoiceDetailLine, 1);
    this.updateLines();
  }

  dateChangedEvent(date: NgbDateStruct): void {
    this.dateLocalDate = LocalDate.of(date.year, date.month, date.day);
    this.invoice.date = this.dateLocalDate;
    this.updateExchangeType();
  }

  editLineEvent(i: number): void {
    if (this.invoice.lines) {
      this.editableDocumentLine = this.invoice.lines[i];
    }
  }

  loadMainClient(): void {
    const searchExample = new Client();
    searchExample.type = ClientType.MAIN;
    this.loading = true;
    this.clientService.search(searchExample).subscribe(
      value => {
        const existentMainClient = value.content?.pop();
        if (existentMainClient) {
          this.currentClient = existentMainClient;
        }
        this.loading = false;
      },
      error => this.processError(error));
  }

  switchIssuerAndReceiver(): void {
    const issuer = this.invoice.issuer;
    this.invoice.issuer = this.invoice.receiver;
    this.invoice.receiver = issuer;

    const issuerId = this.invoice.issuerId;
    this.invoice.issuerId = this.invoice.receiverId;
    this.invoice.receiverId = issuerId;

    if (this.invoice.issuerId !== this.currentClient?.identification) {
      this.invoice.operationType = OperationType.PURCHASE;
    } else {
      this.invoice.operationType = OperationType.SELL;
    }
  }

  selectDocumentEvent(document: Document): void {
    this.selectedDocument = document;
    this.invoice.referenceId = document.id;
    this.invoice.type = DocumentType.PAYMENT_DOCUMENT;
  }
}
