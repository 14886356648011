
import {Tenant} from '../model/tenant';
import {LocalDate, Temporal, ZonedDateTime} from '@js-joda/core';
import {Observable, throwError} from 'rxjs';

export default class ServiceUtils {

  static handleError(error: Response | any): Observable<never> {
    // In a real world app, we might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof Response) {
      errMsg = `${error.status} - ${error.statusText || ''} ${error.text()}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return throwError(error);
  }

  static getTenant(): Tenant {
    const tenantFromStorage = ServiceUtils.getItem('tenant');
    if (tenantFromStorage) {
      return JSON.parse(tenantFromStorage);
    }
    return {};
  }

  static getItem(key: string): string | null {
    return sessionStorage.getItem(key) ? sessionStorage.getItem(key) : localStorage.getItem(key);
  }

  static setItem(key: string, value: string): void {
     sessionStorage.setItem(key, value);
     localStorage.setItem(key, value);
  }

  static dateReviver(key: string, value: any): Temporal {
    const reISODateTime = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|([+-])([\d|:]*))?$/;
    const reISODate = /^(\d{4})-(\d{2})-(\d{2})$/;
    if (typeof value === 'string') {
      let a = reISODate.exec(value);
      if (a) {
        return LocalDate.parse(value);
      }
      a = reISODateTime.exec(value);
      if (a) {
        return ZonedDateTime.parse(value);
      }
    }
    return value;
  }
}
