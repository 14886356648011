<div class="container">

  <div class="loader" *ngIf="loading"></div>

  <div *ngIf="!loading" class="animate__animated animate__fadeIn">
    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">
      <div class="container-fluid">
        <span class="navbar-text">
          Registrar documento
        </span>
        <div class="pull-right">
          <button class="btn btn-secondary me-1" routerLink="/documentos/ver" >
            Cerrar
          </button>
          <button class="btn btn-primary" (click)="createEvent()" >
            Guardar
          </button>
        </div>
      </div>
    </nav>

    <div class="row mb-3">
      <div class="alert alert-info">
        Suministre el emisor para ingresar un documento simple como por ejemplo tiquetes de peaje o documentos de pago.
      </div>
    </div>

    <div class="row mb-3">
      <div class="form-group  col-md-6">
        <label class="form-label" for="client">Cliente Emisor</label>
        <div class="input-group mb-3">
          <input type="text" class="form-control" id="client" name="client" [(ngModel)]="identification"
                 placeholder="Buscar por identificación o nombre..." (change)="searchEvent()">
          <button class="btn btn-primary" (click)="searchEvent()" type="button">
            <span class="fa fa-search "></span>
          </button>
          <div *ngIf="formErrors.get('client.client')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('client.client') }}
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!selectedClient">
      <table *ngIf="clientsFound.metadata.pages > 0" class="table table-hover animate__animated animate__fadeIn">
        <tbody>
        <tr *ngFor="let client of clientsFound.content">
          <td class="mt-content-col" (click)="selectClientEvent(client)">
            <strong>{{client.name}}</strong><br>
            <span *ngIf="client.email" class="text-muted">{{client.email}}</span><br *ngIf="client.email">
            <small *ngIf="client.identification" class="text-muted">{{client.identification}}</small>
          </td>
          <td class="mt-actions-col">
            <button class="btn btn-link btn-lg pull-right"
                    title="Editar">
              <span class="fa fa-edit fa-lg"></span>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <div *ngIf="clientsFound.metadata.pages == 0 && searchDone">
        <div class="alert alert-warning animate__animated animate__fadeIn" role="alert">
          No se encontraron registros, puede <a href="#" class="alert-link" routerLink="/clientes" [queryParams]="{mode:'EDIT', identification: this.identification, returnUrl: this.router.url}">crear</a> un nuevo cliente
        </div>
      </div>
    </div>

    <div *ngIf="selectedClient" class="animate__animated animate__fadeIn">

      <div class="row mb-3">
        <div class="form-group col-md">
          <div class="card">
            <div class="card-header">
              Emisor
            </div>
            <div class="card-body">
              <span>{{invoice.issuer}}</span>
              <br>
              <small class="text-muted">{{invoice.issuerId}}</small>
              <br>
            </div>
          </div>
        </div>

        <div class="col-md-auto">
          <button class="btn btn-primary text-end" (click)="switchIssuerAndReceiver()" type="button" title="Intercambiar receptor y emisor">
            <span class="fa fa-arrows-alt-h fa-lg"></span>
          </button>
        </div>
        <div class="form-group col-md">
          <div class="card">
            <div class="card-header">
              Receptor
            </div>
            <div class="card-body">
              <span>{{invoice.receiver}}</span>
              <br>
              <small class="text-muted">{{invoice.receiverId}}</small>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="form-group col-md-6">
          <label class="form-label" for="date">Fecha de Emisión</label>
          <div class="input-group">
            <input class="form-control" placeholder="aaaa-mm-dd"
                   name="date" [(ngModel)]="date" ngbDatepicker #d1="ngbDatepicker" id="date" title="Fecha" (ngModelChange)="dateChangedEvent($event)" >
            <button class="btn btn-outline-secondary" (click)="d1.toggle()" type="button">
              <span class=" fa fa-calendar"></span>
            </button>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="form-group col-md-3">
          <label class="form-label" for="currency">Moneda</label>
          <select class="form-select" id="currency" [(ngModel)]="invoice.currency" (change)="updateExchangeType()">
            <option *ngFor="let code of currencies" value="{{code.code}}" >{{code.description}} ({{code.code}})</option>
          </select>
          <div *ngIf="formErrors.get('client.currency')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('client.currency') }}
          </div>
        </div>

        <div class="form-group col-md-3">
          <label class="form-label" for="exchange">Tipo Cambio</label>
          <input type="text" class="form-control" id="exchange" name="exchange" [(ngModel)]="invoice.exchange">
          <div *ngIf="formErrors.get('document.exchange')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('document.exchange') }}
          </div>
        </div>

        <div class="form-group col-md-3" >
          <label class="form-label" for="exchange">Secuencia</label>
          <input type="text" class="form-control" id="sequence" name="exchange" [(ngModel)]="invoice.sequence">
          <div *ngIf="formErrors.get('document.sequence')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('document.sequence') }}
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="form-group col-md-6">
          <label class="form-label" for="paymentMethod" >Método de pago</label>
          <select class="form-select" id="paymentMethod" [(ngModel)]="invoice.paymentMethod">
            <option *ngFor="let code of paymentMethods" value="{{code.code}}" >{{code.description}} ({{code.code}})</option>
          </select>
          <div *ngIf="formErrors.get('document.paymentMethod')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('document.paymentMethod') }}
          </div>
        </div>

        <div class="form-group col-md-6">
          <label class="form-label" for="sellCondition">Condición de venta</label>
          <select class="form-select" id="sellCondition" [(ngModel)]="invoice.sellCondition">
            <option *ngFor="let code of sellConditions" value="{{code.code}}" >{{code.description}} ({{code.code}})</option>
          </select>
          <div *ngIf="formErrors.get('client.currency')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('client.currency') }}
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="form-group col-md-12">
          <label class="form-label" for="notes" >Notas</label>
          <input type="text" class="form-control" id="notes" name="notes" [(ngModel)]="invoice.notes">
          <div *ngIf="formErrors.get('document.notes')" class="badge bg-danger animated fadeIn">
            {{ formErrors.get('document.notes') }}
          </div>
        </div>
      </div>

      <h4  class="mb-3" >Referencia</h4>
      <hr class="mb-4">
      <div class="row mb-3" *ngIf="!selectedDocument">
        <div class="form-group  col-md-6">
          <label class="form-label" for="client">Número de documento</label>
          <div class="input-group mb-3">
            <input type="text" class="form-control" id="document" name="client" [(ngModel)]="documentSearch"
                   placeholder="Buscar por secuencia..." (change)="searchDocumentEvent()">

            <button class="btn btn-primary" (click)="searchDocumentEvent()" type="button">
              <span class="fa fa-search "></span>
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="!selectedDocument" >
        <table *ngIf="documentsFound.metadata.pages > 0" class="table table-hover animate__animated animate__fadeIn">
          <tbody>
          <tr *ngFor="let document of documentsFound.content">
            <td class="mt-content-col" (click)="selectDocumentEvent(document)">
              <strong>{{document.sequence}}</strong><br>
              <span class="text-muted">{{document.total | currency:document.currency:'code'}}</span> con saldo de
              <span class="text-muted">{{document.pending | currency:document.currency:'code'}}</span><br>
              Emitido el <span class="text-muted">{{document.date.toString()  | date:'dd/MM/yyyy'}}</span> para <span class="text-muted">{{document.receiver}}</span>
            </td>
          </tr>
          </tbody>
        </table>
        <div *ngIf="documentsFound.metadata.pages == 0 && searchDone">
          <div class="alert alert-warning animate__animated animate__fadeIn" role="alert">
            No se encontraron registros.
          </div>
        </div>
      </div>

      <div class="row mb-3"  *ngIf="selectedDocument" >
        <div class="form-group col-md">
          <div class="card">
            <div class="card-header">
              Documento a pagar
            </div>
            <div class="card-body">
              Secuencia: <strong>{{selectedDocument.sequence}}</strong><br>
              Total: <span class="text-muted">{{selectedDocument.total | currency:selectedDocument.currency:'code'}}</span><br>
              Saldo: <span class="text-muted">{{selectedDocument.pending | currency:selectedDocument.currency:'code'}}</span><br>
              Fecha: <span class="text-muted">{{selectedDocument.date.toString()  | date:'dd/MM/yyyy'}}</span><br>
              Emisor: <span class="text-muted">{{selectedDocument.issuer}}</span><br>
              Receptor: <span class="text-muted">{{selectedDocument.receiver}}</span>
              <br>
            </div>
          </div>
        </div>
      </div>


      <h4  class="mb-3" >Detalles</h4>
      <hr class="mb-4">
      <table class="table table-hover" >
        <thead>
        <tr>
          <th scope="col">
            Linea
          </th>
          <th scope="col">
            Detalles
          </th>
          <th scope="col" class="text-end">
            Total
          </th>
          <th>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let line of invoice.lines; index as i;">
          <td class="mt-content-col">
            {{i+1}}
          </td>
          <td>
            <span>{{line.detail}}</span> <br>
            <small class="text-muted">{{line.quantity}} {{line.units}}</small> <br>
            <small>{{line.cabysCode}}</small>
          </td>
          <td class="text-end">
            {{line.totalAmount | currency:invoice.currency:'code'}}
          </td>
          <td class="text-end  align-middle">
            <button type="button" class="btn btn-light me-1" title="Editar linea" (click)="editLineEvent(i)" data-bs-toggle="modal" data-bs-target="#documentLineDialog">
              <span class="fa fa-edit fa-lg "></span>
            </button>
            <button type="button"  class="btn btn-light me-1" title="Duplicar linea" (click)="copyDetailLineEvent(i)">
              <span class="fa fa-copy fa-lg "></span>
            </button>
            <button type="button"  class="btn btn-danger" title="Eliminar linea" (click)="removeDetailLineEvent(i)">
              <span class="fa fa-trash fa-lg "></span>
            </button>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th scope="col">
          </th>
          <th scope="col">
          </th>
          <th scope="col" class="text-end">
            {{invoice.total | currency:invoice.currency:'code'}}
          </th>
          <th></th>
        </tr>
        </tfoot>
      </table>
      <button type="button" class="btn btn-secondary mt-2" title="Agregar linea" (click)="addDetailLineEvent()" data-bs-toggle="modal" data-bs-target="#documentLineDialog">
        Agregar Línea
      </button>
    </div>
  </div>
</div>

<div class="modal fade" id="documentLineDialog" tabindex="-1" role="dialog"
     aria-labelledby="loadFileLabel">
  <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Agregar línea</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
      </div>

      <div class="modal-body" >
        <div class="row mb-3">

          <input hidden class="form-control" [(ngModel)]="editableDocumentLine.quantity" value="1"/>
          <div class="form-group col-md-6">
            <label class="form-label" for="line-detail">Detalle</label>
            <input type="text" class="form-control" id="line-detail" name="exchange" [(ngModel)]="editableDocumentLine.detail">
            <div *ngIf="formErrors.get('line-detail')" class="badge bg-danger animated fadeIn">
              {{ formErrors.get('line-detail') }}
            </div>
          </div>
          <div class="form-group col-md-6">
            <label class="form-label" for="line-amount">Monto</label>
            <input type="text" class="form-control" id="line-amount" name="exchange" [(ngModel)]="editableDocumentLine.totalAmount">
            <div *ngIf="formErrors.get('line-amount')" class="badge bg-danger animated fadeIn">
              {{ formErrors.get('line-amount') }}
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="updateDetailLineEvent()">Aceptar</button>
      </div>
    </div>
  </div>
</div>


