import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from '../core/service/base.service';
import {Observable} from 'rxjs';
import {AccountingPeriod} from '../model/accounting-period';

@Injectable({
  providedIn: 'root'
})
export class AccountingPeriodService extends BaseService<AccountingPeriod> {

  serviceUrl(): string {
    return `/api/t/{tenant}/accounting-periods`;
  }

  constructor(protected http: HttpClient) {
    super(http);
  }

  getPeriodConfiguration(): Observable<AccountingPeriod> {
    return this.http
      .get<AccountingPeriod>(`${this.serviceUrl()}/current-period`);
  }

  revertLastPeriod(): Observable<any> {
    return this.http
      .post<any>(`${this.serviceUrl()}/revert-last-period`, {});
  }

  initPeriod(systemAccountingIdParam: number): Observable<any> {
    return this.http
      .post<any>(`${this.serviceUrl()}/init-period`, {id: systemAccountingIdParam});
  }
}
