<div class="container">

  <div class="loader" *ngIf="loading"></div>

  <div *ngIf="mode == 'EDIT' && !loading" class="animated fadeIn">
    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top">
      <div class="container">
        <span class="navbar-text">
          Pago
        </span>
        <div class="pull-right">
          <button class="btn btn-link" (click)="cancelEvent()">
            Cerrar
          </button>
          <button class="btn btn-primary" (click)="applyEvent()">
            Aplicar
          </button>
        </div>
      </div>
    </nav>

    <div class="row mb-3">
      <p>
        Empleado: <strong>{{model.employeeName}}</strong>
      </p>
      <p>
        Cédula: {{model.identification}}
      </p>
      <p>
        Puesto: {{model.details?.jobPositionName}}
      </p>
      <p>
        Fecha: {{model.date?.toString()  | date:'dd/MM/yyyy'}}
      </p>
      <p>
        Salario Mensual: {{model.details?.monthlySalary | currency:'CRC':'code'}}
      </p>
      <p>
        Periodicidad de pago: {{model.details?.periodicity === Periodicity.FORTNIGHTLY? 'Quincenal':model.details?.periodicity === Periodicity.MONTHLY?'Mensual':model.details?.periodicity === Periodicity.BIWEEKLY?'Bisemanal':'Semanal'}}
      </p>
      <p>
        Jornada: {{model.details?.workdayName}}
      </p>
      <p>
        Horas del periodo: {{model.details?.totalWorkableHours}}
      </p>
      <p>
        Horas libres del periodo: {{model.details?.totalFreeHours}}
      </p>
      <p>
        Horas reportadas: {{model.details?.reportedHours}}
      </p>
      <p>
        Horas aprobadas: {{model.details?.acceptedHours}}
      </p>
      <p>
        Horas ordinarias: {{model.details?.ordinaryHours}}
      </p>
      <p>
        Horas extra: {{model.details?.extraHours}}
      </p>
      <p>
        Horas feriados: {{model.details?.holidayHours}}
      </p>
      <p>
        Horas incapacidades: {{model.details?.medicalDisabilityHours}}
      </p>
      <p>
        Horas vacaciones: {{model.details?.vacationHours}}
      </p>
      <p>
        Horas no pagadas: {{model.details?.nonPaidHours}}
      </p>
      <p>
        Horas no pagas (otras): {{model.details?.otherNonPaidHours}}
      </p>
      <p>
        Horas no reportadas: {{model.details?.nonReportedHours}}
      </p>
    </div>

    <ul class="list-group mb-3">
      <li class="list-group-item d-flex justify-content-between lh-sm">
        <div>
          <h6 class="my-0">Salario del Periodo</h6>
        </div>
        <span class="text-body-secondary">{{ model.details?.salary | currency:'CRC':'code'}}</span>
      </li>
      <li class="list-group-item d-flex justify-content-between lh-sm">
        <div>
          <h6 class="my-0">Horas Extra</h6>
        </div>
        <span class="text-body-secondary">{{ model.details?.extraHoursAmount | currency:'CRC':'code'}}</span>
      </li>
      <li class="list-group-item d-flex justify-content-between lh-sm">
        <div>
          <h6 class="my-0">Bonos</h6>
        </div>
        <span class="text-body-secondary">{{ model.details?.bonus | currency:'CRC':'code'}}</span>
      </li>
      <li class="list-group-item d-flex justify-content-between lh-sm">
        <div>
          <h6 class="my-0">Horas no trabajadas</h6>
        </div>
        <span class="text-danger">{{ model.details?.totalNonPaid | currency:'CRC':'code'}}</span>
      </li>
      <li class="list-group-item d-flex justify-content-between lh-sm">
        <div>
          <h6 class="my-0">Salario total del periodo</h6>
        </div>
        <strong>{{ model.details?.totalPeriodSalary | currency:'CRC':'code'}}</strong>
      </li>
      <li class="list-group-item d-flex justify-content-between lh-sm">
        <div>
          <h6 class="my-0">Impuestos</h6>
        </div>
        <span class="text-danger">{{ model.details?.taxes | currency:'CRC':'code'}}</span>
      </li>
      <li class="list-group-item d-flex justify-content-between lh-sm">
        <div>
          <h6 class="my-0">CCSS</h6>
        </div>
        <span class="text-danger">{{ model.details?.socialSecurity | currency:'CRC':'code'}}</span>
      </li>

      <li class="list-group-item d-flex justify-content-between lh-sm">
        <div>
          <h6 class="my-0">Pensión Alimenticia</h6>
        </div>
        <span class="text-danger">{{ model.details?.alimony | currency:'CRC':'code'}}</span>
      </li>
      <li class="list-group-item d-flex justify-content-between lh-sm">
        <div>
          <h6 class="my-0">Embargos</h6>
        </div>
        <span class="text-danger">{{ model.details?.embargoes | currency:'CRC':'code'}}</span>
      </li>
      <li class="list-group-item d-flex justify-content-between lh-sm">
        <div>
          <h6 class="my-0">Retenciones</h6>
        </div>
        <span class="text-danger">{{ model.details?.retentions | currency:'CRC':'code'}}</span>
      </li>
      <li class="list-group-item d-flex justify-content-between">
        <span>Total después de deducciones</span>
        <strong>{{ model.details?.salaryAfterDeductions | currency:'CRC':'code'}}</strong>
      </li>
    </ul>

  </div>

  <div *ngIf="mode == 'LIST' && !loading" class="animated fadeIn">

    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top">
      <div class="container">
        <span class="navbar-text">
          Pagos
        </span>
        <div class="pull-right">
          <button class="btn btn-link" (click)="refreshEvent()" title="Refrescar">
            <span class="fa fa-sync fa-lg"></span>
          </button>
          <button class="btn btn-link btn-lg pull-right" data-bs-toggle="modal" data-bs-target="#generateConfirmation"
                   title="Generar Pagos">
            <span class="fa fa-money-check-dollar fa-lg"></span>
          </button>
        </div>
      </div>
    </nav>

    <mt-pagination (loadPage)="getPageEvent($event)" [page]="page"></mt-pagination>

    <table *ngIf="page.metadata.pages > 0" class="table table-hover">
      <thead>
        <tr>
          <th>
            Empleado
          </th>
          <th scope="col" >
            Fecha
          </th>
          <th>

          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let payroll of page.content">
          <td (click)="editEvent(payroll)" class="mt-content-col">
            {{payroll.employeeName}}
          </td>
          <td (click)="editEvent(payroll)" class="mt-content-col">
            {{payroll.date?.toString()  | date:'dd/MM/yyyy'}}
          </td>
          <td class="mt-actions-col">
            <button class="btn btn-link btn-lg pull-right" data-bs-toggle="modal" data-bs-target="#payrollConfirmation"
                    (click)="selectEvent(payroll)" title="Borrar">
              <span class="fa fa-trash-alt fa-lg"></span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="page.metadata.pages == 0">
      No hay registros
    </div>

  </div>

  <!-- Modal -->
  <div class="modal fade" id="payrollConfirmation" tabindex="-1" role="dialog"
       aria-labelledby="removeConfirmationLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirmar</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Desea eliminar el registro?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="removeEvent(model)">Borrar</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="generateConfirmation" tabindex="-1" role="dialog"
       aria-labelledby="removeConfirmationLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Generar pagos</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Seleccione la fecha para la cual desea generar los cálculos de pagos.</p>
          <div class="row g-3">
            <div class="col-md-6">
              <div class="form-group">
                <label for="selectedDate">Fecha</label>
                <mt-date-picker [(date)]="selectedDate" name="selectedDate" id="selectedDate" label="Fecha"/>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="generateEvent(selectedDate)">Generar</button>
        </div>
      </div>
    </div>
  </div>

</div>


