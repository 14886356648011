import {EntityModel} from '../core/model/entity.model';

export class Account extends EntityModel {
  public code?: string;
  public description?: string;
  public active?: boolean;
  public showTotal?: boolean;
  public state = false;
  public level?: number;
  public parent?: number;
  public initialAmount?: number;
  public balance?: number;
  public adjustment?: number;
  public accountingImpact?: AccountingImpact;
  public depreciation?: boolean;
  public dataFormD101?: string[];
  public type?: AccountType;
  public taxAccountSell?: boolean;
  public taxAccountPurchase?: boolean;
}

export class BudgetAccountBalance {
  public code?: string;
  public description?: string;
  public initialBalance?: number;
  public balance?: number;
}

export class MultipleClientAccount {
  public clientAccounts: Account[];

  constructor(clientAccounts: Account[]) {
    this.clientAccounts = clientAccounts;
  }
}

export enum AccountingImpact {
  CREDIT= 'CREDIT',
  DEBIT= 'DEBIT'
}

export enum AccountType {
  ACCOUNTING= 'ACCOUNTING',
  BUDGET= 'BUDGET'
}
