import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest, HttpHandler, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import {HeaderConstants} from '../utils/headers.constants';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private cache = new Map<string, Map<string, any>>();

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.method !== 'GET') {
      return next.handle(request);
    }

    if (!request.headers.get(HeaderConstants.CACHED) || request.headers.get(HeaderConstants.CACHED) === 'false') {
      this.cache.delete(request.url);
    }

    let cachedBaseUrl = this.cache.get(request.url);
    if (cachedBaseUrl && cachedBaseUrl.get(request.urlWithParams)) {
      console.debug('Loading from cache!', cachedBaseUrl, request.urlWithParams);
      return of(cachedBaseUrl.get(request.urlWithParams));
    }

    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          if ( event.ok ) { // errors shouldn't be cached
            if (cachedBaseUrl == null) {
              cachedBaseUrl = new Map<any, any>();
            }
            cachedBaseUrl.set(request.urlWithParams, event);
            this.cache.set(request.url, cachedBaseUrl);
          }
        }
      })
    );
  }
}
