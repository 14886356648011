import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Vacation} from './resource/vacation';
import {BaseService} from '../../core/service/base.service';

@Injectable()
export class VacationService extends BaseService<Vacation> {

  serviceUrl(): string {
    return `/api/t/{tenant}/vacations`;
  }

  constructor(protected http: HttpClient) {
    super(http);
  }
}
