import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CoreComponent} from '../../../core/component/core.component';
import {AlertService} from '../../../core/service/alert.service';
import {Router} from '@angular/router';
import {SecurityService} from '../../../core/service/security.service';
import {MessageService} from '../../../core/service/message.service';
import {LocalDate} from '@js-joda/core';
import {ReportService} from '../../../service/report.service';
import {Tenant} from '../../../core/model/tenant';
import {AccountingEntryService} from '../../../service/accounting-entry.service';
import {LoggerService} from '../../../service/logger.service';
import ServiceUtils from '../../../core/utils/service.utils';

@Component({
  selector: 'app-form-d101',
  templateUrl: './form-d101.component.html'
})
export class FormD101Component extends CoreComponent implements OnInit, OnDestroy {

  formData: any = {};

  @ViewChild('fileUpload', {static: false})
    // @ts-ignore
  fileUpload: ElementRef;

  constructor(private alertService: AlertService,
              protected router: Router,
              protected securityService: SecurityService,
              protected reportService: ReportService,
              protected accountingEntryService: AccountingEntryService,
              protected messageService: MessageService,
              protected loggerService: LoggerService) {
    super(alertService, router, securityService, loggerService);

  }

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>();
  }

  ngOnInit(): void {
    // nothing to do
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  generateFormData(): void {
    this.loading = true;
    this.accountingEntryService.getForm101Data().subscribe(
      response => {
        this.loading = false;
        this.formData = response;
        },
        error => this.processError(error));
  }
  generateFormDataXml(): void {
    this.loading = true;
    const date = LocalDate.now();
    this.accountingEntryService.getForm101DataXml().subscribe(
      response => {
        this.loading = false;
        const tenantFromStorage = ServiceUtils.getItem('tenant');
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(response);
        a.href = objectUrl;
        if (tenantFromStorage) {
          const tenant = JSON.parse(tenantFromStorage) as Tenant;
          a.download = `Formulario_D101_${date.year()}_${tenant.name?.replace(/\./g, '_')}`;
        }
        a.click();
        URL.revokeObjectURL(objectUrl);
      },
      error => this.processError(error));
  }

  fillDocument(): void {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (const file of fileUpload.files) {
        this.uploadFile(file);
      }
    };
    fileUpload.click();
  }

  uploadFile(file: File): void {
    this.loading = true;
    const formData = new FormData();
    formData.append('file', file);
    const date = LocalDate.now();
    this.accountingEntryService.fillForm(formData)
      .subscribe(response => {
          this.loading = false;
          const tenantFromStorage = ServiceUtils.getItem('tenant');
          const a = document.createElement('a');
          const objectUrl = URL.createObjectURL(response);
          a.href = objectUrl;
          if (tenantFromStorage) {
            const tenant = JSON.parse(tenantFromStorage) as Tenant;
            a.download = `Formulario_D101_${date.year()}_${tenant.name?.replace(/\./g, '_')}.xlsm`;
          }
          a.click();
          URL.revokeObjectURL(objectUrl);
        }
        , error => this.processError(error));
  }
}
