import {CodigoTipoMoneda} from './codigo-tipo-moneda';

export class ResumenFactura {
    public CodigoTipoMoneda = new CodigoTipoMoneda();
    public TotalServGravados?: number;
    public TotalServExentos?: number;
    public TotalServExonerado?: number;
    public TotalMercanciasGravadas?: number;
    public TotalMercanciasExentas?: number;
    public TotalMercExonerada?: number;
    public TotalGravado?: number;
    public TotalExento?: number;
    public TotalExonerado?: number;
    public TotalVenta?: number;
    public TotalDescuentos?: number;
    public TotalVentaNeta?: number;
    public TotalImpuesto?: number;
    public TotalIVADevuelto?: number;
    public TotalOtrosCargos?: number;
    public TotalComprobante = 0;
}
