import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../core/component/base.component';

import {AlertService} from '../../../core/service/alert.service';
import {MessageService} from '../../../core/service/message.service';
import {Router} from '@angular/router';
import {SecurityService} from '../../../core/service/security.service';
import {LoggerService} from '../../../service/logger.service';

import {BonusService} from '../../service/bonus.service';
import {Bonus} from '../../service/resource/bonus';
import {EmployeeService} from '../../service/employee.service';
import {Employee} from '../../service/resource/employee';

@Component({
  selector: 'app-bonus',
  templateUrl: './bonus.component.html'
})
export class BonusComponent extends BaseComponent<Bonus> implements OnInit, OnDestroy {

  employees: Employee[] = [];

  employeeId = -1;

  constructor(private bonusService: BonusService,
              private alertService: AlertService,
              protected messageService: MessageService,
              protected loggerService: LoggerService,
              protected router: Router,
              protected securityService: SecurityService,
              protected employeeService: EmployeeService) {
    super(bonusService, 'Bonus', alertService, router, securityService, messageService, loggerService);
  }

  getModelInstance(): Bonus {
    return new Bonus();
  }

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.employeeService.getAll().subscribe({
        next: (v) => this.employees = v,
        error: (e) => this.processError(e)
      }
    );
  }

  addEmployee(): void {
    const employee = this.employees.find((e) => e.id == this.employeeId);
    if (employee !== undefined) {
      if (this.model.employees === undefined) {
        this.model.employees = [];
      }
      if (!this.model.employees.find((e) => e.id == this.employeeId)) {
        this.model.employees.push(employee);
      }
    }
  }
}
