import {Injectable} from '@angular/core';


import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {User} from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService<User> {

  serviceUrl(): string {
    return '/api/admin/users';
  }

  constructor(protected http: HttpClient) {
    super(http);
  }

}
