import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HeaderConstants} from '../core/utils/headers.constants';
import {DashboardSummary} from '../model/dashboard-summary';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  SERVER_URL = '/api/t/{tenant}/dashboard';
  constructor(private httpClient: HttpClient) { }

  getSummary(month: string, year: string, refresh = false): Observable<DashboardSummary> {

    const paramsValue = new HttpParams().append('month', month).append('year', year);
    const headersValue = new HttpHeaders().set(HeaderConstants.CACHED, refresh ? 'false' : 'true');

    const options = {headers: headersValue, params: paramsValue};

    return this.httpClient
      .get<DashboardSummary>(`${this.SERVER_URL}`, options
      );
  }
}
