<div class="container">
  <div class="loader" *ngIf="loading"></div>
  <div class="row justify-content-md-center animate__animated animate__fadeIn" *ngIf="!loading">
    <div class="col-md-6 col-md-offset-3">
      <div class="row mb-3">
        <div class="col-sm-6">
          <h4>Recuperar contraseña</h4>
        </div>
        <div class="col-sm-6">
          <div class="float-end">
            <button class="btn btn-link" routerLink="/login">
              Volver
            </button>
            <button class="btn btn-primary" (click)="recoverUser()">
              Enviar
            </button>
          </div>
        </div>
      </div>

      <div class="form-group">
      <label class="form-label" for="password">Correo electrónico</label>
      <input type="email" class="form-control" id="password" name="password" [(ngModel)]="model.email"
             autocomplete="email">
      <div *ngIf="formErrors.get('email')" class="badge bg-danger animated fadeIn">
        {{ formErrors.get('email') }}
      </div>
    </div>
    </div>
  </div>
</div>


