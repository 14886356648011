import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HeaderConstants} from '../utils/headers.constants';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!req.headers.has('Content-Type') && !req.headers.has(HeaderConstants.IGNORE_CONTENT_TYPE)) {
      req = req.clone({
        headers: req.headers.set('Content-Type', 'application/json')
      });
    }
    req = req.clone({headers: req.headers.set('Accept-Language', 'es')
        .set('X-Request-Id', `ID-${Math.floor(100000 + Math.random() * 900000)}-${new Date().getTime()}`)});
    if (req.url.startsWith('/')) {
      req = req.clone({url: `${environment.apiUrl}${req.url}`});
    }
    return next.handle(req);
  }
}
