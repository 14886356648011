import {Pipe, PipeTransform} from '@angular/core';
import {DateTimeFormatter, ZonedDateTime, ZoneId} from '@js-joda/core';

@Pipe({name: 'zonedDateTime'})
export class ZonedDateTimePipe implements PipeTransform {
  transform(value: string, pattern: string): string {
    let addAmPm = false;
    if (pattern.indexOf('a') > 0) {
      pattern = pattern.replace('a', '');
      addAmPm = true;
    }
    const formatter = DateTimeFormatter.ofPattern(pattern);
    const date = ZonedDateTime.parse(value).withZoneSameInstant(ZoneId.SYSTEM);
    let formatedDate = date.format(formatter);
    if (addAmPm) {
      if (date.hour() > 12) {
        formatedDate += 'pm';
      } else {
        formatedDate += 'am';
      }
    }
    return formatedDate;

  }
}
