import {EntityModel} from './entity.model';

export class Tenant extends EntityModel {
  constructor(public id?: number,
              public name?: string,
              public status?: TenantStatus) {
    super(id);
  }
}

export enum TenantStatus {
  PENDING_CONFIGURATION = 'PENDING_CONFIGURATION',
  PARTIALLY_CONFIGURED = 'PARTIALLY_CONFIGURED',
  CONFIGURED = 'CONFIGURED'
}
