import {Tenant} from '../model/tenant';

export class TenantChangedMessage {
  constructor(public tenant: Tenant) {
  }
}

export class TenantsReloadedMessage {
  constructor() {
  }
}

