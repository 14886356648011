<div class="container">

  <div class="loader" *ngIf="loading"></div>

  <div *ngIf="mode == 'EDIT' && !loading" class="animate__animated animate__fadeIn">
    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">
      <div class="container-fluid">
        <span class="navbar-text">
          Cuenta Bancaria
        </span>
        <div class="pull-right">
          <button class="btn btn-link" (click)="cancelEvent()">
            Cerrar
          </button>
          <button class="btn btn-primary" (click)="saveEvent()">
            Guardar
          </button>
        </div>
      </div>
    </nav>

    <div class="row mb-3">
      <div class="form-group  col-md-4">
        <label class="form-label" for="code">Código de Cuenta</label>
        <input type="text" class="form-control" id="code" name="code" [(ngModel)]="model.number">
        <div *ngIf="formErrors.get('bankaccount.number')" class="badge bg-danger animate__animated animate__fadeIn">
          {{ formErrors.get('bankaccount.number') }}
        </div>
      </div>
      <div class="form-group  col-md-4">
        <label class="form-label" for="bank">Entidad Financiera</label>
        <input type="text" class="form-control" id="bank" name="bank" [(ngModel)]="model.bank">
        <div *ngIf="formErrors.get('bankaccount.bank')" class="badge bg-danger animate__animated animate__fadeIn">
          {{ formErrors.get('bankaccount.bank') }}
        </div>
      </div>
      <div class="form-group  col-md-4">
        <label class="form-label" for="foundingSource">Fuente de Financiamiento</label>

        <select class="form-select" id="foundingSource" name="foundingSource" [(ngModel)]="model.foundingSource">
          <option value="001">Ley 6746</option>
          <option value="002">Ley 7552</option>
          <option value="003">Otros Ingresos</option>
          <option value="004">PANEA</option>
        </select>
        <div *ngIf="formErrors.get('bankaccount.foundingSource')" class="badge bg-danger animate__animated animate__fadeIn">
          {{ formErrors.get('bankaccount.foundingSource') }}
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="form-group  col-md-4">
        <label class="form-label" for="code">Cuenta Contable</label>
        <select class="form-select" [(ngModel)]="model.accountId"  >
          <option value=""></option>
          <option *ngFor="let account of selectableAccounts" [value]="account.id">{{account.description}} ({{account.code}})</option>
        </select>
        <div *ngIf="formErrors.get('bankaccount.accountId')" class="badge bg-danger animate__animated animate__fadeIn">
          {{ formErrors.get('bankaccount.accountId') }}
        </div>
      </div>
    </div>

  </div>

  <div *ngIf="mode == 'LIST' && !loading" class="animate__animated animate__fadeIn">

    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">
      <div class="container-fluid">
        <span class="navbar-text">
          Cuentas Bancarias
        </span>
        <div class="pull-right">
          <button class="btn btn-secondary me-1" (click)="refreshEvent()" title="Refrescar">
            <span class="fa fa-refresh fa-lg"></span>
          </button>
          <button class="btn btn-primary" (click)="createEvent()">
            Agregar
          </button>
        </div>
      </div>
    </nav>

    <mt-pagination (loadPage)="getPageEvent($event)" [page]="page"></mt-pagination>

    <table *ngIf="page.metadata.pages > 0" class="table table-hover">
      <thead>
      <tr>
        <td>Código</td>
        <td>Entidad Financiera</td>
        <td>Fuente de Financiamiento</td>
        <td></td>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let bankAccount of page.content">
        <td (click)="editEvent(bankAccount)" class="mt-content-col">
          {{bankAccount.number}}
        </td>
        <td (click)="editEvent(bankAccount)" class="mt-content-col">
          {{bankAccount.bank}}
        </td>
        <td (click)="editEvent(bankAccount)" class="mt-content-col">
          <span *ngIf="bankAccount.foundingSource == '001'" >Ley 6746</span>
          <span *ngIf="bankAccount.foundingSource == '002'" >Ley 7552</span>
          <span *ngIf="bankAccount.foundingSource == '003'" >Otros Ingresos</span>
          <span *ngIf="bankAccount.foundingSource == '004'" >PANEA</span>
        </td>
        <td class="mt-actions-col">
          <button class="btn btn-link btn-lg pull-right" data-bs-toggle="modal" data-bs-target="#removeCategoryConfirmation"
                  (click)="selectEvent(bankAccount)" title="Borrar">
            <span class="fa fa-trash-alt fa-lg"></span>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
    <div *ngIf="page.metadata.pages == 0">
      No hay registros
    </div>

  </div>

  <!-- Modal -->
  <div class="modal fade" id="removeCategoryConfirmation" tabindex="-1" role="dialog"
       aria-labelledby="removeConfirmationLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirmar</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Desea eliminar el registro?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="removeEvent(model)">Borrar</button>
        </div>
      </div>
    </div>
  </div>

</div>


