<ul class="navbar-nav">
  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="{{currentTenant.name + ' (' + currentTenant.id + ')'}}">
      {{currentTenant.name}}
    </a>
    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
      <button class="dropdown-item" data-bs-toggle="modal"  data-bs-target="#tenantSelectorDialog">
        Cambiar compañia...
      </button>
      <div class="dropdown-divider"></div>
      <button class="dropdown-item"  *mtHasPermission="['ADMIN_ACCOUNT']" routerLink="/company">
        Crear compañía...
      </button>
    </div>
  </li>
</ul>



<!--button class="btn btn-link" data-bs-toggle="modal" data-bs-backdrop="false" data-bs-target="#tenantSelectorDialog">{{currentTenant.name}}</button-->

<!--form class="form-inline" name="tenant-form">
  <div class="input-group me-1">
    <select id="tenant" [ngModel]="currentTenant.id" name="tenant" [disabled]="loadingTenants" >
      <option *ngFor="let tenant of tenants" (click)="setTenant(tenant)"  value="{{tenant.id}}">{{tenant.name}} <ng-container *ngIf="tenant.status=='PENDING_CONFIGURATION'"> *</ng-container> </option>
    </select>
    <div class="input-group-append">
      <button class="btn btn-secondary"  title="Agregar compañía"  data-bs-toggle="modal" data-bs-target="#createCompany" *mtHasPermission="['ADMIN_ACCOUNT']" routerLink="/company">
        <span class="fa fa-plus fa-lg "></span>
      </button>
    </div>
  </div>
</form-->

<div class="modal fade" id="tenantSelectorDialog" data-bs-backdrop="false" tabindex="-1" role="dialog" aria-labelledby="tenantSelectorDialog">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Seleccionar compañía activa</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <select id="tenant" [(ngModel)]="currentTenantId" name="tenant" [disabled]="loadingTenants" class="form-select" (ngModelChange)="setTenant(currentTenantId)">
          <option *ngFor="let tenant of tenants" [ngValue]="tenant.id">{{tenant.name}} <ng-container *ngIf="tenant.status=='PENDING_CONFIGURATION'"> *</ng-container> </option>
        </select>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cerrar</button>
      </div>
    </div>
  </div>
</div>

