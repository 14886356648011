import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {Bonus} from './resource/bonus';
import {BaseService} from '../../core/service/base.service';

@Injectable()
export class BonusService extends BaseService<Bonus> {

  serviceUrl(): string {
    return `/api/t/{tenant}/bonuses`;  }

  constructor(protected http: HttpClient) {
    super(http);
  }
}
