import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../core/component/base.component';
import {Department} from '../../service/resource/department';

import {AlertService} from '../../../core/service/alert.service';
import {MessageService} from '../../../core/service/message.service';
import {Router} from '@angular/router';
import {SecurityService} from '../../../core/service/security.service';
import {LoggerService} from '../../../service/logger.service';
import {IncomeTaxRangeService} from '../../service/income-tax-range.service';
import {IncomeTaxRange} from '../../service/resource/income-tax-range';

@Component({
  selector: 'app-income-tax-range',
  templateUrl: './income-tax-range.component.html'
})
export class IncomeTaxRangeComponent extends BaseComponent<IncomeTaxRange> implements OnInit, OnDestroy {

  constructor(private incomeTaxRangeService: IncomeTaxRangeService,
              private alertService: AlertService,
              protected messageService: MessageService,
              protected loggerService: LoggerService,
              protected router: Router,
              protected securityService: SecurityService) {
    super(incomeTaxRangeService, 'IncomeTaxRange', alertService, router, securityService, messageService, loggerService);
    this.sortBy = ['year', 'lowRange'];
    this.sortOrder = 'asc';
  }

  getModelInstance(): IncomeTaxRange {
    return new IncomeTaxRange();
  }

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
