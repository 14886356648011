import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../core/component/base.component';

import {AlertService} from '../../../core/service/alert.service';
import {MessageService} from '../../../core/service/message.service';
import {Router} from '@angular/router';
import {SecurityService} from '../../../core/service/security.service';
import {LoggerService} from '../../../service/logger.service';
import {Holiday} from '../../service/resource/holiday';
import {HolidayService} from '../../service/holiday.service';

@Component({
  selector: 'app-holiday',
  templateUrl: './holiday.component.html'
})
export class HolidayComponent extends BaseComponent<Holiday> implements OnInit, OnDestroy {

  constructor(private workdayService: HolidayService,
              private alertService: AlertService,
              protected messageService: MessageService,
              protected loggerService: LoggerService,
              protected router: Router,
              protected securityService: SecurityService) {
    super(workdayService, 'Holiday', alertService, router, securityService, messageService, loggerService);
  }

  getModelInstance(): Holiday {
    return new Holiday();
  }

  clearFormErrors(): Map<string, string> {
    return new Map<string, string>();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
