<div>

  <div class="loader" *ngIf="loading"></div>

  <div *ngIf="mode == 'LIST' && !loading" class="animate__animated animate__fadeIn">
    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">
      <div class="container-fluid">
        <form class="d-flex">
          <div class="input-group me-2">
            <input class="form-control" placeholder="aaaa-mm-dd"
                   name="from" [(ngModel)]="from" ngbDatepicker #d1="ngbDatepicker" id="from" (ngModelChange)="fromDateChanged($event)" title="Desde">
              <button class="btn btn-outline-secondary" (click)="d1.toggle()" type="button">
                <span class=" fa fa-calendar"></span>
              </button>
          </div>

          <div class="input-group me-2">
            <input class="form-control" placeholder="aaaa-mm-dd"
                   name="to" [(ngModel)]="to" ngbDatepicker #d2="ngbDatepicker" id="to" (ngModelChange)="toDateChanged($event)" title="Hasta">
              <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
                <span class=" fa fa-calendar"></span>
              </button>
          </div>
          <input class="form-control me-2" id="text" name="text" [(ngModel)]="text" (change)="getPageEvent(0)" title="Buscar..." placeholder="Buscar...">
        </form>
        <div class="pull-right">

          <div class="btn-group  me-1" role="group">
            <button id="btnGroupDrop1" type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              <span class="fa fa-download fa-lg"></span>
            </button>
            <div class="dropdown-menu dropdown-menu-end" aria-labelledby="btnGroupDrop1">
              <button class="dropdown-item" (click)="exportPendingAccountsReceivable()">Reporte CxC Pendientes</button>
              <button class="dropdown-item" (click)="exportAccountsReceivable()" >Reporte CxC</button>
            </div>
          </div>
          <button type="button" class="btn btn-secondary me-1" (click)="refreshEvent()" title="Refrescar">
            <span class="fa fa-sync fa-lg "></span>
          </button>
          <button class="btn btn-secondary me-1" data-bs-toggle="modal" data-bs-target="#loadFile" title="Cargar pagos">
            <span class="fa fa-upload fa-lg "></span>
          </button>
        </div>
      </div>
    </nav>

    <mt-pagination (loadPage)="getPageEvent($event)" [page]="page" [showPages]="false"></mt-pagination>

    <table *ngIf="page.metadata.pages > 0" class="table table-hover">
      <thead >
      <tr>
        <th scope="col">
          <input type="checkbox" [(ngModel)]="allSelected" (change)="changeSelection(allSelected)">
        </th>
        <th scope="col">
          Documento
        </th>
        <th>
          Receptor
        </th>
        <th scope="col">
          Fecha
        </th>
        <th scope="col">
          Tipo
        </th>
        <th scope="col">
          Condición Venta
        </th>
        <th scope="col" class="text-end">
          Total
        </th>
        <th scope="col" class="text-end">
          Saldo
        </th>
        <th>
        </th>
      </tr>
      </thead>
      <tbody>
      <ng-container  *ngFor="let document of page.content">
        <tr>
          <td class="align-middle">
            <input *ngIf="!document.processed" type="checkbox" [(ngModel)]="document.selected" (change)="changeSingleSelection(document.selected)"/>
          </td>
          <td class="mt-content-col align-middle">
            <span>{{document.sequence}}</span>
            <span class="badge bg-danger ms-2" *ngIf="document.processed" title="Procesado en Cierre">P</span>
          </td>
          <td class="mt-content-col align-middle">
            <span class="mt-large-text" [title]="document.receiver"> {{document.receiver}}</span>
          </td>
          <td  class="mt-content-col align-middle">
            <span>{{document.date.toString()  | date:'dd/MM/yyyy'}}</span>
          </td>
          <td class="mt-content-col align-middle">
            <span *ngIf="document.type == 'UNDEFINED'" >Sin Definir</span>
            <span *ngIf="document.type == 'INVOICE'" >Factura</span>
            <span *ngIf="document.type == 'PURCHASE_INVOICE'" >Factura de Compra</span>
            <span *ngIf="document.type == 'CREDIT_NOTE'" >Nota de Crédito</span>
            <span *ngIf="document.type == 'DEBIT_NOTE'" >Nota de Débito</span>
            <span *ngIf="document.type == 'TICKET'" >Tiquete Electrónico</span>
            <span *ngIf="document.type == 'EXPORT_INVOICE'" >Factura de Exportación</span>
            <span *ngIf="document.type == 'PAYMENT_DOCUMENT'" >Pago</span>
            <span *ngIf="document.type == 'EXTRA_PAYMENT'" >Saldo Favor</span>
            <span *ngIf="document.type == 'MANUAL_DOCUMENT'" >Ingreso Manual</span>
          </td>
          <td class="mt-content-col">
            {{getSellConditionDescription(document.sellCondition)}}
          </td>
          <td class="mt-content-col text-end align-middle">
            <span>{{document.total | currency:document.currency:'code'}}</span>
          </td>
          <td class="mt-content-col text-end align-middle">
            <span>{{document.pending | currency:document.currency:'code'}}</span>
          </td>
          <td class="text-end align-middle">
            <button class="btn btn-success  pull-right me-1" (click)="validatePayments(document.id)"
                    title="Validar pagos y pendiente">
              <span class="fas fa-file-circle-check fa-lg"></span>
            </button>
            <button *ngIf="document.type !== 'CREDIT_NOTE' && document.type !== 'DEBIT_NOTE'" class="btn btn-info pull-right me-1" (click)="loadReferences(document)"
                    title="Ver/buscar pagos">
              <span class="fas fa-file-invoice-dollar fa-lg"></span>
            </button>
            <button *ngIf="document.type !== 'CREDIT_NOTE' && document.type !== 'DEBIT_NOTE'" class="btn btn-secondary pull-right me-1" (click)="validateCreditNotes(document.key)"
                    title="Buscar nota de crédito o débito">
              <span class="fas fa-receipt fa-lg"></span>
            </button>
          </td>
        </tr>
        <tr class="table-secondary animate__animated animate__fadeIn" *ngFor="let referenceDocument of document.referencedBy">
          <td class="align-middle">
              >
          </td>
          <td class="mt-content-col align-middle">
            <span class="text-muted">{{referenceDocument.sequence}}</span>
          </td>
          <td class="mt-content-col align-middle">
            --
          </td>
          <td  class="mt-content-col align-middle">
            <span class="text-muted">{{referenceDocument.date.toString()  | date:'dd/MM/yyyy'}}</span>
          </td>
          <td class="mt-content-col align-middle">
            <span *ngIf="referenceDocument.type == 'UNDEFINED'" >Sin Definir</span>
            <span *ngIf="referenceDocument.type == 'INVOICE'" >Factura</span>
            <span *ngIf="referenceDocument.type == 'PURCHASE_INVOICE'" >Factura de Compra</span>
            <span *ngIf="referenceDocument.type == 'CREDIT_NOTE'" >Nota de Crédito</span>
            <span *ngIf="referenceDocument.type == 'DEBIT_NOTE'" >Nota de Débito</span>
            <span *ngIf="referenceDocument.type == 'TICKET'" >Tiquete Electrónico</span>
            <span *ngIf="referenceDocument.type == 'EXPORT_INVOICE'" >Factura de Exportación</span>
            <span *ngIf="referenceDocument.type == 'PAYMENT_DOCUMENT'" >Pago</span>
            <span *ngIf="referenceDocument.type == 'EXTRA_PAYMENT'" >Saldo Favor</span>
            <span *ngIf="referenceDocument.type == 'MANUAL_DOCUMENT'" >Ingreso Manual</span>
          </td>
          <td class="mt-content-col text-end align-middle">
            <span class="text-muted">{{referenceDocument.total | currency:referenceDocument.currency:'code'}}</span>
          </td>
          <td class="mt-content-col text-end align-middle">
            --
          </td>
          <td class="text-end align-middle">
            <button class="btn btn-danger pull-right" data-bs-toggle="modal" data-bs-target="#removeCategoryConfirmation"
                    (click)="selectDocuments(referenceDocument, document)" title="Desvincular pago">
              <span class="fa fa-unlink fa-lg"></span>
            </button>
          </td>
        </tr>
        <tr class="table-secondary animate__animated animate__fadeIn" *ngFor="let possiblePaymentDocument of document.possiblePayments">
          <td class="align-middle">
            ?
          </td>
          <td class="mt-content-col align-middle">
            <span class="text-muted">{{possiblePaymentDocument.sequence}}</span>
          </td>
          <td class="mt-content-col align-middle">
            --
          </td>
          <td  class="mt-content-col align-middle">
            <span class="text-muted">{{possiblePaymentDocument.date.toString()  | date:'dd/MM/yyyy'}}</span>
          </td>
          <td class="mt-content-col align-middle">
            <span *ngIf="possiblePaymentDocument.type == 'UNDEFINED'" >Sin Definir</span>
            <span *ngIf="possiblePaymentDocument.type == 'INVOICE'" >Factura</span>
            <span *ngIf="possiblePaymentDocument.type == 'PURCHASE_INVOICE'" >Factura de Compra</span>
            <span *ngIf="possiblePaymentDocument.type == 'CREDIT_NOTE'" >Nota de Crédito</span>
            <span *ngIf="possiblePaymentDocument.type == 'DEBIT_NOTE'" >Nota de Débito</span>
            <span *ngIf="possiblePaymentDocument.type == 'TICKET'" >Tiquete Electrónico</span>
            <span *ngIf="possiblePaymentDocument.type == 'EXPORT_INVOICE'" >Factura de Exportación</span>
            <span *ngIf="possiblePaymentDocument.type == 'PAYMENT_DOCUMENT'" >Pago</span>
            <span *ngIf="possiblePaymentDocument.type == 'EXTRA_PAYMENT'" >Saldo Favor</span>
            <span *ngIf="possiblePaymentDocument.type == 'MANUAL_DOCUMENT'" >Ingreso Manual</span>
          </td>
          <td class="mt-content-col text-end align-middle">
            <span class="text-muted">{{possiblePaymentDocument.total | currency:possiblePaymentDocument.currency:'code'}}</span>
          </td>
          <td class="mt-content-col text-end align-middle">
            --
          </td>
          <td class="text-end align-middle">
            <button  class="btn btn-success pull-right"
                    (click)="payDocument(possiblePaymentDocument, document)" title="Agregar pago">
              <span class="fa fa-plus fa-lg"></span>
            </button>
          </td>
        </tr>
        <tr class="table-secondary animate__animated animate__fadeIn" *ngIf="document.possiblePayments && document.possiblePayments.length === 0 && document.referencedBy && document.referencedBy.length === 0">
          <td class="align-middle" colspan="8">
            <span class="text-center">Sin pagos registrados ni saldos a favor</span>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
    <div *ngIf="page.metadata.pages == 0" class="p-3">
      No hay registros
    </div>

  </div>

  <!-- Modal -->
  <div class="modal fade" id="removeCategoryConfirmation" tabindex="-1" role="dialog"
       aria-labelledby="removeConfirmationLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirmar</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Desea desvincular el pago?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="unlinkDocument(selectedReferenceDocument, selectedDocument)">Desvincular</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="loadFile" tabindex="-1" role="dialog"
       aria-labelledby="loadFileLabel">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Cargar Archivo</h5>
        </div>
        <div class="modal-body">
          <p *ngIf="loading">Cargando archivo...</p>
          <p *ngIf="fileUploadErrors.length == 0 && !loading">Seleccione el archivo de pagos que desee cargar en el sistema en formato EXCEL. La primera linea debe contener los encabezados, en caso de no traerlos será ignorada. Debe contener las columnas en el siguiente orden:</p>

          <ol *ngIf="fileUploadErrors.length == 0 && !loading" class="list-group">
            <li class="list-group-item"><strong class="me-2">Número de documento</strong> <span class="badge bg-warning">requerido</span><br><small>Texto. Número de transferencia, depósito, cheque.</small></li>
            <li class="list-group-item"><strong class="me-2">Referencia</strong> <span class="badge bg-info">opcional</span><br><small>Texto. Secuencia de la factura a la  que corresponde el registro.</small></li>
            <li class="list-group-item"><strong class="me-2">Medio de Pago</strong> <span class="badge bg-warning">requerido</span><br><small>Texto. Efectivo = 01, Tarjeta = 02, Cheque = 03, Transferencia = 04, Recaudado = 05, Otros = 99</small></li>
            <li class="list-group-item"><strong class="me-2">Fecha</strong>  <span class="badge bg-warning">requerido</span><br><small>Fecha</small></li>
            <li class="list-group-item"><strong class="me-2">Monto</strong>  <span class="badge bg-warning">requerido</span><br><small>Numérico. Para documentos de pago en caso que sea mayor al saldo del documento que paga, se crea otro documento son el sobrante.</small></li>
            <li class="list-group-item"><strong class="me-2">Moneda</strong>  <span class="badge bg-warning">requerido</span><br><small>Texto. Debe ser la misma del documento que paga. Código de moneda: CRC, USD,...</small></li>
            <li class="list-group-item"><strong class="me-2">Tipo Cambio</strong>  <span class="badge bg-warning">requerido</span><br><small>Numérico. Tipo de cambio a utilizar, 1 para CRC</small></li>
            <li class="list-group-item"><strong class="me-2">Cuenta Bancaria</strong>  <span class="badge bg-info">opcional</span><br><small>Texto. Formato: 0.00.00.000.0000</small></li>
            <li class="list-group-item"><strong class="me-2">Cédula del cliente/proveedor</strong>  <span class="badge bg-warning">requerido</span><br> <small>Texto. Formato sin espacios ni guiones. Debe estar registrado como cliente previamente.</small></li>
            <li class="list-group-item"><strong class="me-2">Detalle</strong>  <span class="badge bg-info">opcional</span><br> <small>Texto. Detalle de la linea, se ignora para pagos.</small></li>
          </ol>
          <input type="file" #fileUpload id="fileUpload" name="fileUpload" (change)="onFileSelected($event)" multiple="multiple" style="display:none;" />
          <ul *ngIf="fileUploadErrors.length > 0 && !loading" class="list-group list-group-flush">
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Errores encontrados
              <span class="badge bg-danger bg-pill">{{fileUploadErrors.length}}</span>
            </li>
            <li *ngFor="let error of fileUploadErrors" class="list-group-item">
              <span>Línea {{error.line}}</span> <br> <small>{{error.message}}</small>
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" (click)="fileUpload.click()" title="Seleccionar..." [disabled]="loading">
            Seleccionar...
          </button>
          <button type="button" class="btn btn-link" data-bs-dismiss="modal" (click)="cleanData()" [disabled]="loading">Cerrar</button>
        </div>
      </div>
    </div>
  </div>

</div>


