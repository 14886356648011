import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Department} from './resource/department';
import {BaseService} from '../../core/service/base.service';
import {Observable} from 'rxjs';
import {HeaderConstants} from '../../core/utils/headers.constants';

@Injectable()
export class DepartmentService extends BaseService<Department> {

  serviceUrl(): string {
    return `/api/t/{tenant}/departments`;
  }

  constructor(protected http: HttpClient) {
    super(http);
  }

  getAll(refresh = false): Observable<Department[]> {
    const paramsData = new HttpParams();
    const options = {params: paramsData, headers: new HttpHeaders().set(HeaderConstants.CACHED, refresh ? 'false' : 'true')};
    return this.http
      .get<Department[]>(`${this.serviceUrl()}/all`, options );
  }
}
