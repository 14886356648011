import {Injectable} from '@angular/core';
import { filter, map } from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';

export interface Message {
  channel: string;
  data: any;
}

@Injectable()
export class MessageService {

  private message$: Subject<Message>;

  constructor() {
    this.message$ = new Subject<Message>();
  }

  public publish<T>(message: T): void {
    // @ts-ignore
    const channel = (message.constructor as any).name;
    this.message$.next({channel, data: message});
  }

  public of<T>(messageType: new(...args: any[]) => T): Observable<T> {
    const channel = (messageType as any).name;
    return this.message$.pipe(filter((m: Message) => m.channel === channel), map(m => m.data));
  }

}
