import {Injectable} from '@angular/core';

import {BaseService} from '../core/service/base.service';

import {HttpClient, HttpParams} from '@angular/common/http';
import {DocumentLine, DocumentLineCategorized, DocumentLineClassified} from '../model/document-line';
import {Observable} from 'rxjs';
import {DocumentLineFilter} from '../filter/document-line.filter';
import {Page} from '../model/page';

@Injectable()
export class DocumentLineService extends BaseService<DocumentLine> {

  serviceUrl(): string {
    return `/api/t/{tenant}/documents-lines`;
  }

  constructor(protected http: HttpClient) {
    super(http);
  }

  searchByFilterLoadDocument(filter: DocumentLineFilter, page: number = 0, size: number = 10,
                             sortBy?: string, sortOrder: string = 'desc'): Observable<Page<DocumentLine>> {
    let paramsValue = new HttpParams().append('page', page.toString()).append('size', size.toString());
    if (sortBy) {
      paramsValue = paramsValue.append('sort', `${sortBy},${sortOrder}`);
    }
    const options = {params: paramsValue};
    return this.http
      .post<Page<DocumentLine>>(`${this.serviceUrl()}/search-filter/document`, JSON.stringify(filter), options);
  }

  categorize(documentLines: DocumentLineCategorized[]): Observable<void> {
    return this.http
      .post<void>(`${this.serviceUrl()}/categorize`, JSON.stringify(documentLines));
  }

  classify(documentLines: DocumentLineClassified[]): Observable<void> {
    return this.http
      .post<void>(`${this.serviceUrl()}/classify`, JSON.stringify(documentLines));
  }

  configureBudget(documentLines: DocumentLineClassified[]): Observable<void> {
    return this.http
      .post<void>(`${this.serviceUrl()}/configure-budget`, JSON.stringify(documentLines));
  }


  exportToExcel(filter: DocumentLineFilter, format: string): Observable<any> {
    return this.http.post(`${this.serviceUrl()}/export?format=${format}`, JSON.stringify(filter), {responseType: 'blob'});
  }
}
