<div class="container">
  <div class="row justify-content-md-center animate__animated animate__fadeIn">
    <div class="col-md-6 col-md-offset-3">
        <div class="row">
          <div class="col-sm-6">
            <h4>Crear Compañía</h4>
          </div>
          <div class="col-sm-6">
            <div class="float-right">
              <button class="btn btn-primary" (click)="createCompany()">
                Guardar
              </button>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label" for="companyName">Nombre</label>
          <input type="text" class="form-control" id="companyName" name="companyName" [(ngModel)]="model.name"
                 autocomplete="organization">
          <div *ngIf="formErrors.get('tenant.name')" class="badge bg-danger animate__animated animate__fadeIn">
            {{ formErrors.get('tenant.name') }}
          </div>
        </div>
    </div>
  </div>


</div>


