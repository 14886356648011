import {Component, OnInit} from '@angular/core';
import {AlertService} from '../service/alert.service';
import {debounceTime} from 'rxjs/operators';


@Component({
  selector: 'mt-alert',
  templateUrl: 'alert.component.html',
  styleUrls: ['alert.component.css']
})
export class AlertComponent implements OnInit {

  message: {type: string, text: string} | null = null;
  animationClass = '';

  // toasts: any[] = [];
  // autohide = true;

  constructor(private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.alertService.getMessage().subscribe(message => {
      if (message && message.type) {
        this.animationClass = 'animate__fadeIn';
        this.message = message;
        // this.toasts.push(message);
      }
    });
    this.alertService.getSubject().pipe(debounceTime(5000)).subscribe(() => this.animationClass = 'invisible');
  }

  /*remove(toast: any): void {
    this.toasts = this.toasts.filter(t => t !== toast);
  }*/
  close(): void {
    this.animationClass = 'animate__fadeOutUp';
    this.message = null;
  }
}
