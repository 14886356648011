import {Injectable} from '@angular/core';

import {BaseService} from '../core/service/base.service';

import {HttpClient, HttpParams} from '@angular/common/http';

import {AcceptDocument, Document} from '../model/document';
import {Observable} from 'rxjs';
import {ComprobanteElectronico} from '../model/v4_3/comprobante-electronico';
import {MensajeHacienta} from '../model/v4_3/mensaje-hacienta';
import {DocumentFilter} from '../filter/document.filter';

@Injectable()
export class DocumentService extends BaseService<Document> {

  serviceUrl(): string {
    return `/api/t/{tenant}/documents`;
  }

  constructor(protected http: HttpClient) {
    super(http);
  }

  updateAccounts(documents: Document[]): Observable<void> {
    return this.http
      .post<void>(`${this.serviceUrl()}/accounts`, JSON.stringify(documents));
  }

  acceptDocuments(acceptData: AcceptDocument[]): Observable<void> {
    return this.http
      .post<void>(`${this.serviceUrl()}/accept`, JSON.stringify(acceptData));
  }

  importDocument(document: ComprobanteElectronico): Observable<void> {
    return this.http
      .post<void>(`${this.serviceUrl()}/import`, JSON.stringify(document));
  }

  importResponse(key: string): Observable<MensajeHacienta> {
    const paramsValue = new HttpParams().append('key', key);
    const options = {params: paramsValue};
    return this.http
      .post<MensajeHacienta>(`${this.serviceUrl()}/response/import`, '{}', options);
  }

  validateCreditNotes(key: string): Observable<MensajeHacienta> {
    const paramsValue = new HttpParams().append('key', key);
    const options = {params: paramsValue};
    return this.http
      .post<MensajeHacienta>(`${this.serviceUrl()}/payments/validate-credit-notes`, '{}', options);
  }

  exportPendingAccountsReceivable(filter: DocumentFilter): Observable<any> {
    return this.http.post(`${this.serviceUrl()}/export/pending-accounts-receivable`, JSON.stringify(filter), {responseType: 'blob'});
  }

  export(filter: DocumentFilter, page = 0, size = 10, sortBy?: string, sortOrder = 'desc'): Observable<any> {
    let paramsValue = new HttpParams().append('page', page.toString()).append('size', size.toString());
    if (sortBy) {
      paramsValue = paramsValue.append('sort', `${sortBy},${sortOrder}`);
    }
    return this.http.post(`${this.serviceUrl()}/export`, JSON.stringify(filter), {params: paramsValue, responseType: 'blob'});
  }

  exportAccountsReceivable(filter: DocumentFilter): Observable<any> {
    return this.http.post(`${this.serviceUrl()}/export/accounts-receivable`, JSON.stringify(filter), {responseType: 'blob'});
  }

  getNotAppliedByIssuerId(issuerId: string): Observable<Document[]> {
    return this.http
      .get<Document[]>(`${this.serviceUrl()}/not-applied/${issuerId}`);
  }

  payDocument(paymentId: number, documentId: number): Observable<any> {
    return this.http.post(`${this.serviceUrl()}/${documentId}/pay/${paymentId}`, '{}');
  }

  unlinkPayment(paymentId: number, documentId: number): Observable<any> {
    return this.http.post(`${this.serviceUrl()}/${documentId}/unlink/${paymentId}`, '{}');
  }

  validatePayments(id: number): Observable<any>  {
    const paramsValue = new HttpParams().append('id', id);
    const options = {params: paramsValue};
    return this.http
      .post(`${this.serviceUrl()}/payments/validate-payments`, '{}', options);
  }
}
