<div class="container">

  <div class="loader" *ngIf="loading"></div>

  <div *ngIf="mode == 'EDIT' && !loading" class="animated fadeIn">
    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top">
      <div class="container">
        <span class="navbar-text">
          Posición Laboral
        </span>
        <div class="pull-right">
          <button class="btn btn-link" (click)="cancelEvent()">
            Cerrar
          </button>
          <button class="btn btn-primary" (click)="saveEvent()">
            Guardar
          </button>
        </div>
      </div>
    </nav>

    <div class="row mb-3">
      <div class="form-group">
        <label for="name">Nombre</label>
        <input type="text" class="form-control" id="name" name="name" [(ngModel)]="model.name">
        <div *ngIf="formErrors.get('jobposition.name')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('jobposition.name') }}
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="form-group col-md-6">
        <label for="salaryMin">Salario Mínimo</label>
        <input type="text" class="form-control" id="salaryMin" name="salaryMin" [(ngModel)]="model.salaryMin">
        <div *ngIf="formErrors.get('jobposition.salaryMin')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('jobposition.salaryMin') }}
        </div>
      </div>
      <div class="form-group col-md-6">
        <label for="salaryMax">Salario Máximo</label>
        <input type="text" class="form-control" id="salaryMax" name="salaryMax" [(ngModel)]="model.salaryMax">
        <div *ngIf="formErrors.get('jobposition.salaryMax')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('jobposition.salaryMax') }}
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="mode == 'LIST' && !loading" class="animated fadeIn">

    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top">
      <div class="container">
        <span class="navbar-text">
          Posiciones Laborales
        </span>
        <div class="pull-right">
          <button class="btn btn-link" (click)="refreshEvent()" title="Refrescar">
            <span class="fa fa-sync fa-lg"></span>
          </button>
          <button class="btn btn-primary" (click)="createEvent()">
            Agregar
          </button>
        </div>
      </div>
    </nav>

    <mt-pagination (loadPage)="getPageEvent($event)" [page]="page"></mt-pagination>

    <table *ngIf="page.metadata.pages > 0" class="table table-hover">
      <tbody>
      <tr *ngFor="let department of page.content">
        <td (click)="editEvent(department)" class="mt-content-col">
          {{ department.name }}
        </td>
        <td class="mt-actions-col">
          <button class="btn btn-link btn-lg pull-right" data-bs-toggle="modal"
                  data-bs-target="#removeJobPositionConfirmation"
                  (click)="selectEvent(department)" title="Borrar">
            <span class="fa fa-trash-alt fa-lg"></span>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
    <div *ngIf="page.metadata.pages == 0">
      No hay registros
    </div>

  </div>

  <!-- Modal -->
  <div class="modal fade" id="removeJobPositionConfirmation" tabindex="-1" role="dialog"
       aria-labelledby="removeConfirmationLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirmar</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Desea eliminar el registro?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="removeEvent(model)">Borrar
          </button>
        </div>
      </div>
    </div>
  </div>

</div>


