import {Component, OnDestroy, OnInit} from '@angular/core';
import {SecurityService} from './core/service/security.service';
import {User} from './core/model/user';
import {MessageService} from './core/service/message.service';
import {LoginMessage} from './core/message/login.message';
import {LogoutMessage} from './core/message/logout.message';
import {environment} from '../environments/environment';
import {Router} from '@angular/router';
import {Configuration} from './model/client';

import ServiceUtils from './core/utils/service.utils';
import {UserChangedMessage} from './core/message/user-changed.message';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  user: User | null = null;
  loggedIn = false;

  loginMessageSubscription: any;
  logoutMessageSubscription: any;
  userMessageSubscription: any;

  generateValueAddedTax = false;
  publicInstitution = false;

  env = environment;

  constructor(private messageService: MessageService,
              private securityService: SecurityService,
              public router: Router) {
  }

  ngOnInit(): void {
    this.loginMessageSubscription = this.messageService.of(LoginMessage).subscribe(complete => {
      this.loggedIn = true;
      this.reloadData();
    });
    this.logoutMessageSubscription = this.messageService.of(LogoutMessage).subscribe(complete => {
      this.user = null;
      this.loggedIn = false;
    });

    this.userMessageSubscription = this.messageService.of(UserChangedMessage).subscribe(complete => {
      this.reloadData();
    });

    this.reloadData();
  }

  ngOnDestroy(): void {
    this.loginMessageSubscription.unsubscribe();
    this.logoutMessageSubscription.unsubscribe();
    this.userMessageSubscription.unsubscribe();
  }

  setUser(user: User): void {
    this.user = user;
  }

  logout(): void {
    this.user = null;
    this.securityService.logout();
  }

  private reloadData(): void {
    const userFromStorage = ServiceUtils.getItem('user');
    if (userFromStorage) {
      this.setUser(JSON.parse(userFromStorage));
      this.loggedIn = true;
    }
    const configurationFromStorage = ServiceUtils.getItem('configuration');
    if (configurationFromStorage) {
      const configuration = JSON.parse(configurationFromStorage) as Configuration;
      if (configuration.publicInstitution !== undefined) {
        this.publicInstitution = configuration.publicInstitution;
      } else {
        this.publicInstitution = false;
      }
      if (configuration.generateValueAddedTax !== undefined) {
        this.generateValueAddedTax = configuration.generateValueAddedTax;
      } else {
        this.generateValueAddedTax = false;
      }
    }
  }
}
