import {AlertService} from '../service/alert.service';
import {Router} from '@angular/router';
import {SecurityService} from '../service/security.service';
import {HttpErrorResponse} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {LoggerService} from '../../service/logger.service';

export abstract class CoreComponent {

  formErrors = this.clearFormErrors();

  loading = false;

  subscriptions: Subscription[] = [];

  constructor(protected alert: AlertService,
              protected router: Router,
              protected securityService: SecurityService,
              protected loggerService: LoggerService) {
  }

  private showFieldsErrors(json: any): void {
    for (const error of json) {
      const key = error.name as string;
      const value = error.message as string;
      this.formErrors.set(key, value);
    }
  }

  clearFormErrors(): Map<string, string>{
    return new Map<string, string>();
  }

  logout(): void {
    this.securityService.logout();
  }

  protected async processError(errorResponse: HttpErrorResponse | any): Promise<void> {
    this.alert.clear();
    this.loggerService.error([errorResponse.status, errorResponse.error, errorResponse.headers, errorResponse.url]);
    this.loading = false;
    try {
      if (errorResponse.status === 504 || errorResponse.status === 0) {
        this.alert.error('Error de comunicación con el servidor, verifique su conexión a internet e intente nuevamente.');
      } else if (errorResponse.status === 401) {
        console.error('got 401 login out');
        this.logout();
      } else if (errorResponse.status === 403) {
        this.alert.error('No tiene permisos para realizar la operación solicitada');
      } else {
        let json = (typeof errorResponse.error === 'string') ? JSON.parse(errorResponse.error) : errorResponse.error;
        if (errorResponse.error instanceof Blob) {
          json = JSON.parse(await errorResponse.error.text());
        }
        if (json.error === 'INVALID_VALUES') {
          this.alert.error('Error en los valores suministrados');
          this.showFieldsErrors(json.fields);
        } else if (json.error === 'DATA_INTEGRITY_VIOLATION') {
          if (json.type === 'UNIQUE') {
            if (json.constraintName === 'client_account__tenant_id__code__idx') {
              this.alert.error('El código de cuenta ya existe, favor utilizar un nuevo código.');
            } else {
              this.alert.error('El registro está duplicado, actualizar el valor e intentar nuevamente.');
            }
          } else {
            if (json.constraintName === 'document_line__counter_part_account__client_account__fk' ||
              json.constraintName === 'document_line__tax_registry_account__client_account__fk' ||
              json.constraintName === 'document_line__account__client_account__fk') {
              this.alert.error('La cuenta o alguna subcuenta está siendo utilizada en clasificación de documentos');
            } else {
              this.alert.error('El registro está siendo utilizado, elimine la referencia e intente nuevamente');
            }
          }
        } else if (json.error === 'BAD_CREDENTIALS') {
          this.alert.error(`Credenciales inválidas`);
        } else if ([
          'CONFIRMATION_PASSWORD_NOT_MATCH',
          'SINGLE_TENANT_USER',
          'DUPLICATED_EMAIL',
          'INVALID_CONFIRMATION_CODE',
          'INVALID_PASSWORD',
          'INCOMPLETE_INVOICING_CONFIGURATION',
          'INVALID_ACCOUNT_CODE',
          'PENDING_ACCOUNTS_CONFIGURATION',
          'PENDING_DOCUMENT_CLASSIFICATIONS',
          'DOCUMENT_ALREADY_EXIST',
          'INVALID_DOCUMENT',
          'INVALID_REQUEST_DOCUMENT_NOT_FOUND',
          'INVALID_REQUEST',
          'NOT_SUPPORT_FILE'
        ].includes(json.error)) {
          this.alert.error(json.message);
        } else {
          console.error(errorResponse);
          this.alert.error('Error no esperado.');
        }
      }
    } catch (e) {
      this.alert.error(errorResponse.message);
    }
  }
}
