<div class="container">
  <div class="loader" *ngIf="loading"></div>
  <div class="row justify-content-md-center animate__animated animate__fadeIn" *ngIf="!loading">
    <div class="col-md-6 col-md-offset-3">
      <div class="row">
        <div class="col-sm-6">
          <h4>Actualizar Contraseña</h4>
        </div>
        <div class="col-sm-6">
          <div class="float-right">
            <button class="btn btn-link" routerLink="/login">
              Volver
            </button>
            <button class="btn btn-primary" (click)="registerUser()">
              Enviar
            </button>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label" for="password">Nueva Contraseña</label>
        <input type="password" class="form-control" id="password" name="password" [(ngModel)]="model.password">
        <div *ngIf="formErrors.get('password')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('password') }}
        </div>
      </div>
      <div class="form-group">
        <label class="form-label" for="passwordConfirmation">Confirmar Contraseña</label>
        <input type="password" class="form-control" id="passwordConfirmation" name="passwordConfirmation"
               [(ngModel)]="model.passwordConfirmation">
        <div *ngIf="formErrors.get('passwordConfirmation')" class="badge bg-danger animated fadeIn">
          {{ formErrors.get('passwordConfirmation') }}
        </div>
      </div>
      <div class="row">
      <div class="col-sm-12">
        La contraseña debe:
        <ul>
          <li>Tener longitud de al menos ocho caracteres</li>
          <li>Contener al menos un número</li>
          <li>Contener al menos una letra</li>
        </ul>
      </div>
  </div>
    </div>
  </div>
</div>
