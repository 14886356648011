<div class="container">

  <div class="loader" *ngIf="loading"></div>

  <div *ngIf="!loading" class="animate__animated animate__fadeIn">
    <nav class="navbar navbar-light bg-light fixed-top mt-fixed-top shadow-sm">
      <div class="container-fluid">
        <span class="navbar-text">
          Formulario D101
        </span>
      </div>
    </nav>

    <div>
      <h4  class="mb-3 mt-3">Llenar Formulario D101</h4>
      <hr class="mb-4">
      <p class="alert alert-info">Llenar formulario D101 para el periodo actual. Se incluyen datos de las cuentas para los asientos de los meses cerrados.</p>
      <div class="row">
        <div class="col-12 text-center">
          <button class="btn btn-warning me-2" data-bs-toggle="modal" data-bs-target="#loadFile" title="Llenar Formulario">
            Cargar Formulario
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="loadFile" tabindex="-1" role="dialog"
     aria-labelledby="loadFileLabel">
  <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Carga Archivo</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <p>Seleccione la última version del formulario 101 suministrado por hacienda en formato xlsm</p>

        <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" style="display:none;" />
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" (click)="fillDocument()" title="Seleccionar..." data-bs-dismiss="modal">
          Seleccionar...
        </button>
        <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cerrar</button>
      </div>
    </div>
  </div>
</div>

